define("plutof/components/clipboard/clipboard-button", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/utils", "@ember-decorators/component", "@ember-decorators/object", "plutof/misc/clipboard", "plutof/misc/content_types", "plutof/utils/notifications"], function (_exports, _component, _object, _service, _utils, _component2, _object2, _clipboard, _content_types, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ClipboardButton = (_dec = (0, _component2.tagName)('span'), _dec2 = (0, _object2.observes)('object'), _dec3 = (0, _object2.observes)('content_type', 'object_id'), _dec4 = (0, _object.computed)('status.included', 'isLoading'), _dec(_class = (_class2 = class ClipboardButton extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      // Can pass either object or ctype/id pair to the view
      _defineProperty(this, "object", null);
      _defineProperty(this, "content_type", null);
      _defineProperty(this, "object_id", null);
      _defineProperty(this, "status", null);
      _defineProperty(this, "isLoading", false);
    }
    init() {
      super.init(...arguments);
      this.ctype_id_setter();
      this.initial_load();
    }
    ctype_id_setter() {
      const object = this.object;
      if (!(0, _utils.isNone)(object)) {
        this.set('isLoading', true);
        (0, _content_types.get_object_ctype)(this.store, object).then(ctype => {
          if (!this.isDestroyed) {
            this.set('content_type', ctype);
            this.set('object_id', object.get('id'));
          }
          this.set('isLoading', false);
        }).catch(_notifications.reportError);
      }
    }
    initial_load() {
      const ctype = this.content_type;
      const id = this.object_id;
      if (!(0, _utils.isNone)(ctype) && !(0, _utils.isNone)(id)) {
        const status = (0, _clipboard.get_clipboard_status)(this.ajax, this.store, ctype, id);
        this.set('isLoading', true);
        status.check(this.object).then(() => {
          this.set('status', status);
          this.set('isLoading', false);
        });
      }
    }
    get iconClass() {
      if (this.isLoading) {
        return 'loading-icon';
      } else if (this.get('status.included')) {
        return 'icon-clipboard-remove clickable';
      } else {
        return 'icon-clipboard-add clickable';
      }
    }
    toggleInclusion() {
      const status = this.status;
      if (this.isLoading) {
        return;
      }
      if (!(0, _utils.isNone)(status) && status.get('checked')) {
        this.set('isLoading', true);
        status.change(!status.get('included')).then(() => {
          this.set('isLoading', false);
          // TODO Need I update object's on_clipboard state?
        });
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "ctype_id_setter", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "ctype_id_setter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "initial_load", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "initial_load"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "iconClass", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "iconClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleInclusion", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleInclusion"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = ClipboardButton;
});