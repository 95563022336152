define("plutof/components/collection-lab/view/treatments", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/service", "ember-concurrency", "plutof/utils/pagination", "plutof/misc/content_types"], function (_exports, _component, _templateFactory, _object, _service, _emberConcurrency, _pagination, _content_types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::View
      @title={{i18n-t "CollectionManagement.treatmentsTitle"}}
      @pagination={{this.pagination}}
      @add={{this.add}}
      @canModify={{true}}
  >
      <RelatedObjects::Content @pagination={{this.pagination}} as |Field treatment|>
          <Field @label={{backend-translations/label "treatment.carried_out_by"}}>
              <LinkTo @route="treatment.view" @model={{treatment.id}}>
                  {{treatment.carried_out_by.representation}}
              </LinkTo>
          </Field>
  
          <Field @label={{backend-translations/label "treatment.start_date"}}>
              {{treatment.start_date}}
          </Field>
  
          <Field @label={{backend-translations/label "treatment.end_date"}}>
              {{treatment.end_date}}
          </Field>
  
          <Field @label={{backend-translations/label "treatment.description"}}>
              {{treatment.description}}
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "k3O1EHra",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@pagination\",\"@add\",\"@canModify\"],[[28,[37,1],[\"CollectionManagement.treatmentsTitle\"],null],[30,0,[\"pagination\"]],[30,0,[\"add\"]],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@pagination\"],[[30,0,[\"pagination\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1],null,[[\"@label\"],[[28,[37,3],[\"treatment.carried_out_by\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"treatment.view\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,2,[\"carried_out_by\",\"representation\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1],null,[[\"@label\"],[[28,[37,3],[\"treatment.start_date\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"start_date\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1],null,[[\"@label\"],[[28,[37,3],[\"treatment.end_date\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"end_date\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1],null,[[\"@label\"],[[28,[37,3],[\"treatment.description\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"description\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[1,2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"Field\",\"treatment\"],false,[\"related-objects/view\",\"i18n-t\",\"related-objects/content\",\"backend-translations/label\",\"link-to\"]]",
    "moduleName": "plutof/components/collection-lab/view/treatments.hbs",
    "isStrictMode": false
  });
  let ViewTreatments = _exports.default = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class ViewTreatments extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "load", _descriptor2, this);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.load.perform();
    }
    add() {
      return this.router.transitionTo('treatment.add', {
        queryParams: {
          occurrenceId: this.object.id,
          occurrenceType: this.occurrenceType
        }
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "load", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const ctype = yield (0, _content_types.get_object_ctype)(this.store, this.object);
        const pagination = yield (0, _pagination.paginateModel)(this.store, 'collection-lab/treatment', {
          content_type: ctype.id,
          object_id: this.object.id
        });
        this.set('pagination', pagination);
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewTreatments);
});