define("plutof/components/coordinate-converter", ["exports", "@ember/application", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/utils", "@ember-decorators/component", "@ember-decorators/object", "ember-cp-validations", "plutof/misc/config", "plutof/utils/coordinates", "plutof/utils/string"], function (_exports, _application, _component, _object, _computed, _runloop, _utils, _component2, _object2, _emberCpValidations, _config, _coordinates, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CoordinateConverterData = void 0;
  _exports.createCoordinateConverterData = createCoordinateConverterData;
  _exports.default = void 0;
  var _dec, _class, _dec2, _dec3, _dec4, _dec5, _dec6, _class2, _class3, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var LAT_REGEXP = new RegExp('^-?(\\d|[0-8]\\d|90)(\\.(\\d{1,8}))?$');
  var LON_REGEXP = new RegExp('^-?(\\d{1,2}|1[0-7]\\d|180)(\\.(\\d{1,8}))?$');
  function numberBetween(min, max) {
    let integer = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    return (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true,
      gte: min,
      lte: max,
      integer: integer
    });
  }
  function roundPreservingZeroes(n, places) {
    let s = n.toString();
    if (!s.includes('.')) {
      return s;
    }
    let [integer, fraction] = s.split('.');
    if (fraction.length > places) {
      const rounding = parseInt(fraction[places]) > 4 ? 1 : 0;
      const roundedFractionTail = (parseInt(fraction.substring(0, places)) + rounding).toString();
      const leadingZeroes = (0, _string.repeat)('0', places - roundedFractionTail.length);
      fraction = leadingZeroes + roundedFractionTail;
    }
    return `${integer}.${fraction}`;
  }
  function toString(n) {
    return (0, _utils.isEmpty)(n) ? '' : n.toString();
  }
  const CC_INPUTS = ['latitude', 'longitude', 'degree1', 'minute1', 'second1', 'degree2', 'minute2', 'second2', 'latitudeDeg', 'latitudeMin', 'longitudeDeg', 'longitudeMin'];
  const Validations = (0, _emberCpValidations.buildValidations)({
    latitude: (0, _emberCpValidations.validator)('format', {
      regex: LAT_REGEXP,
      allowBlank: true
    }),
    longitude: (0, _emberCpValidations.validator)('format', {
      regex: LON_REGEXP,
      allowBlank: true
    }),
    latitudeDeg: numberBetween(-90, 90),
    latitudeMin: numberBetween(0, 60, false),
    longitudeDeg: numberBetween(-180, 180),
    longitudeMin: numberBetween(0, 60, false),
    degree1: numberBetween(-90, 90),
    minute1: numberBetween(0, 60),
    second1: numberBetween(0, 60, false),
    degree2: numberBetween(-180, 180),
    minute2: numberBetween(0, 60),
    second2: numberBetween(0, 60, false)
  });
  let CoordinateConverterData = _exports.CoordinateConverterData = (_dec = (0, _object2.observes)('validations.isValid'), _class = class CoordinateConverterData extends _object.default.extend(Validations) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "format", 'decimal');
      _defineProperty(this, "latitude", null);
      _defineProperty(this, "longitude", null);
    }
    init() {
      super.init(...arguments);
      this.coordinatesAreValid();
    }
    setCoordinates(lat, lon) {
      this.switchFormat('decimal');
      this.setProperties({
        latitude: lat,
        longitude: lon
      });
    }
    switchFormat(format) {
      if (format === this.format) {
        return;
      }
      this.clearInputs();
      this.set('format', format);
      this.set('latitude', null);
      this.set('longitude', null);
    }
    clearInputs() {
      CC_INPUTS.forEach(input => this.set(input, null));
    }
    extractCoordinates() {
      const {
        lat,
        lon
      } = this.convert();
      if ((0, _utils.isEmpty)(lat) || (0, _utils.isEmpty)(lon)) {
        return {
          lat: null,
          lon: null
        };
      }
      const precision = _config.default.Sample.COORDINATE_DECIMAL_PLACES;
      return {
        lat: roundPreservingZeroes(lat, precision),
        lon: roundPreservingZeroes(lon, precision)
      };
    }
    convert() {
      const format = this.format;
      if (!this.get('validations.isValid')) {
        return {
          lat: null,
          lon: null
        };
      }

      // TODO: Stringify and round
      switch (format) {
        case 'decimal':
          return {
            lat: this.latitude,
            lon: this.longitude
          };
        case 'dm':
          return this.convertDM();
        case 'dms':
          return this.convertDMS();
        default:
          {
            throw `Unknown format: ${format}`;
          }
      }
    }
    convertDM() {
      const latitudeDMS = {
        degrees: this.latitudeDeg,
        minutes: this.latitudeMin,
        seconds: 0
      };
      const longitudeDMS = {
        degrees: this.longitudeDeg,
        minutes: this.longitudeMin,
        seconds: 0
      };
      return this.convertCoords(latitudeDMS, longitudeDMS);
    }
    convertDMS() {
      const latitudeDMS = {
        degrees: this.degree1,
        minutes: this.minute1,
        seconds: this.second1
      };
      const longitudeDMS = {
        degrees: this.degree2,
        minutes: this.minute2,
        seconds: this.second2
      };
      return this.convertCoords(latitudeDMS, longitudeDMS);
    }
    convertCoords(latitudeDMS, longitudeDMS) {
      if ((0, _utils.isEmpty)(latitudeDMS.degrees) || (0, _utils.isEmpty)(longitudeDMS.degrees)) {
        return {
          lat: null,
          lon: null
        };
      }

      // Check for minus sign in string form because of points like -00:10:00.
      // If we just parse degrees here, minus is lost. So we first look at the input
      // as a string
      const latitudeNegative = latitudeDMS.degrees[0] === '-';
      latitudeDMS.degrees = parseInt(latitudeDMS.degrees);
      latitudeDMS.minutes = latitudeDMS.minutes || 0;
      latitudeDMS.seconds = latitudeDMS.seconds || 0;
      const longitudeNegative = longitudeDMS.degrees[0] === '-';
      longitudeDMS.degrees = parseInt(longitudeDMS.degrees);
      longitudeDMS.minutes = longitudeDMS.minutes || 0;
      longitudeDMS.seconds = longitudeDMS.seconds || 0;
      return {
        lat: (0, _coordinates.DMSToDecimal)(latitudeDMS, latitudeNegative),
        lon: (0, _coordinates.DMSToDecimal)(longitudeDMS, longitudeNegative)
      };
    }

    // Validations
    coordinatesAreValid() {
      this.set('validity', this.get('validations.isValid'));
    }
  }, _applyDecoratedDescriptor(_class.prototype, "coordinatesAreValid", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "coordinatesAreValid"), _class.prototype), _class); // Owner is something managed by Ember DI container: route/controller/component etc
  function createCoordinateConverterData(owner) {
    let props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const container = (0, _application.getOwner)(owner).ownerInjection();
    return CoordinateConverterData.create(container, Object.assign({
      format: 'decimal',
      validity: true
    }, props));
  }
  let CoordinateConverterComponent = (_dec2 = (0, _component2.classNames)('coordinate-converter'), _dec3 = (0, _computed.equal)('geometryData.format', 'decimal'), _dec4 = (0, _computed.equal)('geometryData.format', 'dm'), _dec5 = (0, _computed.equal)('geometryData.format', 'dms'), _dec6 = (0, _object2.observes)('lat', 'lon'), _dec2(_class2 = (_class3 = class CoordinateConverterComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "decimalActive", _descriptor, this);
      _initializerDefineProperty(this, "dmActive", _descriptor2, this);
      _initializerDefineProperty(this, "dmsActive", _descriptor3, this);
    }
    tabChanged(format) {
      this.geometryData.switchFormat(format);
    }
    init() {
      super.init(...arguments);

      // XXX
      if ((0, _utils.isNone)(this.geometryData)) {
        this.set('geometryData', createCoordinateConverterData(this));
      }
      this.argumentsChanged();
    }
    copyLatlonToData() {
      const {
        lat,
        lon
      } = this.getProperties(['lat', 'lon']);
      const coords = this.geometryData.extractCoordinates();
      if (coords.lat !== lat || coords.lon !== lon) {
        this.geometryData.setCoordinates(toString(lat), toString(lon));
      }
    }
    extractLatLon() {
      const geometryData = this.geometryData;

      // This can trigger during the destruction of the component, need to guard against that
      if ((0, _utils.isNone)(geometryData)) {
        return;
      }
      const coords = geometryData.extractCoordinates();
      if (coords.lat !== this.lat || coords.lon !== this.lon) {
        this.updated(coords.lat, coords.lon);
      }
    }

    // XXX This triggers twice when the point tool is used.
    argumentsChanged() {
      (0, _runloop.once)(this, 'copyLatlonToData');
    }
    setActive(format) {
      if (this.disabled) {
        return;
      }
      this.geometryData.switchFormat(format);
    }
    changed() {
      (0, _runloop.debounce)(this, 'extractLatLon', 1250);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class3.prototype, "decimalActive", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "dmActive", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "dmsActive", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "argumentsChanged", [_dec6], Object.getOwnPropertyDescriptor(_class3.prototype, "argumentsChanged"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "setActive", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "setActive"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "changed", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "changed"), _class3.prototype), _class3)) || _class2);
  var _default = _exports.default = CoordinateConverterComponent;
});