define("plutof/models/taxonoccurrence/sequence/sequence", ["exports", "@ember/object", "@ember/utils", "@ember/object/computed", "ember-data", "@ember-data/model", "rsvp", "plutof/misc/abstract", "plutof/misc/content_types", "plutof/mixins/model_mixins", "plutof/models/taxonoccurrence/taxonoccurrence-model", "plutof/utils/model", "plutof/utils/promises"], function (_exports, _object, _utils, _computed, _emberData, _model, _rsvp, _abstract, _content_types, _model_mixins, _taxonoccurrenceModel, _model2, _promises) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UNITEStatus = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  async function loadParent(store, content_type, object_id) {
    const contentType = await content_type;
    return (0, _content_types.resolve_generic_link)(store, contentType, object_id);
  }
  const UNITEStatus = _exports.UNITEStatus = {
    Unused: 0,
    WaitingForCode: 1,
    Verified: 2,
    Failed: 3
  };
  let Sequence = (_dec = (0, _model.belongsTo)('taxonoccurrence/sequence/sequence', {
    inverse: null
  }), _dec2 = (0, _model.belongsTo)('measurement/mainform'), _dec3 = (0, _model.belongsTo)('contenttype'), _dec4 = (0, _model.attr)('number'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('number'), _dec7 = (0, _model.belongsTo)('taxonoccurrence/sequence/source'), _dec8 = (0, _model.attr)('text'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('boolean'), _dec14 = (0, _model.attr)('enum', {
    defaultValue: UNITEStatus.Unused
  }), _dec15 = (0, _model.attr)('boolean'), _dec16 = (0, _model.attr)('boolean'), _dec17 = (0, _model.attr)('boolean'), _dec18 = (0, _model.attr)('boolean'), _dec19 = (0, _model.attr)('boolean'), _dec20 = (0, _model.belongsTo)('taxonoccurrence/sequence/insdsequencedata'), _dec21 = (0, _model.attr)('boolean'), _dec22 = (0, _model.attr)('number'), _dec23 = (0, _model.attr)('boolean'), _dec24 = (0, _object.computed)('content_type', 'object_id'), _dec25 = (0, _model2.privateGuard)('parentObject'), _dec26 = (0, _object.computed)('id'), _dec27 = (0, _object.computed)('sequence_ids'), _dec28 = (0, _object.computed)('id'), _dec29 = (0, _computed.equal)('unite_status', UNITEStatus.Verified), _dec30 = (0, _model.attr)('string'), _dec31 = (0, _object.computed)('compound_id'), _class = class Sequence extends _taxonoccurrenceModel.default.extend(_model_mixins.DeterminationsMixin) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "parent_sequence", _descriptor, this);
      _initializerDefineProperty(this, "mainform", _descriptor2, this);
      _initializerDefineProperty(this, "content_type", _descriptor3, this);
      _initializerDefineProperty(this, "object_id", _descriptor4, this);
      _initializerDefineProperty(this, "sequence", _descriptor5, this);
      _initializerDefineProperty(this, "seq_length", _descriptor6, this);
      _initializerDefineProperty(this, "seq_source", _descriptor7, this);
      _initializerDefineProperty(this, "remarks", _descriptor8, this);
      _initializerDefineProperty(this, "forw_primer_name", _descriptor9, this);
      _initializerDefineProperty(this, "forw_primer_sequence", _descriptor10, this);
      _initializerDefineProperty(this, "rev_primer_name", _descriptor11, this);
      _initializerDefineProperty(this, "rev_primer_sequence", _descriptor12, this);
      _initializerDefineProperty(this, "is_available_for_unite", _descriptor13, this);
      _initializerDefineProperty(this, "unite_status", _descriptor14, this);
      _initializerDefineProperty(this, "unitestatus", _descriptor15, this);
      _initializerDefineProperty(this, "chimeric_status", _descriptor16, this);
      _initializerDefineProperty(this, "quality_status", _descriptor17, this);
      _initializerDefineProperty(this, "rev_comp_status", _descriptor18, this);
      _initializerDefineProperty(this, "insdstatus", _descriptor19, this);
      _initializerDefineProperty(this, "insd_sequence_data", _descriptor20, this);
      _initializerDefineProperty(this, "on_clipboard", _descriptor21, this);
      _initializerDefineProperty(this, "download_count", _descriptor22, this);
      _initializerDefineProperty(this, "is_parent_event", _descriptor23, this);
      _initializerDefineProperty(this, "guardedParent", _descriptor24, this);
      _initializerDefineProperty(this, "inUNITE", _descriptor25, this);
      _defineProperty(this, "modelType", 'sequence/sequence');
      _initializerDefineProperty(this, "compound_id", _descriptor26, this);
    }
    get parentObject() {
      return loadParent(this.store, this.content_type, this.object_id);
    }
    get sequence_ids() {
      var id = this.id;
      var store = this.store;
      return _emberData.default.PromiseArray.create({
        promise: (0, _utils.isNone)(id) ? (0, _abstract.wrap_as_promise)([]) : store.query('taxonoccurrence/sequence/sequencedatabase', {
          sequence: id
        }).then(function (sdbs) {
          return _rsvp.default.all(sdbs.mapBy('database')).then(() => sdbs);
        })
      });
    }
    get GenBank_ENA_database() {
      return _emberData.default.PromiseObject.create({
        promise: this.sequence_ids.then(ids => {
          return _promises.default.find(ids, id => {
            return _promises.default.get(id, 'database.name').then(dbName => {
              return dbName === 'GenBank' || dbName === 'ENA';
            });
          });
        })
      });
    }
    get interactions() {
      var store = this.store;
      var id = this.id;
      return _emberData.default.PromiseArray.create({
        promise: (0, _utils.isNone)(id) ? (0, _abstract.wrap_as_promise)([]) : (0, _content_types.get_ctype_by_name)(this.store, 'sequence/sequence').then(function (ctype) {
          return store.query('taxonoccurrence/interaction/interaction', {
            content_type: ctype.get('id'),
            object_id: id
          });
        })
      });
    }
    get representation() {
      var cid = this.compound_id;
      return (0, _utils.isEmpty)(cid) ? 'No id' : cid;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "parent_sequence", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mainform", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "content_type", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "object_id", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sequence", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "seq_length", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "seq_source", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "remarks", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "forw_primer_name", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "forw_primer_sequence", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "rev_primer_name", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "rev_primer_sequence", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "is_available_for_unite", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "unite_status", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "unitestatus", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "chimeric_status", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "quality_status", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "rev_comp_status", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "insdstatus", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "insd_sequence_data", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "on_clipboard", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "download_count", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "is_parent_event", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "parentObject", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "parentObject"), _class.prototype), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "guardedParent", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sequence_ids", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "sequence_ids"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "GenBank_ENA_database", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "GenBank_ENA_database"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "interactions", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "interactions"), _class.prototype), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "inUNITE", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "compound_id", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "representation", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "representation"), _class.prototype), _class);
  var _default = _exports.default = Sequence;
});