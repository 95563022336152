define("plutof/misc/content_types", ["exports", "@ember/utils", "rsvp", "ember-inflector", "plutof/config/environment", "plutof/utils/caching", "plutof/utils/push-to-store", "plutof/utils/store"], function (_exports, _utils, _rsvp, _emberInflector, _environment, _caching, _pushToStore, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.get_ctype_by_name = get_ctype_by_name;
  _exports.get_ctype_by_url = get_ctype_by_url;
  _exports.get_object_ctype = get_object_ctype;
  _exports.modelPathByUrl = modelPathByUrl;
  _exports.resolve_generic_link = resolve_generic_link;
  const getCache = (0, _caching.lazy)(async function (store) {
    let names = new Map();
    const backend = await _caching.CacheManager.registerCache({
      name: 'content-types',
      version: 3 // Bump version to break the cache
    });
    const contents = await backend.all();
    Object.keys(contents).forEach(name => {
      const ctype = (0, _pushToStore.default)(store, 'contenttype', contents[name]);
      names.set(name, _rsvp.default.Promise.resolve(ctype));
    });
    function persist(ctype) {
      backend.set(ctype.full_name, ctype.getProperties(['id', 'name', 'app_label', 'model']));
      names.set(ctype.full_name, _rsvp.default.Promise.resolve(ctype));
    }
    function getByName(name) {
      if (!names.has(name)) {
        const [appLabel, model] = name.split('/');
        const ctypePromise = (0, _store.query)(store, 'contenttype', {
          model
        }).then(matches => {
          matches.forEach(persist);

          // matches[0] exception is for dnalab, where applabel and name applabel are different,
          // which is XXX
          return matches.findBy('app_label', appLabel) || matches[0];
        });
        names.set(name, ctypePromise);
      }
      return names.get(name);
    }
    return {
      getByName
    };
  });
  const contentTypeExceptions = {
    'data-management-plan': 'datamanagementplan',
    'dna-extraction': 'dnaextraction',
    'condition-assessment': 'conditionassessment',
    'red-list': 'redlist',
    'red-list-assessment': 'redlistspecies'
  };
  function get_ctype_by_name(store, name) {
    return getCache(store).then(cache => cache.getByName(name));
  }
  function get_object_ctype(store, record) {
    let name;
    if (record.constructor.modelName === 'agent/collection') {
      name = 'agent/organization';
    } else {
      const parts = record.get('constructor.modelName').split('/');
      const part_count = parts.length;
      let last = parts[part_count - 1];

      // XXX Backend endpoints dont exactly always reflect their model names
      if (contentTypeExceptions[last]) {
        last = contentTypeExceptions[last];
      }
      name = parts[part_count - 2] + '/' + last;
    }
    return get_ctype_by_name(store, name);
  }
  function resolve_generic_link(store, content_type, object_id) {
    return _rsvp.default.Promise.resolve(content_type).then(function (content_type) {
      if ((0, _utils.isNone)(content_type)) {
        return null;
      }
      return store.findRecord(content_type.get('model_name'), object_id);
    });
  }
  function modelPathByUrl(url) {
    const urlWithoutAPI = url.split(_environment.default.API_HOST)[1];
    const urlWithoutID = urlWithoutAPI.replace(/[0-9]/g, '');
    const nameParts = urlWithoutID.replace('//', '').split('/').reject(_utils.isEmpty);
    return nameParts.map(_emberInflector.singularize).join('/');
  }
  function get_ctype_by_url(store, url) {
    var urlWithoutAPI = url.split(_environment.default.API_HOST)[1];
    var urlWithoutID = urlWithoutAPI.replace(/[0-9]/g, '');
    var nameParts = urlWithoutID.replace('//', '').split('/').reject(_utils.isEmpty);
    var modelName = (0, _emberInflector.singularize)(nameParts.pop());
    return store.query('contenttype', {
      model: modelName
    }).then(function (ctypes) {
      const match = ctypes.findBy('app_label', nameParts.pop());
      ctypes.destroy();
      return match;
    });
  }
  // TODO: Remove default export
  var _default = _exports.default = {
    get_by_name: get_ctype_by_name,
    get_object_ctype: get_object_ctype,
    resolve_generic_link: resolve_generic_link
  };
});