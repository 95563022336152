define("plutof/helpers/not", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.notHelper = notHelper;
  // Expects boolean
  function notHelper(_ref) {
    let [b] = _ref;
    return !b;
  }
  var _default = _exports.default = (0, _helper.helper)(notHelper);
});