define("plutof/controllers/observation/utils/sheets", ["exports", "@ember/object", "@ember/object/computed", "@ember/utils", "ember-data", "rsvp", "plutof/config/environment", "plutof/misc/config", "plutof/utils/i18n", "plutof/utils/loading", "plutof/utils/model"], function (_exports, _object, _computed, _utils, _emberData, _rsvp, _environment, _config, _i18n, _loading, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getMainformSheetset = getMainformSheetset;
  var _dec, _class, _dec2, _class2, _dec3, _class3, _descriptor, _class4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const i18n = (0, _i18n.getI18n)();
  let Sheet = (_dec = (0, _object.computed)('foo'), _class = class Sheet {
    constructor(_ref) {
      let {
        name
      } = _ref;
      this.name = name;
    }
    get items() {
      return [];
    }
  }, _applyDecoratedDescriptor(_class.prototype, "items", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype), _class);
  let TaimeatlasSheet = (_dec2 = (0, _object.computed)('foo'), _class2 = class TaimeatlasSheet extends Sheet {
    constructor(_ref2) {
      let {
        name,
        source,
        breaks,
        ajax
      } = _ref2;
      super({
        name
      });
      this.source = source;
      this.breaks = breaks;
      this.ajax = ajax;
    }
    get items() {
      const promise = this.ajax.request(this.source, {
        dataType: 'text'
      }).then(text => {
        return text.split('\n').reject(_utils.isEmpty).map(line => {
          const [taxon_name, id, taxon, category] = line.split(',');
          return {
            taxon_name,
            id,
            taxon,
            category
          };
        });
      });
      return _emberData.default.PromiseArray.create({
        promise
      });
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "items", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "items"), _class2.prototype), _class2); // XXX: Deprecated. When BSP form sheets are filled in live, remove
  let MainformTaxonListSheet = (_dec3 = (0, _computed.reads)('mainform.taxon_list'), _class3 = class MainformTaxonListSheet extends Sheet {
    constructor(_ref3) {
      let {
        name,
        mainform
      } = _ref3;
      super({
        name
      });
      _initializerDefineProperty(this, "items", _descriptor, this);
      this.mainform = mainform;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class3.prototype, "items", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class3);
  let MainformSheet = (_class4 = class MainformSheet extends Sheet {
    constructor(_ref4) {
      let {
        mainform
      } = _ref4;
      super({
        name: mainform.representation
      });
      this.mainform = mainform;
    }
    get items() {
      const promise = (0, _loading.loadAll)(this.mainform.store, 'measurement/sheet-taxon', {
        mainform: this.mainform.id
      }, 100).then(items => items.map(item => ({
        id: item.taxon_id,
        taxon_name: item.taxon_name,
        commonName: item.common_name
      })));
      return _emberData.default.PromiseArray.create({
        promise
      });
    }
  }, _applyDecoratedDescriptor(_class4.prototype, "items", [_object.computed], Object.getOwnPropertyDescriptor(_class4.prototype, "items"), _class4.prototype), _class4);
  async function createMainformSheetset(ajax, mainform) {
    const count = await (0, _model.recordCount)(ajax, 'measurement/sheet-taxon', {
      mainform: mainform.id
    });
    if (count === 0) {
      return null;
    }

    // TODO: Reuse count
    const sheet = new MainformSheet({
      mainform
    });
    return {
      // TODO: Rename this string
      title: 'Observations.showTaimeatlas',
      component: 'observation/taxon-sheet/form-dialog',
      sheets: [sheet]
    };
  }
  let sheetsetCache = {};
  function getMainformSheetset(ajax, mainform) {
    if (!sheetsetCache[mainform.id]) {
      if (mainform.id === _config.default.Observation.TAIMEATLAS_MAINFORM_ID) {
        sheetsetCache[mainform.id] = {
          // TODO: Translate here, not on usage side
          title: 'Observations.showTaimeatlas',
          component: 'observation/taxon-sheet/taimeatlas-dialog',
          // Meh, but looks like there is no point in making TA acronym AC generic. Other
          // sheetsets' items area plain taxa, which don't need an AC.
          isTaimeatlas: true,
          autocomplete: {
            title: 'Observations.taAcronym',
            tooltip: 'Observations.placeholders.taAcronym'
          },
          sheets: [new TaimeatlasSheet({
            ajax,
            name: i18n.t('Observations.taSheetCurrent'),
            source: `${window.location.origin}/assets/data/taimeatlas.txt`,
            breaks: ['Art abs', 'Camp glome', 'Carl vulga', 'Dact ochro', 'Euph tomma', 'Gym co', 'Lam ample', 'Med sativ', 'Peta spuri', 'Pote crant', 'Rub saxat', 'Set gla', 'Trif alp']
          }), new TaimeatlasSheet({
            ajax,
            name: i18n.t('Observations.taSheetOld'),
            source: `${window.location.origin}/assets/data/taimeatlas_vana.txt`,
            breaks: ['Achi mi', 'Bert in', 'Care panice', 'Dro inter', 'Ger pusil', 'Leon au', 'Nau thy', 'Poly vivip', 'Sap off', 'Thy serpy']
          })]
        };
      } else if (_environment.default.TAXON_SHEET_MAINFORMS.includes(mainform.id)) {
        const sheet = new MainformTaxonListSheet({
          name: mainform.representation,
          mainform
        });
        const sheetset = {
          // TODO: Rename this string
          title: 'Observations.showTaimeatlas',
          component: 'observation/taxon-sheet/form-dialog',
          sheets: [sheet]
        };
        sheetsetCache[mainform.id] = _rsvp.default.Promise.resolve(sheetset);
      } else {
        sheetsetCache[mainform.id] = createMainformSheetset(ajax, mainform);
      }
    }
    return sheetsetCache[mainform.id];
  }
});