define("plutof/templates/profile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "oEulF/Am",
    "block": "[[[10,0],[14,0,\"profileForm\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@kind\"],[\"util\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"header\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"header\"]],null,[[\"@icon\",\"@title\"],[[28,[37,1],[\"settings\"],null],[28,[37,2],[\"General.Settings\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"primaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"info\"]],null,[[\"@title\"],[[28,[37,2],[\"Profile.infoTitle\"],null]]],null],[1,\"\\n\\n        \"],[8,[30,1,[\"bookmark\"]],null,null,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"tab-group\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tab-group__tablist-container\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"tab-group__tablist\"],[14,\"role\",\"tablist\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"tabs\"]]],null]],null],null,[[[1,\"                \"],[10,\"li\"],[15,0,[29,[\"tab-group__tab \",[52,[30,3,[\"isActive\"]],\"tab-group__tab--active\"]]]],[12],[1,\"\\n                    \"],[8,[39,6],null,[[\"@route\"],[[30,3,[\"route\"]]]],[[\"default\"],[[[[1,\"\\n                        \"],[10,1],[15,0,[29,[[30,3,[\"icon\"]]]]],[12],[13],[1,\"\\n                        \"],[1,[28,[35,2],[[30,3,[\"name\"]]],null]],[1,\"\\n                    \"]],[]]]]],[1,\"\\n                \"],[13],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"tab-content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"form-group no-margin\"],[12],[1,\"\\n        \"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"navbar\",\"slots\",\"tab\"],false,[\"navbar/navbar-base\",\"icon\",\"i18n-t\",\"each\",\"-track-array\",\"if\",\"link-to\",\"component\",\"-outlet\"]]",
    "moduleName": "plutof/templates/profile.hbs",
    "isStrictMode": false
  });
});