define("plutof/components/tab-group/tab", ["exports", "@glimmer/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let Tab = (_dec = (0, _object.computed)('args.group', 'args.name'), _class = class Tab extends _component.default {
    get tab() {
      return this.args.group.getTab(this.args.name);
    }
    willDestroy() {
      super.willDestroy();
      this.args.group.removeTab(this.args.name);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "tab", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "tab"), _class.prototype), _class);
  var _default = _exports.default = Tab;
});