define("plutof/components/reference/edit", ["exports", "@ember/component", "@ember/template-factory", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Reference::GeneralData
      @reference={{@reference}}
      @authors={{@authors}}
      @validationContext={{@validationContext.reference}} />
  
  <Reference::Authors
      @reference={{@reference}}
      @authors={{@authors}}
      @objectsToDelete={{@objectsToDelete}}
      @validationContext={{@validationContext.authors}} />
  
  <LinkedItems::Edit
      @data={{@itemData}}
      @validationContext={{@validationContext.associatedData}} />
  
  <Access::EditRights
      @rights={{@accessRights}}
      @validationContext={{@validationContext.access}} />
  
  */
  {
    "id": "zLT8FgFE",
    "block": "[[[8,[39,0],null,[[\"@reference\",\"@authors\",\"@validationContext\"],[[30,1],[30,2],[30,3,[\"reference\"]]]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@reference\",\"@authors\",\"@objectsToDelete\",\"@validationContext\"],[[30,1],[30,2],[30,4],[30,3,[\"authors\"]]]],null],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@data\",\"@validationContext\"],[[30,5],[30,3,[\"associatedData\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@rights\",\"@validationContext\"],[[30,6],[30,3,[\"access\"]]]],null],[1,\"\\n\"]],[\"@reference\",\"@authors\",\"@validationContext\",\"@objectsToDelete\",\"@itemData\",\"@accessRights\"],false,[\"reference/general-data\",\"reference/authors\",\"linked-items/edit\",\"access/edit-rights\"]]",
    "moduleName": "plutof/components/reference/edit.hbs",
    "isStrictMode": false
  });
  class ReferenceEdit extends _component.default.extend(_componentValidations.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "validationChildren", [{
        name: 'reference',
        label: 'References.reference'
      }, {
        name: 'authors',
        label: 'References.authors'
      }, {
        name: 'associatedData',
        label: 'General.associatedData'
      }, {
        name: 'access',
        label: 'General.access'
      }]);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ReferenceEdit);
});