define("plutof/controllers/profile/info", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "ember-cp-validations", "plutof/controllers/step-one-register", "plutof/mixins/component-validations", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _controller, _object, _computed, _service, _emberCpValidations, _stepOneRegister, _componentValidations, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  const Validations = (0, _emberCpValidations.buildValidations)({
    'languagePresent': (0, _emberCpValidations.validator)('is-truthy'),
    'userAccount.first_name': [(0, _emberCpValidations.validator)('present-not-blank'), (0, _emberCpValidations.validator)('format', {
      regex: _stepOneRegister.NAME_REGEX
    })],
    'userAccount.last_name': [(0, _emberCpValidations.validator)('present-not-blank'), (0, _emberCpValidations.validator)('format', {
      regex: _stepOneRegister.NAME_REGEX
    })],
    'emailIsValid': (0, _emberCpValidations.validator)('is-truthy'),
    'emailIsUnique': (0, _emberCpValidations.validator)('is-truthy')
  });
  let ProfileInfoController = (_dec = (0, _computed.notEmpty)('model.profile.language.id'), _dec2 = (0, _computed.alias)('model.profile.user'), _dec3 = (0, _object.computed)('userAccount.email'), _dec4 = (0, _object.computed)('emailIsValid', 'emailIsUnique'), _class = class ProfileInfoController extends _controller.default.extend(Validations, _componentValidations.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "languagePresent", _descriptor2, this);
      _initializerDefineProperty(this, "userAccount", _descriptor3, this);
      _defineProperty(this, "emailIsUnique", true);
    }
    get emailIsValid() {
      const email = this.get('userAccount.email');
      return _stepOneRegister.EMAIL_REGEX.test(email);
    }
    get emailIsUniqueAndValid() {
      return this.emailIsValid && this.emailIsUnique;
    }
    updateProfile() {
      const profile = this.get('model.profile');
      (0, _notifications.displayNotification)('status', i18n.t('General.saving'));
      return profile.get('user').then(user => user.save()).then(() => profile.save()).then(() => {
        (0, _notifications.displayNotification)('success', i18n.t('General.done'));
      }).catch(_notifications.reportError);
    }
    checkEmailUniqueness() {
      const email = this.get('userAccount.email');
      if (_stepOneRegister.EMAIL_REGEX.test(email)) {
        const url = `/users/email_uniqueness/?email=${email}`;
        this.ajax.request(url).then(response => {
          this.set('emailIsUnique', response.unique);
        });
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "languagePresent", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "emailIsValid", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "emailIsValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emailIsUniqueAndValid", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "emailIsUniqueAndValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateProfile", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateProfile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkEmailUniqueness", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "checkEmailUniqueness"), _class.prototype), _class);
  var _default = _exports.default = ProfileInfoController;
});