define("plutof/modifiers/collapse", ["exports", "ember-modifier", "@ember/runloop"], function (_exports, _emberModifier, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function getDatasetBoolean(value) {
    if (typeof value === 'string') {
      return value.toLowerCase() === 'true';
    }
    return value;
  }
  var _default = _exports.default = (0, _emberModifier.modifier)(function collapse(element, _ref) {
    let [open] = _ref;
    const version = parseInt(element.dataset.collapseVersion || '0') + 1;
    element.dataset.collapseVersion = version.toString();
    function modify(fn) {
      const activeVersion = parseInt(element.dataset.collapseVersion || '0');
      if (version === activeVersion) {
        fn(element);
      }
    }
    if (element.dataset.collapse !== undefined) {
      element.style.transition = 'height 333ms';
    }
    function expand(event) {
      modify(element => {
        element.dataset.collapse = false;
        // In case triggered by transition event, only react to height transitions.
        if (event && event.type === 'transitionend' && event.propertyName !== 'height') {
          return;
        }

        // Set element height to initial height, so it can play with other elements.
        element.style.height = 'initial';
        element.style.overflow = 'initial';
      });
    }
    function collapse() {
      modify(element => {
        element.dataset.collapse = true;
        element.style.overflow = 'hidden';
        // Set transition starting point height.
        element.style.height = `${element.scrollHeight}px`;
        (0, _runloop.next)(this, () => {
          // Transition to zero height.
          element.style.height = '0px';
        });
      });
    }

    // XXX dataset values are stored as strings.
    const datasetCollapse = getDatasetBoolean(element.dataset.collapse);

    // Run animation only if we've been collapsed before
    if (open && datasetCollapse) {
      element.style.height = `${element.scrollHeight}px`;
      element.addEventListener('transitionend', expand);
      return function () {
        element.removeEventListener('transitionend', expand);
      };
      // Expand when set open but in background (inactive) tab.
    } else if (open && !datasetCollapse) {
      window.requestAnimationFrame(expand);
    } else {
      element.style.height = `${element.scrollHeight}px`;
      window.requestAnimationFrame(collapse);
    }
  });
});