define("plutof/components/series/edit", ["exports", "@ember/component", "@ember/template-factory", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Series::GeneralData
      @series={{@series}}
      @validationContext={{@validationContext.series}} />
  
  <Access::EditRights
      @rights={{@accessRights}}
      @validationContext={{@validationContext.access}} />
  
  */
  {
    "id": "TYC9g0Pv",
    "block": "[[[8,[39,0],null,[[\"@series\",\"@validationContext\"],[[30,1],[30,2,[\"series\"]]]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@rights\",\"@validationContext\"],[[30,3],[30,2,[\"access\"]]]],null],[1,\"\\n\"]],[\"@series\",\"@validationContext\",\"@accessRights\"],false,[\"series/general-data\",\"access/edit-rights\"]]",
    "moduleName": "plutof/components/series/edit.hbs",
    "isStrictMode": false
  });
  class ReferenceSeriesEdit extends _component.default.extend(_componentValidations.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "validationChildren", [{
        name: 'series',
        label: 'General.generalData'
      }, {
        name: 'access',
        label: 'General.access'
      }]);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ReferenceSeriesEdit);
});