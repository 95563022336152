define("plutof/components/dmp/string-list", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AddMultipleForm @objects={{@values}} as |adder|>
      <div class="add-component">
          {{! TODO: Label should not be a part of this }}
          <adder.column @path={{@label}}>
              <Input
                  @type="text"
                  @value={{this.newValue}}
                  @enter={{this.add}}
                  @class="form-control control" />
          </adder.column>
  
          <adder.addButton @add={{this.add}} @disable={{this.disableAddButton}} />
      </div>
  
      <Common::RecordList @records={{@values}} @remove={{adder.remove}} as |record|>
          {{record}}
      </Common::RecordList>
  </AddMultipleForm>
  
  */
  {
    "id": "n3CRQcaV",
    "block": "[[[8,[39,0],null,[[\"@objects\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"add-component\"],[12],[1,\"\\n\"],[1,\"        \"],[8,[30,2,[\"column\"]],null,[[\"@path\"],[[30,3]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,1],null,[[\"@type\",\"@value\",\"@enter\",\"@class\"],[\"text\",[30,0,[\"newValue\"]],[30,0,[\"add\"]],\"form-control control\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2,[\"addButton\"]],null,[[\"@add\",\"@disable\"],[[30,0,[\"add\"]],[30,0,[\"disableAddButton\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@records\",\"@remove\"],[[30,1],[30,2,[\"remove\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@values\",\"adder\",\"@label\",\"record\"],false,[\"add-multiple-form\",\"input\",\"common/record-list\"]]",
    "moduleName": "plutof/components/dmp/string-list.hbs",
    "isStrictMode": false
  });
  let DmpDatasetkeyword = _exports.default = (_dec = (0, _object.computed)('newValue', 'args.values.[]'), _class = class DmpDatasetkeyword extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "newValue", _descriptor, this);
    }
    get disableAddButton() {
      const value = this.newValue.trim();
      return (0, _utils.isEmpty)(value) || this.args.values.includes(value);
    }
    add() {
      if (!this.disableAddButton) {
        this.args.values.pushObject(this.newValue);
        this.newValue = '';
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "newValue", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "disableAddButton", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "disableAddButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DmpDatasetkeyword);
});