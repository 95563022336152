define("plutof/routes/step-two-register", ["exports", "@ember/service", "rsvp", "plutof/misc/options-getter", "plutof/misc/profile_settings", "plutof/utils/routes"], function (_exports, _service, _rsvp, _optionsGetter, _profile_settings, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let RegistrationRoute = (_class = class RegistrationRoute extends _routes.Route {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "hideRegisterButton", true);
    }
    model(params) {
      var store = this.store;
      var person_p = store.queryRecord('agent/person', {
        user: params.user_id
      });
      return person_p.then(person => {
        return _rsvp.default.hash({
          user: store.findRecord('users/user', params.user_id),
          person: person,
          agentAssocation: store.createRecord('agent/agentassociation', {
            person: person,
            address: store.createRecord('agent/address', {})
          }),
          profile: (0, _profile_settings.get_personal_settings)(store),
          genders: (0, _optionsGetter.getChoices)(this.ajax, '/agent/persons/', 'gender'),
          languages: store.query('geography/language', {
            translated: true
          })
        });
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = RegistrationRoute;
});