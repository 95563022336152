define("plutof/services/store", ["exports", "@ember/service", "plutof/utils/annotation/store"], function (_exports, _service, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let StoreService = (_class = class StoreService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "emberDataStore", _descriptor, this);
      _defineProperty(this, "_store", this.emberDataStore);
      _defineProperty(this, "annotatedStore", null);
    }
    /* Store proxy */
    createRecord() {
      return this._store.createRecord(...arguments);
    }
    find() {
      return this._store.find(...arguments);
    }
    findAll() {
      return this._store.findAll(...arguments);
    }
    findRecord() {
      return this._store.findRecord(...arguments);
    }
    modelFor() {
      return this._store.modelFor(...arguments);
    }
    query() {
      return this._store.query(...arguments);
    }
    queryRecord() {
      return this._store.queryRecord(...arguments);
    }
    unloadRecord() {
      return this._store.unloadRecord(...arguments);
    }
    peekRecord() {
      return this._store.peekRecord(...arguments);
    }
    peekAll() {
      return this._store.peekAll(...arguments);
    }
    normalize() {
      return this._store.normalize(...arguments);
    }
    push() {
      return this._store.push(...arguments);
    }
    /* End store proxy */

    // This is not as pure (if that world can be appplied to whatever that is) as
    // dumb proxy, but some things do unfortunately need to know whether they are
    // annotating or changing
    startAnnotation() {
      let annotation = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      if (this.inAnnotation) {
        throw new Error('Already inside annotation');
      }
      const annotatedStore = new _store.AnnotatedStore(this.emberDataStore, annotation, options);
      this._store = annotatedStore;
      this.annotatedStore = annotatedStore;
    }
    endAnnotation() {
      if (!this.inAnnotation) {
        throw new Error('Not inside annotation');
      }
      this._store = this.emberDataStore;
      this.annotatedStore = null;
    }
    get inAnnotation() {
      return Boolean(this.annotatedStore);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "emberDataStore", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = StoreService;
});