define("plutof/components/search/filters/layer-area", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/utils", "@ember/service", "plutof/components/auto-complete/backends"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _utils, _service, _backends) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="filter-input" ...attributes>
      <div>
          {{common/local-label
              "Search.filters.layer.label"
              "Search.filters.layer.tooltip"}}
  
          <Layer::AutoComplete
              @value={{this._layer}}
              @update={{this.updateLayer}} />
      </div>
  
      <div>
          {{common/local-label
              "Search.filters.layerAreaGeom.label"
              "Search.filters.layerAreaGeom.tooltip"}}
  
          {{#resolve-promise this._layerAreaGeomFilter as |area|}}
              <AutoComplete::Base
                  @backend={{this.areaBackend}}
                  @value={{area}}
                  @update={{this.updateAreaGeomFilter}}
                  @disabled={{false}}
                  @params={{hash allowIncompleteValue=true}}
                  class="filter-input-ac"
              >
                  <:result as |result attrs|>
                      {{hl-subs result.value attrs.query}}
                  </:result>
              </AutoComplete::Base>
          {{/resolve-promise}}
      </div>
  
      <div>
          {{common/local-label
              "Search.filters.layerArea.label"
              "Search.filters.layerArea.tooltip"}}
  
          <AutoComplete::Base
              @backend={{this.areaBackend}}
              @value={{@layerAreaFilter.value.firstObject}}
              @update={{this.updateArea}}
              @disabled={{false}}
              @params={{hash allowIncompleteValue=true}}
              class="filter-input-ac"
          >
              <:result as |result attrs|>
                  {{hl-subs result.value attrs.query}}
              </:result>
          </AutoComplete::Base>
      </div>
  </div>
  
  */
  {
    "id": "+oMKkZZo",
    "block": "[[[11,0],[24,0,\"filter-input\"],[17,1],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"Search.filters.layer.label\",\"Search.filters.layer.tooltip\"],null]],[1,\"\\n\\n        \"],[8,[39,1],null,[[\"@value\",\"@update\"],[[30,0,[\"_layer\"]],[30,0,[\"updateLayer\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"Search.filters.layerAreaGeom.label\",\"Search.filters.layerAreaGeom.tooltip\"],null]],[1,\"\\n\\n\"],[6,[39,2],[[30,0,[\"_layerAreaGeomFilter\"]]],null,[[\"default\"],[[[[1,\"            \"],[8,[39,3],[[24,0,\"filter-input-ac\"]],[[\"@backend\",\"@value\",\"@update\",\"@disabled\",\"@params\"],[[30,0,[\"areaBackend\"]],[30,2],[30,0,[\"updateAreaGeomFilter\"]],false,[28,[37,4],null,[[\"allowIncompleteValue\"],[true]]]]],[[\"result\"],[[[[1,\"\\n                    \"],[1,[28,[35,5],[[30,3,[\"value\"]],[30,4,[\"query\"]]],null]],[1,\"\\n                \"]],[3,4]]]]],[1,\"\\n\"]],[2]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"Search.filters.layerArea.label\",\"Search.filters.layerArea.tooltip\"],null]],[1,\"\\n\\n        \"],[8,[39,3],[[24,0,\"filter-input-ac\"]],[[\"@backend\",\"@value\",\"@update\",\"@disabled\",\"@params\"],[[30,0,[\"areaBackend\"]],[30,5,[\"value\",\"firstObject\"]],[30,0,[\"updateArea\"]],false,[28,[37,4],null,[[\"allowIncompleteValue\"],[true]]]]],[[\"result\"],[[[[1,\"\\n                \"],[1,[28,[35,5],[[30,6,[\"value\"]],[30,7,[\"query\"]]],null]],[1,\"\\n            \"]],[6,7]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"area\",\"result\",\"attrs\",\"@layerAreaFilter\",\"result\",\"attrs\"],false,[\"common/local-label\",\"layer/auto-complete\",\"resolve-promise\",\"auto-complete/base\",\"hash\",\"hl-subs\"]]",
    "moduleName": "plutof/components/search/filters/layer-area.hbs",
    "isStrictMode": false
  });
  let SearchLayerArea = (_dec = (0, _object.computed)('_layer.id'), _dec2 = (0, _object.computed)('args.layerAreaGeomFilter.value'), _class = class SearchLayerArea extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "_layer", _descriptor3, this);
    }
    get areaBackend() {
      return new _backends.ModelAutocomplete({
        ajax: this.ajax,
        store: this.store,
        modelName: 'geography/layer-area',
        filters: {
          layer: (0, _object.get)(this, '_layer.id')
        }
      });
    }
    get _layerAreaGeomFilter() {
      const areaId = (0, _object.get)(this.args, 'layerAreaGeomFilter.value');
      return (0, _utils.isEmpty)(areaId) ? null : this.store.findRecord('geography/layer-area', areaId);
    }
    set _layerAreaGeomFilter(value) {
      return value;
    }
    updateArea(area) {
      if (area) {
        this.args.layerAreaGeomFilter.set('value', null);
      }
      this.args.layerAreaFilter.set('value', area ? [area] : []);
    }
    updateAreaGeomFilter(area) {
      if (area) {
        this.args.layerAreaFilter.set('value', []);
      }
      this.args.layerAreaGeomFilter.set('value', area ? area.id : null);
    }
    updateLayer(layer) {
      this._layer = layer;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_layer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "areaBackend", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "areaBackend"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_layerAreaGeomFilter", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_layerAreaGeomFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateArea", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateArea"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAreaGeomFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateAreaGeomFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateLayer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateLayer"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SearchLayerArea);
});