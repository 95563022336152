define("ember-changeset/index", ["exports", "@ember/debug", "@ember/object/compat", "validated-changeset", "@ember/array/proxy", "@ember/object/proxy", "@ember/object", "ember-changeset/utils/merge-deep", "ember-changeset/utils/is-object", "@glimmer/tracking"], function (_exports, _debug, _compat, _validatedChangeset, _proxy, _proxy2, _object, _mergeDeep, _isObject, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Changeset = Changeset;
  _exports.EmberChangeset = void 0;
  _exports.buildOldValues = buildOldValues;
  _exports.changeset = changeset;
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const CHANGES = '_changes';
  const PREVIOUS_CONTENT = '_previousContent';
  const CONTENT = '_content';
  const defaultValidatorFn = () => true;
  function buildOldValues(content, changes, getDeep) {
    const obj = Object.create(null);
    for (let change of changes) {
      obj[change.key] = getDeep(content, change.key);
    }
    return obj;
  }
  function isProxy(o) {
    return !!(o && (o instanceof _proxy2.default || o instanceof _proxy.default));
  }
  function maybeUnwrapProxy(o) {
    return isProxy(o) ? maybeUnwrapProxy((0, _object.get)(o, 'content')) : o;
  }
  let EmberChangeset = _exports.EmberChangeset = (_class = class EmberChangeset extends _validatedChangeset.BufferedChangeset {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "_changes", _descriptor, this);
      _initializerDefineProperty(this, "_errors", _descriptor2, this);
      _initializerDefineProperty(this, "_content", _descriptor3, this);
      _defineProperty(this, "isObject", _isObject.default);
      _defineProperty(this, "maybeUnwrapProxy", maybeUnwrapProxy);
      // DO NOT override setDeep. Ember.set does not work wth empty hash and nested
      // key Ember.set({}, 'user.name', 'foo');
      // override base class
      // DO NOT override setDeep. Ember.set does not work with Ember.set({}, 'user.name', 'foo');
      _defineProperty(this, "getDeep", _object.get);
      _defineProperty(this, "mergeDeep", _mergeDeep.default);
    }
    // override base class
    safeGet(obj, key) {
      return (0, _object.get)(obj, key);
    }
    safeSet(obj, key, value) {
      return (0, _object.set)(obj, key, value);
    }

    /**
     * @property isValid
     * @type {Array}
     */
    get isValid() {
      return super.isValid;
    }

    /**
     * @property isInvalid
     * @type {Boolean}
     */
    get isInvalid() {
      return super.isInvalid;
    }

    /**
     * @property isPristine
     * @type {Boolean}
     */
    get isPristine() {
      return super.isPristine;
    }

    /**
     * @property isDirty
     * @type {Boolean}
     */
    get isDirty() {
      return super.isDirty;
    }
    get pendingData() {
      let content = this[CONTENT];
      let changes = this[CHANGES];
      let pendingChanges = this.mergeDeep(Object.create(Object.getPrototypeOf(content)), content, {
        safeGet: _object.get,
        safeSet: _object.set
      });
      return this.mergeDeep(pendingChanges, changes, {
        safeGet: _object.get,
        safeSet: _object.set
      });
    }

    /**
     * Manually add an error to the changeset. If there is an existing
     * error or change for `key`, it will be overwritten.
     *
     * @method addError
     */
    addError(key, error) {
      super.addError(key, error);
      (0, _object.notifyPropertyChange)(this, key);
      // Return passed-in `error`.
      return error;
    }

    /**
     * Manually push multiple errors to the changeset as an array.
     *
     * @method pushErrors
     */
    pushErrors(key) {
      for (var _len = arguments.length, newErrors = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        newErrors[_key - 1] = arguments[_key];
      }
      const {
        value,
        validation
      } = super.pushErrors(key, ...newErrors);
      (0, _object.notifyPropertyChange)(this, key);
      return {
        value,
        validation
      };
    }

    /**
     * Sets property or error on the changeset.
     * Returns value or error
     */
    _setProperty(_ref) {
      let {
        key,
        value,
        oldValue
      } = _ref;
      super._setProperty({
        key,
        value,
        oldValue
      });
      (0, _object.notifyPropertyChange)(this, key);
    }

    /**
     * Notifies virtual properties set on the changeset of a change.
     * You can specify which keys are notified by passing in an array.
     *
     * @private
     * @param {Array} keys
     * @return {Void}
     */
    _notifyVirtualProperties(keys) {
      keys = super._notifyVirtualProperties(keys);
      (keys || []).forEach(key => (0, _object.notifyPropertyChange)(this, key));
      return;
    }

    /**
     * Deletes a key off an object and notifies observers.
     */
    _deleteKey(objName) {
      let key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      const result = super._deleteKey(objName, key);
      (0, _object.notifyPropertyChange)(this, key);
      return result;
    }

    /**
     * Executes the changeset if in a valid state.
     *
     * @method execute
     */
    execute() {
      let oldContent;
      if (this.isValid && this.isDirty) {
        let content = this[CONTENT];
        let changes = this[CHANGES];

        // keep old values in case of error and we want to rollback
        oldContent = buildOldValues(content, this.changes, this.getDeep);

        // we want mutation on original object
        // @tracked
        this[CONTENT] = this.mergeDeep(content, changes, {
          safeGet: _object.get,
          safeSet: _object.set
        });
      }
      this[PREVIOUS_CONTENT] = oldContent;
      return this;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_changes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_content", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isValid", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "isValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isInvalid", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "isInvalid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPristine", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "isPristine"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDirty", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "isDirty"), _class.prototype), _class);
  /**
   * Creates new changesets.
   */
  function changeset(obj) {
    let validateFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultValidatorFn;
    let validationMap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    (true && !(Boolean(obj)) && (0, _debug.assert)('Underlying object for changeset is missing', Boolean(obj)));
    (true && !(!Array.isArray(obj)) && (0, _debug.assert)('Array is not a valid type to pass as the first argument to `changeset`', !Array.isArray(obj)));
    if (options.changeset) {
      return new options.changeset(obj, validateFn, validationMap, options);
    }
    const c = new EmberChangeset(obj, validateFn, validationMap, options);
    return c;
  }

  /**
   * Creates new changesets.
   * @function Changeset
   */
  function Changeset(obj) {
    let validateFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultValidatorFn;
    let validationMap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    const c = changeset(obj, validateFn, validationMap, options);
    return new Proxy(c, {
      get(targetBuffer, key /*, receiver*/) {
        const res = targetBuffer.get(key.toString());
        return res;
      },
      set(targetBuffer, key, value /*, receiver*/) {
        targetBuffer.set(key.toString(), value);
        return true;
      }
    });
  }
  class ChangesetKlass {
    /**
     * Changeset factory
     * TODO: deprecate in favor of factory function
     *
     * @class ChangesetKlass
     * @constructor
     */
    constructor(obj) {
      let validateFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultValidatorFn;
      let validationMap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      const c = changeset(obj, validateFn, validationMap, options);
      return new Proxy(c, {
        get(targetBuffer, key /*, receiver*/) {
          const res = targetBuffer.get(key.toString());
          return res;
        },
        set(targetBuffer, key, value /*, receiver*/) {
          targetBuffer.set(key.toString(), value);
          return true;
        }
      });
    }
  }
  _exports.default = ChangesetKlass;
});