define("plutof/helpers/formatted-timespan", ["exports", "@ember/component/helper", "plutof/utils/formatters"], function (_exports, _helper, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function formattedTimespan(_ref) {
    let [begin, end] = _ref;
    return (0, _formatters.formatTimespan)(begin, end);
  });
});