define("plutof/components/plutof-select/multiple/-wrap", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/runloop"], function (_exports, _component, _templateFactory, _component2, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
      {{! value is just passed to clear the select }}
      {{yield (hash
          value=this.selectValue
          update=this.add
      ) to="select"}}
  
      <Common::RecordList
          @records={{@value}}
          @remove={{@remove}}
          as |item|
      >
          {{yield item to="selection"}}
      </Common::RecordList>
  </div>
  
  */
  {
    "id": "NIDu+yCh",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[1,\"    \"],[18,5,[[28,[37,1],null,[[\"value\",\"update\"],[[30,0,[\"selectValue\"]],[30,0,[\"add\"]]]]]]],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@records\",\"@remove\"],[[30,2],[30,3]]],[[\"default\"],[[[[1,\"\\n        \"],[18,6,[[30,4]]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@value\",\"@remove\",\"item\",\"&select\",\"&selection\"],false,[\"yield\",\"hash\",\"common/record-list\"]]",
    "moduleName": "plutof/components/plutof-select/multiple/-wrap.hbs",
    "isStrictMode": false
  });

  // import { tracked } from '@glimmer/tracking';
  let MultipleSelectWrapper = (_class = class MultipleSelectWrapper extends _component2.default {
    constructor() {
      super(...arguments);
      // @tracked
      _defineProperty(this, "selectValue", null);
    }
    add(value) {
      if (value) {
        this.args.add(value);
        (0, _object.set)(this, 'selectValue', value);
        (0, _runloop.later)(() => {
          (0, _object.set)(this, 'selectValue', null);
        });
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MultipleSelectWrapper);
});