define("plutof/components/related-objects/clipboard-buttons", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/misc/clipboard"], function (_exports, _component, _templateFactory, _component2, _object, _service, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ToolTip
      @tooltip={{i18n-t "Clipboard.actions.addPage.tooltip"}}
      class="plutof-tooltip-hover plutof-tooltip-hover--inline"
  >
      <PromiseButton
          @clicked={{this.sendPage}}
          class="plutof-btn__controls plutof-btn__controls--xs"
          @restIcon={{icon "clipboard"}}
          @disabled={{@disabled}}
      >
          {{i18n-t "Clipboard.actions.addPage.label"}}
      </PromiseButton>
  </ToolTip>
  
  {{#if @clipboardQuery}}
      <ToolTip
          @tooltip={{i18n-t "Clipboard.actions.addAll.tooltip"}}
          class="plutof-tooltip-hover plutof-tooltip-hover--inline"
      >
          <PromiseButton
              @clicked={{this.sendQuery}}
              class="plutof-btn__controls plutof-btn__controls--xs"
              @restIcon={{icon "clipboard"}}
              @disabled={{@disabled}}
          >
              {{i18n-t "Clipboard.actions.addAll.label"}}
          </PromiseButton>
      </ToolTip>
  {{/if}}
  
  */
  {
    "id": "TGofAUTi",
    "block": "[[[8,[39,0],[[24,0,\"plutof-tooltip-hover plutof-tooltip-hover--inline\"]],[[\"@tooltip\"],[[28,[37,1],[\"Clipboard.actions.addPage.tooltip\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"plutof-btn__controls plutof-btn__controls--xs\"]],[[\"@clicked\",\"@restIcon\",\"@disabled\"],[[30,0,[\"sendPage\"]],[28,[37,3],[\"clipboard\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"Clipboard.actions.addPage.label\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,0],[[24,0,\"plutof-tooltip-hover plutof-tooltip-hover--inline\"]],[[\"@tooltip\"],[[28,[37,1],[\"Clipboard.actions.addAll.tooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],[[24,0,\"plutof-btn__controls plutof-btn__controls--xs\"]],[[\"@clicked\",\"@restIcon\",\"@disabled\"],[[30,0,[\"sendQuery\"]],[28,[37,3],[\"clipboard\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"Clipboard.actions.addAll.label\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@disabled\",\"@clipboardQuery\"],false,[\"tool-tip\",\"i18n-t\",\"promise-button\",\"icon\",\"if\"]]",
    "moduleName": "plutof/components/related-objects/clipboard-buttons.hbs",
    "isStrictMode": false
  });
  let RelatedObjectsClipboardButtons = (_class = class RelatedObjectsClipboardButtons extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "confirmationDialog", _descriptor2, this);
    }
    async prepareAndSend(fn) {
      const dialog = this.confirmationDialog.CLIPBOARD_APPEND(this.args.clipboard);
      const option = await this.confirmationDialog.selectOption(dialog);
      if (option === 'cancel') {
        return;
      }
      if (option === 'overwrite') {
        await (0, _clipboard.clearClipboard)(this.ajax, this.args.clipboard);
      }
      await fn();
    }
    sendPage() {
      return this.prepareAndSend(async () => {
        const objects = this.args.pagination.objects.slice();
        const ids = objects.map(record => record.id);
        await (0, _clipboard.sendToClipboard)(this.ajax, this.args.clipboard, ids);
        objects.forEach(object => (0, _object.set)(object, 'on_clipboard', true));
      });
    }
    sendQuery() {
      return this.prepareAndSend(async () => {
        await (0, _clipboard.sendToClipboardRaw)(this.ajax, this.args.clipboard, this.args.clipboardQuery);
        this.args.pagination.objects.forEach(object => (0, _object.set)(object, 'on_clipboard', true));
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "confirmationDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sendPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendQuery", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendQuery"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RelatedObjectsClipboardButtons);
});