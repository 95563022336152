define("plutof/session-stores/application", ["exports", "ember-simple-auth/session-stores/adaptive", "plutof/utils/caching"], function (_exports, _adaptive, _caching) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PlutoFSessionStore extends _adaptive.default {
    async persist() {
      try {
        return super.persist(...arguments);
      } catch (err) {
        // Assuming we have no space left, drop the caches, which unfortunately
        // share the same pool as session. This might not help (error could be
        // unrelated or entire space taken by unmanaged caches)
        await _caching.CacheManager.dropCaches();

        // Swallowing the error so that login still works even if persist fails despite our best efforts
        return super.persist(...arguments).catch(() => null);
      }
    }
  }
  var _default = _exports.default = PlutoFSessionStore;
});