define("plutof/components/annotation/moderation-buttons", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "plutof/utils/annotation/moderation", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _moderation, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@button
      @clicked={{this.accept}}
      @icon={{icon "ok"}}
  >
      {{i18n-t "moderation.accept"}}
  </@button>
  
  <@button
      @clicked={{this.reject}}
      @icon={{icon "remove"}}
  >
      {{i18n-t "moderation.reject"}}
  </@button>
  
  <Ui::Modal::Dialog
      @open={{this.dialogOpen}}
      @close={{this.cancel}}
  >
      <:header>
          {{i18n-t "annotation.moderation.dialog.title"}}
      </:header>
  
      <:body>
          <div class={{if this.commentInvalid 'has-error'}}>
              <FlexibleTextarea
                  @value={{this.comment}}
                  @rows={{2}}
                  placeholder={{i18n-t "annotation.moderation.dialog.placeholder"}}
                  class="form-control" />
          </div>
      </:body>
  
      <:footer>
          <PromiseButton
              @clicked={{this.moderate}}
              @disabled={{this.commentInvalid}}
              class="btn btn-default plutof-btn-green terms-of-service__accept"
          >
              {{i18n-t (if this.accepting "moderation.accept" "moderation.reject")}}
          </PromiseButton>
  
          <PromiseButton
              @clicked={{this.cancel}}
              class="btn btn-default"
          >
              {{i18n-t "General.Cancel"}}
          </PromiseButton>
      </:footer>
  </Ui::Modal::Dialog>
  
  */
  {
    "id": "hjiIzWB0",
    "block": "[[[8,[30,1],null,[[\"@clicked\",\"@icon\"],[[30,0,[\"accept\"]],[28,[37,0],[\"ok\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"moderation.accept\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[30,1],null,[[\"@clicked\",\"@icon\"],[[30,0,[\"reject\"]],[28,[37,0],[\"remove\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"moderation.reject\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@open\",\"@close\"],[[30,0,[\"dialogOpen\"]],[30,0,[\"cancel\"]]]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"annotation.moderation.dialog.title\"],null]],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[10,0],[15,0,[52,[30,0,[\"commentInvalid\"]],\"has-error\"]],[12],[1,\"\\n            \"],[8,[39,4],[[16,\"placeholder\",[28,[37,1],[\"annotation.moderation.dialog.placeholder\"],null]],[24,0,\"form-control\"]],[[\"@value\",\"@rows\"],[[30,0,[\"comment\"]],2]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[8,[39,5],[[24,0,\"btn btn-default plutof-btn-green terms-of-service__accept\"]],[[\"@clicked\",\"@disabled\"],[[30,0,[\"moderate\"]],[30,0,[\"commentInvalid\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[[52,[30,0,[\"accepting\"]],\"moderation.accept\",\"moderation.reject\"]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,5],[[24,0,\"btn btn-default\"]],[[\"@clicked\"],[[30,0,[\"cancel\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"General.Cancel\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@button\"],false,[\"icon\",\"i18n-t\",\"ui/modal/dialog\",\"if\",\"flexible-textarea\",\"promise-button\"]]",
    "moduleName": "plutof/components/annotation/moderation-buttons.hbs",
    "isStrictMode": false
  });
  let AnnotationModerationButtons = (_class = class AnnotationModerationButtons extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "emberDataStore", _descriptor2, this);
      _initializerDefineProperty(this, "i18n", _descriptor3, this);
      _initializerDefineProperty(this, "comment", _descriptor4, this);
      _initializerDefineProperty(this, "accepting", _descriptor5, this);
      _initializerDefineProperty(this, "dialogOpen", _descriptor6, this);
    }
    get commentInvalid() {
      // Required only for rejections
      return !this.accepting && this.comment.trim().length === 0;
    }
    accept() {
      this.accepting = true;
      this.dialogOpen = true;
    }
    reject() {
      this.accepting = false;
      this.dialogOpen = true;
    }
    async moderate() {
      try {
        if (this.accepting) {
          await (0, _moderation.acceptAnnotation)(this.args.annotation, this.comment);
        } else {
          await (0, _moderation.rejectAnnotation)(this.args.annotation, this.comment);
        }
        (0, _notifications.displayNotification)('success', this.i18n.translate('General.done'));
        this.args.moderated();
      } catch (err) {
        (0, _notifications.reportError)(err);
      }
    }
    cancel() {
      this.dialogOpen = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "emberDataStore", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "comment", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "accepting", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dialogOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "accept", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "accept"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reject", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "moderate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "moderate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnnotationModerationButtons);
});