define("plutof/utils/identity-providers", ["exports", "plutof/config/environment", "plutof/misc/abstract"], function (_exports, _environment, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ENABLED_PROVIDERS = void 0;
  _exports.connect = connect;
  _exports.disconnect = disconnect;
  _exports.listIdentities = listIdentities;
  _exports.login = login;
  function normal(backend) {
    return {
      backend,
      name: `login.provider.${backend}`,
      additionalParams: {},
      button: `auth/identity-provider-buttons/${backend}`,
      isSourceOf: back => back === backend
    };
  }
  function taat(idp) {
    return {
      backend: 'saml',
      name: `login.provider.${idp}`,
      additionalParams: {
        idp
      },
      button: 'auth/identity-provider-buttons/taat',
      isSourceOf(back, identity) {
        return back === 'saml' && identity.split(':')[0] === idp;
      }
    };
  }
  const PROVIDERS = {
    'google-oauth2': normal('google-oauth2'),
    'orcid': normal('orcid'),
    'taat': taat('taat'),
    'taat-test': taat('taat-test')
  };
  const ENABLED_PROVIDERS = _exports.ENABLED_PROVIDERS = _environment.default.ENABLED_IDENTITY_PROVIDERS.map(id => PROVIDERS[id]).filter(Boolean);
  function login(provider) {
    const {
      backend,
      additionalParams
    } = provider;
    const url = (0, _abstract.construct_request)(`${_environment.default.API_DOMAIN}/social/login/${backend}/`, Object.assign({
      client_id: _environment.default.API_CLIENT_ID
    }, additionalParams));
    window.location.href = url;
  }
  function connect(provider, access_token) {
    const {
      backend,
      additionalParams
    } = provider;
    const url = (0, _abstract.construct_request)(`${_environment.default.API_DOMAIN}/social/login/${backend}/`, Object.assign({
      access_token
    }, additionalParams));
    window.location.href = url;
  }
  function disconnect(ajax, provider) {
    return ajax.post(`${_environment.default.API_DOMAIN}/social/disconnect/${provider.backend}/`);
  }
  async function listIdentities(ajax) {
    const identities = Object.entries(await ajax.request(`${_environment.default.API_DOMAIN}/social/identities/`));
    let mappedIdentities = [];
    for (let provider of ENABLED_PROVIDERS) {
      const match = identities.find(_ref => {
        let [backend, id] = _ref;
        return provider.isSourceOf(backend, id);
      });
      mappedIdentities.push({
        provider,
        identity: match ? match[1] : null
      });
    }
    return mappedIdentities;
  }
});