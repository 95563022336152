define("plutof/controllers/specimen/edit", ["exports", "@ember/controller", "@ember/object", "plutof/mixins/edit-controller", "plutof/utils/notifications"], function (_exports, _controller, _object, _editController, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let SpecimenEditController = (_class = class SpecimenEditController extends _controller.default.extend(_editController.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "recordsToUnload", [{
        record: 'specimenData.specimen',
        subRecords: ['exsiccata', 'substrate']
      }]);
    }
    saveSpecimen() {
      return this.model.specimenData.save();
    }
    _save() {
      return (0, _notifications.notifyProcess)(this.saveSpecimen(), this.i18n);
    }
    editSpecimen() {
      return this._save().then(() => {
        this.routeActions.goBack();
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "editSpecimen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editSpecimen"), _class.prototype), _class);
  var _default = _exports.default = SpecimenEditController;
});