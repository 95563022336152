define("plutof/components/agent/auto-complete", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "ember-concurrency", "plutof/components/auto-complete/backends"], function (_exports, _component, _templateFactory, _component2, _service, _emberConcurrency, _backends) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AutoComplete::Base
      @backend={{this.backend}}
      @value={{@value}}
      @update={{@update}}
      @disabled={{@disabled}}
      @params={{merge-params @params (hash
          sideAction=(perform this.selectDefault)
          sideActionIcon=(icon "user")
      )}}
      ...attributes
  >
      <:result as |result context|>
          <Agent::-AutoCompleteItem
              @result={{result}}
              @context={{context}} />
      </:result>
  </AutoComplete::Base>
  
  */
  {
    "id": "rvcvZDWO",
    "block": "[[[8,[39,0],[[17,1]],[[\"@backend\",\"@value\",\"@update\",\"@disabled\",\"@params\"],[[30,0,[\"backend\"]],[30,2],[30,3],[30,4],[28,[37,1],[[30,5],[28,[37,2],null,[[\"sideAction\",\"sideActionIcon\"],[[28,[37,3],[[30,0,[\"selectDefault\"]]],null],[28,[37,4],[\"user\"],null]]]]],null]]],[[\"result\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@result\",\"@context\"],[[30,6],[30,7]]],null],[1,\"\\n    \"]],[6,7]]]]],[1,\"\\n\"]],[\"&attrs\",\"@value\",\"@update\",\"@disabled\",\"@params\",\"result\",\"context\"],false,[\"auto-complete/base\",\"merge-params\",\"hash\",\"perform\",\"icon\",\"agent/-auto-complete-item\"]]",
    "moduleName": "plutof/components/agent/auto-complete.hbs",
    "isStrictMode": false
  });
  let AgentAutocomplete = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class AgentAutocomplete extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "settings", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _defineProperty(this, "backend", new _backends.ModelAutocomplete({
        ajax: this.ajax,
        store: this.store,
        modelName: 'agent/agent'
      }));
      _initializerDefineProperty(this, "selectDefault", _descriptor4, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectDefault", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const person = yield this.settings.getUserPerson();
        const agent = yield person.valid_agent;
        this.args.update(agent);
      };
    }
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AgentAutocomplete);
});