define("plutof/transforms/enum", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EnumTransform extends _transform.default {
    deserialize(value) {
      return value;
    }
    serialize(value, _ref) {
      let {
        undefinedValue = null
      } = _ref;
      // undefined-values enums don't even get sent to API,
      // which means clearing an enum to undefined is not
      // possible
      if (value === undefined) {
        value = undefinedValue;
      }
      return value;
    }
  }
  var _default = _exports.default = EnumTransform;
});