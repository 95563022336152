define("plutof/components/dmp/projects", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "plutof/models/dmp/data-management-plan"], function (_exports, _component, _templateFactory, _component2, _object, _dataManagementPlan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::ItemList
      @collapse={{not @projects.length}}
      as |panel|
  >
      <panel.header
          @title={{i18n-t "dmp.panels.project"}}
          @count={{@projects.length}}
      >
          <panel.button
              @clicked={{this.add}}
          >
              {{i18n-t "General.Add"}}
          </panel.button>
      </panel.header>
  
      <panel.content>
          <Validation::SplitContext
              @items={{@projects}}
              @context={{@validationContext}}
              as |project context|
          >
              <Dmp::Project
                  @project={{project}}
                  @validationContext={{context}} />
          </Validation::SplitContext>
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "6NGEFKwb",
    "block": "[[[8,[39,0],null,[[\"@collapse\"],[[28,[37,1],[[30,1,[\"length\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,2],[\"dmp.panels.project\"],null],[30,1,[\"length\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"button\"]],null,[[\"@clicked\"],[[30,0,[\"add\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"General.Add\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@items\",\"@context\"],[[30,1],[30,3]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,4],null,[[\"@project\",\"@validationContext\"],[[30,4],[30,5]]],null],[1,\"\\n        \"]],[4,5]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@projects\",\"panel\",\"@validationContext\",\"project\",\"context\"],false,[\"plutof-panel/item-list\",\"not\",\"i18n-t\",\"validation/split-context\",\"dmp/project\"]]",
    "moduleName": "plutof/components/dmp/projects.hbs",
    "isStrictMode": false
  });
  let DMPProjects = _exports.default = (_class = class DMPProjects extends _component2.default {
    add() {
      this.args.projects.pushObject((0, _dataManagementPlan.emptyProject)());
    }
  }, _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DMPProjects);
});