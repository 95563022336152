define("plutof/components/search/filters/-exclude-filter", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Common::Ui::ControlLabel>
      <:label>
          {{@filter.config.label}}
      </:label>
  
      <:controls as |controls|>
          <controls.toggle
              @checked={{@filter.negative}}
              @toggle={{this.toggleNegative}}
          >
              {{i18n-t "Search.excludeFilter"}}
          </controls.toggle>
      </:controls>
  </Common::Ui::ControlLabel>
  
  {{yield}}
  
  */
  {
    "id": "E44V5mm8",
    "block": "[[[8,[39,0],null,null,[[\"label\",\"controls\"],[[[[1,\"\\n        \"],[1,[30,1,[\"config\",\"label\"]]],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[8,[30,2,[\"toggle\"]],null,[[\"@checked\",\"@toggle\"],[[30,1,[\"negative\"]],[30,0,[\"toggleNegative\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"Search.excludeFilter\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\\n\"],[18,3,null],[1,\"\\n\"]],[\"@filter\",\"controls\",\"&default\"],false,[\"common/ui/control-label\",\"i18n-t\",\"yield\"]]",
    "moduleName": "plutof/components/search/filters/-exclude-filter.hbs",
    "isStrictMode": false
  });
  let SearchExcludeFilterLabel = _exports.default = (_class = class SearchExcludeFilterLabel extends _component2.default {
    toggleNegative() {
      this.args.filter.negative = !this.args.filter.negative;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "toggleNegative", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleNegative"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SearchExcludeFilterLabel);
});