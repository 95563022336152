define("plutof/components/gallery/image", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
      id="zoom-container"
      class="zoom-container {{if this.isZoomed 'zoom-container--zoomed'}}"
  >
      <img
          {{on "click" this.toggleZoom}}
          class="zoom-container__image {{if this.isZoomed 'zoom-container__image--zoomed'}}"
          ...attributes />
  </div>
  
  */
  {
    "id": "biXZwbGe",
    "block": "[[[10,0],[14,1,\"zoom-container\"],[15,0,[29,[\"zoom-container \",[52,[30,0,[\"isZoomed\"]],\"zoom-container--zoomed\"]]]],[12],[1,\"\\n    \"],[11,\"img\"],[16,0,[29,[\"zoom-container__image \",[52,[30,0,[\"isZoomed\"]],\"zoom-container__image--zoomed\"]]]],[17,1],[4,[38,1],[\"click\",[30,0,[\"toggleZoom\"]]],null],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\"],false,[\"if\",\"on\"]]",
    "moduleName": "plutof/components/gallery/image.hbs",
    "isStrictMode": false
  });
  function scrollTo(container, image, x, y) {
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    const relativeX = x / containerWidth;
    const relativeY = y / containerHeight;
    const imageX = relativeX * image.naturalWidth;
    const imageY = relativeY * image.naturalHeight;
    const scrollX = imageX - containerWidth / 2;
    const scrollY = imageY - containerHeight / 2;
    (0, _runloop.next)(() => container.scrollTo(Math.round(scrollX), Math.round(scrollY)));
  }
  let GalleryImage = (_class = class GalleryImage extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isZoomed", _descriptor, this);
    }
    toggleZoom(event) {
      this.isZoomed = !this.isZoomed;
      if (this.isZoomed) {
        const container = document.getElementById('zoom-container');
        const image = event.target;
        scrollTo(container, image, event.layerX, event.layerY);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isZoomed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleZoom", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleZoom"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GalleryImage);
});