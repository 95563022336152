define("plutof/utils/annotation/access", ["exports", "rsvp", "plutof/services/ajax", "plutof/utils/access"], function (_exports, _rsvp, _ajax, _access) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.extendPermissionsToModerators = extendPermissionsToModerators;
  // Scenario:
  // 1. A annotated B's record, adding a file item linked to @file
  // 2. @file is public and thus B can view annotated version properly
  // 3. B accepts the annotation, but as filerepo/item POST is down by them, they lack
  //    the *edit* permission necessary for creating an item
  //
  // This gives every moderator of @recordBeingAnnotated edit rights to @usedRelation
  async function extendPermissionsToModerators(emberDataStore, recordBeingAnnotated, usedRelation) {
    if (usedRelation.isNew) {
      // New relation will be created with moderator permission in any case
      return;
    }
    const ajax = (0, _ajax.ajaxFromControlled)(emberDataStore);
    const [recordPermissions, relationPermissions] = await _rsvp.default.all([(0, _access.get_permissions)(emberDataStore, ajax, recordBeingAnnotated), (0, _access.get_permissions)(emberDataStore, ajax, usedRelation)]);
    relationPermissions.set('editable', 'WORKGROUPS');
    relationPermissions.user_edit.pushObjects(recordPermissions.user_edit);
    relationPermissions.user_edit.pushObject(recordPermissions.owner);
    relationPermissions.group_edit.pushObjects(recordPermissions.group_edit);
    const managedPermissionSources = await _rsvp.default.all([recordBeingAnnotated.deposited_in, recordBeingAnnotated.project]);
    for (const source of managedPermissionSources) {
      if (source) {
        const group = await source.managing_group;
        if (group) {
          relationPermissions.group_edit.pushObject(group);
        }
      }
    }
    await relationPermissions.save();
  }
});