define("plutof/services/confirmation-dialog", ["exports", "@ember/runloop", "@ember/service", "@glimmer/tracking", "rsvp", "plutof/utils/i18n"], function (_exports, _runloop, _service, _tracking, _rsvp, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const NEXT_DIALOG_DELAY = 500;
  const i18n = (0, _i18n.getI18n)();
  const YES_NO = {
    yes: i18n.t('General.yes'),
    no: i18n.t('General.no')
  };
  let ConfirmationDialog = (_class = class ConfirmationDialog extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "visible", _descriptor, this);
      _defineProperty(this, "dialogStack", []);
      _initializerDefineProperty(this, "done", _descriptor2, this);
      _defineProperty(this, "DELETE_CONFIRMATION", {
        title: i18n.t('General.dialogs.delete.title'),
        content: i18n.t('General.dialogs.delete.content'),
        labels: {
          yes: i18n.t('General.dialogs.delete.yes'),
          no: i18n.t('General.dialogs.delete.no')
        }
      });
    }
    showNextDialog() {
      if (this.dialogStack.length > 0) {
        const dialog = this.dialogStack.popObject();
        this.set('dialog', dialog);
        this.set('done', false);
        this.set('visible', true);
      }
    }
    finished() {
      (0, _runloop.later)(() => {
        this.set('done', true);
        this.showNextDialog();
      }, NEXT_DIALOG_DELAY);
    }

    // options: [{ label, handler, cancel }]
    // NB: At most one option should have cancel == true
    ask(_ref) {
      let {
        title,
        content,
        options
      } = _ref;
      const dialog = {
        title,
        content,
        options,
        finished: false
      };
      this.dialogStack.pushObject(dialog);
      if (this.done) {
        this.showNextDialog();
      }
    }
    confirm() {
      let {
        title,
        content,
        labels = YES_NO
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new _rsvp.default.Promise((resolve, reject) => {
        this.ask({
          title,
          content,
          options: [{
            label: labels.yes,
            action: () => resolve(true)
          }, {
            label: labels.no,
            action: () => resolve(false),
            cancel: true
          }]
        });
      });
    }

    // values :: [{ value, label, cancel }]
    selectOption() {
      let {
        title,
        content,
        values
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new _rsvp.default.Promise((resolve, reject) => {
        this.ask({
          title,
          content,
          options: values.map(_ref2 => {
            let {
              label,
              value,
              cancel
            } = _ref2;
            return {
              label,
              action: () => resolve(value),
              cancel
            };
          })
        });
      });
    }
    CLIPBOARD_APPEND(module) {
      const options = {
        hash: {
          moduleRecords: i18n.t(`General.dialogs.clipboardAppend.modules.${module}`)
        }
      };
      return {
        title: i18n.t('General.dialogs.clipboardAppend.title', options),
        content: i18n.t('General.dialogs.clipboardAppend.content', options),
        values: [{
          value: 'append',
          label: i18n.t('General.dialogs.clipboardAppend.append')
        }, {
          value: 'overwrite',
          label: i18n.t('General.dialogs.clipboardAppend.overwrite')
        }, {
          value: 'cancel',
          label: i18n.t('General.Cancel'),
          cancel: true
        }]
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "visible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "done", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _class);
  var _default = _exports.default = ConfirmationDialog;
});