define("plutof/translations/est/search", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    foundInfo: (count, query) => `Otsing "${query}" tagastas ${count} tulemust.`,
    foundCount: count => `Otsing tagastas ${count} tulemust.`,
    downloadTooltip: limit => `Laadi alla otsingu tulemused CSV-failina. Allalaadimine on piiratud ${limit} kirjega.`,
    resultsPerPage: 'Tulemust lehel',
    columnName: 'Nimi',
    columnOwner: (0, _helpers.alias)('General.owner'),
    columnModified: 'Viimati muudetud',
    columnTools: 'Lisa näpitstahvlile',
    tabTable: 'Loend',
    tabGallery: 'Pildivaade',
    tabMap: 'Kaardivaade',
    panelTaxon: 'Takson',
    panelTime: 'Aeg',
    panelLocation: 'Ala',
    panelProperties: 'Atribuudid',
    panelMeasurements: (0, _helpers.alias)('General.measurements'),
    inputLabel: 'Otsingusõna',
    inputTooltip: 'Sisesta otsingusõna(d) või komaga eraldatud sõnade nimekiri. Tühikuga eraldatud otsingusõnade puhul eeldatakse nende koosesinemist ühes tagastatavas kirjes, komaga eraldatud sõnad võivad esineda erinevates kirjetes.',
    module: 'Moodul',
    searchButton: 'Otsi',
    activeFilters: 'Aktiivsed filtrid',
    filterPanels: 'Filtrid',
    ordering: 'Järjekord',
    pageSizeToolip: 'Tulemust lehelküljel',
    columnMenuTooltip: 'Tulbad',
    event: 'Sündmus',
    createdAt: 'Sisestatud',
    lastModified: 'Muudetud',
    digitizationDate: 'Digitaliseeritud',
    dateIdentified: 'Määramise kuupäev',
    booleanTrue: 'Sees',
    booleanFalse: 'Väljas',
    booleanIndeterminate: 'Määramata',
    anyValue: 'Suvaline väärtus',
    noValue: 'Puuduv väärtus',
    addTrait: 'Lisa',
    newTrait: 'Tunnus',
    isParentAreaTrait: 'Ülemala tunnus',
    traitInUse: 'Tunnus on juba lisatud',
    noResults: 'Ühtegi vastet ei leitud',
    searchAllFields: 'Otsi üle kõikide väljade',
    searchNameField: 'Otsi ainult "nimi" välja järgi',
    toggleAllColumns: 'Kõik',
    loadFullMap: (0, _helpers.alias)('Clipboard.loadFullMap'),
    unloadFullMap: 'Kuva ainult üks tulemuste leht',
    includeSynonyms: 'Sünonüümid',
    uniteAndInsd: 'UNITE ja INSD',
    excludeFilter: 'Välja jäetud',
    modules: {
      'taxonoccurrence': 'Taksoni esinemised',
      'study': 'Projektid',
      'citizenscience': 'Harrastusteaduse projektid',
      'specimen': 'Eksemplarid',
      'observation': 'Vaatlused',
      'sequence': 'Sekventsid',
      'livingculture': 'Eluseksemplarid',
      'referencebased': 'Kirjanduspõhised',
      'materialsample': 'Proovid',
      'filerepository': 'Failide repositoorium',
      'publishing': (0, _helpers.alias)('Publishing.dois'),
      'photobank': 'Fotopank',
      'taxonnode': 'Taksonoomia',
      'taxondescription': 'Taksonikirjeldused',
      'uniteSH': 'UNITE liigihüpoteesid',
      'referencesequence': 'Referentssekventsid',
      'reference': 'Kirjandusviited',
      'referenceseries': 'Kirjandsuviidete sarjad',
      'publisher': 'Kirjastajad',
      'glossary': 'Märksõnad',
      'glossarycollection': 'Märksõnade kogumikud',
      'person': 'Isikud',
      'organization': 'Asutused',
      'collection': 'Kogud',
      'compoundclusters': 'Ühendklastrid',
      'transaction': 'Kogude transaktsioonid',
      'dna': 'Eraldatud DNA',
      'dnaextraction': 'DNA eraldused',
      'pcr': 'PCR reaktsioonid',
      'sequencing': 'DNA järjendamine'
    },
    geometry: {
      fromArea: 'Lisa prooviala geomeetria kaardile',
      relation: {
        relation: 'Kaasa',
        intersects: 'Kirjed, mis osaliselt jäävad otsingu geomeetria sisse',
        within: 'Kirjed, mis jäävad 100% otsingu geomeetria sisse',
        contains: 'Kirjed, mis katavad 100% otsingu geomeetria'
      },
      buffer: {
        label: 'Raadius/Täpsus (m)',
        tooltip: 'Loob sisendgeomeetria ümber puhverpolügoonil, mis on määratud kaugusel meetrites'
      }
    },
    columns: {
      transactionCountAndNumber: 'Objekte sisestatud / kokku'
    },
    topResults: {
      creator: 'Top sisestajad',
      owner: 'Top õiguste hoidjad',
      country: 'Top riigid',
      determiner: 'Top määrajad',
      collector: 'Top kogujad',
      habitat: 'Top elupaigad',
      form: 'Top vormid',
      natureReserve: 'Top looduskaitsealad',
      study: 'Top projektid',
      taxon: 'Top taksonid',
      district: 'Top maakonnad',
      commune: 'Top vallad/linnad',
      parish: 'Top kihelkonnad',
      collection: 'Top kogud',
      shCluster: 'Top liigihüpoteesid',
      isolationSource: 'Top DNA eraldamise allikad',
      reference: 'Top kirjandusviited',
      cluster: 'Top klastrid',
      phenoFilterWarning: 'Top tulemusi ei saa laadida "Taksoni esinemised" otsingumoodulis kui "Unikaalsed taksonid" filter on aktiivne.'
    },
    filters: {
      commonName: {
        label: 'Tavanimetus',
        tooltip: 'Otsi taksoneid tavanimetuse järgi. Tavanimetuse valides täidetakse taksoni otsingufilter tavanimetusele vastava teadusliku taksoni nimega.'
      },
      taxon: {
        label: 'Takson'
      },
      includeTaxonSynonyms: {
        label: 'Sünonüümid',
        tooltip: 'Kaasa taksoni sünonüümid'
      },
      includeSubtaxa: {
        label: 'Alamtaksonid',
        tooltip: 'Kaasa alamtaksonid'
      },
      layer: {
        label: 'Kiht',
        tooltip: 'Filtreeri kihi alasid valitud kihi järgi. See sisend ei mõjuta otsingu tulemust.'
      },
      layerArea: {
        label: 'Kihi ala',
        tooltip: 'Otsi kihi alaga seotud kirjeid. Ala valimiseks vali esmalt kiht.'
      },
      layerAreaGeom: {
        label: 'Kihi ala ja geomeetria',
        tooltip: 'Otsi kirjeid, mis on seotud valitud kihi alaga ning kirjeid, mis asuvad kihi ala geomeetrias.'
      }
    },
    clipboard: {
      sendPageToClipboard: 'Saada lehel olevad kirjed näpitstahvlile',
      sendAllToClipboard: 'Saada kõik leitud kirjed näpitstahvlile',
      noClipboard: 'Otsingutulemusi ei saa näpitstahvlile saata, kuna "{{moduleName}}" tüüpi kirjete jaoks pole näpitstahvli moodulit.',
      noTaxonOccurrenceClipboard: '"{{moduleName}}" otsingumoodul otsib kõiki taksoni esinemise tüüpi kirjeid. Taksoni esinemiste näpitstahvlile saatmiseks kasuta otsingumoodulit, mis otsib üht tüüpi taksoni esinemisi.'
    }
  };
});