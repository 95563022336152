define("plutof/routes/conservation-lab/red-list-assessment/edit", ["exports", "plutof/components/conservation/red-list-assessment/edit", "plutof/utils/routes"], function (_exports, _edit, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RedListAssessmentEditRoute extends _routes.EditRoute {
    async model(_ref) {
      let {
        id
      } = _ref;
      const record = await this.store.findRecord('redbook/red-list-assessment', id);
      const assessment = await (0, _edit.loadRedListAssessmentModel)(this.store, record);
      return {
        assessment
      };
    }
    getTitle(model) {
      return model.assessment.assessment.title;
    }
    transitionToDefaultRoute() {
      return this.transitionTo('conservation-lab.red-list-assessment.view', this.currentModel.assessment.assessment.id);
    }
  }
  var _default = _exports.default = RedListAssessmentEditRoute;
});