define("plutof/controllers/unite/statistics", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-data", "plutof/misc/abstract"], function (_exports, _controller, _object, _service, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let UNITEStatisticsController = (_dec = (0, _object.computed)('selectedVersion'), _class = class UNITEStatisticsController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
    }
    get stats() {
      const version = this.selectedVersion;
      if (!version) {
        return {};
      }
      const url = (0, _abstract.construct_request)('/globalkey/statistics/', {
        version: version.get('id')
      });
      return _emberData.default.PromiseObject.create({
        promise: this.ajax.request(url).then(statistics => ({
          total: statistics.cluster_count,
          thresholdStats: statistics.clusters_by_threshold.map((clusters, i) => ({
            threshold: clusters.threshold,
            clusters: clusters.all,
            referencedClusters: clusters.referenced,
            singletons: statistics.singletons_by_threshold[i].all,
            referencedSingletons: statistics.singletons_by_threshold[i].referenced
          })).filter(stats => stats.threshold !== '0.0')
        }))
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "stats", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "stats"), _class.prototype), _class);
  var _default = _exports.default = UNITEStatisticsController;
});