define("plutof/components/study/area-map", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-concurrency", "ember-data", "latlon-geohash", "rsvp", "ol/extent", "ol/proj", "plutof/components/map/grid", "plutof/config/environment", "plutof/misc/abstract", "plutof/utils/pagination", "plutof/utils/search/modules"], function (_exports, _component, _component2, _object, _service, _tracking, _emberConcurrency, _emberData, _latlonGeohash, _rsvp, _extent, _proj, _grid, _environment, _abstract, _pagination, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AreaMap = (_dec = (0, _component.classNames)('project-area-map'), _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec3 = (0, _object.computed)('currentMode.cluster'), _dec4 = (0, _object.computed)('samplingAreas.[]', 'predefinedAreas.[]', 'currentMode'), _dec5 = (0, _object.computed)('projectAreas.[]'), _dec6 = (0, _object.computed)('project.id'), _dec7 = (0, _object.computed)('currentMapMode', 'mapPredefinedAreas.isPending', 'mapSamplingAreas.isPending', 'mapPaginatedAreas.isPending'), _dec8 = (0, _object.computed)('project.id'), _dec(_class = (_class2 = class AreaMap extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "selection", _descriptor2, this);
      _initializerDefineProperty(this, "setupAreaPagination", _descriptor3, this);
      _initializerDefineProperty(this, "gridSelection", _descriptor4, this);
    }
    init() {
      super.init(...arguments);
      const gridMode = {
        name: this.i18n.t('Projects.samplingAreasOnMap'),
        value: 'samplingareas',
        grid: true
      };
      const modes = [gridMode,
      // Previous default mode
      {
        name: this.i18n.t('Projects.samplingAreasOnMapLegacy'),
        value: 'samplingareas-cluster',
        grid: false,
        predefined: false,
        sampling: true,
        cluster: true,
        popup: true
      }, {
        name: this.i18n.t('Projects.predefinedAreasOnMap'),
        value: 'projectareas',
        grid: false,
        predefined: true,
        sampling: false,
        cluster: true,
        popup: true
      }, {
        name: this.i18n.t('Projects.allPointsOnMap'),
        value: 'allpoints',
        grid: false,
        predefined: true,
        sampling: true,
        cluster: false,
        popup: false
      }];
      this.set('currentMode', gridMode);
      this.set('modes', modes);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.setupAreaPagination.perform();
    }
    get mapComponent() {
      return this.currentMode.cluster ? 'map/cluster-map' : 'map/view-map';
    }
    get data() {
      let areas = [];
      if (this.currentMode.predefined) {
        areas = areas.concat(this.predefinedAreas.toArray());
      }
      if (this.currentMode.sampling) {
        areas = areas.concat(this.samplingAreas.toArray());
      }
      return areas.filter(area => area.geom);
    }
    loadAreas(areasPromise, areaIDField, styleClass) {
      return _emberData.default.PromiseArray.create({
        promise: areasPromise.then(areas => areas.map(area => ({
          id: (0, _object.get)(area, 'id'),
          areaID: (0, _object.get)(area, areaIDField),
          source: area,
          geom: (0, _object.get)(area, 'geom'),
          styleClass: styleClass
        })))
      });
    }
    get predefinedAreas() {
      return this.loadAreas(_rsvp.default.Promise.resolve(this.projectAreas), 'area_id', 'predefined');
    }

    // This used to query /sample/samplingareas/geoms/, but now users want
    // to select areas on the map
    get samplingAreas() {
      return this.loadAreas(this._fetchAreas(this.project.id), 'id', 'base');
    }
    async _fetchAreas(projectID) {
      const pageSize = 500;
      const loadPage = number => {
        return this.ajax.request(`${_environment.default.API_HOST}/sample/samplingareas/search/`, {
          data: {
            study: projectID,
            page: number,
            page_size: pageSize
          }
        });
      };

      // Have to fetch the first page to get the count
      const firstPage = await loadPage(1);
      const pageCount = Math.ceil(firstPage.count / pageSize);

      // The rest can be fetched in parallel
      const rest = await _rsvp.default.Promise.all((0, _abstract.range)(2, pageCount + 1).map(number => loadPage(number)));
      const areas = [].concat(...[firstPage, ...rest].map(page => page.results));
      return areas.filter(area => !area.is_site);
    }
    get dataIsLoading() {
      return this.currentMode.predefined && this.get('mapPredefinedAreas.isPending') || this.currentMode.predefined && this.get('mapPaginatedAreas.isPending') || this.currentMode.sampling && this.get('mapSamplingAreas.isPending');
    }
    get gridModel() {
      const module = _modules.default.find(module => module.id === 'samplingarea');
      return _grid.SearchGridSource.setup({
        ajax: this.ajax,
        module,
        urlQuery: new URLSearchParams({
          'study': this.project.id
        })
      }).then(source => {
        // Experimentally handle a corner case where the points are
        // densely packed in a very small (<1km2) area, so that
        // default max-level 8 is too coarse.
        //
        // This is all very imprecise, but we don't need
        // much from it. Ideally, we'd have a max level grid under
        // the limit and uplevel the cells depending on the current
        // map resolution. But that's a much more involved development,
        // this will suffice for now.
        //
        // In most cases, the map will show the points themselves anyway.
        const projection = (0, _proj.get)('EPSG:4326');
        const metersPerDegree = projection.getMetersPerUnit();

        // XXX: Why does extent have a negative width? Somebody is lying
        const area = Math.abs((0, _extent.getWidth)(source.extent) * metersPerDegree * (0, _extent.getHeight)(source.extent) * metersPerDegree);
        return new _grid.GridModel({
          source,
          config: {
            maxGridLevel: area < 1_000_000 ? 10 : 8
          }
        });
      });
    }
    async loadCellAreas(geohash) {
      // TODO: This should load only the first page (and not even 500,
      // nobody will scroll this far)
      //
      // But before piling even more complexity here, have to know
      // if anybody even want these in the cell popup
      //
      // Also: using geohash in elastic query directly is probably
      // more efficient than converting it to a generic geometry filter
      const gridModel = await this.gridModel;
      const bounds = _latlonGeohash.default.bounds(geohash);
      const extent = [bounds.sw.lon, bounds.sw.lat, bounds.ne.lon, bounds.ne.lat];
      const {
        points
      } = await gridModel.source.loadPoints({
        extent
      });
      return points.map(p => p.payload);
    }
    loadSelectionAreas(selection) {
      if (selection.cell) {
        return this.loadCellAreas(selection.cell.geohash);
      } else {
        return _rsvp.default.Promise.resolve(selection.areas);
      }
    }
    _addArea() {
      return this.addArea();
    }
    gridCellSelected(cell) {
      this.gridSelection = {
        cell
      };
    }
    areasSelected(areas) {
      this.gridSelection = {
        areas
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "selection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "setupAreaPagination", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        let pagination = yield (0, _pagination.paginateAjaxEndpoint)(this.ajax, 'sample/samplingareas', {
          filters: {
            study: this.project.id,
            parent_samplingarea: null,
            site: 'False'
          }
        });
        this.set('areaPagination', pagination);
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "mapComponent", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "mapComponent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "data", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "data"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "predefinedAreas", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "predefinedAreas"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "samplingAreas", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "samplingAreas"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "dataIsLoading", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "dataIsLoading"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "gridModel", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "gridModel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadSelectionAreas", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "loadSelectionAreas"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_addArea", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_addArea"), _class2.prototype), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "gridSelection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        // One or the other
        cell: null,
        areas: null
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "gridCellSelected", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "gridCellSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "areasSelected", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "areasSelected"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = AreaMap;
});