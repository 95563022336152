define("plutof/templates/components/check-box", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ViOMZBOV",
    "block": "[[[10,\"label\"],[14,0,\"plutof-label plutof-label--overflow\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[30,1],[30,2]]]]],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"        \"],[10,1],[15,0,[29,[\"plutof-label plutof-label--overflow \",[52,[30,2],\"text-muted\"]]]],[12],[1,\"\\n            \"],[1,[28,[35,2],[[30,3]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@checked\",\"@disabled\",\"@label\"],false,[\"input\",\"if\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/check-box.hbs",
    "isStrictMode": false
  });
});