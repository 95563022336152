define("plutof/components/reference/import-from-doi", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/service", "@ember/template", "@ember/utils", "rsvp", "plutof/misc/abstract", "plutof/utils/i18n", "plutof/utils/notifications", "plutof/utils/string"], function (_exports, _component, _templateFactory, _object, _service, _template, _utils, _rsvp, _abstract, _i18n, _notifications, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Layout::RegularGrid @columns={{2}}>
      <div>
          <div class="plutof-tooltip-hover">
              <div class="plutof-label"> {{i18n-t "References.searchDOI"}} </div>
  
              <div>
                  <span class="plutof-tooltip">{{i18n-t "References.searchDOITooltip"}}</span>
              </div>
          </div>
  
          <div class="input-group">
              <Input
                  @type="text"
                  @value={{@reference.doi}}
                  @enter={{this.getDois}}
                  class="form-control" />
  
              <span {{on "click" this.getDois}} disabled={{this.isSearching}} class="input-group-addon clickable">
                  <span class="icon-search {{if this.isSearching 'spinicon'}}"></span>
              </span>
          </div>
      </div>
  </Layout::RegularGrid>
  
  {{#if this.searchResults.length}}
      <ExpandedSelect
          @content={{this.searchResults}}
          @selection={{this.doiSelection}}
          @details={{this.doiDetails}}
          as |result|
      >
          {{result.doi}}
      </ExpandedSelect>
  
      <button disabled={{not this.doiSelection}} class="btn btn-default btn-xs" {{on "click" this.importDOI}}>
          <span class={{icon "ok"}}></span>
  
          {{i18n-t "Import.import"}}
      </button>
  {{/if}}
  
  */
  {
    "id": "RmJ9s0lF",
    "block": "[[[8,[39,0],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n        \"],[10,0],[14,0,\"plutof-tooltip-hover\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\" \"],[1,[28,[35,1],[\"References.searchDOI\"],null]],[1,\" \"],[13],[1,\"\\n\\n            \"],[10,0],[12],[1,\"\\n                \"],[10,1],[14,0,\"plutof-tooltip\"],[12],[1,[28,[35,1],[\"References.searchDOITooltip\"],null]],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n            \"],[8,[39,2],[[24,0,\"form-control\"]],[[\"@type\",\"@value\",\"@enter\"],[\"text\",[30,1,[\"doi\"]],[30,0,[\"getDois\"]]]],null],[1,\"\\n\\n            \"],[11,1],[16,\"disabled\",[30,0,[\"isSearching\"]]],[24,0,\"input-group-addon clickable\"],[4,[38,3],[\"click\",[30,0,[\"getDois\"]]],null],[12],[1,\"\\n                \"],[10,1],[15,0,[29,[\"icon-search \",[52,[30,0,[\"isSearching\"]],\"spinicon\"]]]],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"searchResults\",\"length\"]],[[[1,\"    \"],[8,[39,5],null,[[\"@content\",\"@selection\",\"@details\"],[[30,0,[\"searchResults\"]],[30,0,[\"doiSelection\"]],[30,0,[\"doiDetails\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"doi\"]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\\n    \"],[11,\"button\"],[16,\"disabled\",[28,[37,6],[[30,0,[\"doiSelection\"]]],null]],[24,0,\"btn btn-default btn-xs\"],[4,[38,3],[\"click\",[30,0,[\"importDOI\"]]],null],[12],[1,\"\\n        \"],[10,1],[15,0,[28,[37,7],[\"ok\"],null]],[12],[13],[1,\"\\n\\n        \"],[1,[28,[35,1],[\"Import.import\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@reference\",\"result\"],false,[\"layout/regular-grid\",\"i18n-t\",\"input\",\"on\",\"if\",\"expanded-select\",\"not\",\"icon\"]]",
    "moduleName": "plutof/components/reference/import-from-doi.hbs",
    "isStrictMode": false
  });
  const i18n = (0, _i18n.getI18n)();
  let ImportFromDoi = (_dec = (0, _service.inject)(), _dec2 = (0, _object.computed)('doiSelection.fullCitation'), _class = class ImportFromDoi extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "searchResults", []);
    }
    nonExistentPersonsToPlutoF(persons, personsJSON) {
      // Weird format from external link author = ['name', 'lastname']
      const store = this.store;
      const personPromises = persons.map((person, index) => {
        if ((0, _utils.isNone)(person)) {
          const [givenName, familyName] = personsJSON[index];

          // Meaning person is not in plutof
          const newPerson = store.createRecord('agent/person', {
            given_name: this.modifyNames(givenName),
            family_name: this.modifyNames(familyName)
          });
          return newPerson.save();
        } else {
          return (0, _abstract.wrap_as_promise)(person);
        }
      });
      return _rsvp.default.all(personPromises);
    }
    modifyNames(string) {
      if ((0, _utils.isNone)(string)) {
        return null;
      }

      // Make JOHN SMITH => John Smith, otherwise ignore and send what the original value was.
      return (0, _string.isUpperCase)(string) ? (0, _string.capitalizeAllWords)(string) : string;
    }
    personsJSONToEmberRecords(persons) {
      // Weird format from external link author = ['name', 'lastname']
      const store = this.store;
      const personsPromises = persons.map(person => {
        const [givenName, familyName] = person;
        return store.queryRecord('agent/person', {
          given_name: this.modifyNames(givenName),
          family_name: this.modifyNames(familyName)
        });
      });
      return _rsvp.default.all(personsPromises);
    }
    agentsToReferenceAuthors(agents) {
      const store = this.store;
      const reference = this.reference;
      const authorsRecords = agents.map(agent => {
        return store.createRecord('reference/author', {
          agent: agent,
          reference: reference,
          author_type: 1
        });
      });
      this.authors.pushObjects(authorsRecords);
    }
    personsToAgents(persons) {
      const store = this.store;
      const agentPromises = persons.mapBy('id').map(id => {
        return store.findRecord('agent/agent', id);
      });
      return _rsvp.default.all(agentPromises);
    }
    get doiDetails() {
      const citation = this.get('doiSelection.fullCitation');
      return citation ? [{
        name: 'Citation',
        value: (0, _template.htmlSafe)(citation)
      }] : null;
    }
    getDois() {
      if ((0, _utils.isEmpty)(this.get('reference.doi'))) {
        return _abstract.EMPTY_PROMISE;
      }
      const refinderURL = (0, _abstract.construct_request)('/reference/refinder/find/', {
        search: 'simple',
        db: 'crossref',
        limit: 3,
        text: this.get('reference.doi')
      });
      this.set('isSearching', true);
      return this.ajax.request(refinderURL).then(DoiJSON => {
        if ((0, _utils.isEmpty)(DoiJSON)) {
          return (0, _notifications.displayNotification)('success', i18n.t('General.notFound'));
        }
        return this.setProperties({
          'searchResults': DoiJSON,
          'doiSelection': DoiJSON[0]
        });
      }).catch(_notifications.reportError).finally(() => this.set('isSearching', false));
    }
    importDOI() {
      const doi = this.doiSelection;
      const doiPersons = doi.authors;
      this.reference.setProperties({
        'year': doi.year ? doi.year : '',
        'volume': doi.volume ? doi.volume : '',
        'title': doi.title ? doi.title : '',
        'pages': (doi.spage ? doi.spage : '') + (doi.epage ? ' - ' + doi.epage : ''),
        'doi': doi.doi,
        'secondary_title': doi.publishedIn ? doi.publishedIn : ''
      });
      if ((0, _utils.isEmpty)(doiPersons)) {
        this.setProperties({
          'doiSelection': null,
          'searchResults': []
        });
        return (0, _notifications.displayNotification)('success', i18n.t('General.done'));
      }
      return this.personsJSONToEmberRecords(doiPersons).then(persons => {
        return this.nonExistentPersonsToPlutoF(persons, doiPersons).then(persons => {
          return this.personsToAgents(persons).then(agents => {
            (0, _notifications.displayNotification)('success', i18n.t('General.done'));
            return this.agentsToReferenceAuthors(agents);
          });
        });
      }).catch(_notifications.reportError).finally(() => {
        this.setProperties({
          'doiSelection': null,
          'searchResults': []
        });
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "doiDetails", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "doiDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getDois", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getDois"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "importDOI", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "importDOI"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ImportFromDoi);
});