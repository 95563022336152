define("plutof/helpers/formatted-date", ["exports", "@ember/component/helper", "@ember/utils", "plutof/utils/formatters"], function (_exports, _helper, _utils, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;
  function formatDate(_ref) {
    let [date, format] = _ref;
    if ((0, _utils.isEmpty)(date)) {
      return '';
    }
    const usedFormat = (0, _utils.isEmpty)(format) ? (0, _formatters.getAmbiguousDateFormat)(date) : format;
    return (0, _formatters.parseAmbiguousDate)(date, format).format(usedFormat);
  }
  var _default = _exports.default = (0, _helper.helper)(formatDate);
});