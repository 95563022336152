define("plutof/helpers/on-change", ["exports", "@ember/component/helper", "@ember/utils", "plutof/utils/structures"], function (_exports, _helper, _utils, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  // DIY didReceiveAttrs for glimmer components (with explicit dependencies though)
  // Have to confirm a change because helper always get caught on rerender
  //
  // Note: this is experimental, not 100% sure it's a good idea
  class OnChangeHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "lastDeps", []);
      _defineProperty(this, "hadInitial", false);
    }
    isUpdate(deps) {
      // don't count undefined <-> null
      return (0, _structures.zip)(deps, this.lastDeps).some(_ref => {
        let [current, prev] = _ref;
        return current !== prev && !((0, _utils.isNone)(current) && (0, _utils.isNone)(prev));
      });
    }
    compute(_ref2, _ref3) {
      let [fn, ...dependencies] = _ref2;
      let {
        skipInitial
      } = _ref3;
      if (this.isUpdate(dependencies)) {
        this.lastDeps = dependencies;
        if (this.hadInitial || !skipInitial) {
          fn(...dependencies);
        }
        this.hadInitial = true;
      }
    }
  }
  _exports.default = OnChangeHelper;
});