define("plutof/controllers/dmp/view", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/utils", "plutof/config/environment", "plutof/controllers/publishing/utils", "plutof/misc/fileupload", "plutof/utils/access", "plutof/utils/errors", "plutof/utils/notifications"], function (_exports, _controller, _object, _service, _utils, _environment, _utils2, _fileupload, _access, _errors, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loadDmpFile = loadDmpFile;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  async function uploadFile(file, fileUpload, store, i18n) {
    (0, _notifications.displayNotification)('status', i18n.t('datacite.actions.savingFile'));
    const [poolFile] = await fileUpload.addLocalFiles([file]);
    // File must be public for its URL to be persistent
    const access = await (0, _access.create_access_rights)(store);
    access.visible = 'PUBLIC';
    const savedFile = await poolFile.save(access);
    (0, _notifications.displayNotification)('success', i18n.t('datacite.actions.savedFile', {
      hash: {
        name: savedFile.representation
      }
    }));
    return savedFile;
  }
  function uploadDmpJson(json, filename, fileUpload, store, i18n) {
    const file = new File([JSON.stringify(json)], filename, {
      type: 'application/json'
    });
    return uploadFile(file, fileUpload, store, i18n);
  }
  function uploadDmpPdf(pdf, filename, fileUpload, store, i18n) {
    const file = new File([pdf], filename, {
      type: 'application/pdf'
    });
    return uploadFile(file, fileUpload, store, i18n);
  }
  async function loadDmpFile(type, dmpId, session) {
    const response = await fetch(`${_environment.default.API_HOST}/dmp/data-management-plans/${dmpId}/${type}/`, {
      headers: {
        'Authorization': `Bearer ${session.data.authenticated.access_token}`
      }
    });
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(await response.text());
    }
  }
  let DMPViewController = (_dec = (0, _object.computed)('model.record.dmp.dmp.dataset.[]'), _class = class DMPViewController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "fileUpload", _descriptor2, this);
      _initializerDefineProperty(this, "i18n", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
    }
    init() {
      super.init(...arguments);
      this.set('publishingAllowed', false);
      (0, _utils2.userIsAllowedToRegisterDOIs)(this.store).then(allowed => {
        this.set('publishingAllowed', allowed);
      });
    }
    get hasOnlyOneDataset() {
      return this.model.record.dmp.dmp.dataset.length === 1;
    }
    downloadPDF() {
      const id = this.model.record.id;
      (0, _fileupload.downloadFile)(`${_environment.default.API_HOST}/dmp/data-management-plans/${id}/pdf/`);
    }
    downloadJSON() {
      const id = this.model.record.id;
      (0, _fileupload.downloadFile)(`${_environment.default.API_HOST}/dmp/data-management-plans/${id}/json/`);
    }
    async publishViaDatacite(_ref) {
      let {
        baseFilename,
        saveJson,
        savePdf,
        dataciteId
      } = _ref;
      const dmpId = this.model.record.id;
      let jsonFile = null;
      let pdfFile = null;
      try {
        if (saveJson) {
          const json = await loadDmpFile('json', dmpId, this.session);
          jsonFile = await uploadDmpJson(json, baseFilename, this.fileUpload, this.store, this.i18n);
        }
        if (savePdf) {
          const pdf = await loadDmpFile('pdf', dmpId, this.session);
          pdfFile = await uploadDmpPdf(pdf, baseFilename, this.fileUpload, this.store, this.i18n);
        }
      } catch (err) {
        (0, _notifications.reportError)(new _errors.userError(err.message));
      }
      if (!(0, _utils.isNone)(dataciteId)) {
        return this.transitionToRoute('datacite.edit', dataciteId, {
          queryParams: {
            dmp_id: this.model.record.id,
            resource_file_ids: [jsonFile?.id, pdfFile?.id].compact()
          }
        });
      }
      return this.transitionToRoute('datacite.add', {
        queryParams: {
          dmp_id: this.model.record.id,
          resource_file_ids: [jsonFile?.id, pdfFile?.id].compact()
        }
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fileUpload", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "hasOnlyOneDataset", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "hasOnlyOneDataset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadPDF", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "downloadPDF"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadJSON", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "downloadJSON"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publishViaDatacite", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "publishViaDatacite"), _class.prototype), _class);
  var _default = _exports.default = DMPViewController;
});