define("plutof/components/one-way-input", ["exports", "@ember/component", "@ember-decorators/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  let OneWayInput = (_dec = (0, _component2.tagName)('input'), _dec2 = (0, _component2.attributeBindings)('internalValue:value', 'type', 'placeholder', 'autocomplete', 'disabled'), _dec(_class = _dec2(_class = class OneWayInput extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "type", 'text');
      _defineProperty(this, "placeholder", null);
      _defineProperty(this, "autocomplete", 'off');
      _defineProperty(this, "disabled", false);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.set('internalValue', this.value);
    }
    input(evt) {
      this.update(evt.target.value);
    }
  }) || _class) || _class);
  var _default = _exports.default = OneWayInput;
});