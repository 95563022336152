define("plutof/components/flexible-textarea", ["exports", "@ember/component/text-area", "@ember/runloop", "@ember-decorators/component"], function (_exports, _textArea, _runloop, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  let FlexibleTextarea = (_dec = (0, _component.classNames)('flexible-textarea'), _dec(_class = class FlexibleTextarea extends _textArea.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "rows", 1);
      _defineProperty(this, "rowsLimit", 50);
    }
    didRender() {
      if (this.initialHeight) {
        this.element.height = this.initialHeight;
      }
      (0, _runloop.throttle)(this, this.updateHeight, 333, false);
    }
    updateHeight() {
      if (this.isDestroyed) {
        return;
      }
      const e = this.element;
      let scrollHeight, lineHeight, rows;

      // Lower the height to get scroll height.
      e.style.height = '1px';

      // Some browsers include scrollbars in textarea inner area when
      // calculating scroll height, some do not. Hide overflow to ensure same
      // in all browesers rules for scroll height calculation.
      e.style.overflowY = 'hidden';
      scrollHeight = e.scrollHeight;

      // Restore overflow to auto.
      e.style.overflowY = 'auto';
      lineHeight = parseInt(getComputedStyle(e).lineHeight);

      // Not actual textarea rows.
      rows = Math.floor(scrollHeight / lineHeight);
      if (rows > this.rowsLimit) {
        e.style.height = `${lineHeight * this.rowsLimit + lineHeight}px`;
      } else if (rows > 1) {
        // Compensate for Chrome ghost scrollbar anomalies with additional
        // space in the textarea.
        e.style.height = `${scrollHeight + lineHeight}px`;
      } else {
        if (this.initialHeight) {
          e.style.height = this.initialHeight;
        } else {
          e.style.removeProperty('height');
        }
      }
    }
  }) || _class);
  var _default = _exports.default = FlexibleTextarea;
});