define("plutof/components/navbar/-navbar-base/delete-taxon-occurrence", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "plutof/components/navbar/-navbar-base/delete-record", "plutof/utils/notifications", "plutof/utils/errors", "plutof/utils/i18n", "plutof/utils/model"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _deleteRecord, _notifications, _errors, _i18n, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@navbar.button
      @clicked={{this.openDialog}}
      @icon={{icon "delete"}}
      class="btn-delete"
      data-test="Navbar::NavbarBase::DeleteTaxonOccurrence"
  >
      {{i18n-t "General.Delete"}}
  </@navbar.button>
  
  <Ui::Modal::Dialog
      @open={{this.dialogOpen}}
      @close={{this.closeDialog}}
  >
      <:header>
          {{i18n-t "General.Delete"}}
      </:header>
  
      <:body>
          <Common::DeleteOptions
              @changed={{this.updateDeleteOptions}}
              @invalid={{not this.deleteOptions}} />
      </:body>
  
      <:footer>
          <PromiseButton
              @clicked={{this.deleteRecord}}
              @disabled={{is-equal this.deleteOptions null}}
              class="btn btn-default"
          >
              {{i18n-t "General.Delete"}}
          </PromiseButton>
  
          <button
              {{on "click" this.closeDialog}}
              class="btn btn-default"
              data-test="cancel"
          >
              {{i18n-t "General.Cancel"}}
          </button>
      </:footer>
  </Ui::Modal::Dialog>
  
  */
  {
    "id": "G5nxfnhQ",
    "block": "[[[8,[30,1,[\"button\"]],[[24,0,\"btn-delete\"],[24,\"data-test\",\"Navbar::NavbarBase::DeleteTaxonOccurrence\"]],[[\"@clicked\",\"@icon\"],[[30,0,[\"openDialog\"]],[28,[37,0],[\"delete\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"General.Delete\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@open\",\"@close\"],[[30,0,[\"dialogOpen\"]],[30,0,[\"closeDialog\"]]]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"General.Delete\"],null]],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[8,[39,3],null,[[\"@changed\",\"@invalid\"],[[30,0,[\"updateDeleteOptions\"]],[28,[37,4],[[30,0,[\"deleteOptions\"]]],null]]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[8,[39,5],[[24,0,\"btn btn-default\"]],[[\"@clicked\",\"@disabled\"],[[30,0,[\"deleteRecord\"]],[28,[37,6],[[30,0,[\"deleteOptions\"]],null],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"General.Delete\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[11,\"button\"],[24,0,\"btn btn-default\"],[24,\"data-test\",\"cancel\"],[4,[38,7],[\"click\",[30,0,[\"closeDialog\"]]],null],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"General.Cancel\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@navbar\"],false,[\"icon\",\"i18n-t\",\"ui/modal/dialog\",\"common/delete-options\",\"not\",\"promise-button\",\"is-equal\",\"on\"]]",
    "moduleName": "plutof/components/navbar/-navbar-base/delete-taxon-occurrence.hbs",
    "isStrictMode": false
  });
  const i18n = (0, _i18n.getI18n)();
  function displaySuccess(representation) {
    (0, _notifications.displayNotification)('success', i18n.t('navbar.delete.success', {
      hash: {
        name: representation
      }
    }), null, 10000);
  }
  function displayFailure(representation, error) {
    const message = `${i18n.t('navbar.delete.failure', {
      hash: {
        name: representation
      }
    })}: ` + `${(0, _errors.getErrorMessage)(error)}`;
    (0, _notifications.displayNotification)('error', message);
  }
  function tryDelete(record) {
    const representation = record.get('representation');
    return (0, _model.destroyCompletely)(record).then(() => displaySuccess(representation), error => displayFailure(representation, error));
  }
  let NavbarDeleteTaxonOccurrenceButton = (_class = class NavbarDeleteTaxonOccurrenceButton extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "confirmationDialog", _descriptor, this);
      _initializerDefineProperty(this, "listRoutes", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "dialogOpen", _descriptor4, this);
      _initializerDefineProperty(this, "deleteOptions", _descriptor5, this);
    }
    async deleteWithOptions(record, options) {
      const event = await record.samplingevent;
      const area = await event.samplingarea;
      await tryDelete(record, 'record');
      if (options.remove_events) {
        await tryDelete(event, 'event');
      }
      if (options.remove_areas) {
        await tryDelete(area, 'area');
      }
    }
    deleteRecord() {
      const options = this.deleteOptions;
      return (0, _deleteRecord.deleteRelatedFiles)(this.store, this.args.record, this.args.neverDeleteRelatedFiles, this.confirmationDialog, i18n).then(() => {
        return this.deleteWithOptions(this.args.record, options).then(() => {
          this.store.unloadRecord(this.args.record);
          if (this.args.module) {
            this.listRoutes.reset(this.args.module);
          }
          this.args.removed();
        });
      }).catch(err => {
        this.args.record.rollbackAttributes();
        (0, _notifications.reportError)(err);
      });
    }
    openDialog() {
      this.dialogOpen = true;
    }
    closeDialog() {
      this.dialogOpen = false;
    }
    updateDeleteOptions(options) {
      this.deleteOptions = options;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "confirmationDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "listRoutes", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dialogOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "deleteOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deleteRecord", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteRecord"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDeleteOptions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateDeleteOptions"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NavbarDeleteTaxonOccurrenceButton);
});