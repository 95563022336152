define("plutof/components/clipboard/bulk-update-sampling-area", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "@ember-decorators/component", "plutof/components/clipboard/bulk-update", "plutof/components/sample/area-fields-edit"], function (_exports, _component, _object, _computed, _runloop, _service, _component2, _bulkUpdate, _areaFieldsEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let BulkUpdateSamplingArea = (_dec = (0, _component2.classNames)('bulk-update-sampling-area'), _dec2 = (0, _computed.alias)('data.inputs'), _dec(_class = (_class2 = class BulkUpdateSamplingArea extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "inputs", _descriptor2, this);
      _defineProperty(this, "methods", _areaFieldsEdit.MAP_METHODS);
    }
    init() {
      super.init(...arguments);
      const inputs = {
        samplingarea: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'samplingevent.samplingarea',
          canDelete: false
        }),
        study: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'samplingarea.study'
        }),
        parent_samplingarea: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'samplingarea.parent_samplingarea'
        }),
        country: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'samplingarea.country',
          canDelete: false
        }),
        state: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'samplingarea.state'
        }),
        district: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'samplingarea.district'
        }),
        commune: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'samplingarea.commune'
        }),
        parish: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'samplingarea.parish'
        }),
        coordinate_uncertainty_in_meters: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'samplingarea.coordinate_uncertainty_in_meters'
        }),
        method: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'samplingarea.method'
        })
      };
      const sourceSelectionOptions = [{
        existingArea: true,
        label: 'Clipboard.bulkAreaSwitch'
      }, {
        existingArea: false,
        label: 'Clipboard.bulkAreaEdit'
      }];

      // XXX, See bulk-update-specimen-general
      (0, _runloop.next)(() => {
        this.setProperties({
          'data.inputs': (0, _bulkUpdate.processInputs)(inputs, this.data),
          'sourceSelectionOptions': sourceSelectionOptions,
          'selectedSourceOption': (0, _object.get)(sourceSelectionOptions, 'firstObject')
        });
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "inputs", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class2)) || _class);
  var _default = _exports.default = BulkUpdateSamplingArea;
});