define("plutof/serializers/application", ["exports", "@ember-data/serializer/rest", "@ember/array", "@ember/string", "@ember/utils", "ember-inflector", "plutof/config/environment", "plutof/misc/abstract", "plutof/utils/reflection"], function (_exports, _rest, _array, _string, _utils, _emberInflector, _environment, _abstract, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ApplicationSerializer extends _rest.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "isNewSerializerAPI", true);
    }
    isStringLink(str) {
      return !(0, _utils.isNone)(str.match) && typeof str.match === 'function' && str.match(/^((https?:)?\/\/|\/)\w/);
    }
    recordURL(type, id) {
      return _environment.default.API_HOST + '/' + (0, _emberInflector.pluralize)(type) + '/' + id + '/';
    }
    extractRelationships(modelClass, resourceHash) {
      if (!resourceHash.hasOwnProperty('links')) {
        resourceHash['links'] = {};
      }
      if (!resourceHash.hasOwnProperty('relationships')) {
        resourceHash['relationships'] = {};
      }
      var hasmanyRelations = [];
      modelClass.eachRelationship(function (key, relationshipMeta) {
        var payloadRelKey = this.keyForRelationship(key);
        if (!resourceHash.hasOwnProperty(payloadRelKey)) {
          return;
        }
        if (relationshipMeta.kind === 'hasMany' || relationshipMeta.kind === 'belongsTo') {
          // Matches strings starting with: https://, http://, //, /
          var payloadRel = resourceHash[payloadRelKey];
          if (!(0, _utils.isNone)(payloadRel)) {
            if (this.isStringLink(payloadRel)) {
              delete resourceHash[payloadRelKey];
              resourceHash[payloadRelKey] = {
                id: (0, _reflection.recordURLToID)(payloadRel),
                type: relationshipMeta.type,
                links: {
                  related: payloadRel
                }
              };
            }
            if ((0, _array.isArray)(payloadRel)) {
              const type = relationshipMeta.type;
              var serialized = payloadRel.map(function (link) {
                return {
                  id: (0, _reflection.recordURLToID)(link),
                  type: type
                };
              }, this);
              hasmanyRelations[key] = {
                data: serialized
              };
              resourceHash['relationships'][key] = serialized;
              delete resourceHash[payloadRelKey];
            }
          }
        }
      }, this);
      var result = super.extractRelationships(modelClass, resourceHash);
      return (0, _abstract.mergeObjects)(result, hasmanyRelations);
    }
    extractPageNumber(url) {
      let match = /.*?[?&]page=(\d+).*?/.exec(url);
      if (match) {
        return Number(match[1]).valueOf();
      }
      return null;
    }
    serializeBelongsTo(snapshot, json, relationship) {
      var key = relationship.key;
      if (this._canSerialize(key)) {
        var belongsToId = snapshot.belongsTo(key, {
          id: true
        });
        var payloadKey = this._getMappedKey(key, snapshot.type);
        if (payloadKey === key && this.keyForRelationship) {
          payloadKey = this.keyForRelationship(key, 'belongsTo', 'serialize');
        }
        if ((0, _utils.isNone)(belongsToId)) {
          json[payloadKey] = null;
        } else {
          // The only line different from JSON serializer. Unfortunately, the closest override
          // hook for this is serializeBelongsTo, nothing smaller
          json[payloadKey] = this.recordURL(relationship.type, belongsToId);
        }
        if (relationship.options.polymorphic) {
          this.serializePolymorphicType(snapshot, json, relationship);
        }
      }
    }
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      let convertedPayload = {};
      if (!(0, _utils.isNone)(payload) && payload.hasOwnProperty('next') && payload.hasOwnProperty('previous') && payload.hasOwnProperty('results')) {
        // Move DRF metadata to the meta hash.
        convertedPayload[primaryModelClass.modelName] = JSON.parse(JSON.stringify(payload.results));
        delete payload.results;
        convertedPayload['meta'] = JSON.parse(JSON.stringify(payload));

        // The next and previous pagination URLs are parsed to make it easier to paginate data in applications.
        if (!(0, _utils.isNone)(convertedPayload.meta['next'])) {
          convertedPayload.meta['next'] = this.extractPageNumber(convertedPayload.meta['next']);
        }
        if (!(0, _utils.isNone)(convertedPayload.meta['previous'])) {
          let pageNumber = this.extractPageNumber(convertedPayload.meta['previous']);
          // The DRF previous URL doesn't always include the page=1 query param in the results for page 2. We need to
          // explicitly set previous to 1 when the previous URL is defined but the page is not set.
          if ((0, _utils.isNone)(pageNumber)) {
            pageNumber = 1;
          }
          convertedPayload.meta['previous'] = pageNumber;
        }
      } else {
        convertedPayload[primaryModelClass.modelName] = JSON.parse(JSON.stringify(payload));
      }

      // return single result for requestType 'queryRecord'
      let records = convertedPayload[primaryModelClass.modelName];
      if (requestType === 'queryRecord' && Array.isArray(records)) {
        let first = records.length > 0 ? records[0] : null;
        convertedPayload[primaryModelClass.modelName] = first;
      }
      return super.normalizeResponse(store, primaryModelClass, convertedPayload, id, requestType);
    }
    serializeIntoHash(hash, type, snapshot, options) {
      Object.assign(hash, this.serialize(snapshot, options));
    }
    keyForAttribute(key) {
      return (0, _string.decamelize)(key);
    }
  }
  var _default = _exports.default = ApplicationSerializer;
});