define("plutof/models/batchimport/process", ["exports", "@ember/object", "@ember/object/computed", "@ember-data/model", "moment", "plutof/controllers/import/add", "plutof/models/plutof-model"], function (_exports, _object, _computed, _model, moment, _add, _plutofModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // The date since when imported records can be sent to Clipboard.
  const IMPORTABLE_SINCE_GT = '2018-10-16';
  let ImportProcess = (_dec = (0, _model.belongsTo)('filerepository/file', {
    async: true
  }), _dec2 = (0, _model.belongsTo)('measurement/mainform', {
    async: true
  }), _dec3 = (0, _model.belongsTo)('study/study', {
    async: true
  }), _dec4 = (0, _model.attr)({
    defaultValue: () => []
  }), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('boolean'), _dec10 = (0, _model.attr)('boolean'), _dec11 = (0, _model.attr)('boolean'), _dec12 = (0, _model.attr)('boolean'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('json'), _dec15 = (0, _model.attr)('number'), _dec16 = (0, _model.attr)('number'), _dec17 = (0, _model.attr)('json'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('boolean'), _dec20 = (0, _computed.equal)('status', 'processing'), _dec21 = (0, _computed.equal)('status', 'finished'), _dec22 = (0, _computed.equal)('status', 'errors'), _dec23 = (0, _computed.equal)('status', 'stopping'), _dec24 = (0, _computed.equal)('status', 'stopped'), _dec25 = (0, _object.computed)('module'), _dec26 = (0, _object.computed)('total_imported', 'created_at'), _class = class ImportProcess extends _plutofModel.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "source", _descriptor, this);
      _initializerDefineProperty(this, "mainform", _descriptor2, this);
      _initializerDefineProperty(this, "project", _descriptor3, this);
      _initializerDefineProperty(this, "taxonomies", _descriptor4, this);
      _initializerDefineProperty(this, "format_coordinates", _descriptor5, this);
      _initializerDefineProperty(this, "format_date", _descriptor6, this);
      _initializerDefineProperty(this, "format_datetime", _descriptor7, this);
      _initializerDefineProperty(this, "timezone", _descriptor8, this);
      _initializerDefineProperty(this, "match_predefined_areas", _descriptor9, this);
      _initializerDefineProperty(this, "use_source_event_if_missing", _descriptor10, this);
      _initializerDefineProperty(this, "include_ehak", _descriptor11, this);
      _initializerDefineProperty(this, "sh_match_sequences_by_frontend_url", _descriptor12, this);
      _initializerDefineProperty(this, "module", _descriptor13, this);
      _initializerDefineProperty(this, "selections", _descriptor14, this);
      _initializerDefineProperty(this, "total", _descriptor15, this);
      _initializerDefineProperty(this, "total_imported", _descriptor16, this);
      _initializerDefineProperty(this, "ignored_rows", _descriptor17, this);
      _initializerDefineProperty(this, "status", _descriptor18, this);
      _initializerDefineProperty(this, "is_async", _descriptor19, this);
      _initializerDefineProperty(this, "isBeingProcessed", _descriptor20, this);
      _initializerDefineProperty(this, "isFinished", _descriptor21, this);
      _initializerDefineProperty(this, "isInError", _descriptor22, this);
      _initializerDefineProperty(this, "isStopping", _descriptor23, this);
      _initializerDefineProperty(this, "isStopped", _descriptor24, this);
    }
    get moduleAttributes() {
      return _add.MODULES.findBy('id', this.module);
    }
    set moduleAttributes(module) {
      this.module = module.id;
      return module;
    }
    get disableClipboard() {
      if (!this.total_imported) {
        return true;
      }
      const createdAt = moment(this.get('created_at'));
      const newImportDate = moment(IMPORTABLE_SINCE_GT);

      // This is arranged this way because it is possible for a process
      // record not have created_at value if its import file is missing.
      // This means createdAt date can be invalid; thus can not be after
      // newImportDate, which we use to disable clipboard for this import
      // process.
      return !createdAt.isAfter(newImportDate);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "source", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mainform", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "project", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "taxonomies", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "format_coordinates", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "format_date", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "format_datetime", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "timezone", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "match_predefined_areas", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "use_source_event_if_missing", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "include_ehak", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "sh_match_sequences_by_frontend_url", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "module", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "selections", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "total", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "total_imported", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "ignored_rows", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "is_async", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "isBeingProcessed", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "isFinished", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "isInError", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "isStopping", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "isStopped", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "moduleAttributes", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "moduleAttributes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableClipboard", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "disableClipboard"), _class.prototype), _class);
  var _default = _exports.default = ImportProcess;
});