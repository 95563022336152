define("plutof/templates/sequence/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "qG0U1rqe",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"sequence.add\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@goBack\",\"@infoContent\"],[[28,[37,2],[\"sequence\"],null],[28,[37,3],[\"Sequences.newSequence\"],null],[30,0,[\"model\",\"sequenceData\"]],[30,0,[\"routeActions\",\"triggerReset\"]],[30,0,[\"routeActions\",\"goBack\"]],\"information/content/sequence/add\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@data\",\"@validationContext\",\"@showSourceSelector\",\"@showFormSelector\",\"@quickFormChoices\",\"@requireProject\",\"@allowAreaReset\",\"@preserveMapZoom\"],[[30,0,[\"model\",\"sequenceData\"]],[30,0,[\"validationContext\"]],true,true,[30,0,[\"plutofMainforms\"]],[30,0,[\"parentIsProject\"]],true,[30,0,[\"preserveZoom\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@save\",\"@disabled\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,8],null,[[\"@cancel\"],[[28,[37,9],[[30,0],\"cancel\"],null]]],null],[1,\"\\n\\n        \"],[8,[39,10],[[24,0,\"btn btn-default save-and-new-button save-panel__btn\"]],[[\"@save\",\"@disabled\"],[[30,0,[\"saveAndReset\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"sequence/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\",\"action\",\"common/save-and-reset\"]]",
    "moduleName": "plutof/templates/sequence/add.hbs",
    "isStrictMode": false
  });
});