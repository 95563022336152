define("plutof/components/auto-complete/result", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{hl-subs @value @query}}
  
  {{#if (has-block "suffix")}}
      <span class="plutof-ac-suggestion-description">
          {{yield to="suffix"}}
      </span>
  {{/if}}
  
  */
  {
    "id": "NWFXg2J3",
    "block": "[[[1,[28,[35,0],[[30,1],[30,2]],null]],[1,\"\\n\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[10,1],[14,0,\"plutof-ac-suggestion-description\"],[12],[1,\"\\n        \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@value\",\"@query\",\"&suffix\"],false,[\"hl-subs\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "plutof/components/auto-complete/result.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});