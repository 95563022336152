define("plutof/components/dmp/view/dataset-citations", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object/computed", "ember-concurrency"], function (_exports, _component, _templateFactory, _component2, _tracking, _computed, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isDoi}}
      {{#if (not @onlyCount)}}
          <a href="https://dx.doi.org/{{@dataset.dataset_id.identifier}}" target="_blank">
              {{@dataset.dataset_id.identifier}}
          </a>
      {{/if}}
  
      {{#if this.findCitationsCount.isRunning}}
          <span class="loading-icon"></span>
      {{else if this.citationsCount}}
          <a href="https://opencitations.net/index/search?text={{@dataset.dataset_id.identifier}}&rule=citeddoi" target="_blank">
              {{#if @onlyCount}}
                  {{this.citationsCount}}
              {{else}}
                  - {{this.citationsCount}} {{i18n-t "dmp.dataset.citationsCount"}}
              {{/if}}
          </a>
      {{/if}}
  {{else if (not @onlyCount)}}
      {{@dataset.dataset_id.identifier}}
  {{/if}}
  
  */
  {
    "id": "yewDAyXF",
    "block": "[[[41,[30,0,[\"isDoi\"]],[[[41,[28,[37,1],[[30,1]],null],[[[1,\"        \"],[10,3],[15,6,[29,[\"https://dx.doi.org/\",[30,2,[\"dataset_id\",\"identifier\"]]]]],[14,\"target\",\"_blank\"],[12],[1,\"\\n            \"],[1,[30,2,[\"dataset_id\",\"identifier\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"findCitationsCount\",\"isRunning\"]],[[[1,\"        \"],[10,1],[14,0,\"loading-icon\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"citationsCount\"]],[[[1,\"        \"],[10,3],[15,6,[29,[\"https://opencitations.net/index/search?text=\",[30,2,[\"dataset_id\",\"identifier\"]],\"&rule=citeddoi\"]]],[14,\"target\",\"_blank\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"                \"],[1,[30,0,[\"citationsCount\"]]],[1,\"\\n\"]],[]],[[[1,\"                - \"],[1,[30,0,[\"citationsCount\"]]],[1,\" \"],[1,[28,[35,2],[\"dmp.dataset.citationsCount\"],null]],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n    \"]],[]],null]],[]]]],[]],[[[41,[28,[37,1],[[30,1]],null],[[[1,\"    \"],[1,[30,2,[\"dataset_id\",\"identifier\"]]],[1,\"\\n\"]],[]],null]],[]]]],[\"@onlyCount\",\"@dataset\"],false,[\"if\",\"not\",\"i18n-t\"]]",
    "moduleName": "plutof/components/dmp/view/dataset-citations.hbs",
    "isStrictMode": false
  });
  let DatasetCitations = _exports.default = (_dec = (0, _computed.equal)('args.dataset.dataset_id.type', 'doi'), _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class DatasetCitations extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "citationsCount", _descriptor, this);
      _initializerDefineProperty(this, "isDoi", _descriptor2, this);
      _initializerDefineProperty(this, "findCitationsCount", _descriptor3, this);
      if (this.isDoi) {
        this.findCitationsCount.perform();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "citationsCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isDoi", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "findCitationsCount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        if (!this.isDoi) {
          return;
        }
        const citations = yield this.args.datasetCitations.then(citationsData => citationsData.compact().find(item => item.dataset === this.args.dataset));
        this.citationsCount = citations?.count;
      };
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DatasetCitations);
});