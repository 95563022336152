define("plutof/components/determination/edit-single", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/utils", "ember-cp-validations", "plutof/components/taxonomy/taxon-lineage", "plutof/misc/config", "plutof/config/environment", "plutof/mixins/component-validations", "plutof/utils/model", "plutof/utils/structures"], function (_exports, _component, _object, _service, _utils, _emberCpValidations, _taxonLineage, _config, _environment, _componentValidations, _model, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'dateValidity': (0, _emberCpValidations.validator)('is-truthy')
  });
  function getSpecificParent(store, ajax, taxon, rankId) {
    // Taxon node itself is of given rank
    if (parseInt((0, _model.relationID)(taxon, 'taxon_rank')) === rankId) {
      return taxon;
    }
    return (0, _taxonLineage.fetchLineage)(store, ajax, taxon).then(lineage => {
      return lineage.find(node => parseInt((0, _model.relationID)(node, 'taxon_rank')) === rankId);
    });
  }
  async function fetchCultivarGroups(store, ajax, taxonNode) {
    const taxon = await taxonNode;
    if ((0, _utils.isNone)(taxon)) {
      return [];
    }
    const lineage = await (0, _taxonLineage.fetchLineage)(store, ajax, taxon);
    const parentTaxonIds = lineage.map(taxon => parseInt(taxon.id));
    let rankId;
    if (taxon.id === _config.default.Taxonomy.nodes.orchidFamily || parentTaxonIds.includes(_config.default.Taxonomy.nodes.orchidFamily)) {
      rankId = _config.default.Taxonomy.ranks.family;
    } else {
      rankId = _config.default.Taxonomy.ranks.genus;
    }
    const parentNode = await getSpecificParent(store, ajax, taxon, rankId);
    if ((0, _utils.isNone)(parentNode)) {
      return [];
    }
    const url = `${_environment.default.API_HOST}/determination/cultivar-groups/?taxon_node=${parentNode.id}`;
    return ajax.request(url);
  }
  async function isTaxonPlant(taxonNode, store, ajax) {
    let taxon = await taxonNode;
    if ((0, _utils.isNone)(taxon)) {
      return false;
    }
    let parentTaxon = await taxon.parent;

    // Synonyms have "parent" field as empty and need to fetch lineage for the parent. By backend API design
    if ((0, _utils.isNone)(parentTaxon)) {
      taxon = await taxon.valid_name;
    }
    const lineage = await (0, _taxonLineage.fetchLineage)(store, ajax, taxonNode);
    const lineageIds = lineage.map(taxon => parseInt(taxon.id));
    const plantIds = _config.default.Taxonomy.nodes.viridiplantae;
    return !(0, _utils.isEmpty)((0, _structures.set_intersection)(lineageIds, plantIds));
  }
  let EditSingle = (_dec = (0, _model.choices)('determination.determination.assessment'), _dec2 = (0, _model.choices)('determination.determination.basis'), _dec3 = (0, _object.computed)('entry.determination.taxon_node.id'), _dec4 = (0, _object.computed)('entry.determination.taxon_node.id'), _class = class EditSingle extends _component.default.extend(Validations, _componentValidations.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _defineProperty(this, "dateValidity", false);
      _initializerDefineProperty(this, "assessments", _descriptor3, this);
      _initializerDefineProperty(this, "basises", _descriptor4, this);
    }
    get cultivarGroups() {
      return fetchCultivarGroups(this.store, this.ajax, this.get('entry.determination.taxon_node'));
    }
    get taxonIsPlant() {
      return isTaxonPlant(this.entry.determination.taxon_node, this.store, this.ajax);
    }
    createDeterminer(person) {
      if ((0, _utils.isNone)(person)) {
        return null;
      }
      if (this.entry.determiners.find(determiner => (0, _model.relationID)(determiner, 'person') === person.id)) {
        return null;
      }
      const determiner = this.store.createRecord('determination/determiner', {
        determination: this.get('entry.determination'),
        person
      });
      return determiner;
    }
    addDeterminer(person) {
      if ((0, _utils.isNone)(person)) {
        return null;
      }
      if (this.entry.determiners.find(determiner => (0, _model.relationID)(determiner, 'person') === person.id)) {
        return null;
      }
      const determiner = this.store.createRecord('determination/determiner', {
        determination: this.get('entry.determination'),
        person
      });
      this.entry.determiners.pushObject(determiner);
    }
    removeDeterminer(determiner) {
      this.entry.removeDeterminer(determiner);
    }
    replaceSynonym(taxon) {
      this.entry.determination.taxon_node = taxon;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "assessments", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "basises", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cultivarGroups", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "cultivarGroups"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "taxonIsPlant", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "taxonIsPlant"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createDeterminer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createDeterminer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addDeterminer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addDeterminer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeDeterminer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeDeterminer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "replaceSynonym", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "replaceSynonym"), _class.prototype), _class);
  var _default = _exports.default = EditSingle;
});