define("plutof/components/clipboard/-export/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ExportFormatModel = void 0;
  class ExportFormatModel {
    constructor(_ref) {
      let {
        endpoint
      } = _ref;
      this.endpoint = endpoint;
    }
    prepareSaveData(objectList) {
      return {
        object_list: objectList
      };
    }
    async export(ajax, objectList) {
      const data = this.prepareSaveData(objectList);
      const response = await ajax.request(this.endpoint, {
        method: 'POST',
        processData: false,
        contentType: 'application/json; charset=UTF-8',
        data: JSON.stringify(data)
      });
      return {
        fileID: response.id,
        fileName: response.name
      };
    }
    get valid() {
      return true;
    }
    get canReset() {
      return false;
    }
    reset() {
      throw new Error('Does not support reset');
    }
  }
  _exports.ExportFormatModel = ExportFormatModel;
});