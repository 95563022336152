define("plutof/components/dmp/general", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object/computed", "plutof/utils/validations"], function (_exports, _component, _templateFactory, _component2, _computed, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "dmp.panels.general"}}>
      <Layout::RegularGrid @columns={{2}}>
          <GuidedForm @path="dmp.fields.title" class={{unless this.titleIsValid "has-error"}}>
              <Input @type="text" @value={{@dmp.title}} @class="form-control" />
          </GuidedForm>
  
          <GuidedForm @path="dmp.fields.identifier" class={{unless this.identifierIsValid "has-error"}}>
              <Input @type="text" @value={{@dmp.dmp_id.identifier}} @class="form-control" />
          </GuidedForm>
  
          <GuidedForm @path="dmp.fields.identifierType">
              <PlutofSelect::Value
                  @content={{this.identifierTypes}}
                  @value={{@dmp.dmp_id.type}}
                  @optionLabelPath="display_name"
                  @optionValuePath="value"
                  @prompt={{null}} />
          </GuidedForm>
  
          <GuidedForm @path="dmp.fields.ethical_issues_exist">
              <PlutofSelect::Value
                  @content={{this.ethicalIssues}}
                  @value={{@dmp.ethical_issues_exist}}
                  @optionLabelPath="display_name"
                  @optionValuePath="value"
                  @prompt={{null}} />
          </GuidedForm>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "CRAYyJ5M",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"dmp.panels.general\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],[[16,0,[52,[51,[30,0,[\"titleIsValid\"]]],\"has-error\"]]],[[\"@path\"],[\"dmp.fields.title\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,[[\"@type\",\"@value\",\"@class\"],[\"text\",[30,1,[\"title\"]],\"form-control\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],[[16,0,[52,[51,[30,0,[\"identifierIsValid\"]]],\"has-error\"]]],[[\"@path\"],[\"dmp.fields.identifier\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,[[\"@type\",\"@value\",\"@class\"],[\"text\",[30,1,[\"dmp_id\",\"identifier\"]],\"form-control\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.fields.identifierType\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],null,[[\"@content\",\"@value\",\"@optionLabelPath\",\"@optionValuePath\",\"@prompt\"],[[30,0,[\"identifierTypes\"]],[30,1,[\"dmp_id\",\"type\"]],\"display_name\",\"value\",null]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.fields.ethical_issues_exist\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],null,[[\"@content\",\"@value\",\"@optionLabelPath\",\"@optionValuePath\",\"@prompt\"],[[30,0,[\"ethicalIssues\"]],[30,1,[\"ethical_issues_exist\"]],\"display_name\",\"value\",null]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@dmp\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"guided-form\",\"unless\",\"input\",\"plutof-select/value\"]]",
    "moduleName": "plutof/components/dmp/general.hbs",
    "isStrictMode": false
  });
  let DmpGeneral = _exports.default = (_dec = (0, _computed.notEmpty)('args.dmp.title'), _dec2 = (0, _computed.notEmpty)('args.dmp.dmp_id.identifier'), _class = class DmpGeneral extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "titleIsValid", _descriptor, this);
      _initializerDefineProperty(this, "identifierIsValid", _descriptor2, this);
      _defineProperty(this, "ethicalIssues", [{
        display_name: 'Yes',
        value: 'yes'
      }, {
        display_name: 'No',
        value: 'no'
      }, {
        display_name: 'Unknown',
        value: 'unknown'
      }]);
      _defineProperty(this, "identifierTypes", [{
        display_name: 'Handle',
        value: 'handle'
      }, {
        display_name: 'DOI',
        value: 'doi'
      }, {
        display_name: 'ARK',
        value: 'ark'
      }, {
        display_name: 'URL',
        value: 'url'
      }, {
        display_name: 'Other',
        value: 'other'
      }]);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "titleIsValid", [_validations.validator, _dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "identifierIsValid", [_validations.validator, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DmpGeneral);
});