define("plutof/components/transaction/occurrence-transactions", ["exports", "@ember/component", "@ember/template-factory", "ember-concurrency", "plutof/utils/pagination"], function (_exports, _component, _templateFactory, _emberConcurrency, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::View
      @pagination={{this.pagination}}
      @title={{i18n-t "transactions.title"}}
  >
      <RelatedObjects::Content::Transactions @pagination={{this.pagination}} />
  </RelatedObjects::View>
  
  */
  {
    "id": "ecIInwOi",
    "block": "[[[8,[39,0],null,[[\"@pagination\",\"@title\"],[[30,0,[\"pagination\"]],[28,[37,1],[\"transactions.title\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@pagination\"],[[30,0,[\"pagination\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"related-objects/view\",\"i18n-t\",\"related-objects/content/transactions\"]]",
    "moduleName": "plutof/components/transaction/occurrence-transactions.hbs",
    "isStrictMode": false
  });
  let OccurrenceTransactions = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class OccurrenceTransactions extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "load", _descriptor, this);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.load.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "load", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const query = Object.assign({
          ordering: '-start_date'
        }, this.filters);
        const pagination = yield (0, _pagination.paginateModel)(this.store, 'transaction/transaction', query);
        this.set('pagination', pagination);
      };
    }
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OccurrenceTransactions);
});