define("plutof/routes/datacite/add", ["exports", "@ember/service", "rsvp", "plutof/controllers/publishing/utils", "plutof/components/datacite/edit", "plutof/controllers/dmp/view", "plutof/utils/routes"], function (_exports, _service, _rsvp, _utils, _edit, _view, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DataciteAddRoute = (_class = class DataciteAddRoute extends _routes.AddRoute {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _defineProperty(this, "defaultReturnRoute", 'datacite.index');
      _defineProperty(this, "title", 'Publishing.newDOI');
    }
    async beforeModel(transition) {
      super.beforeModel(...arguments);
      const allowed = await (0, _utils.userIsAllowedToRegisterDOIs)(this.store);
      if (!allowed) {
        transition.abort();
        this.transitionTo('/not-found');
      }
    }
    async model(queryParams) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const doi = await _edit.DataciteEditModel.create(this.store);
      if (queryParams.dmp_id) {
        const dmpJson = await (0, _view.loadDmpFile)('json', queryParams.dmp_id, this.session);
        doi.fillDmpMetadata(dmpJson);
      }
      if (queryParams.resource_file_ids) {
        const files = await _rsvp.default.all(queryParams.resource_file_ids.split(',').map(id => this.store.findRecord('filerepository/file', id)));
        doi.addFiles(files);
      }
      return {
        doi
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = DataciteAddRoute;
});