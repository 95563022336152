define("plutof/components/search/taxon-filter-inputs", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils", "ember-concurrency"], function (_exports, _component, _templateFactory, _component2, _object, _utils, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @filters.taxon_node}}
      <div
          class="filter-input"
          data-input={{@filters.taxon_node.config.label}}
      >
          <Search::Filters::Taxon
              @filters={{@filters}}
              @removed={{this.removeTaxon}} />
      </div>
  
      <div class="filter-input">
          <Common::LocalLabel
              @label="Search.filters.commonName.label"
              @tooltip="Search.filters.commonName.tooltip" />
  
          <Taxonomy::CommonNames::AutoComplete
              @update={{perform this.addCommonName}}
              class="filter-input-ac" />
  
          <Common::RecordList
              @records={{this.localCommonNames}}
              @remove={{perform this._removeCommonName}} />
      </div>
  {{/if}}
  
  */
  {
    "id": "fpKeWd0v",
    "block": "[[[41,[30,1,[\"taxon_node\"]],[[[1,\"    \"],[10,0],[14,0,\"filter-input\"],[15,\"data-input\",[30,1,[\"taxon_node\",\"config\",\"label\"]]],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@filters\",\"@removed\"],[[30,1],[30,0,[\"removeTaxon\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"filter-input\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@tooltip\"],[\"Search.filters.commonName.label\",\"Search.filters.commonName.tooltip\"]],null],[1,\"\\n\\n        \"],[8,[39,3],[[24,0,\"filter-input-ac\"]],[[\"@update\"],[[28,[37,4],[[30,0,[\"addCommonName\"]]],null]]],null],[1,\"\\n\\n        \"],[8,[39,5],null,[[\"@records\",\"@remove\"],[[30,0,[\"localCommonNames\"]],[28,[37,4],[[30,0,[\"_removeCommonName\"]]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@filters\"],false,[\"if\",\"search/filters/taxon\",\"common/local-label\",\"taxonomy/common-names/auto-complete\",\"perform\",\"common/record-list\"]]",
    "moduleName": "plutof/components/search/taxon-filter-inputs.hbs",
    "isStrictMode": false
  });
  let TaxonFilterInput = _exports.default = (_class = class TaxonFilterInput extends _component2.default {
    constructor() {
      super(...arguments);
      // taxonomy/commonname auto-complete value
      //
      // These are purely UI state, the actual filter is taxa added to
      // @filters.taxon_node
      _defineProperty(this, "localCommonNames", []);
      // Add taxon nodes of common names to taxon node search filter input.
      _initializerDefineProperty(this, "addCommonName", _descriptor, this);
      // Remove common names' taxon node from taxon node search filter input.
      _initializerDefineProperty(this, "_removeCommonName", _descriptor2, this);
    }
    // Remove taxon node's common names from common name input.
    removeTaxon(taxon) {
      taxon.vernacular_names.then(commonNames => {
        if ((0, _utils.isNone)(commonNames)) {
          return;
        }
        this.localCommonNames.removeObjects(commonNames.toArray());
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "removeTaxon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeTaxon"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "addCommonName", [_emberConcurrency.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (commonName) {
        if (!commonName) {
          return;
        }
        this.localCommonNames.pushObject(commonName);
        const taxon = yield commonName.taxon_node;
        this.args.filters.taxon_node.value.addObjects([taxon]);
      };
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_removeCommonName", [_emberConcurrency.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (commonName) {
        this.localCommonNames.removeObject(commonName);
        const taxon = yield commonName.taxon_node;
        this.args.filters.taxon_node.value.removeObject(taxon);
      };
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TaxonFilterInput);
});