define("plutof/routes/compound/view", ["exports", "rsvp", "plutof/misc/abstract", "plutof/misc/resetmixin", "plutof/mixins/clear-ui-route", "plutof/utils/routes", "plutof/utils/validations"], function (_exports, _rsvp, _abstract, _resetmixin, _clearUiRoute, _routes, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class CompoundViewRoute extends _routes.ViewRoute.extend(_resetmixin.default, _validations.ValidatedRoute, _clearUiRoute.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "modelTitle", 'cluster');
    }
    model(params) {
      const commonURL = '/globalkey/clustersequencestabels/';
      const countURL = (0, _abstract.construct_request)(`${commonURL}count/`, {
        cluster: params.cluster_id
      });
      const clusterURL = (0, _abstract.construct_request)(commonURL, {
        cluster: params.cluster_id,
        page_size: 100,
        ordering: 'cheat_combined_order'
      });
      return _rsvp.default.hash({
        cluster: this.store.findRecord('globalkey/cluster', params.cluster_id),
        clusterSequences: this.ajax.request(clusterURL),
        totalCount: this.ajax.request(countURL),
        thresholdOrdering: this.store.query('globalkey/threshold', {
          ordering: '-ordering'
        })
      });
    }
  }
  var _default = _exports.default = CompoundViewRoute;
});