define("plutof/components/taxonomy/tree-root", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/service", "@ember/utils", "ember-data", "@ember-decorators/object", "ember-concurrency", "plutof/misc/abstract", "plutof/misc/config", "plutof/utils/push-to-store"], function (_exports, _component, _templateFactory, _object, _service, _utils, _emberData, _object2, _emberConcurrency, _abstract, _config, _pushToStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
      {{#each this.children as |child|}}
          {{#let
              (component "taxonomy/tree-node"
                  expandedNodes=this.expandedNodes
                  selectedTaxon=@selectedTaxon
                  selectedTaxonPath=this.selectedTaxonPath
                  editable=@editable
                  selectTaxon=@selectTaxon)
              as |TreeNode|
          }}
              {{! No letrec to pass @treeNode, unfortunately }}
              <TreeNode
                  @rootNode={{child}}
                  @treeNode={{TreeNode}} />
          {{/let}}
      {{/each}}
  </div>
  
  <div>
      {{#if this._loadPage.isRunning}}
          <span class="loading-icon"></span>
          {{i18n-t "General.loading"}}...
      {{else if this.moreChildrenAvailable}}
          <button
              {{on "click" this.loadMoreChildren}}
              class="btn-fake-link"
          >
              <span class="fas fa-chevron-down"></span>
              {{i18n-t "General.showMore"}}
          </button>
      {{/if}}
  </div>
  
  {{#if @editable}}
      <LinkTo @route="taxonomy.add" @model={{@tree.id}} @query={{hash parent_id=null}}>
          <p class="button-marker__taxon-node-add-button taxon-root-add">
              {{i18n-t "General.Add"}}
          </p>
      </LinkTo>
  {{/if}}
  
  */
  {
    "id": "//B5bPdx",
    "block": "[[[10,0],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"children\"]]],null]],null],null,[[[44,[[50,\"taxonomy/tree-node\",0,null,[[\"expandedNodes\",\"selectedTaxon\",\"selectedTaxonPath\",\"editable\",\"selectTaxon\"],[[30,0,[\"expandedNodes\"]],[30,2],[30,0,[\"selectedTaxonPath\"]],[30,3],[30,4]]]]],[[[1,\"            \"],[8,[30,5],null,[[\"@rootNode\",\"@treeNode\"],[[30,1],[30,5]]],null],[1,\"\\n\"]],[5]]]],[1]],null],[13],[1,\"\\n\\n\"],[10,0],[12],[1,\"\\n\"],[41,[30,0,[\"_loadPage\",\"isRunning\"]],[[[1,\"        \"],[10,1],[14,0,\"loading-icon\"],[12],[13],[1,\"\\n        \"],[1,[28,[35,5],[\"General.loading\"],null]],[1,\"...\\n\"]],[]],[[[41,[30,0,[\"moreChildrenAvailable\"]],[[[1,\"        \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,6],[\"click\",[30,0,[\"loadMoreChildren\"]]],null],[12],[1,\"\\n            \"],[10,1],[14,0,\"fas fa-chevron-down\"],[12],[13],[1,\"\\n            \"],[1,[28,[35,5],[\"General.showMore\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]],null]],[]]],[13],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"    \"],[8,[39,7],null,[[\"@route\",\"@model\",\"@query\"],[\"taxonomy.add\",[30,6,[\"id\"]],[28,[37,8],null,[[\"parent_id\"],[null]]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,2],[14,0,\"button-marker__taxon-node-add-button taxon-root-add\"],[12],[1,\"\\n            \"],[1,[28,[35,5],[\"General.Add\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"child\",\"@selectedTaxon\",\"@editable\",\"@selectTaxon\",\"TreeNode\",\"@tree\"],false,[\"each\",\"-track-array\",\"let\",\"component\",\"if\",\"i18n-t\",\"on\",\"link-to\",\"hash\"]]",
    "moduleName": "plutof/components/taxonomy/tree-root.hbs",
    "isStrictMode": false
  });
  let TreeRoot = (_dec = (0, _object.computed)('selectedTaxonPath.[]'), _dec2 = (0, _object.computed)('selectedTaxon.valid_name', 'selectedTaxon.lineage'), _dec3 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec4 = (0, _object2.observes)('expandedNodes.[]'), _class = class TreeRoot extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "lastTree", null);
      _initializerDefineProperty(this, "_loadPage", _descriptor2, this);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (this.lastTree === this.tree) {
        this.searchForPathNodes();
      } else {
        this.setProperties({
          lastTree: this.tree,
          children: [],
          pageNumber: 1,
          moreChildrenAvailable: false
        });
        this.loadPage(1);
      }
    }
    get expandedNodes() {
      return this.selectedTaxonPath.mapBy('id');
    }
    get selectedTaxonPath() {
      if (!this.selectedTaxon) {
        return [];
      }
      const pathPromise = this.selectedTaxon.get('valid_name').then(valid_name => {
        if ((0, _utils.isNone)(valid_name)) {
          return this.selectedTaxon.get('lineage');
        } else {
          return valid_name.get('lineage').then(lineage => lineage.concat(this.selectedTaxon));
        }
      });
      return _emberData.default.PromiseArray.create({
        promise: pathPromise
      });
    }
    loadPage(number) {
      return this._loadPage.perform(number);
    }
    loadNextPage() {
      this.incrementProperty('pageNumber');
      return this.loadPage(this.pageNumber);
    }
    searchForPathNodes() {
      if (this._loadPage.isRunning) {
        return;
      }
      const path = this.expandedNodes;
      if (path.get('length') === 0) {
        return;
      }
      const load = () => {
        if (this.moreChildrenAvailable && this.children.every(child => !path.includes(child.get('id')))) {
          this.loadNextPage().then(load);
        }
      };
      load();
    }
    loadMoreChildren() {
      this.loadNextPage();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "expandedNodes", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "expandedNodes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedTaxonPath", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectedTaxonPath"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_loadPage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (pageNumber) {
        const treeID = this.get('tree.id');
        if (!treeID) {
          return;
        }
        const url = (0, _abstract.construct_request)(`/taxonomy/trees/${treeID}/root_children/`, {
          page_size: _config.default.Taxonomy.TOPLEVEL_PAGE_SIZE,
          page: pageNumber
        });
        try {
          const childrenData = yield this.ajax.request(url);
          const page = (0, _pushToStore.default)(this.store, 'taxonomy/taxonnode', childrenData);
          this.children.pushObjects(page);
          // /root_children/ endpoint doesn't have /count method, so this will do
          this.set('moreChildrenAvailable', page.length === _config.default.Taxonomy.TOPLEVEL_PAGE_SIZE);
          this.searchForPathNodes();
        } catch (err) {
          this.set('moreChildrenAvailable', false);
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchForPathNodes", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "searchForPathNodes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMoreChildren", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMoreChildren"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TreeRoot);
});