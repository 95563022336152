define("plutof/templates/components/block-label", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "mOovl/Dg",
    "block": "[[[10,\"label\"],[15,0,[29,[[30,0,[\"labelClasses\"]]]]],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n\\n    \"],[1,[30,0,[\"data\",\"labelText\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"enableTooltip\"]],[[[1,\"    \"],[10,1],[14,0,\"plutof-tooltip\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[[30,0,[\"data\",\"tooltipText\"]]],null]],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"data\",\"infoLinks\"]]],null]],null],null,[[[1,\"            \"],[1,[28,[35,5],null,[[\"infoLink\"],[[30,1,[\"url\"]]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[\"info\",\"&default\"],false,[\"yield\",\"if\",\"break-line\",\"each\",\"-track-array\",\"info-link\"]]",
    "moduleName": "plutof/templates/components/block-label.hbs",
    "isStrictMode": false
  });
});