define("plutof/components/filerepository/gallery/-model-viewer-preview", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "ember-concurrency", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _emberConcurrency, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.GalleryModelViewerPreviewController = void 0;
  var _class, _descriptor, _dec, _class2, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.loadModelViewer.isRunning}}
      <span class="gallery-model-viewer-loading-icon"></span>
  {{else if @model}}
      <div id="model-viewer-container">
          <model-viewer
              src={{@model}}
              loading="auto"
              camera-controls
              touch-action="pan-y"
              shadow-intensity="1"
              class="model-viewer {{if @controller.fullscreen 'model-viewer--fullscreen'}}"
          >
          </model-viewer>
  
          {{#if @controller.fullscreen}}
              <button
                  {{on "click" @controller.toggleFullscreen}}
                  class="exit-fullscreen-button"
              >
                  <span class={{icon "compress"}}></span>
              </button>
          {{/if}}
      </div>
  {{/if}}
  
  */
  {
    "id": "DJyEW26Y",
    "block": "[[[41,[30,0,[\"loadModelViewer\",\"isRunning\"]],[[[1,\"    \"],[10,1],[14,0,\"gallery-model-viewer-loading-icon\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[30,1],[[[1,\"    \"],[10,0],[14,1,\"model-viewer-container\"],[12],[1,\"\\n        \"],[10,\"model-viewer\"],[15,\"src\",[30,1]],[14,\"loading\",\"auto\"],[14,\"camera-controls\",\"\"],[14,\"touch-action\",\"pan-y\"],[14,\"shadow-intensity\",\"1\"],[15,0,[29,[\"model-viewer \",[52,[30,2,[\"fullscreen\"]],\"model-viewer--fullscreen\"]]]],[12],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[30,2,[\"fullscreen\"]],[[[1,\"            \"],[11,\"button\"],[24,0,\"exit-fullscreen-button\"],[4,[38,1],[\"click\",[30,2,[\"toggleFullscreen\"]]],null],[12],[1,\"\\n                \"],[10,1],[15,0,[28,[37,2],[\"compress\"],null]],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[\"@model\",\"@controller\"],false,[\"if\",\"on\",\"icon\"]]",
    "moduleName": "plutof/components/filerepository/gallery/-model-viewer-preview.hbs",
    "isStrictMode": false
  });
  let GalleryModelViewerPreviewController = _exports.GalleryModelViewerPreviewController = (_class = class GalleryModelViewerPreviewController {
    constructor() {
      _initializerDefineProperty(this, "fullscreen", _descriptor, this);
      // Initialised simply so that we don't have to check for null
      _defineProperty(this, "_fullscreenAbortController", new AbortController());
    }
    toggleFullscreen() {
      const element = document.querySelector('#model-viewer-container');
      if (element) {
        // Fullscreen exit via button.
        if (document.fullscreenElement) {
          document.exitFullscreen();
          this._fullscreenAbortController.abort();
          this.fullscreen = false;
        } else {
          this._fullscreenAbortController = new AbortController();
          document.addEventListener('fullscreenchange', () => {
            if (!document.fullscreenElement) {
              this._fullscreenAbortController.abort();
              this.fullscreen = false;
            }
          }, {
            signal: this._fullscreenAbortController.signal
          });
          element.requestFullscreen().catch(_notifications.reportError);
          this.fullscreen = true;
        }
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "fullscreen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleFullscreen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFullscreen"), _class.prototype), _class);
  let GalleryModelViewerPreview = (_dec = (0, _emberConcurrency.task)({
    drop: true
  }), _class2 = class GalleryModelViewerPreview extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "loadModelViewer", _descriptor2, this);
      this.loadModelViewer.perform();
    }
  }, _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "loadModelViewer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        yield emberAutoImportDynamic("@google/model-viewer");
      };
    }
  }), _class2);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GalleryModelViewerPreview);
});