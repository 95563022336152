define("plutof/components/search/tabs/gallery", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="equal-flex">
      <div class="equal-flex__child">
          {{@paginationControls}}
      </div>
  
      <@resultsInfo />
  
      <div class="equal-flex__child"></div>
  </div>
  
  <Search::ResultsGallery
      @isLoadingPage={{@pagination.isLoading}}
      @results={{@rows}}
      @mustLoad={{@mustLoadGallery}}
      @announceImages={{this.announceImages}}
      @showOnMap={{@showOnMap}}
      @isActiveTab={{@isActiveTab}} />
  
  {{#if this.hasImages}}
      {{!-- ### PAGINATION ### --}}
      {{@paginationControls}}
  {{/if}}
  
  */
  {
    "id": "T3MEfitR",
    "block": "[[[10,0],[14,0,\"equal-flex\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"equal-flex__child\"],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[30,2],null,null,null],[1,\"\\n\\n    \"],[10,0],[14,0,\"equal-flex__child\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@isLoadingPage\",\"@results\",\"@mustLoad\",\"@announceImages\",\"@showOnMap\",\"@isActiveTab\"],[[30,3,[\"isLoading\"]],[30,4],[30,5],[30,0,[\"announceImages\"]],[30,6],[30,7]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"hasImages\"]],[[[1,\"    \"],[1,[30,1]],[1,\"\\n\"]],[]],null]],[\"@paginationControls\",\"@resultsInfo\",\"@pagination\",\"@rows\",\"@mustLoadGallery\",\"@showOnMap\",\"@isActiveTab\"],false,[\"search/results-gallery\",\"if\"]]",
    "moduleName": "plutof/components/search/tabs/gallery.hbs",
    "isStrictMode": false
  });
  let SearchGalleryTab = (_class = class SearchGalleryTab extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "hasImages", _descriptor, this);
    }
    announceImages(hasImages) {
      this.hasImages = hasImages;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "hasImages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "announceImages", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "announceImages"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SearchGalleryTab);
});