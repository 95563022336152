define("plutof/components/map/cluster-map", ["exports", "@ember/object", "@ember/utils", "ol/layer/Vector", "ol/source/Cluster", "ol/source/Vector", "ol/style", "plutof/components/map/view-map", "plutof/utils/map", "plutof/utils/structures"], function (_exports, _object, _utils, _Vector, _Cluster, _Vector2, _style, _viewMap, _map, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ClusterMap extends _viewMap.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layoutName", 'components/map/base-map');
    }
    init() {
      super.init(...arguments);
      this.set('sizeStyleCache', {});
    }
    createFullToolbar() {
      let buttons = super.createFullToolbar(...arguments);
      if (!(0, _utils.isNone)(buttons.select)) {
        buttons.select.destroy();
      }
      const clusterLayer = this.clusterLayer;
      const nonclusteredLayer = this.nonclusteredLayer;
      function getUnderlyingFeatures(feature) {
        const parts = feature.get('features');
        return (0, _utils.isNone)(parts) ? [feature] : parts;
      }
      buttons.select = _map.default.controls.toolButtons.Select.create({
        layers: [clusterLayer, nonclusteredLayer],
        getStyle: feature => {
          const underlyingFeatures = getUnderlyingFeatures(feature);
          if (underlyingFeatures.length === 1) {
            const underlyingFeature = underlyingFeatures[0];
            if (!(0, _utils.isNone)(underlyingFeature.get('data').rawStyle)) {
              return underlyingFeature.get('data').rawStyle(true);
            }
            return this.getStyle(feature.getGeometry().getType(), {
              styleClass: 'selected'
            });
          } else {
            return this.getClusterStyle(underlyingFeatures.length, {
              selected: true
            });
          }
        },
        selectionChanged: selected => {
          // Select in cluster-
          // operated on two layers that have different feature formats
          //
          // nonclusteredLayer - selection is just a feature, as in base-map
          // clusterLayer - selection is all the features in the cluster (though select filter out
          //                non-size-one clusters)
          //
          // So we need to massage them into a single format before passing to parent methods
          const selection = (0, _structures.concat)(selected.map(feature => getUnderlyingFeatures(feature)));
          this.selectionUpdated(selection);
        }
      });
      return buttons;
    }
    shouldCluster(feature) {
      const data = feature.get('data');
      return feature.getGeometry().getType() === 'Point' && ((0, _utils.isNone)(data) || !(0, _object.get)(data, 'dontCluster'));
    }
    createClusterLayer(featureSource) {
      const clusteredFeatureSource = new _Vector2.default();
      const clusterSource = new _Cluster.default({
        source: clusteredFeatureSource
      });
      let styleCache = this.sizeStyleCache;
      const layer = new _Vector.default({
        source: clusterSource,
        useSpatialIndex: false,
        // XXX
        // TODO: Cluster style
        style: feature => {
          const size = feature.get('features').length;
          if (size === 1) {
            const sourceFeature = feature.get('features')[0];
            return this.getFeatureStyle(sourceFeature.getGeometry().getType(), sourceFeature.get('data'));
          }
          let style = styleCache[size];
          if (!style) {
            style = this.getClusterStyle(size, {
              selected: false
            });
            styleCache[size] = style;
          }
          return style;
        }
      });
      this.set('clusteredFeatureSource', clusteredFeatureSource);
      this.set('clusterLayer', layer);
      return layer;
    }
    getClusterStyle(size) {
      let {
        selected = false
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const classStyle = this.getStyleFromClass(selected ? 'selected' : 'base');
      return new _style.Style({
        image: new _style.Circle({
          radius: 10,
          stroke: new _style.Stroke({
            color: '#fff'
          }),
          fill: new _style.Fill({
            color: classStyle.color
          })
        }),
        text: new _style.Text({
          text: size.toString(),
          fill: new _style.Fill({
            color: '#fff'
          })
        })
      });
    }
    createNonclusteredLayer() {
      const source = new _Vector2.default();
      let layer = new _Vector.default({
        source
      });
      this.set('nonclusteredFeatureSource', source);
      this.set('nonclusteredLayer', layer);
      return layer;
    }
    setupWKTListeners() {
      // Disabling observer in map/base-map
    }
    createCommonLayers() {
      let layers = super.createCommonLayers(...arguments);
      const featureSource = this.featureSource;
      const clusterLayer = this.createClusterLayer(featureSource);
      const nonclusteredLayer = this.createNonclusteredLayer(featureSource);

      // No more raw features
      const featureLayer = this.featureLayer;
      return layers.filter(layer => layer !== featureLayer).concat(nonclusteredLayer, clusterLayer);
    }
    dataLoaded() {
      // Updateclusters
      const clusteredSource = this.clusteredFeatureSource;
      const nonclusteredSource = this.nonclusteredFeatureSource;
      const features = this.featureSource.getFeatures();
      const clusteredFeatures = features.filter(feature => this.shouldCluster(feature));
      const nonclusteredFeatures = features.reject(feature => this.shouldCluster(feature));
      clusteredSource.clear();
      clusteredSource.addFeatures(clusteredFeatures);
      nonclusteredSource.clear();
      nonclusteredSource.addFeatures(nonclusteredFeatures);

      // Have to do it here, before featureSource is emptied
      this.zoomToIncludeEveryFeature();
      this.featureSource.clear(true);
    }
    showSelectionInPopup(selection) {
      if (this.popupEnabled) {
        if (selection.length === 0) {
          this.hidePopup();
        } else {
          const feature = selection[0];
          this.showPopup((0, _map.getGeometryCenter)(feature.getGeometry()));
        }
      }
    }
  }
  var _default = _exports.default = ClusterMap;
});