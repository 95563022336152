define("plutof/models/agent/collection", ["exports", "@ember/object", "@ember-data/model", "plutof/models/plutof-model"], function (_exports, _object, _model, _plutofModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CollectionType = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const CollectionType = _exports.CollectionType = {
    Collection: 2,
    DNACollection: 4
  };
  let Collection = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('boolean'), _dec3 = (0, _model.attr)('enum'), _dec4 = (0, _model.attr)('enum'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.belongsTo)('geography/language'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.belongsTo)('agent/organization', {
    inverse: null
  }), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.belongsTo)('agent/address'), _dec17 = (0, _model.belongsTo)('agent/person'), _dec18 = (0, _model.belongsTo)('users/workgroup'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('json'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('number'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)('number'), _dec28 = (0, _model.attr)('string'), _dec29 = (0, _object.computed)('abbreviation', 'cheat_full_name'), _class = class Collection extends _plutofModel.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "name", _descriptor, this);
      _initializerDefineProperty(this, "is_collection", _descriptor2, this);
      _initializerDefineProperty(this, "organization_level", _descriptor3, this);
      _initializerDefineProperty(this, "organization_type", _descriptor4, this);
      _initializerDefineProperty(this, "abbreviation", _descriptor5, this);
      _initializerDefineProperty(this, "logo_url", _descriptor6, this);
      _initializerDefineProperty(this, "cheat_full_name", _descriptor7, this);
      _initializerDefineProperty(this, "herbarium_name", _descriptor8, this);
      _initializerDefineProperty(this, "original_language", _descriptor9, this);
      _initializerDefineProperty(this, "original_name", _descriptor10, this);
      _initializerDefineProperty(this, "original_herbarium_name", _descriptor11, this);
      _initializerDefineProperty(this, "parent_organization", _descriptor12, this);
      _initializerDefineProperty(this, "birth_date", _descriptor13, this);
      _initializerDefineProperty(this, "death_date", _descriptor14, this);
      _initializerDefineProperty(this, "grbio_url", _descriptor15, this);
      _initializerDefineProperty(this, "address", _descriptor16, this);
      _initializerDefineProperty(this, "contact", _descriptor17, this);
      _initializerDefineProperty(this, "managing_group", _descriptor18, this);
      _initializerDefineProperty(this, "contact_email", _descriptor19, this);
      _initializerDefineProperty(this, "homepage", _descriptor20, this);
      _initializerDefineProperty(this, "organization_hierarchy", _descriptor21, this);
      _initializerDefineProperty(this, "transaction_prefix", _descriptor22, this);
      _initializerDefineProperty(this, "default_is_transaction_public", _descriptor23, this);
      _initializerDefineProperty(this, "specimen_identifier_format_prefix", _descriptor24, this);
      _initializerDefineProperty(this, "specimen_identifier_format_width", _descriptor25, this);
      _initializerDefineProperty(this, "material_sample_identifier_format_prefix", _descriptor26, this);
      _initializerDefineProperty(this, "material_sample_identifier_format_width", _descriptor27, this);
      // Write-only, available during the creation
      _initializerDefineProperty(this, "new_group_name", _descriptor28, this);
    }
    get representation() {
      let prefix = this.abbreviation ? `${this.abbreviation} - ` : '';
      return prefix + this.cheat_full_name;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "is_collection", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "organization_level", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "organization_type", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "abbreviation", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "logo_url", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "cheat_full_name", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "herbarium_name", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "original_language", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "original_name", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "original_herbarium_name", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "parent_organization", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "birth_date", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "death_date", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "grbio_url", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "address", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "contact", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "managing_group", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "contact_email", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "homepage", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "organization_hierarchy", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "transaction_prefix", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "default_is_transaction_public", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "specimen_identifier_format_prefix", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "specimen_identifier_format_width", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "material_sample_identifier_format_prefix", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "material_sample_identifier_format_width", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "new_group_name", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "representation", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "representation"), _class.prototype), _class);
  var _default = _exports.default = Collection;
});