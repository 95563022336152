define("plutof/components/filerepository/edit", ["exports", "@ember/component", "@ember/template-factory", "rsvp", "plutof/components/filerepository/item-list", "plutof/components/linked-items/edit", "plutof/utils/access", "plutof/utils/promises", "plutof/utils/store", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _rsvp, _itemList, _edit, _access, _promises, _store, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loadFileModel = loadFileModel;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Filerepository::-Edit::GeneralData
      @file={{@model.file}}
      @validationContext={{@validationContext.file}}
      @markedForDeleted={{@model.toDelete}}
      @creators={{@model.creators}} />
  
  <PlutofPanel::Simple
      @title={{i18n-t "FileRepository.linkedObjects"}}
      @collapse={{@model.fileItems.isEmpty}}
  >
      <Filerepository::ItemList @data={{@model.fileItems}} />
  </PlutofPanel::Simple>
  
  <LinkedItems::Edit @data={{@model.items}} />
  
  <Access::EditRights @rights={{@model.accessRights}}
      @enableOwnerEdit={{true}}
      @validationContext={{this.validationContext.access}} />
  
  */
  {
    "id": "ocNMHBaZ",
    "block": "[[[8,[39,0],null,[[\"@file\",\"@validationContext\",\"@markedForDeleted\",\"@creators\"],[[30,1,[\"file\"]],[30,2,[\"file\"]],[30,1,[\"toDelete\"]],[30,1,[\"creators\"]]]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@title\",\"@collapse\"],[[28,[37,2],[\"FileRepository.linkedObjects\"],null],[30,1,[\"fileItems\",\"isEmpty\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@data\"],[[30,1,[\"fileItems\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@data\"],[[30,1,[\"items\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@rights\",\"@enableOwnerEdit\",\"@validationContext\"],[[30,1,[\"accessRights\"]],true,[30,0,[\"validationContext\",\"access\"]]]],null],[1,\"\\n\"]],[\"@model\",\"@validationContext\"],false,[\"filerepository/-edit/general-data\",\"plutof-panel/simple\",\"i18n-t\",\"filerepository/item-list\",\"linked-items/edit\",\"access/edit-rights\"]]",
    "moduleName": "plutof/components/filerepository/edit.hbs",
    "isStrictMode": false
  });
  class FileModel {
    constructor(_ref2) {
      let {
        store,
        file,
        creators,
        fileItems,
        items,
        accessRights
      } = _ref2;
      _defineProperty(this, "toDelete", []);
      this.store = store;
      this.file = file;
      this.creators = creators;
      this.fileItems = fileItems;
      this.items = items;
      this.accessRights = accessRights;
    }
    async save() {
      await this.file.save();
      await _rsvp.default.Promise.all(this.toDelete.invoke('destroyRecord'));
      await _rsvp.default.Promise.all([_promises.default.saveSequentially(this.creators), this.items.save(this.file, this.accessRights), this.fileItems.save()]);
      await this.accessRights.saveWithTarget([this.file]);
    }
  }
  const LINK_MODELS = ['agent/organization', 'study/study', 'sample/samplingarea', 'sequence/sequence', 'specimen/specimen', 'livingculture/strain'];
  async function loadFileModel(store, ajax, id) {
    const file = await store.findRecord('filerepository/file', id);
    const [creators, fileItems, items, accessRights] = await _rsvp.default.Promise.all([(0, _store.query)(store, 'filerepository/creator', {
      file: id,
      ordering: 'id'
    }), _itemList.ItemListData.load(store, file, LINK_MODELS), (0, _edit.loadLinkedItemsData)(store, file, {
      disabledTabs: ['files', 'links', 'permits']
    }), (0, _access.get_permissions)(store, ajax, file)]);
    const model = new FileModel({
      store,
      file,
      creators,
      fileItems,
      items,
      accessRights
    });
    return model;
  }
  class FileEdit extends _component.default.extend(_componentValidations.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "validationChildren", [{
        name: 'file',
        label: 'General.generalData'
      }, {
        name: 'metadata',
        label: 'FileRepository.metadata'
      }, {
        name: 'access',
        label: 'General.access'
      }]);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FileEdit);
});