define("plutof/components/sample/view-link", ["exports", "@ember/component", "@ember/utils", "rsvp", "@ember-decorators/component", "plutof/utils/modules"], function (_exports, _component, _utils, _rsvp, _component2, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  let ViewLink = (_dec = (0, _component2.tagName)('span'), _dec2 = (0, _component2.classNames)('clickable'), _dec(_class = _dec2(_class = class ViewLink extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "checkRights", false);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      _rsvp.default.Promise.resolve(this.object).then(object => {
        if ((0, _utils.isNone)(object) || this.isDestroyed) {
          return;
        }
        const module = (0, _modules.getRecordModule)(object);
        this.set('route', module.routes.view);
      });
    }
  }) || _class) || _class);
  var _default = _exports.default = ViewLink;
});