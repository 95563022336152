define("plutof/translations/est/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    import: 'Import',
    generateTemplate: 'Loo alusfail',
    formats: 'Vormindamine',
    selectFile: 'Vali fail',
    datetimeFormat: 'Kuupäeva ja kellaaja vorming',
    ambiguousDatetime: 'Umbmäärane kuupäev [AAAA-KK-PP tt:mm, AAAA-KK-PP, AAAA-KK või AAAA]',
    coordinateFormat: 'Koordinaatide vorming',
    source: 'Fail',
    createdCount: 'Sisestatud kirjed',
    totalCount: 'Importida',
    fixedCount: 'Parandatud kirjed',
    ignoredCount: 'Eiratud kirjed',
    finished: 'Valmis',
    resolutions: 'Resolutions',
    saveSession: 'Laadi alla töötlemata/eiratud kirjed',
    sendFixedRows: 'Salvesta ja jätka',
    sendToClipboardBtn: 'Lisa imporditud kirjed näpitstahvlile',
    statusHeading: 'Olek',
    legendHeading: 'Legend',
    indexTemplatePage: 'Alusfaili loomine',
    indexImportPage: 'Import',
    downloadTemplate: 'Laadi fail alla',
    parameters: 'Lisaseaded',
    async: 'Asünkroonne',
    asyncInfo: 'Suurte (>500 rida) failide jaoks; kasutada siis kui enamik andmevälju on PlutoF-ile juba tuttavad',
    matchPredefinedAreas: 'Sobita eeldefineeritud aladega',
    useSourceEventIfMissing: 'Kasuta seotud kirje ala ja sündmust kui sündmuse tulbad on tühjad',
    copyToClipboard: 'Kopeeri näpitstahvlile',
    dateTimeFormat1: 'AAAA-KK-PP tt:mm',
    dateTimeFormat2: 'PP/KK/AAAA tt:mm',
    timezone: 'Ajatsoon',
    timezoneUTC: 'UTC',
    timezoneLocal: 'Kohalik ({{offset}})',
    selectedFields: 'Valitud väljad',
    includeEhak: 'Täida haldusjaotus väljad automaatselt (Ainult Eesti)',
    coordinateFormats: {
      decimal: 'Kraadid ja komakohad [58.29, -26.42]',
      separatedWithColons: 'Kraadid, minutid, sekundid [50:40:30:N, 20:30:00:W]',
      separatedWithUnits: 'Kraadid, minutid, sekundid [50°40\'30"N, 20°30\'00"W]'
    },
    options: {
      matchSequencesByURL: {
        label: 'Sobita liigihüpoteeside sekventsid URL järgi',
        tooltip: 'Selle asemel, et sobitada sekventsid koodide järgi, kasuta täpsed IDd PlutoF URLide kujul, nt https://app.plutof.ut.ee/sequence/view/1'
      }
    },
    areaMatching: {
      label: 'Aladega sobitamine',
      none: 'Ära sobita',
      predefined: 'Sobita eeldefineritud aladega',
      project: 'Sobita projekti aladega'
    },
    progress: {
      progress: 'Progress',
      created: 'sisestatud',
      ignored: 'eiratud',
      unprocessed: 'töötlemata'
    },
    actions: {
      fixTooltip: 'Paranda vead',
      downloadTooltip: 'Laadi alla töötlemata/eiratud kirjed',
      stopTooltip: 'Peata faili töötlemine',
      clearTooltip: 'Tühista',
      deleteTooltip: 'Tühista ja kustuta fail',
      clipboardTooltip: 'Lisa imporditud kirjed näpitstahvlile',
      selectTemplateFile: {
        label: 'Alusfail',
        tooltip: 'Täidetud alusfail (CSV)'
      },
      save: {
        label: 'Salvesta',
        tooltip: 'Salvesta impordi protsess'
      },
      saveAndStart: {
        label: 'Salvesta ja käivita',
        tooltip: 'Salvesta ja käivita impordi protesess'
      }
    },
    status: {
      waiting: {
        sync: 'Vajab käivitamist',
        async: 'Järjekorras'
      },
      processing: 'Töötlemisel',
      errors: 'Esinevad vead',
      finished: 'Lõpetatud',
      stopped: 'Peatatud',
      stopping: 'Peatamine'
    },
    legend: {
      addRecord: 'Lisa uus kirje (kui puudub PlutoF-ist)',
      needsFix: 'Parandamine on soovituslik (klõpsa väärtuse kopeerimiseks)',
      ignore: 'Eira tervet rida või üksikut välja (eiratud kirje info jääb kättesaadavaks hilisemaks allalaadimiseks)',
      required: 'Parandamine on vajalik',
      sendFixes: 'Salvestab parandatud andmed ja jätkab impordiga',
      back: 'Viib tagasi impordifailide nimekirja',
      stop: 'Laadib alla .CSV faili töötlamata/eiratud kirjetega (käimasolev import peatatakse)'
    },
    info: {
      generateTemplate: 'Alusfaili seadistamine',
      setupImport: 'Impordi protsessi ettevalmistamine täidetud alusfailiga'
    },
    template: {
      column: 'CSV tulba nimi',
      unit: 'Ühik',
      type: 'Tüüp'
    }
  };
});