define("plutof/components/map/multi-popup", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield this.current}}
  
  {{on-change this.reset @items}}
  
  {{#if this.showPagination}}
      <Layout::SplitRow>
          <:left>
              {{#if this.hasPrevious}}
                  <button
                      {{on "click" this.previous}}
                      class="btn-fake-link"
                  >
                      <span class="fas fa-chevron-left"></span>
                  </button>
              {{/if}}
          </:left>
  
          <:center>
              {{this.pageNumber}} / {{@items.length}}
          </:center>
  
          <:right>
              {{#if this.hasNext}}
                  <button
                      {{on "click" this.next}}
                      class="btn-fake-link"
                  >
                      <span class="fas fa-chevron-right"></span>
                  </button>
              {{/if}}
          </:right>
      </Layout::SplitRow>
  {{/if}}
  
  */
  {
    "id": "GIl8Fx/x",
    "block": "[[[18,2,[[30,0,[\"current\"]]]],[1,\"\\n\\n\"],[1,[28,[35,1],[[30,0,[\"reset\"]],[30,1]],null]],[1,\"\\n\\n\"],[41,[30,0,[\"showPagination\"]],[[[1,\"    \"],[8,[39,3],null,null,[[\"left\",\"center\",\"right\"],[[[[1,\"\\n\"],[41,[30,0,[\"hasPrevious\"]],[[[1,\"                \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,4],[\"click\",[30,0,[\"previous\"]]],null],[12],[1,\"\\n                    \"],[10,1],[14,0,\"fas fa-chevron-left\"],[12],[13],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]],[[[1,\"\\n            \"],[1,[30,0,[\"pageNumber\"]]],[1,\" / \"],[1,[30,1,[\"length\"]]],[1,\"\\n        \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"hasNext\"]],[[[1,\"                \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,4],[\"click\",[30,0,[\"next\"]]],null],[12],[1,\"\\n                    \"],[10,1],[14,0,\"fas fa-chevron-right\"],[12],[13],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@items\",\"&default\"],false,[\"yield\",\"on-change\",\"if\",\"layout/split-row\",\"on\"]]",
    "moduleName": "plutof/components/map/multi-popup.hbs",
    "isStrictMode": false
  });
  let MapMultiPopup = _exports.default = (_dec = (0, _object.computed)('effectiveIndex', 'args.items.[]'), _dec2 = (0, _object.computed)('currentIndex', 'args.items.[]'), _class = class MapMultiPopup extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentIndex", _descriptor, this);
    }
    get current() {
      if (!this.args.items) {
        return null;
      }
      return this.args.items[this.effectiveIndex];
    }

    // This is ridiculous, but ember doesn't let me do this properly. If we
    // have items = [a, b], set currentIndex to 1 to show b, then switch to
    // a point with items = [c], currentIndex needs to be reset to 0.
    //
    // However, reacting to component argument changes is unheard of in
    // the "framework for perfectionists with deadlines". Their solution is
    // something like {{on-change}}. Which is a moronic hack. Nevertheless,
    // even if used here, it doesn't work! Something in the rendering order
    // means that @items[this.currentIndex] can become null, even if currentIndex
    // is reset to zero.
    get effectiveIndex() {
      return Math.max(0, Math.min(this.currentIndex, this.args.items.length - 1));
    }
    get showPagination() {
      return this.args.items.length > 1;
    }
    get pageNumber() {
      return this.currentIndex + 1;
    }
    get hasPrevious() {
      return this.currentIndex > 0;
    }
    get hasNext() {
      return this.currentIndex < this.args.items.length - 1;
    }
    previous() {
      this.currentIndex = Math.max(0, this.effectiveIndex - 1);
    }
    next() {
      this.currentIndex = Math.min(this.args.items.length - 1, this.effectiveIndex + 1);
    }
    reset() {
      this.currentIndex = 0;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "current", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "current"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "effectiveIndex", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "effectiveIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previous", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "previous"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "next", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MapMultiPopup);
});