define("plutof/misc/measurements", ["exports", "@ember/application", "@ember/object", "@ember/object/computed", "@ember/utils", "rsvp", "plutof/config/environment", "plutof/helpers/read-only-boolean", "plutof/misc/abstract", "plutof/misc/content_types", "plutof/utils/annotation/format", "plutof/utils/loading", "plutof/utils/reflection", "plutof/utils/structures", "plutof/services/ajax"], function (_exports, _application, _object, _computed, _utils, _rsvp, _environment, _readOnlyBoolean, _abstract, _content_types, _format, _loading, _reflection, _structures, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ObjectMeasurement = void 0;
  _exports.formatRange = formatRange;
  _exports.getMainformData = getMainformData;
  _exports.get_object_form_measurements = get_object_form_measurements;
  _exports.translatedTraitDescription = translatedTraitDescription;
  _exports.translatedTraitName = translatedTraitName;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _ObjectMeasurement;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const FLOAT_REGEXP = new RegExp('^$|^((-[1-9])|\\d)\\d*([.]\\d+)?$');
  const INT_REGEXP = new RegExp('^$|^((-[1-9])|\\d)\\d*');
  function delete_object_measurement(ajax, store, object, om_id) {
    var url = `${_environment.default.API_HOST}/measurement/objectmeasurements/${om_id}/`;
    var data = {
      object_measurement_id: om_id
    };
    return ajax.request(url, {
      method: 'DELETE',
      data: JSON.stringify(data),
      processData: false,
      contentType: 'application/json; charset=UTF-8'
    });
  }
  function shouldTranslateTrait(trait, locale) {
    if (locale !== 'est') {
      return false;
    }
    const language = trait.language;

    // XXX
    if ((0, _utils.isPresent)(language) && typeof language === 'object') {
      return parseInt((0, _object.get)(language, 'id')) === _environment.default.LANG_EST_ID;
    }
    const languageId = (0, _utils.isNone)(language) ? null : (0, _reflection.recordURLToID)(language);
    return parseInt(languageId) === _environment.default.LANG_EST_ID;
  }
  function translatedTraitName(trait, locale) {
    const translate = shouldTranslateTrait(trait, locale);
    return translate ? trait.name_translated ?? trait.name : trait.name;
  }
  function translatedTraitDescription(trait, locale) {
    const translate = shouldTranslateTrait(trait, locale);
    return translate ? trait.description_translated ?? trait.description : trait.description;
  }
  function formatRange(min, max) {
    const minLabel = (0, _utils.isEmpty)(min) ? '...' : min;
    const maxLabel = (0, _utils.isEmpty)(max) ? '...' : max;
    return `${minLabel} - ${maxLabel}`;
  }
  const AGGREGATE_FIELDS = ['individual_count', 'statistical_method', 'dispersion', 'measurement_value_min', 'measurement_value_max', 'measurement_accuracy'];
  const AS_IS_META_FIELDS = ['method_desc', 'warnings', 'determined_at', 'remarks', 'aggregate_measure'];
  const AS_IS_FIELDS = AS_IS_META_FIELDS.concat(AGGREGATE_FIELDS);
  const PROCESSED_FIELDS = ['value', 'determined_by'];
  const ALL_FIELDS = AS_IS_FIELDS.concat(PROCESSED_FIELDS);
  let ObjectMeasurement = _exports.ObjectMeasurement = (_dec = (0, _computed.none)('id'), _dec2 = (0, _computed.equal)('measurementData.type', 'set'), _dec3 = (0, _computed.equal)('measurementData.type', 'int'), _dec4 = (0, _computed.equal)('measurementData.type', 'float'), _dec5 = (0, _computed.equal)('measurementData.type', 'logical'), _dec6 = (0, _object.computed)('measurementData.{name,unit}'), _dec7 = (0, _object.computed)('measurementData.{description,unit}'), _dec8 = (0, _object.computed)('value', 'measurementData', 'locale'), _dec9 = (0, _object.computed)('stringValue', 'method_desc'), _dec10 = (0, _object.computed)('measurementData.set_choices', 'translate'), _dec11 = (0, _object.computed)('measurementdata.type', 'measurementdata.is_required', 'value'), _dec12 = (0, _computed.empty)('value'), _class = (_ObjectMeasurement = class ObjectMeasurement extends _object.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isNew", _descriptor, this);
      _initializerDefineProperty(this, "isSet", _descriptor2, this);
      _initializerDefineProperty(this, "isInteger", _descriptor3, this);
      _initializerDefineProperty(this, "isFloat", _descriptor4, this);
      _initializerDefineProperty(this, "isLogical", _descriptor5, this);
      _defineProperty(this, "augmented", false);
      _initializerDefineProperty(this, "valueIsEmpty", _descriptor6, this);
    }
    init() {
      super.init(...arguments);
      this._initialValue = this.value;
      this._initialMethodDesc = this.method_desc;
      this._initialFields = (0, _structures.select)(this, ALL_FIELDS);
      this.locale = (0, _application.getOwner)(this.store).lookup('service:locale').locale;
    }
    get fullName() {
      const name = translatedTraitName(this.measurementData, this.locale);
      const unit = (0, _object.get)(this.measurementData, 'unit.marker');
      return (0, _utils.isEmpty)(unit) ? name : `${name}(${unit})`;
    }
    get fullDescription() {
      const unit = (0, _object.get)(this.measurementData, 'unit.marker');
      const name = translatedTraitName(this.measurementData, this.locale);
      let description = translatedTraitDescription(this.measurementData, this.locale) || name;
      if (!(0, _utils.isEmpty)(unit)) {
        description = `${description}\n(${unit})`;
      }
      if ((this.measurementData.type === 'int' || this.measurementData.type === 'float') && (!(0, _utils.isEmpty)(this.measurementData.min_allowed_value) || !(0, _utils.isEmpty)(this.measurementData.max_allowed_value))) {
        const range = formatRange(this.measurementData.min_allowed_value, this.measurementData.max_allowed_value);
        description = `${description}\n(${range})`;
      }
      return description;
    }
    get stringValue() {
      let value = (0, _utils.isNone)(this.value) ? '' : this.value;
      const translate = shouldTranslateTrait(this.measurementData, this.locale);
      if ((0, _utils.isPresent)(value) && this.isSet) {
        value = (0, _structures.makeArray)(value).map(v => translate ? v.element_translated : v.element).join(', ');
      } else if (this.isLogical) {
        value = (0, _readOnlyBoolean.translateBoolean)([value]);
      }
      return value;
    }
    get representation() {
      const methodRepresentation = (0, _utils.isPresent)(this.method_desc) ? ` (${this.method_desc})` : '';
      return this.stringValue + methodRepresentation;
    }
    get setChoices() {
      if (!this.measurementData.set_choices) {
        return this.measurementData.set_choices;
      }
      return this.measurementData.set_choices.map(choice => {
        const element = this.translate ? choice.element_translated : choice.element;
        return {
          id: choice.id,
          element,
          label: choice.code ? `${choice.code}: ${element}` : element
        };
      });
    }
    checkRange(value) {
      if (isNaN(value)) {
        return true;
      }
      const {
        min_allowed_value: min,
        max_allowed_value: max
      } = this.measurementData;
      return ((0, _utils.isEmpty)(min) || min <= value) && ((0, _utils.isEmpty)(max) || value <= max);
    }
    get isValid() {
      const value = (0, _utils.isNone)(this.value) ? '' : this.value;
      const {
        is_required,
        type
      } = this.measurementData;
      if (is_required && (0, _utils.isEmpty)(value)) {
        return false;
      }
      switch (type) {
        case 'int':
          return INT_REGEXP.test(value) && this.checkRange(parseInt(value));
        case 'float':
          return FLOAT_REGEXP.test(value) && this.checkRange(parseFloat(value));
        default:
          return true;
      }
    }
    serializeValue(value) {
      if (this.isSet) {
        return this.measurementData.allow_multiple ? value.mapBy('id').join(',') : value.id;
      } else if (this.isLogical) {
        return value ? 'true' : 'false';
      }
      return value;
    }
    _add() {
      return (0, _content_types.get_object_ctype)(this.store, this.object).then(ctype => {
        let data = Object.assign(this._serialize(), {
          object_id: this.object.id,
          // content_type: get_record_url(ctype),
          measurement: this.measurementData.url,
          measurement_id: this.measurementData.id
        });
        if (this.store.inAnnotation) {
          data.content_type = ctype.id;
          this.store.annotatedStore.addChange({
            operation: _format.Operation.Add,
            model: 'measurement/objectmeasurement',
            id: this.store.annotatedStore.generateID(),
            fields: data
          });
          return _rsvp.default.Promise.resolve();
        } else {
          data.content_type = (0, _reflection.get_record_url)(ctype);
          const url = `${_environment.default.API_HOST}/measurement/objectmeasurements/`;
          return this.ajax.request(url, {
            method: 'POST',
            data: JSON.stringify(data),
            processData: false,
            contentType: 'application/json; charset=UTF-8'
          });
        }
      });
    }
    _serialize() {
      let serialized = {};
      const fieldset = this.aggregate_measure ? AS_IS_FIELDS : AS_IS_META_FIELDS;
      for (const field of fieldset) {
        serialized[field] = this[field];
      }
      serialized.value = this.serializeValue(this.value);
      serialized.determined_by = this.determined_by ? (0, _reflection.get_record_url)(this.determined_by) : null;
      return serialized;
    }
    _update() {
      if (this.store.inAnnotation) {
        const diff = {};
        const previousFields = {};
        for (const field of ALL_FIELDS) {
          if (this[field] !== this._initialFields[field]) {
            diff[field] = this[field];
            previousFields[field] = this._initialFields[field];
          }
        }
        if (this.value !== this._initialFields.value) {
          diff.value = this.serializeValue(this.value);
          previousFields.value = this.serializeValue(this._initialFields.value);
        }
        if (Object.keys(diff).length > 0) {
          this.store.annotatedStore.addChange({
            operation: _format.Operation.Modify,
            model: 'measurement/objectmeasurement',
            id: this.id.toString(),
            fields: diff,
            previousFields,
            comments: {}
          });
          this._initialFields = (0, _structures.select)(this, ALL_FIELDS);
        }
        return _rsvp.default.Promise.resolve();
      } else {
        const data = this._serialize();
        const url = `${_environment.default.API_HOST}/measurement/objectmeasurements/${this.id}/`;
        return this.ajax.request(url, {
          method: 'PUT',
          data: JSON.stringify(data),
          processData: false,
          contentType: 'application/json; charset=UTF-8'
        });
      }
    }
    save() {
      if (this.valueIsEmpty) {
        if (!this.isNew) {
          return this.delete();
        } else {
          return _abstract.EMPTY_PROMISE;
        }
      } else {
        if (this.isNew) {
          return this._add();
        } else {
          return this._update();
        }
      }
    }
    delete() {
      if (this.store.inAnnotation) {
        this.store.annotatedStore.addChange({
          operation: _format.Operation.Remove,
          model: 'measurement/objectmeasurement',
          id: this.id,
          // It's bending the format a little, but it's all still in flux
          // anyways
          // TODO: Think about measurements changes just getting their own
          // place in the format, so instead of hacks we can do stuff
          // properly (applying the annotation would get more complex, but
          // that's an easy exchange)
          fields: {},
          previousFields: Object.assign({}, this._initialFields, {
            value: this.serializeValue(this._initialFields.value)
          }),
          comments: {}
        });
        return _rsvp.default.Promise.resolve();
      } else {
        return delete_object_measurement(this.ajax, this.store, this.object, this.id);
      }
    }
  }, _defineProperty(_ObjectMeasurement, "modelName", 'measurement/objectmeasurement'), _ObjectMeasurement), _descriptor = _applyDecoratedDescriptor(_class.prototype, "isNew", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isSet", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isInteger", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isFloat", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isLogical", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fullName", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "fullName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fullDescription", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "fullDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stringValue", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "stringValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "representation", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "representation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setChoices", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setChoices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "isValid"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "valueIsEmpty", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  async function get_object_measurements(store, object) {
    const ctype = await (0, _content_types.get_object_ctype)(store, object);
    const params = {
      content_type: ctype.id,
      object_id: object.id
    };
    const ajax = (0, _ajax.ajaxFromControlled)(store);
    const endpoint = _environment.default.API_HOST + '/measurement/objectmeasurements/';
    let results;
    if (store.inAnnotation) {
      results = await (0, _loading.loadAllRawCountless)(ajax, endpoint, params);
      const additions = store.annotatedStore.rawQueryAdditions('measurement/objectmeasurement', params);
      additions.forEach(addition => addition.augmented = true);
      results.forEach(result => {
        const augmentation = store.annotatedStore.getRecordAugmentation('measurement/objectmeasurement', result.id.toString());
        (0, _object.setProperties)(result, augmentation);
        if (Object.keys(augmentation).length > 0) {
          result.augmented = true;
        }
      });
      results = results.concat(additions);
    } else {
      results = await (0, _loading.loadAllRaw)(ajax, endpoint, params);
    }
    return results;
  }
  const mainformDataCache = {};
  function getMainformData(ajax, id) {
    let {
      forceReload = false
    } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    if ((0, _utils.isNone)(id)) {
      return (0, _abstract.wrap_as_promise)([]);
    }
    if (forceReload || !mainformDataCache[id]) {
      mainformDataCache[id] = ajax.request(`${_environment.default.API_HOST}/measurement/mainforms/${id}/measurements/`);
    }
    return mainformDataCache[id];
  }
  function getObjectForm(mainformData, object) {
    const fullModel = object.constructor.modelName; // 'sample/samplingevent'
    let model;

    // XXX TODO: Refactor this entire module
    if (fullModel === 'redbook/red-list-assessment') {
      model = 'red_list';
    } else {
      model = (0, _object.get)(fullModel.split('/'), 'lastObject'); // 'samplingevent'
    }
    return mainformData[`${model}_form`] || []; // mainformData['samplingevent_form']
  }

  // Object can have two types of OMs - those that come with selected mainform and are always shown,
  // and extra ones that are added manually or left over from mainform change (however that was done).
  async function get_object_form_measurements(store, object, mainform) {
    if ((0, _utils.isNone)(object)) {
      return _rsvp.default.Promise.resolve([]);
    }
    const ajax = (0, _ajax.ajaxFromControlled)(store);
    const mainformId = (0, _utils.isNone)(mainform) ? null : mainform.id;
    const [measurements, existingOMs] = await _rsvp.default.all([getMainformData(ajax, mainformId).then(mainformData => getObjectForm(mainformData, object)), (0, _utils.isNone)(object.id) ? [] : get_object_measurements(store, object)]);
    async function createOM(measurement, existingOM) {
      let props = {
        object,
        ajax,
        store,
        measurementData: measurement
      };
      if (!(0, _utils.isNone)(existingOM)) {
        props.augmented = existingOM.augmented;
        const omValue = existingOM.value;
        let value = omValue;
        if (measurement.type === 'set') {
          const elements = (0, _structures.makeArray)(omValue).map(elementID => {
            return measurement.set_choices.findBy('id', elementID);
          });
          value = measurement.allow_multiple ? elements : elements[0];
        }
        props.value = value;
        props.id = existingOM.id;
        for (const field of AS_IS_FIELDS) {
          props[field] = existingOM[field];
        }
        props.determined_by = (0, _utils.isNone)(existingOM.determined_by) ? null : await store.findRecord('agent/person', (0, _reflection.recordURLToID)(existingOM.determined_by));
      } else if (measurement.allow_multiple) {
        props.value = [];
      }
      return ObjectMeasurement.create(props);
    }

    // Mainform measurements go first. I object already has a value for some mainform measurement, it gets
    // mapped here. Note that this part is mainform-guided, because form measurements can have a defined
    // ordering
    const formMeasurements = await _rsvp.default.all(measurements.map(measurement => {
      const correspondingOM = existingOMs.find(om => {
        return om.measurement_id === measurement.id && om.object_id.toString() === object.id;
      });
      return createOM(measurement, correspondingOM);
    }));

    // Remaining OM values are put at the end
    const usedUp = new Set(formMeasurements.mapBy('measurementData.id'));
    const extraMeasurements = await _rsvp.default.all(existingOMs.filter(om => !usedUp.has(om.measurement_id)).map(async om => {
      const measurement = await store.findRecord('measurement/measurement', om.measurement_id);
      return createOM(measurement, om);
    }));
    return formMeasurements.concat(extraMeasurements);
  }
});