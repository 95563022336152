define("plutof/components/taxonomy-lab/taxon-description/view", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.generalData"}}
      data-test="TaxonomyLab::TaxonDescription::View"
  >
      <GenericViews::Record @record={{@taxonDescription}} as |view|>
          <Layout::RegularGrid @columns={{2}}>
              {{view.link "taxon_node" route="taxonomy.view"}}
  
              {{#view.attr "language"}}
                  {{@taxonDescription.language.representation}}
              {{/view.attr}}
  
              {{#each fields as |field|}}
                  {{view.attr field}}
              {{/each}}
          </Layout::RegularGrid>
      </GenericViews::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "VSQ0uy+D",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"TaxonomyLab::TaxonDescription::View\"]],[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,2,[\"link\"]],[\"taxon_node\"],[[\"route\"],[\"taxonomy.view\"]]]],[1,\"\\n\\n\"],[6,[30,2,[\"attr\"]],[\"language\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"language\",\"representation\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[33,6]],null]],null],null,[[[1,\"                \"],[1,[28,[30,2,[\"attr\"]],[[30,3]],null]],[1,\"\\n\"]],[3]],null],[1,\"        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@taxonDescription\",\"view\",\"field\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"generic-views/record\",\"layout/regular-grid\",\"each\",\"-track-array\",\"fields\"]]",
    "moduleName": "plutof/components/taxonomy-lab/taxon-description/view.hbs",
    "isStrictMode": false
  });
  class TaxonDescriptionView extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "fields", ['description', 'ecology', 'phylogeny', 'distribution', 'diagnostic_description', 'morphology', 'size', 'growth', 'look_alikes', 'habitat', 'trophic_strategy', 'behaviour', 'life_cycle', 'reproduction', 'dispersal', 'associations', 'migration', 'population_biology', 'trends', 'threats', 'risk_statement', 'conservation_status', 'legislation', 'use_field', 'key_field', 'remarks']);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TaxonDescriptionView);
});