define("plutof/components/search/filter-input", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils", "ember-data", "@ember-decorators/component", "plutof/controllers/search/index", "plutof/misc/abstract", "plutof/mixins/search-filter", "plutof/utils/notifications", "plutof/utils/search"], function (_exports, _component, _object, _computed, _utils, _emberData, _component2, _index, _abstract, _searchFilter, _notifications, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function getModelPath(modelName) {
    const model = modelName.replace('auth/', 'users/');
    return model === 'contenttypes/contenttype' ? 'contenttype' : model;
  }
  function extractLastNumber(str) {
    return str.match(/\d+/g).pop();
  }
  let FilterInput = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _object.computed)('classes'), _dec3 = (0, _computed.or)('label', 'filter.config.label'), _dec4 = (0, _computed.equal)('filter.config.type', 'string'), _dec5 = (0, _computed.equal)('filter.config.type', 'choice'), _dec6 = (0, _computed.equal)('filter.config.type', 'integer'), _dec7 = (0, _computed.equal)('filter.config.type', 'boolean'), _dec8 = (0, _computed.equal)('filter.config.type', 'datetime'), _dec9 = (0, _computed.alias)('filter.config.autocomplete'), _dec10 = (0, _object.computed)('isInteger', 'isAutocomplete', 'isMultipleChoice'), _dec11 = (0, _object.computed)('filter.name'), _dec12 = (0, _object.computed)('isRange', 'filters'), _dec13 = (0, _object.computed)('contentTypeId', 'isAutocomplete'), _dec14 = (0, _computed.and)('isChoice', 'configChoices.isFulfilled'), _dec15 = (0, _computed.and)('isMultipleChoice', 'multipleChoices.isFulfilled'), _dec16 = (0, _computed.and)('isAutocomplete', 'autocompleteModel.isFulfilled'), _dec17 = (0, _object.computed)('isMultipleChoice', 'contentQueryArguments', 'contentType'), _dec18 = (0, _object.computed)('filter.config.choices'), _dec19 = (0, _object.computed)('isAutocomplete', 'contentType'), _dec20 = (0, _object.computed)('queryArguments', 'filter.queryArguments'), _dec21 = (0, _object.computed)('contentQueryArguments'), _dec22 = (0, _object.computed)('filter.config.content_type'), _dec23 = (0, _object.computed)('contentTypeId'), _dec(_class = (_class2 = class FilterInput extends _component.default.extend(_searchFilter.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "contentArguments", null);
      _initializerDefineProperty(this, "filterLabel", _descriptor, this);
      _initializerDefineProperty(this, "isString", _descriptor2, this);
      _initializerDefineProperty(this, "isChoice", _descriptor3, this);
      _initializerDefineProperty(this, "isInteger", _descriptor4, this);
      _initializerDefineProperty(this, "isBoolean", _descriptor5, this);
      _initializerDefineProperty(this, "isDatetime", _descriptor6, this);
      _initializerDefineProperty(this, "isAutocomplete", _descriptor7, this);
      _initializerDefineProperty(this, "showSelectionInput", _descriptor8, this);
      _initializerDefineProperty(this, "showMultipleSelectionInput", _descriptor9, this);
      _initializerDefineProperty(this, "showAutocompleteInput", _descriptor10, this);
    }
    get styleClasses() {
      return this.classes + ' filter-input';
    }
    get isNumber() {
      return this.isInteger && !this.isAutocomplete && !this.isMultipleChoice;
    }
    get isRange() {
      return _search.RANGE_FILTERS.includes(this.get('filter.name'));
    }
    get rangeInput() {
      const name = this.filter.name;
      return {
        name,
        inputSets: [(0, _index.getDateFilterSet)(name, '<=', this.filters)]
      };
    }
    get isMultipleChoice() {
      const ctid = this.contentTypeId;
      const isAutocomplete = this.isAutocomplete;
      return (0, _utils.isPresent)(ctid) && !isAutocomplete;
    }
    get multipleChoices() {
      const isMultipleChoice = this.isMultipleChoice;
      const contentQueryArguments = this.contentQueryArguments;
      const promise = this.contentType.then(ct => {
        const modelPath = getModelPath(ct.get('model_name'));
        if ((0, _utils.isNone)(contentQueryArguments)) {
          return this.store.findAll(modelPath);
        } else {
          return this.store.query(modelPath, contentQueryArguments);
        }
      }).catch(_notifications.reportError);
      return _emberData.default.PromiseObject.create({
        promise: isMultipleChoice ? promise : _abstract.EMPTY_PROMISE
      });
    }
    get configChoices() {
      const choices = this.filter.config.choices ?? [];
      const selectionChoices = choices.map(choice => {
        if ((0, _utils.typeOf)(choice) === 'object') {
          return {
            display_name: choice.display_name,
            value: String(choice.value)
          };
        } else {
          return choice;
        }
      });
      return _emberData.default.PromiseArray.create({
        promise: (0, _abstract.wrap_as_promise)(selectionChoices)
      });
    }
    get autocompleteModel() {
      const isAutocomplete = this.isAutocomplete;
      const promise = this.contentType.then(ct => {
        return getModelPath(ct.get('model_name'));
      }).catch(_notifications.reportError);
      return _emberData.default.PromiseObject.create({
        promise: isAutocomplete ? promise : _abstract.EMPTY_PROMISE
      });
    }
    get contentQueryArguments() {
      return this.contentArguments || this.get('filter.queryArguments');
    }
    get autocompleteFilters() {
      // Default to {} to overwrite the default access=edit filter
      return this.contentQueryArguments || {};
    }
    get contentTypeId() {
      const filter = this.filter;
      const ct = (0, _utils.isPresent)(filter) ? filter.config['content_type'] : null;
      return (0, _utils.isPresent)(ct) ? extractLastNumber(ct) : null;
    }
    get contentType() {
      const ctid = this.contentTypeId;
      if ((0, _utils.isPresent)(ctid)) {
        return _emberData.default.PromiseObject.create({
          promise: this.store.findRecord('contenttype', ctid)
        });
      } else {
        return _abstract.EMPTY_PROMISE;
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "styleClasses", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "styleClasses"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "filterLabel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isString", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isChoice", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isInteger", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "isBoolean", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "isDatetime", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "isAutocomplete", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isNumber", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "isNumber"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isRange", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "isRange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "rangeInput", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "rangeInput"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isMultipleChoice", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "isMultipleChoice"), _class2.prototype), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "showSelectionInput", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "showMultipleSelectionInput", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "showAutocompleteInput", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "multipleChoices", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "multipleChoices"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "configChoices", [_dec18], Object.getOwnPropertyDescriptor(_class2.prototype, "configChoices"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "autocompleteModel", [_dec19], Object.getOwnPropertyDescriptor(_class2.prototype, "autocompleteModel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "contentQueryArguments", [_dec20], Object.getOwnPropertyDescriptor(_class2.prototype, "contentQueryArguments"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "autocompleteFilters", [_dec21], Object.getOwnPropertyDescriptor(_class2.prototype, "autocompleteFilters"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "contentTypeId", [_dec22], Object.getOwnPropertyDescriptor(_class2.prototype, "contentTypeId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "contentType", [_dec23], Object.getOwnPropertyDescriptor(_class2.prototype, "contentType"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = FilterInput;
});