define("plutof/components/tab-group", ["exports", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Tab extends _object.default {}
  class TabGroup extends _component.default {
    init() {
      super.init(...arguments);
      this.set('tabs', []);
      this.set('headersContainerId', Math.floor(Math.random() * 1e9).toString());
    }
    createTab(name) {
      return Tab.create({
        name,
        selected: false
      });
    }
    getTab(name) {
      const tabs = this.tabs;
      const selectedName = this.selected;
      let tab = tabs.findBy('name', name);
      if ((0, _utils.isNone)(tab)) {
        tab = this.createTab(name);
        tabs.pushObject(tab);

        // TODO: external initial selection
        const selected = selectedName === name || (0, _utils.isNone)(selectedName) && tabs.get('length') === 1;
        tab.set('selected', selected);
        return tab;
      }
      return tab;
    }
    removeTab(name) {
      const tab = this.getTab(name);
      if (tab?.selected) {
        const newSelectedIndex = Math.min(this.tabs.indexOf(tab), this.tabs.length - 2);
        this.tabs.removeObject(tab);
        const newSelected = this.tabs[newSelectedIndex];
        if (newSelected) {
          this.switchTab(newSelected);
        }
      }
    }
    notifySelection(tab) {
      if (!(0, _utils.isNone)(this.tabSwitched)) {
        this.tabSwitched(tab.get('name'));
      }
    }
    switchTab(tab) {
      let manual = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.tabs.forEach(t => {
        t.set('selected', t === tab);
      });
      if (manual) {
        this.notifySelection(tab);
      }
    }
    get headersContainer() {
      return document.getElementById(this.headersContainerId);
    }
  }
  var _default = _exports.default = TabGroup;
});