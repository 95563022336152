define("plutof/components/analysis/type-itsx", ["exports", "@ember/component", "@ember-decorators/component", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _component, _component2, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'run.domains': (0, _emberCpValidations.validator)('presence', true),
    'run.minlen': (0, _emberCpValidations.validator)('number', {
      allowString: true,
      allowBlank: true,
      integer: true
    }),
    'run.partial': (0, _emberCpValidations.validator)('number', {
      allowString: true,
      allowBlank: true,
      integer: true
    }),
    'run.anchor': (0, _emberCpValidations.validator)('number', {
      allowString: true,
      allowBlank: true,
      integer: true
    })
  });
  let TypeItsx = (_dec = (0, _component2.classNames)('type-itsx'), _dec(_class = class TypeItsx extends _component.default.extend(Validations, _componentValidations.default) {
    constructor() {
      super(...arguments);
      // Run.organism
      _defineProperty(this, "organismContent", [{
        value: 'F',
        display_name: 'Fungi'
      }, {
        value: 'A',
        display_name: 'Alveolata (alveolates)'
      }, {
        value: 'B',
        display_name: 'Bryophyta (mosses)'
      }, {
        value: 'C',
        display_name: 'Bacillariophyta (diatoms)'
      }, {
        value: 'D',
        display_name: 'Amoebozoa'
      }, {
        value: 'E',
        display_name: 'Euglenozoa'
      }, {
        value: 'G',
        display_name: 'Chlorophyta (green-algae)'
      }, {
        value: 'H',
        display_name: 'Rhodophyta (red-algae)'
      }, {
        value: 'I',
        display_name: 'Phaeophyceae (brown-algae)'
      }, {
        value: 'L',
        display_name: 'Marchantiophyta (liverworts)'
      }, {
        value: 'M',
        display_name: 'Metazoa (animals)'
      }, {
        value: 'O',
        display_name: 'Oomycota (oomycetes)'
      }, {
        value: 'P',
        display_name: 'Haptophyceae (prymnesiophytes)'
      }, {
        value: 'Q',
        display_name: 'Raphidophyceae (raphidophytes)'
      }, {
        value: 'R',
        display_name: 'Rhizaria'
      }, {
        value: 'S',
        display_name: 'Synurophyceae (synurids)'
      }, {
        value: 'T',
        display_name: 'Tracheophyta (higher-plants)'
      }, {
        value: 'U',
        display_name: 'Eustigmatophyceae (eustigmatophytes)'
      }, {
        value: 'X',
        display_name: 'Apusozoa'
      }, {
        value: 'Y',
        display_name: 'Parabasalia (parabasalids)'
      }, {
        value: '',
        display_name: 'All'
      }]);
      _defineProperty(this, "domainsContent", [1, 2, 3]);
    }
  }) || _class);
  var _default = _exports.default = TypeItsx;
});