define("plutof/components/search/filter-panels/material-sample-properties", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelProperties"}}
      @collapse={{true}}
  >
      <Search::FilterPanels::CommonProperties::Agents @filterInputs={{@filterInputs}} />
  
      <Layout::RegularGrid @columns={{6}} as |grid|>
          <Search::Filters::Project @filter={{@filterInputs.study}} />
  
          {{#grid.span 2}}
              <Search::RepositoryInput
                  @repositoryFilter={{@filterInputs.deposited_in}}
                  @subrepositoryFilter={{@filterInputs.sub_repository}} />
          {{/grid.span}}
  
          <Search::FilterInput @filter={{@filterInputs.mainform}} />
          <Search::FilterInput @filter={{@filterInputs.references}} />
          <Search::FilterInput @filter={{@filterInputs.keywords}} />
  
          {{#if @filterInputs.additional_identifiers}}
              {{#grid.span 2}}
                  <Search::Filters::AdditionalIdentifiers
                      @filter={{@filterInputs.additional_identifiers}} />
              {{/grid.span}}
          {{/if}}
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterPanels::CommonProperties::Checkboxes @filterInputs={{@filterInputs}} />
          <Search::FilterInput @filter={{@filterInputs.is_restricted}} />
          <Search::FilterInput @filter={{@filterInputs.on_loan}} />
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "Lvk6U6zc",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"Search.panelProperties\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@filterInputs\"],[[30,1]]],null],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"study\"]]]],null],[1,\"\\n\\n\"],[6,[30,2,[\"span\"]],[2],null,[[\"default\"],[[[[1,\"            \"],[8,[39,5],null,[[\"@repositoryFilter\",\"@subrepositoryFilter\"],[[30,1,[\"deposited_in\"]],[30,1,[\"sub_repository\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n        \"],[8,[39,6],null,[[\"@filter\"],[[30,1,[\"mainform\"]]]],null],[1,\"\\n        \"],[8,[39,6],null,[[\"@filter\"],[[30,1,[\"references\"]]]],null],[1,\"\\n        \"],[8,[39,6],null,[[\"@filter\"],[[30,1,[\"keywords\"]]]],null],[1,\"\\n\\n\"],[41,[30,1,[\"additional_identifiers\"]],[[[6,[30,2,[\"span\"]],[2],null,[[\"default\"],[[[[1,\"                \"],[8,[39,8],null,[[\"@filter\"],[[30,1,[\"additional_identifiers\"]]]],null],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"    \"]],[2]]]]],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,9],null,[[\"@filterInputs\"],[[30,1]]],null],[1,\"\\n        \"],[8,[39,6],null,[[\"@filter\"],[[30,1,[\"is_restricted\"]]]],null],[1,\"\\n        \"],[8,[39,6],null,[[\"@filter\"],[[30,1,[\"on_loan\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\",\"grid\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"search/filter-panels/common-properties/agents\",\"layout/regular-grid\",\"search/filters/project\",\"search/repository-input\",\"search/filter-input\",\"if\",\"search/filters/additional-identifiers\",\"search/filter-panels/common-properties/checkboxes\"]]",
    "moduleName": "plutof/components/search/filter-panels/material-sample-properties.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});