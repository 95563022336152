define("plutof/routes/datacite/edit", ["exports", "rsvp", "plutof/components/datacite/edit", "plutof/utils/routes"], function (_exports, _rsvp, _edit, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class DataciteEditRoute extends _routes.EditRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "confirmExit", true);
      _defineProperty(this, "defaultReturnRoute", 'datacite.index');
    }
    async model(queryParams) {
      const doi = await _edit.DataciteEditModel.load(this.store, queryParams.metadata_id);
      if (queryParams.resource_file_ids) {
        const files = await _rsvp.default.all(queryParams.resource_file_ids.split(',').map(id => this.store.findRecord('filerepository/file', id)));
        doi.addFiles(files);
      }
      return {
        doi
      };
    }
    getTitle(model) {
      return model.doi.metadata.representation;
    }
  }
  var _default = _exports.default = DataciteEditRoute;
});