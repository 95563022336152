define("plutof/components/pagination/load-more", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let LoadMore = (_dec = (0, _object.computed)('showInterface', 'pagination.objects.[]', 'pagination.objectCount'), _class = class LoadMore extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "pagination", null);
      _defineProperty(this, "showInterface", true);
    }
    get showControls() {
      return this.showInterface && this.get('pagination.objects.length') < this.get('pagination.objectCount');
    }
    more() {
      this.pagination.nextPage();
    }
    async all() {
      while (!this.pagination.onLastPage) {
        await this.pagination.nextPage();
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "showControls", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "showControls"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "more", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "more"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "all", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "all"), _class.prototype), _class);
  var _default = _exports.default = LoadMore;
});