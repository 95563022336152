define("plutof/components/map/grid", ["exports", "@glimmer/tracking", "ember-concurrency", "rsvp", "ol/layer/Group", "ol/layer/Vector", "ol/source/Cluster", "latlon-geohash", "ol/Feature", "ol/format", "ol/geom", "ol/geom/Polygon", "ol/source/Vector", "ol/style", "plutof/components/map/view-map", "plutof/config/environment", "plutof/misc/abstract", "plutof/utils/map", "ol/proj"], function (_exports, _tracking, _emberConcurrency, _rsvp, _Group, _Vector, _Cluster, _latlonGeohash, _Feature, _format, _geom, _Polygon, _Vector2, _style, _viewMap, _environment, _abstract, _map, _proj) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SearchGridSource = _exports.GridSource = _exports.GridModel = void 0;
  var _class, _descriptor, _dec, _class2, _descriptor2; // TODO: This was already imported from some old WMS-based search map attempt, which
  // didn't get used or something? Anyway, there are newer packages, but this is still fine
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // Starting with level 1, so careful with off-by-one errors
  const GEOHASH_CELL_WIDTHS = [5_000_000, 1_250_000, 156_000, 39_100, 4_890, 1_220, 153, 38.2, 4.77, 1.19, 0.149, 0.0372];
  const GEOHASH_CELL_MIN_WIDTH_IN_PIXELS = 3;
  let GridSource = _exports.GridSource = (_class = class GridSource {
    // Having extent here makes creating a source complicated, but
    // leaving extent calculation until after the data is loaded
    // makes an even bigger mess
    constructor(_ref) {
      let {
        count,
        extent
      } = _ref;
      _initializerDefineProperty(this, "_loadingCount", _descriptor, this);
      this.count = count;
      this.extent = extent;
    }

    // What's the output of this? Just a list of cells with counts?
    // Probably, then convert it to geometry later. The benefit of this
    // is that precision negotion can skip conversion if the precision
    // level is a bust
    //
    // : { cells: { [geohash: string]: number }, overflow: boolean }
    loadGrid(_ref2) {
      let {
        precision,
        limit
      } = _ref2;
      return this._trackLoading(this._loadGrid({
        precision,
        limit
      }));
    }

    // Extent is optional, when loading the entire result set
    //
    // : { points: [ { wkt: string, payload: unknown } ] }
    loadPoints() {
      let {
        extent
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this._trackLoading(this._loadPoints({
        extent
      }));
    }
    get isLoading() {
      return this._loadingCount > 0;
    }

    // Override in sublcasses
    // :: -> { grid: { [geohash:str]: int } }
    _loadGrid(_ref3) {
      let {
        precision,
        limit
      } = _ref3;
      throw new Error('Not implemented');
    }

    // Override in sublcasses
    // :: -> { points: [{ wkt, payload }] }
    _loadPoints(_ref4) {
      let {
        extent
      } = _ref4;
      throw new Error('Not implemented');
    }
    async _trackLoading(promise) {
      try {
        this._loadingCount++;
        return await promise;
      } finally {
        this._loadingCount--;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_loadingCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _class);
  class SearchGridSource extends GridSource {
    // Ideally, this would take external search query, but that
    // would have to be converted into these two, which requires a whole
    // lot of setup that is currently deeply integrated into the search
    // route controller, so this turns out to be cleaner.
    static async setup(_ref5) {
      let {
        ajax,
        module,
        urlQuery
      } = _ref5;
      const response = await ajax.request(`${_environment.default.API_HOST}/${module.value}/search/map/extent/?${urlQuery}`);
      let extent;
      if (response.extent) {
        extent = [response.extent.top_left.lon, response.extent.top_left.lat, response.extent.bottom_right.lon, response.extent.bottom_right.lat];
      } else {
        // Country extent, seems a better choice than
        // zooming out completely
        extent = [21.40294, 57.39415, 28.30515, 59.86187];
      }
      return new SearchGridSource({
        ajax,
        module,
        urlQuery,
        count: response.count,
        extent
      });
    }
    constructor(_ref6) {
      let {
        ajax,
        module,
        urlQuery,
        count,
        extent
      } = _ref6;
      super({
        count,
        extent
      });
      this.ajax = ajax;
      this.module = module;
      this.urlQuery = urlQuery;
    }
    async _loadGrid(_ref7) {
      let {
        precision,
        limit
      } = _ref7;
      const params = new URLSearchParams(this.urlQuery);
      params.set('precision', precision);
      params.set('max_cells', limit);
      const response = await this.ajax.request(`${_environment.default.API_HOST}/${this.module.value}/search/map/grid/?${params}`);
      const cells = {};
      for (const bucket of response.grid) {
        cells[bucket.key] = bucket.count;
      }
      return {
        cells,
        overflow: Object.keys(cells).length >= limit
      };
    }
    async _loadPoints(_ref8) {
      let {
        extent
      } = _ref8;
      const pageSize = 500;
      const params = new URLSearchParams(this.urlQuery);
      params.set('page_size', pageSize);
      if (extent) {
        if (this.urlQuery.get('geom')) {
          // XXX TODO: Handle this by using intersection of extent and geom
          console.error('Search includes geom filter, zoomed-in points map won\' take it into account');
        }
        const wkt = new _format.WKT();
        const extentGeometry = (0, _Polygon.fromExtent)(extent);
        params.set('geom', wkt.writeGeometry(extentGeometry));
      }
      const loadPage = async number => {
        const pageParams = new URLSearchParams(params);
        pageParams.set('page', number);
        const response = await this.ajax.request(`${_environment.default.API_HOST}/${this.module.value}/search/?${pageParams}`);
        return response;
      };
      const pages = [];
      let pageNum = 1;
      let count = this.count;
      if (extent) {
        // If we're searching in an extent, result count is unknown until
        // we load the first page
        const firstPage = await loadPage(1);
        pages.push(firstPage.results);
        count = firstPage.count;
        pageNum++;
      }
      const pageCount = Math.ceil(count / pageSize);
      await _rsvp.default.Promise.all((0, _abstract.range)(pageNum, pageCount + 1).map(async number => {
        const page = await loadPage(number);
        pages.push(page.results);
      }));
      const points = [].concat(...pages).filter(doc => !doc.is_site && doc.geom).map(doc => ({
        wkt: doc.geom,
        payload: doc
      }));
      return {
        points
      };
    }
  }

  // TODO: DRY with the base map styling code
  _exports.SearchGridSource = SearchGridSource;
  const pointsStyle = feature => {
    if (feature.get('geometry').getType() === 'Point') {
      return new _style.Style({
        text: new _style.Text({
          text: '\uf3c5',
          font: 'normal 900 18px "Font Awesome 5 Free"',
          textBaseline: 'bottom',
          scale: 1.25,
          fill: new _style.Fill({
            color: [0xff, 0x40, 0, 0.75]
          }),
          stroke: new _style.Stroke({
            color: [0xff, 0x40, 0, 0.75],
            width: 1
          })
        })
      });
    } else {
      const fill = new _style.Fill({
        color: [0xff, 0x40, 0, 0.75]
      });
      const stroke = new _style.Stroke({
        color: [0xff, 0x40, 0, 1],
        width: 1
      });
      return new _style.Style({
        fill: fill,
        stroke: stroke
      });
    }
  };
  function clusterStyle(size) {
    let {
      selected = false
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return new _style.Style({
      image: new _style.Circle({
        radius: 10,
        stroke: new _style.Stroke({
          color: '#fff'
        }),
        fill: new _style.Fill({
          color: selected ? [0xff, 0xcc, 0x33, 0.75] : [0xff, 0x40, 0, 0.75]
        })
      }),
      text: new _style.Text({
        text: size.toString(),
        fill: new _style.Fill({
          color: '#fff'
        })
      })
    });
  }
  class GridModel {
    constructor(_ref9) {
      let {
        source,
        projection = (0, _proj.get)('EPSG:3857'),
        config: {
          // If count < minGridPoints, we just use the old point/cluster map.
          //
          // Keep in min that those will have to be loaded via pagination, so there is a maximum
          // possible value for this. And, of course, the bigger this is, the more time it will
          // take to load and the more memory it will consume.
          //
          // Allan wants this to be 20k IIRC
          minGridPoints = 1000,
          // Maximum number of cells in a grid that we dare to render
          //
          // 20k is pretty conservative, but this depends heavily on the user's hardware, so better
          // not to push it.
          gridCellLimit = 20000,
          // If there < gridSwitchPoints points in the viewport, show points instead of grid
          //
          // NB: This allows zoom to points. However, in this mode, every viewport change, both
          // zoom and pan will cause a new request
          gridSwitchPoints = 1000,
          clusterViewportPoints = false,
          // The first grid level we'll try to load. If it's too precise, we bail and go upwards
          initialGridLevel = 6,
          // If minGridPoints < counts <= gridCellLimit, we can basically choose whatever precision,
          // so a ceiling is necessary
          maxGridLevel = 8
        } = {}
      } = _ref9;
      // 'unitialized', 'points', 'grid', 'points-viewport',
      _defineProperty(this, "mode", 'unitialized');
      // While we request the most precise grid we can from the API,
      // just showing it doesn't work sometimes when the map is zoomed
      // out. To accomodate this, we generate upper-level grids from
      // the available one and show those based on map resolution,
      // so that grid cells are at least GEOHASH_CELL_MIN_WIDTH_IN_PIXELS
      // pixels wide, which should be visible by user.
      //
      // In some projects, showing the precise grid at the full extent
      // would result in sub-pixel cells
      _defineProperty(this, "mostPreciseGridLevel", 3);
      _defineProperty(this, "currentGridLevel", null);
      // { cells: { [geohash: string]: number }, overflow: boolean }
      // This is the precise grid data. The upper levels are made
      // in getGeohashGrid
      _defineProperty(this, "grid", null);
      // Layers: points, pointsViewport, polygons, gridLayers[]
      //
      // Have to precreate layers for select control to work. Points
      // layers are created in the constructor, because they are
      // potentially clustered (see config)
      //
      // NB: These must not be reassigned
      //
      // When mode is switched, we replace this.layer group content
      // with some of these
      _defineProperty(this, "gridLayers", GEOHASH_CELL_WIDTHS.map(_ => ({
        layer: new _Vector.default({
          source: new _Vector2.default()
        }),
        // Not filling those layers by default, because the feature
        // do take memory
        initialized: false
      })));
      // Polygons are not clustered, so we separate them
      _defineProperty(this, "polygons", new _Vector.default({
        source: new _Vector2.default(),
        style: pointsStyle
      }));
      this.source = source;
      this.projection = projection;
      this.config = {
        minGridPoints,
        gridCellLimit,
        gridSwitchPoints,
        clusterViewportPoints,
        initialGridLevel,
        maxGridLevel
      };

      // Connection GridSource <- Map is unidirectional.
      // Map:
      // - Adds gridSource.layer to itself
      // - Calls viewportChange
      //
      // GridSource changes .layer content itself, without having
      // to know much about map state
      this.layer = new _Group.default();

      // Can we just use one points layer? Those will never be shown at the same time
      this.points = this.createPointsLayer({
        cluster: true
      });
      this.pointsViewport = this.createPointsLayer({
        cluster: clusterViewportPoints
      });
      if (this.source.count < this.config.minGridPoints) {
        this.switchToPoints();
      } else {
        this.setupGridAndSwitchToIt();
      }
    }
    createPointsLayer(_ref10) {
      let {
        cluster
      } = _ref10;
      if (cluster) {
        const source = new _Cluster.default({
          source: new _Vector2.default(),
          geometryFunction: feature => {
            const geometry = feature.getGeometry();
            if (geometry.getType() === 'Point') {
              return geometry;
            } else {
              return null;
            }
          }
        });
        return new _Vector.default({
          source,
          style: feature => {
            const size = feature.get('features').length;
            if (size === 1) {
              return pointsStyle(feature.get('features')[0]);
            } else {
              return clusterStyle(size);
            }
          }
        });
      } else {
        return new _Vector.default({
          source: new _Vector2.default(),
          style: pointsStyle
        });
      }
    }
    setProjection(projection) {
      if (this.projection === projection) {
        return;
      }
      console.debug(`Reprojecting from ${this.projection.getCode()} to ${projection.getCode()}`);
      const reproject = feature => {
        feature.getGeometry().transform(this.projection, projection);
      };
      for (const {
        layer
      } of this.gridLayers) {
        layer.getSource().forEachFeature(reproject);
      }
      this.points.getSource().getSource().forEachFeature(reproject);
      this.polygons.getSource().forEachFeature(reproject);
      const source = this.config.clusterViewportPoints ? this.pointsViewport.getSource().getSource() : this.pointsViewport.getSource();
      source.forEachFeature(reproject);
      this.projection = projection;
    }
    async switchToPoints() {
      if (this.mode === 'points') {
        return;
      }
      const points = await this.source.loadPoints();
      const features = this.convertAreasToFeatures(points.points);
      const pointSource = this.points.getSource().getSource();
      pointSource.clear();
      pointSource.addFeatures(features.points);
      this.polygons.getSource().clear();
      this.polygons.getSource().addFeatures(features.polygons);
      this.layer.getLayers().clear();
      this.layer.getLayers().push(this.points);
      this.layer.getLayers().push(this.polygons);
      this.mode = 'points';
      console.debug('switched to points view');
    }
    switchToGrid(level) {
      if (this.mode === 'grid' && this.currentGridLevel === level) {
        return;
      }
      this.layer.getLayers().clear();
      this.layer.getLayers().push(this.getGridLayer(level));
      this.currentGridLevel = level;
      this.mode = 'grid';
      console.debug(`switched to grid view at level ${level}`);
    }
    getGridLayer(level) {
      level = Math.min(level, this.mostPreciseGridLevel);
      if (!this.gridLayers[level - 1].initialized) {
        console.debug(`Initializing grid layer at level ${level}`);
        this.updateGridLayer(this.gridLayers[level - 1].layer, this.getGeohashGrid(level));
        this.gridLayers[level - 1].initialized = true;
      }
      return this.gridLayers[level - 1].layer;
    }
    getGeohashGrid(level) {
      if (level >= this.mostPreciseGridLevel) {
        return this.grid;
      }
      const cells = {};
      for (const [geohash, count] of Object.entries(this.grid.cells)) {
        const levelGeohash = geohash.slice(0, level);
        cells[levelGeohash] = (cells[levelGeohash] ?? 0) + count;
      }
      return {
        cells,
        overflow: false
      };
    }

    // TODO: Proper async queueing for this, we don't want to have 10
    // of these active at once
    async switchToPointsViewport(extent) {
      const latlonExtent = (0, _proj.transformExtent)(extent, this.projection, 'EPSG:4326');
      const points = await this.source.loadPoints({
        extent: latlonExtent
      });
      if (this.mode !== 'points-viewport') {
        this.layer.getLayers().clear();
        this.layer.getLayers().push(this.pointsViewport);
        this.layer.getLayers().push(this.polygons);
      }
      const features = this.convertAreasToFeatures(points.points);
      const pointSource = this.config.clusterViewportPoints ? this.pointsViewport.getSource().getSource() : this.pointsViewport.getSource();
      pointSource.clear();
      pointSource.addFeatures(features.points);
      this.polygons.getSource().clear();
      this.polygons.getSource().addFeatures(features.polygons);
      this.mode = 'points-viewport';
      console.debug('Switched to points-viewport');
    }

    // Initial precision-negotiation flow
    async setupGridAndSwitchToIt() {
      console.debug('Setting up grid');
      let grid;
      let level;
      if (this.source.count <= this.config.gridCellLimit) {
        console.debug('Using max precision, because count <= gridCellLimit');
        level = this.config.maxGridLevel;

        // Corner case: we can use whatever precision we want
        grid = await this.source.loadGrid({
          precision: this.config.maxGridLevel,
          limit: this.config.gridCellLimit
        });
      } else {
        console.debug('Negotiating grid precision');

        // Negotiate. For now, only in direction of decreasing precision
        let precision = this.config.initialGridLevel;
        let candidate;
        do {
          candidate = await this.source.loadGrid({
            precision,
            limit: this.config.gridCellLimit
          });
          if (candidate.overflow) {
            console.debug(`Overflow at ${precision}, trying next level`);
          }
          precision--;
        } while (candidate.overflow && precision > 1);
        level = precision + 1;
        console.debug(`Negotiated grid precision ${level}, with ${Object.keys(candidate.cells).length} cells`);
        grid = candidate;
      }
      this.grid = grid;
      this.mostPreciseGridLevel = level;
      console.debug('Most precise grid level:', this.mostPreciseGridLevel);
      this.switchToGrid(level);
    }
    updateGridLayer(layer, grid) {
      const features = Object.entries(grid.cells).map(_ref11 => {
        let [geohash, count] = _ref11;
        const bounds = _latlonGeohash.default.bounds(geohash);
        const sw = bounds.sw;
        const ne = bounds.ne;
        const geometry = new _geom.Polygon([[[sw.lon, sw.lat], [ne.lon, sw.lat], [ne.lon, ne.lat], [sw.lon, ne.lat], [sw.lon, sw.lat]]]);
        geometry.transform('EPSG:4326', this.projection);
        return new _Feature.default({
          geometry,
          count,
          geohash
        });
      });
      const counts = features.map(f => f.get('count'));
      const maxCount = Math.max(...counts);
      const minCount = Math.min(...counts);
      const range = Math.max(maxCount - minCount, 1); // Max handles empty/singleton maps

      const style = feature => {
        const intensity = (feature.get('count') - minCount) / range;
        const green = Math.round(255 * intensity);
        return new _style.Style({
          fill: new _style.Fill({
            color: [255, green, 0, 1]
          })
        });
      };

      // Have to do this inplace for select to work
      layer.setStyle(style);
      layer.getSource().clear();
      layer.getSource().addFeatures(features);
    }
    convertAreasToFeatures(areas) {
      const points = [];
      const polygons = [];
      for (const {
        wkt,
        payload
      } of areas) {
        const geometry = new _format.WKT().readGeometry(wkt, {
          dataProjection: 'EPSG:4326',
          featureProjection: this.projection
        });
        const source = geometry.getType() === 'Point' ? points : polygons;
        const feature = new _Feature.default({
          geometry,
          payload
        });
        source.push(feature);
      }
      return {
        points,
        polygons
      };
    }

    // Should this also take center and zoom?
    // Do we debouce here or is there a way to wait for map to settle?
    viewportChange(extent, resolution) {
      if (this.mode === 'unitialized') {
        return;
      }
      const size = this.estimateExtentSize(this.gridLayers[this.mostPreciseGridLevel - 1].layer, extent);
      if (this.mode === 'grid') {
        // Check for possible switch to points-viewport
        if (size <= this.config.gridSwitchPoints) {
          console.debug(`Estimated extent size: ${size}, switching to points`);
          this.switchToPointsViewport(extent);
        } else {
          // Grid level can change based on resolution
          const gridLevel = this.getSuitableGeohashLevel(resolution);
          if (this.currentGridLevel !== gridLevel) {
            this.switchToGrid(gridLevel);
          }
        }
      } else if (this.mode === 'points-viewport') {
        // Check for possible switch to grid
        // Otherwise, reload points with a new viewport
        if (size > this.config.gridSwitchPoints) {
          const gridLevel = this.getSuitableGeohashLevel(resolution);
          this.switchToGrid(gridLevel);
        } else {
          this.switchToPointsViewport(extent);
        }
      }
    }
    getSuitableGeohashLevel(resolution) {
      let level = this.mostPreciseGridLevel;
      while (level > 1) {
        const size = GEOHASH_CELL_WIDTHS[level - 1] / resolution;
        if (size >= GEOHASH_CELL_MIN_WIDTH_IN_PIXELS) {
          break;
        }
        level--;
      }
      return level;
    }
    estimateExtentSize(grid, extent) {
      // XXX: Does this include intersecting cells? If it doesn't, it should
      const features = grid.getSource().getFeaturesInExtent(extent);
      return (0, _abstract.sum)(features.map(f => f.get('count')));
    }
  }
  _exports.GridModel = GridModel;
  let GridMapComponent = _exports.default = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _class2 = class GridMapComponent extends _viewMap.default {
    constructor() {
      super(...arguments);
      // TODO: This all should work as a composition, not a cursed component hierarchy, smth like:
      //
      // <Map::Map as |map|>
      //   <map.editFeatures @features={{...}} />
      //   <map.features @features={{array this.countryGeom}} @style={{...}} @selected={{...}} />
      //   <map.grid @source={{...}} />
      // </Map::Map>
      _defineProperty(this, "layoutName", 'components/map/base-map');
      _initializerDefineProperty(this, "syncViewport", _descriptor2, this);
      // Hacks for zooming to grid extent *once*, unless the model changes
      _defineProperty(this, "_usedModel", null);
      _defineProperty(this, "_gridExtentApplied", false);
      _defineProperty(this, "popupEnabled", true);
    }
    createCommonLayers() {
      return super.createCommonLayers().concat([this.model.layer]);
    }
    createMap(layerset, extent) {
      super.createMap(layerset, extent);
      this.model.setProjection(this.view.getProjection());
      this.addEventListener(this.view, 'change:center', () => {
        this.syncViewport.perform();
      });
      this.addEventListener(this.view, 'change:resolution', () => {
        this.syncViewport.perform();
      });
      if (!this._gridExtentApplied) {
        this._gridExtentApplied = true;
        const extent = (0, _proj.transformExtent)(this.model.source.extent, 'EPSG:4326', this.view.getProjection());
        this.view.fit(extent, {
          maxZoom: 16
        });
      }
    }
    createFullToolbar() {
      const buttons = super.createFullToolbar(...arguments);
      buttons.select = _map.default.controls.toolButtons.Select.create({
        layers: [this.model.points, this.model.polygons, ...this.model.gridLayers.map(layer => layer.layer), this.model.pointsViewport],
        getStyle: feature => {
          if (this.model.mode === 'grid') {
            const baseStyle = this.model.gridLayers[this.model.currentGridLevel - 1].layer.getStyle()(feature);
            const style = baseStyle.clone();
            style.setStroke(new _style.Stroke({
              color: [0, 0, 0, 1],
              width: 4
            }));
            return style;
          } else {
            if (feature.get('features') && feature.get('features').length > 1) {
              return clusterStyle(feature.get('features').length, {
                selected: true
              });
            } else {
              const geometry = feature.getGeometry();
              return this.getStyle(geometry.getType(), {
                styleClass: 'base.selected'
              }, geometry);
            }
          }
        },
        selectionChanged: selected => {
          let showPopup = false;
          if (selected.length > 0) {
            const feature = selected[0];
            if (this.model.mode === 'grid') {
              if (this.gridCellSelected) {
                this.gridCellSelected({
                  geohash: feature.get('geohash'),
                  count: feature.get('count')
                });
                showPopup = true;
              }
            } else if (this.model.mode === 'points' || this.model.mode === 'points-viewport') {
              if (this.areasSelected) {
                let features = [];
                if (feature.get('features')) {
                  // Clustered feature. We could check by the mode + config, but that seems
                  // more brittle
                  features = feature.get('features');
                } else {
                  features = [feature];
                }
                this.areasSelected(features.map(f => f.get('payload')));
                showPopup = true;
              }
            }
            if (showPopup) {
              this.showPopup((0, _map.getGeometryCenter)(feature.getGeometry()));
            }
          }
          if (!showPopup) {
            this.hidePopup();
          }
        }
      });
      buttons.select.select.setHitTolerance(20);
      return buttons;
    }
    didReceiveAttrs() {
      super.didReceiveAttrs();

      // TODO: Should probably recreate map here (though make sure not
      // not do it on the first load)
      if (this._usedModel !== this.model) {
        this._usedModel = this.model;
        this._gridExtentApplied = false;
      }
    }
  }, _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "syncViewport", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        yield (0, _emberConcurrency.timeout)(100);
        this.model.viewportChange(
        // Not reprojecting here, because grid is projected as well.
        // Will have to be reprojected if points are to be loaded, though.
        this.view.calculateExtent(), this.view.getResolution());
      };
    }
  }), _class2);
});