define("plutof/components/analysis/ml-taxon/content", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/misc/fileupload", "plutof/utils/pagination"], function (_exports, _component, _templateFactory, _component2, _object, _service, _fileupload, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Analysis::View::WrapperContent @run={{@run}} />
  
  {{#resolve-promise this.results as |results|}}
      {{#if results}}
          <RelatedObjects::View
              @title={{i18n-t "Analysis.results"}}
              @pagination={{results}}
          >
              <RelatedObjects::Content @pagination={{results}} as |Field sequence|>
                  <Field @label={{i18n-t "Sequences.accessionNumber"}}>
                      {{sequence.accno}}
                  </Field>
  
                  {{#each this.RANKS as |rank|}}
                      <Field @label={{rank.label}}>
                          <Analysis::MlSh::Predictions as |predictions|>
                              {{#each (get sequence.predictions rank.rank) as |rank_prediction|}}
                                  <predictions.item @confidence={{rank_prediction.confidence}}>
                                      {{call this.formatTaxon rank_prediction.taxon}}
                                  </predictions.item>
                              {{/each}}
                          </Analysis::MlSh::Predictions>
                      </Field>
                  {{/each}}
              </RelatedObjects::Content>
          </RelatedObjects::View>
      {{/if}}
  {{/resolve-promise}}
  
  */
  {
    "id": "iYfqVAhl",
    "block": "[[[8,[39,0],null,[[\"@run\"],[[30,1]]],null],[1,\"\\n\\n\"],[6,[39,1],[[30,0,[\"results\"]]],null,[[\"default\"],[[[[41,[30,2],[[[1,\"        \"],[8,[39,3],null,[[\"@title\",\"@pagination\"],[[28,[37,4],[\"Analysis.results\"],null],[30,2]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,[[\"@pagination\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n                \"],[8,[30,3],null,[[\"@label\"],[[28,[37,4],[\"Sequences.accessionNumber\"],null]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,4,[\"accno\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"RANKS\"]]],null]],null],null,[[[1,\"                    \"],[8,[30,3],null,[[\"@label\"],[[30,5,[\"label\"]]]],[[\"default\"],[[[[1,\"\\n                        \"],[8,[39,8],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[28,[37,9],[[30,4,[\"predictions\"]],[30,5,[\"rank\"]]],null]],null]],null],null,[[[1,\"                                \"],[8,[30,6,[\"item\"]],null,[[\"@confidence\"],[[30,7,[\"confidence\"]]]],[[\"default\"],[[[[1,\"\\n                                    \"],[1,[28,[35,10],[[30,0,[\"formatTaxon\"]],[30,7,[\"taxon\"]]],null]],[1,\"\\n                                \"]],[]]]]],[1,\"\\n\"]],[7]],null],[1,\"                        \"]],[6]]]]],[1,\"\\n                    \"]],[]]]]],[1,\"\\n\"]],[5]],null],[1,\"            \"]],[3,4]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null]],[2]]]]]],[\"@run\",\"results\",\"Field\",\"sequence\",\"rank\",\"predictions\",\"rank_prediction\"],false,[\"analysis/view/wrapper-content\",\"resolve-promise\",\"if\",\"related-objects/view\",\"i18n-t\",\"related-objects/content\",\"each\",\"-track-array\",\"analysis/ml-sh/predictions\",\"get\",\"call\"]]",
    "moduleName": "plutof/components/analysis/ml-taxon/content.hbs",
    "isStrictMode": false
  });
  let MLTaxonAnalysisContent = _exports.default = (_dec = (0, _object.computed)('args.run'), _class = class MLTaxonAnalysisContent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      // No point in kingdom atm, as the training data only had Fungi
      _defineProperty(this, "RANKS", ['phylum', 'class', 'order', 'family', 'genus'].map(rank => ({
        rank,
        label: this.i18n.translate(`Taxonomy.ranks.${rank}`)
      })));
    }
    get results() {
      if (this.args.run.status !== 'ready') {
        return null;
      }
      return this.args.run.results_file.then(async results => {
        if (!results) {
          return null;
        }
        const content = await (0, _fileupload.readFileContent)(results.download_links.link);
        return (0, _pagination.paginateLocalObjects)(content.results);
      });
    }
    formatTaxon(taxon) {
      // An artifact of how taxa are represent in model (actually, in training
      // data): 'g_Pica' meaning genus Pica
      return taxon[1] === '_' ? taxon.slice(3) : taxon;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "results", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "results"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formatTaxon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "formatTaxon"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MLTaxonAnalysisContent);
});