define("plutof/components/map/mixins/preferred-layerset", ["exports", "@ember/object", "@ember/object/mixin", "@ember/utils", "plutof/misc/config", "plutof/misc/profile_settings"], function (_exports, _object, _mixin, _utils, _config, _profile_settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LAYERSET_CODES = {
    'eesti': 'est_landboard_base',
    'eesti_foto': 'est_landboard_combined_photo_hybrid',
    'open_layers_3': 'osm',
    'esri_sat': 'esri_sat'
  };
  const PreferredLayersetMixin = _mixin.default.create({
    init() {
      this._super(...arguments);
      this.setPreferredLayerset();
      this.selectLayerset();
    },
    setPreferredLayerset: (0, _object.observer)('country', function () {
      (0, _profile_settings.get_personal_settings)(this.store).then(settings => {
        let layerset = settings.get('default_map');
        const countryCode = this.get('country.code');
        const notEstonia = !(0, _utils.isNone)(countryCode) && countryCode !== 'EST';
        const isMaaametLayerset = layerset === _config.default.Sample.MAAAMET_LAYERSET;

        // Override default Maa-amet layerset if country is not Estonia.
        // When no country is set, the layer can be used.
        if (isMaaametLayerset && notEstonia) {
          layerset = this.defaultLayerset;
        }
        this.set('preferredLayerset', layerset);
      });
    }),
    preferredLayerset: _config.default.Sample.DEFAULT_LAYERSET,
    defaultLayerset: _config.default.Sample.DEFAULT_LAYERSET,
    // XXXX
    typeChanged: (0, _object.observer)('preferredLayerset', function () {
      const preferred = LAYERSET_CODES[this.preferredLayerset];
      if (this.insertedIntoDOM && this.get('layerSelection.layerset.name') !== preferred) {
        this.reinitializeMap();
      }
    }),
    selectLayerset: (0, _object.observer)('preferredLayerset', 'insertedIntoDOM', function () {
      if (!this.insertedIntoDOM) {
        return;
      }
      var code = this.preferredLayerset;
      if ((0, _utils.isEmpty)(code)) {
        code = _config.default.Sample.DEFAULT_LAYERSET;
      }
      this.layerSelection.switchLayerset(LAYERSET_CODES[code]);
    })
  });
  var _default = _exports.default = PreferredLayersetMixin;
});