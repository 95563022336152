define("plutof/controllers/datacite/view-private", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/utils", "ember-data", "rsvp", "plutof/components/map/mixins/default-styling", "plutof/config/environment", "plutof/controllers/publishing/utils", "plutof/misc/fileupload", "plutof/misc/profile_settings", "plutof/utils/notifications"], function (_exports, _controller, _object, _service, _utils, _emberData, _rsvp, _defaultStyling, _environment, _utils2, _fileupload, _profile_settings, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ViewPrivate = _exports.default = (_dec = (0, _object.computed)('publishingDisabled'), _dec2 = (0, _object.computed)('model.doi.metadata.owner.id', 'user.id', 'publishingAllowed'), _dec3 = (0, _object.computed)('model.doi.geolocations.[]'), _class = class ViewPrivate extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _defineProperty(this, "contributorsConfig", {
        panel: {
          title: 'datacite.panels.contributors',
          marker: 'datacite-view-contributors'
        },
        fields: [{
          name: 'datacite.contributor.contributor_name',
          path: 'contributor_name.representation',
          marker: 'contributor-name'
        }, {
          name: 'datacite.contributor.contributor_type',
          path: 'contributor_type',
          marker: 'contributor-type'
        }, {
          name: 'datacite.contributor.affiliation',
          path: 'affiliation',
          marker: 'affiliation'
        }]
      });
      _defineProperty(this, "datesConfig", {
        panel: {
          title: 'datacite.panels.dates',
          marker: 'datacite-view-dates'
        },
        fields: [{
          name: 'datacite.datesmodel.date_type',
          path: 'date_type',
          marker: 'date-type'
        }, {
          name: 'datacite.datesmodel.date_start',
          path: 'date_start',
          marker: 'date-start'
        }, {
          name: 'datacite.datesmodel.date_end',
          path: 'date_end',
          marker: 'date-end'
        }, {
          name: 'datacite.datesmodel.date_information',
          path: 'date_information',
          marker: 'date-information'
        }]
      });
      _defineProperty(this, "resourcesConfig", {
        panel: {
          title: 'datacite.panels.resources',
          marker: 'datacite-view-resources'
        },
        fields: [{
          name: 'datacite.media.linked_file',
          path: 'linked_file',
          marker: 'linked-file',
          link: true
        }, {
          name: 'datacite.media.size',
          path: 'size',
          marker: 'size'
        }, {
          name: 'datacite.media.remarks',
          path: 'remarks',
          marker: 'remarks'
        }]
      });
      _defineProperty(this, "fundersConfig", {
        panel: {
          title: 'datacite.panels.funders',
          marker: 'datacite-view-funders'
        },
        fields: [{
          name: 'datacite.fundingreference.identifier_type',
          path: 'identifier_type',
          marker: 'identifier-type'
        }, {
          name: 'datacite.fundingreference.funder_name',
          path: 'funder_name',
          marker: 'funder-name'
        }, {
          name: 'datacite.fundingreference.funder_identifier',
          path: 'funder_identifier',
          marker: 'funder-identifier'
        }, {
          name: 'datacite.fundingreference.award_number',
          path: 'award_number',
          marker: 'award-number'
        }, {
          name: 'datacite.fundingreference.award_title',
          path: 'award_title',
          marker: 'award-title'
        }, {
          name: 'datacite.fundingreference.award_uri',
          path: 'award_uri',
          marker: 'award-uri'
        }]
      });
      _defineProperty(this, "relatedIdentifiersConfig", {
        panel: {
          title: 'datacite.panels.relatedIdentifiers',
          marker: 'datacite-view-related-identifiers'
        },
        fields: [{
          name: 'datacite.relatedidentifier.related_dataset',
          path: 'related_dataset',
          marker: 'related-dataset',
          link: true
        }, {
          name: 'datacite.relatedidentifier.relation_type',
          path: 'relation_type',
          marker: 'relation-type'
        }, {
          name: 'datacite.relatedidentifier.related_identifier',
          path: 'related_identifier',
          marker: 'related-identifier'
        }, {
          name: 'datacite.relatedidentifier.related_identifier_type',
          path: 'related_identifier_type',
          marker: 'related-identifier-type'
        }, {
          name: 'datacite.relatedidentifier.resource_type_general',
          path: 'resource_type_general',
          marker: 'resource-type-general'
        }]
      });
      _defineProperty(this, "alternateIdentifiersConfig", {
        panel: {
          title: 'datacite.panels.alternateIdentifiers',
          marker: 'datacite-view-alternate-identifiers'
        },
        fields: [{
          name: 'datacite.alternateidentifier.alternate_identifier',
          path: 'alternate_identifier',
          marker: 'identifier'
        }, {
          name: 'datacite.alternateidentifier.alternate_identifier_type',
          path: 'alternate_identifier_type',
          marker: 'identifier-type'
        }]
      });
    }
    get permissions() {
      const canModify = !this.publishingDisabled;
      return {
        canModify,
        canDelete: canModify
      };
    }
    init() {
      super.init(...arguments);
      this.set('publishingAllowed', false);
      (0, _utils2.userIsAllowedToRegisterDOIs)(this.store).then(allowed => {
        this.set('publishingAllowed', allowed);
      });
      (0, _profile_settings.get_current_user)(this.store).then(user => {
        (0, _object.set)(this, 'user', user);
      });
    }
    get publishingDisabled() {
      const notOwner = (0, _object.get)(this, 'model.doi.metadata.owner.id') !== (0, _object.get)(this, 'user.id');
      return notOwner || !this.publishingAllowed;
    }
    get mapInfo() {
      const geolocations = this.model.doi.geolocations;
      if ((0, _utils.isNone)(geolocations)) {
        return [];
      }
      return _emberData.default.PromiseArray.create({
        promise: _rsvp.default.all(geolocations.map(area => {
          const geom = area.get('geom');
          return area.get('country').then(country => {
            if ((0, _utils.isNone)(country)) {
              return null;
            }
            let feature = {};
            if ((0, _utils.isPresent)(geom)) {
              feature.geom = geom;
              feature.styleClass = 'base';
            } else {
              const centroid = country.get('centroid');
              if ((0, _utils.isEmpty)(centroid)) {
                return null;
              }
              feature = (0, _defaultStyling.makeCountryCentroidFeature)(country.get('code'), centroid);
            }
            feature.country = country.get('representation');
            feature.name = area.get('geo_location_place');
            return feature;
          });
        })).then(features => features.compact())
      });
    }
    downloadEML() {
      (0, _fileupload.downloadFile)(`${_environment.default.API_HOST}/datacite/metadatas/${this.get('model.doi.metadata.id')}/eml/`);
    }
    publish() {
      const url = '/datacite/metadatas/' + this.model.doi.metadata.id;
      return this.ajax.put(`${url}/regmeta/`).then(() => {
        (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
        this.router.transitionTo('datacite.index');
      }).catch(_notifications.reportError);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "permissions", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "permissions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publishingDisabled", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "publishingDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mapInfo", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "mapInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadEML", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "downloadEML"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publish", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "publish"), _class.prototype), _class);
});