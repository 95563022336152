define("plutof/components/search/filter-panels/annotation/taxon", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelTaxon"}}
      @collapse={{true}}
  >
      {{#if this.taxonFilter}}
          <div class="out-of-form-field">
              <Common::Ui::ControlLabel>
                  <:label>
                      {{i18n-t "General.taxon"}}
                  </:label>
  
                  <:controls as |controls|>
                      <controls.toggle
                          @checked={{this.includeSubtree}}
                          @toggle={{this.toggleSubtree}}
                      >
                          {{i18n-t "annotation.routes.search.includeTaxonSubtree"}}
                      </controls.toggle>
                  </:controls>
              </Common::Ui::ControlLabel>
  
              <Taxonomy::Taxon::AutoComplete @update={{this.addTaxon}} />
  
              <Common::RecordList
                  @records={{this.taxonFilter.value}}
                  @remove={{this.removeTaxon}} />
          </div>
      {{/if}}
  </PlutofPanel::Simple>
  
  */
  {
    "id": "g/Dg8MG3",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"Search.panelTaxon\"],null],true]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"taxonFilter\"]],[[[1,\"        \"],[10,0],[14,0,\"out-of-form-field\"],[12],[1,\"\\n            \"],[8,[39,3],null,null,[[\"label\",\"controls\"],[[[[1,\"\\n                    \"],[1,[28,[35,1],[\"General.taxon\"],null]],[1,\"\\n                \"]],[]],[[[1,\"\\n                    \"],[8,[30,1,[\"toggle\"]],null,[[\"@checked\",\"@toggle\"],[[30,0,[\"includeSubtree\"]],[30,0,[\"toggleSubtree\"]]]],[[\"default\"],[[[[1,\"\\n                        \"],[1,[28,[35,1],[\"annotation.routes.search.includeTaxonSubtree\"],null]],[1,\"\\n                    \"]],[]]]]],[1,\"\\n                \"]],[1]]]]],[1,\"\\n\\n            \"],[8,[39,4],null,[[\"@update\"],[[30,0,[\"addTaxon\"]]]],null],[1,\"\\n\\n            \"],[8,[39,5],null,[[\"@records\",\"@remove\"],[[30,0,[\"taxonFilter\",\"value\"]],[30,0,[\"removeTaxon\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"]],[\"controls\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"if\",\"common/ui/control-label\",\"taxonomy/taxon/auto-complete\",\"common/record-list\"]]",
    "moduleName": "plutof/components/search/filter-panels/annotation/taxon.hbs",
    "isStrictMode": false
  });
  let AnnotationTaxonFilters = (_dec = (0, _object.computed)('args.filterInputs.{taxon.value.[],taxon_tree.value.[]}', 'preferInclude'), _dec2 = (0, _object.computed)('includeSubtree'), _class = class AnnotationTaxonFilters extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "preferInclude", _descriptor, this);
    }
    get includeSubtree() {
      const filters = this.args.filterInputs;
      if (!filters) {
        return this.preferInclude;
      }
      const {
        taxon,
        taxon_tree
      } = filters;
      if (!taxon || !taxon_tree) {
        return this.preferInclude;
      }
      if (taxon.value.length > 0) {
        return false;
      } else if (taxon_tree.value.length > 0) {
        return true;
      } else {
        return this.preferInclude;
      }
    }
    get taxonFilter() {
      const filters = this.args.filterInputs;
      if (!filters) {
        return null;
      }
      return this.includeSubtree ? filters.taxon_tree : filters.taxon;
    }
    toggleSubtree() {
      if (this.taxonFilter.value.length > 0) {
        const value = this.taxonFilter.value;
        const other = this.includeSubtree ? this.args.filterInputs.taxon : this.args.filterInputs.taxon_tree;
        this.taxonFilter.clear();
        // TODO: Add this to clear
        this.taxonFilter.value = [];
        other.value = value.slice();
      }
      this.preferInclude = !this.preferInclude;
    }
    addTaxon(taxon) {
      this.taxonFilter.value.pushObject(taxon);
    }
    removeTaxon(taxon) {
      this.taxonFilter.value.removeObject(taxon);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "preferInclude", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "includeSubtree", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "includeSubtree"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "taxonFilter", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "taxonFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSubtree", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSubtree"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addTaxon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addTaxon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTaxon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeTaxon"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnnotationTaxonFilters);
});