define("plutof/components/clipboard/reference-items", ["exports", "@ember/component", "@ember/object", "@ember/utils", "rsvp", "@ember-decorators/object", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _component, _object, _utils, _rsvp, _object2, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ReferenceItems = (_dec = (0, _object2.observes)('entires.[]', 'reference'), _dec2 = (0, _object.computed)('entries.length', 'reference.id'), _class = class ReferenceItems extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "reference", null);
      _defineProperty(this, "referenceItems", []);
    }
    init() {
      super.init(...arguments);
      this.referenceItemsSetter();
    }
    referenceItemsSetter() {
      var reference = this.reference;
      if ((0, _utils.isNone)(reference)) {
        return;
      }
      var referenceItems = this.referenceItems;
      var selectedEntries = this.entries;
      var items = selectedEntries.map(function (entry) {
        return _object.default.create({
          entry: entry,
          reference: reference,
          pages: ''
        });
      }, this);
      referenceItems.clear();
      referenceItems.pushObjects(items);
    }
    get disabled() {
      return (0, _utils.isNone)(this.get('reference.id')) || this.get('entries.length') === 0;
    }
    save() {
      const store = this.store;
      const i18n = (0, _i18n.getI18n)();
      let referenceItems = this.referenceItems;
      (0, _notifications.displayNotification)('info', i18n.t('General.saving'));
      const itemsSaved = _rsvp.default.all(referenceItems.map(refitem => {
        const cbdatum = refitem.get('entry.cbdatum');
        return store.findRecord('contenttype', cbdatum.content_type_id).then(ctype => {
          const item = store.createRecord('reference/item', {
            content_type: ctype,
            object_id: cbdatum.id,
            reference: refitem.get('reference'),
            pages: refitem.get('pages')
          });
          return item.save();
        });
      }));
      return itemsSaved.then(() => {
        referenceItems.clear();
        this.set('reference', null);
        (0, _notifications.displayNotification)('success', i18n.t('General.done'));
      }).catch(_notifications.reportError);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "referenceItemsSetter", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "referenceItemsSetter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "disabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _class);
  var _default = _exports.default = ReferenceItems;
});