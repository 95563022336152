define("plutof/helpers/show-field", ["exports", "@ember/component/helper", "plutof/helpers/read-only-boolean", "plutof/utils/string"], function (_exports, _helper, _readOnlyBoolean, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(_ref => {
    let [value] = _ref;
    switch (typeof value) {
      case 'string':
        return (0, _string.showWithLinebreaks)(value);
      case 'boolean':
        return (0, _readOnlyBoolean.translateBoolean)([value]);
      default:
        return value;
    }
  });
});