define("plutof/routes/livingspecimen/add", ["exports", "@ember/object", "@ember/service", "@ember/utils", "rsvp", "plutof/components/livingspecimen/edit", "plutof/misc/abstract", "plutof/misc/config", "plutof/misc/content_types", "plutof/misc/profile_settings", "plutof/utils/model", "plutof/utils/routes", "plutof/components/layer/occurrence-areas"], function (_exports, _object, _service, _utils, _rsvp, _edit, _abstract, _config, _content_types, _profile_settings, _model, _routes, _occurrenceAreas) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  async function getMainform(params, store) {
    if ((0, _utils.isNone)(params.mainform_id)) {
      const settings = await (0, _profile_settings.get_personal_settings)(store);
      const form = await settings.strain_form;
      return [form, Boolean(form)];
    } else {
      const form = await store.findRecord('measurement/mainform', params.mainform_id);
      return [form, false];
    }
  }
  function getParentCtype(params, store) {
    return params.content_type ? store.findRecord('contenttype', params.content_type) : _abstract.EMPTY_PROMISE;
  }
  function getTarget(params, store) {
    return params.content_type ? getParentCtype(params, store).then(function (ctype) {
      return (0, _content_types.resolve_generic_link)(store, ctype, params.object_id);
    }) : _abstract.EMPTY_PROMISE;
  }
  let LivingspecimenAddRoute = (_class = class LivingspecimenAddRoute extends _routes.AddRoute {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "settings", _descriptor2, this);
      _defineProperty(this, "defaultReturnRoute", 'livingspecimen.index');
      _defineProperty(this, "title", 'LivingCultures.newLivingC');
    }
    async model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      let livingSpecimenModel;
      let usingDefaultForm;
      if (params.template_id) {
        const template = await this.store.findRecord('taxonoccurrence/livingculture/strain', params.template_id);
        livingSpecimenModel = await (0, _edit.cloneLivingSpecimenModel)(this.store, template, {
          cloneArea: params.clone_area === 'true',
          cloneEvent: params.clone_event === 'true'
        });
        usingDefaultForm = false;
      } else {
        const {
          livingSpecimen,
          sample,
          layers,
          parent,
          usingDefaultForm: udf
        } = await this.createStrainData(params);
        const requireIdentification = !parent || parent.constructor.modelName !== 'taxonoccurrence/specimen/specimen';
        livingSpecimenModel = await (0, _edit.createLivingSpecimenModel)(this.store, livingSpecimen, parent, sample, layers, {
          requireIdentification
        });
        usingDefaultForm = udf;
        if (parent) {
          await livingSpecimenModel.setParentToOccurrence(parent);
        }
      }
      return {
        livingSpecimenModel,
        usingDefaultForm
      };
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      if (!(0, _utils.isNone)(model)) {
        controller.setProperties({
          routeHasBeenLoaded: true
        });
      }
    }
    async createStrainData(params) {
      const livingSpecimen = this.store.createRecord('taxonoccurrence/livingculture/strain');
      const [[mainform, usingDefaultForm], targetCType, {
        formData
      }, collection, project] = await _rsvp.default.all([getMainform(params, this.store), getParentCtype(params, this.store),
      // createSamplingData(params, this.store),
      (0, _model.getSamplingDataForParams)(this.store, params), (0, _utils.isNone)(params.collection_id) ? this.settings.wait().then(settings => settings.default_living_specimen_collection) : this.store.findRecord('agent/collection', params.collection_id), (0, _utils.isNone)(params.project_id) ? null : this.store.findRecord('study/study', params.project_id)]);
      livingSpecimen.setProperties({
        mainform,
        project: project || formData.project,
        deposited_in: collection
      });
      const {
        POSSIBLE_TARGETS
      } = _config.default.Livingculture;
      let target;
      if (!(0, _utils.isNone)(targetCType) && POSSIBLE_TARGETS.indexOf((0, _object.get)(targetCType, 'model')) !== -1) {
        target = await getTarget(params, this.store);
        livingSpecimen.setProperties({
          target,
          // XXX: Should not be needed after the refactor
          object_id: target.id,
          content_type: targetCType
        });
      }
      return {
        livingSpecimen,
        sample: formData,
        layers: _occurrenceAreas.LinkedLayersModel.create(this.ajax),
        parent: target,
        usingDefaultForm
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = LivingspecimenAddRoute;
});