define("plutof/components/filerepository/gallery/single-file", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "plutof/components/filerepository/gallery/-model-viewer-preview"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _modelViewerPreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash open=this.open)}}
  
  {{#if this.file}}
      <Gallery::Gallery
          @open={{this.show}}
          @close={{this.close}}
      >
          <:preview>
              {{! TODO: DRY this if block}}
              {{#if this.file.is3dModel}}
                  <Filerepository::Gallery::-ModelViewerPreview
                      @controller={{this.modelViewerPreviewController}}
                      @model={{this.file.download_links.link}} />
              {{else}}
                  <Filerepository::Gallery::-Preview
                      @filename={{this.file.file_name}}
                      @type={{this.file.type}}
                      @links={{this.file.download_links}} />
              {{/if}}
          </:preview>
  
          <:footer-left as |footer|>
              <Filerepository::Gallery::-FooterLeft
                  @footer={{footer}}
                  @file={{this.file}} />
          </:footer-left>
  
          <:footer-right as |footer|>
              <Filerepository::Gallery::-FooterRight
                  @footer={{footer}}
                  @file={{this.file}}
                  @close={{this.close}} />
          </:footer-right>
      </Gallery::Gallery>
  {{/if}}
  
  */
  {
    "id": "rmdpZX8y",
    "block": "[[[18,3,[[28,[37,1],null,[[\"open\"],[[30,0,[\"open\"]]]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"file\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@open\",\"@close\"],[[30,0,[\"show\"]],[30,0,[\"close\"]]]],[[\"preview\",\"footer-left\",\"footer-right\"],[[[[1,\"\\n\"],[41,[30,0,[\"file\",\"is3dModel\"]],[[[1,\"                \"],[8,[39,4],null,[[\"@controller\",\"@model\"],[[30,0,[\"modelViewerPreviewController\"]],[30,0,[\"file\",\"download_links\",\"link\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"                \"],[8,[39,5],null,[[\"@filename\",\"@type\",\"@links\"],[[30,0,[\"file\",\"file_name\"]],[30,0,[\"file\",\"type\"]],[30,0,[\"file\",\"download_links\"]]]],null],[1,\"\\n\"]],[]]],[1,\"        \"]],[]],[[[1,\"\\n            \"],[8,[39,6],null,[[\"@footer\",\"@file\"],[[30,1],[30,0,[\"file\"]]]],null],[1,\"\\n        \"]],[1]],[[[1,\"\\n            \"],[8,[39,7],null,[[\"@footer\",\"@file\",\"@close\"],[[30,2],[30,0,[\"file\"]],[30,0,[\"close\"]]]],null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n\"]],[]],null]],[\"footer\",\"footer\",\"&default\"],false,[\"yield\",\"hash\",\"if\",\"gallery/gallery\",\"filerepository/gallery/-model-viewer-preview\",\"filerepository/gallery/-preview\",\"filerepository/gallery/-footer-left\",\"filerepository/gallery/-footer-right\"]]",
    "moduleName": "plutof/components/filerepository/gallery/single-file.hbs",
    "isStrictMode": false
  });
  let SingleFileGallery = (_class = class SingleFileGallery extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "show", _descriptor, this);
      _initializerDefineProperty(this, "file", _descriptor2, this);
      _defineProperty(this, "modelViewerPreviewController", new _modelViewerPreview.GalleryModelViewerPreviewController());
    }
    open(file) {
      this.file = file;
      this.show = true;
    }
    close() {
      this.show = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "show", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "file", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "open", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SingleFileGallery);
});