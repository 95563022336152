define("plutof/components/filerepository/image-area/select", ["exports", "@ember/component", "@ember/object", "@ember/utils", "exifr", "rsvp", "blueimp-load-image", "ember-concurrency", "plutof/misc/config"], function (_exports, _component, _object, _utils, _exifr, _rsvp, _blueimpLoadImage, _emberConcurrency, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  // TODO: DRY with misc/fileupload
  function loadImageP(file) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return new _rsvp.default.Promise(function (resolve, reject) {
      (0, _blueimpLoadImage.default)(file, function (result) {
        if (result.type === 'error') {
          reject(result);
        } else {
          resolve(result);
        }
      }, options);
    });
  }
  let ImageAreaSelect = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class ImageAreaSelect extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "preview", null);
      _defineProperty(this, "scale", 1.0);
      _defineProperty(this, "width", 0);
      _defineProperty(this, "height", 0);
      _defineProperty(this, "area", null);
      _initializerDefineProperty(this, "prepareImage", _descriptor, this);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.prepareImage.perform();
    }
    updateArea(area) {
      this.set('area', area);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "updateArea", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateArea"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "prepareImage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        // Clear previous
        this.set('preview', null);
        if ((0, _utils.isNone)(this.file)) {
          return;
        }
        let fullCanvas;
        try {
          const exif = yield _exifr.default.parse(this.file);
          let loadOptions = {
            canvas: true
          };
          if (!(0, _utils.isNone)(exif) && !(0, _utils.isEmpty)(exif.Orientation)) {
            loadOptions.orientation = exif.Orientation;
          }
          fullCanvas = yield loadImageP(this.file, loadOptions);
        } catch (error) {
          // Unsupported image format
          this.accept();
          return;
        }
        const {
          AREA_MINIMUM_SIZE,
          MAX_PREVIEW_WIDTH
        } = _config.default.Filerepository;

        // TODO: Probably should handle 2000x200 images as well
        if (fullCanvas.width < AREA_MINIMUM_SIZE || fullCanvas.height < AREA_MINIMUM_SIZE) {
          // No need to retransform
          this.accept();
          return;
        }
        let previewCanvas = fullCanvas;

        // If the image is bigger than MAX_PREVIEW_WIDTH, we need to scale it so that
        // browser doesn't choke on it during a preview
        if (fullCanvas.width > MAX_PREVIEW_WIDTH) {
          previewCanvas = _blueimpLoadImage.default.scale(fullCanvas, {
            maxWidth: MAX_PREVIEW_WIDTH
          });
        }
        this.setProperties({
          preview: previewCanvas.toDataURL(),
          width: fullCanvas.width,
          height: fullCanvas.height
        });
      };
    }
  }), _class);
  var _default = _exports.default = ImageAreaSelect;
});