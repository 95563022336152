define("plutof/routes/study/view", ["exports", "@ember/service", "rsvp", "plutof/config/environment", "plutof/misc/config", "plutof/misc/content_types", "plutof/utils/access", "plutof/utils/loading", "plutof/utils/model", "plutof/utils/notifications", "plutof/utils/routes"], function (_exports, _service, _rsvp, _environment, _config, _content_types, _access, _loading, _model, _notifications, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function traverseProjects(parentId, projects) {
    let traversedProjects = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    let depth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    projects.forEach(project => {
      if (project.study_parent === parentId) {
        traversedProjects.push({
          ...project,
          depth: depth
        });
        traverseProjects(project.id, projects, traversedProjects, depth + 1);
      }
    });
    return traversedProjects;
  }
  function fetchChildProjects(ajax, id) {
    const url = `${_environment.default.API_HOST}/study/studies/${id}/sub-projects/`;
    return ajax.request(url).then(projects => traverseProjects(id, projects)).catch(_notifications.reportError);
  }
  let ProjectViewRoute = (_class = class ProjectViewRoute extends _routes.ViewRoute {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "groupMembership", _descriptor2, this);
      _defineProperty(this, "defaultReturnRoute", 'study.index');
      _defineProperty(this, "modelTitle", 'study');
    }
    async model(params, transition) {
      const project = await this.store.findRecord('study/study', params.id);
      return _rsvp.default.hash({
        study: project,
        projectAreas: (0, _loading.loadAll)(this.store, 'sample/project-area', {
          project: params.id,
          ordering: '-id'
        }, _config.default.Sample.PROJECT_MAP_LOADING_PAGE, _config.default.Sample.PROJECT_MAP_LIMIT),
        childProjects: fetchChildProjects(this.ajax, parseInt(project.id)),
        userIsGroupMember: this.groupMembership.currentUserBelongs((0, _model.relationID)(project, 'managing_group')),
        contenttype: (0, _content_types.get_ctype_by_name)(this.store, 'study/study'),
        accessRights: (0, _access.get_permissions)(this.store, this.ajax, project)
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        topLevelSamplingAreas: [],
        childProjects: [],
        studypersons: []
      });

      // Reset scroll when going between a child and a
      // parent project
      window.scrollTo(0, 0);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "groupMembership", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = ProjectViewRoute;
});