define("plutof/mixins/scrollbar-top", ["exports", "@ember/object/mixin", "@ember/runloop", "@ember/utils"], function (_exports, _mixin, _runloop, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function addMutationObservers(nodes, callback, config) {
    return nodes.map(node => {
      const observer = new MutationObserver(callback);
      observer.observe(node, config);
      return observer;
    });
  }
  var _default = _exports.default = _mixin.default.create({
    updateScrollbarWidth: function () {
      const table = document.querySelector('#' + this.tableBodyId);
      const dummyScrollbar = document.querySelector('.dummy-scrollbar');
      if (table && dummyScrollbar) {
        dummyScrollbar.style.width = table.scrollWidth + 'px';
      }
    },
    didRender: function () {
      this._super(...arguments);
      const dummyWrapper = document.querySelector('.dummy-scrollbar-wrapper');
      const tableWrapper = document.querySelector('.table-wrapper');
      const body = document.querySelector('#' + this.tableBodyId);
      const headerRow = document.querySelector('#' + this.tableHeaderRowId);
      if ((0, _utils.isNone)(dummyWrapper) || (0, _utils.isNone)(body)) {
        return;
      }
      function scrollTable() {
        tableWrapper.scroll(dummyWrapper.scrollLeft, 0);
      }
      function scrollDummy() {
        dummyWrapper.scroll(tableWrapper.scrollLeft, 0);
      }
      if (!this.hasEventListeners) {
        this.debounceScrollDummy = function () {
          (0, _runloop.debounce)(this, scrollDummy, 50);
        };
        this.debounceScrollTable = function () {
          (0, _runloop.debounce)(this, scrollTable, 100);
        };
        dummyWrapper.addEventListener('scroll', this.debounceScrollTable);
        tableWrapper.addEventListener('scroll', this.debounceScrollDummy);
        this.hasEventListeners = true;
      }
      if ((0, _utils.isEmpty)(this.mutationObservers)) {
        this.mutationObservers = addMutationObservers([body, headerRow], () => {
          (0, _runloop.debounce)(this, this.updateScrollbarWidth, 200);
        }, {
          childList: true
        });
      }

      // initial update
      (0, _runloop.debounce)(this, this.updateScrollbarWidth, 100);
    },
    willClearRender: function () {
      this._super(...arguments);
      const dummyWrapper = document.querySelector('.dummy-scrollbar-wrapper');
      const tableWrapper = document.querySelector('.table-wrapper');
      if (dummyWrapper) {
        dummyWrapper.removeEventListener('scroll', this.debounceScrollTable);
      }
      if (tableWrapper) {
        tableWrapper.removeEventListener('scroll', this.debounceScrollDummy);
      }
      if (this.mutationObservers) {
        this.mutationObservers.forEach(observer => observer.disconnect());
      }
    },
    didInsertElement: function () {
      this._super(...arguments);

      // XXX
      if (this.generateTable) {
        (0, _runloop.scheduleOnce)('afterRender', this, this.generateTable);
      }
    }
  });
});