define("plutof/helpers/is-equal", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkEquality = checkEquality;
  _exports.default = void 0;
  function checkEquality(_ref) {
    let [leftSide, rightSide] = _ref;
    return leftSide === rightSide;
  }
  var _default = _exports.default = (0, _helper.helper)(checkEquality);
});