define("plutof/components/identifiers/edit", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/utils", "rsvp", "plutof/utils/modules", "plutof/utils/errors", "plutof/utils/pagination", "plutof/utils/pagination/editable", "plutof/utils/reflection"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _utils, _rsvp, _modules, _errors, _pagination, _editable, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createIdentifiersEditModel = createIdentifiersEditModel;
  _exports.default = void 0;
  _exports.loadIdentifiersEditModel = loadIdentifiersEditModel;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "identifiers.identifiers"}}>
      <TabGroup
          @selected={{this.selectedTab}}
          @tabSwitched={{action (mut this.selectedTab)}}
      >
          <:tabs as |tabGroup|>
              {{#each @model.namespaces as |namespace|}}
                  <tabGroup.header @name={{namespace.type.contentType}}>
                      <span class={{namespace.icon}}></span>
                      {{namespace.name}}
                  </tabGroup.header>
  
                  <tabGroup.tab
                      @name={{namespace.type.contentType}}
                  >
                      <Identifiers::-Format @namespace={{namespace}} />
  
                      {{#if namespace.formatSet}}
                          <Identifiers::-Reservations
                              @namespace={{namespace}}
                              @workgroupData={{@workgroupData}}
                              @allowReservingForAnyone={{@allowReservingForAnyone}} />
                      {{/if}}
                  </tabGroup.tab>
              {{/each}}
          </:tabs>
      </TabGroup>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "DfzqPxba",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"identifiers.identifiers\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@selected\",\"@tabSwitched\"],[[30,0,[\"selectedTab\"]],[28,[37,3],[[30,0],[28,[37,4],[[30,0,[\"selectedTab\"]]],null]],null]]],[[\"tabs\"],[[[[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,2,[\"namespaces\"]]],null]],null],null,[[[1,\"                \"],[8,[30,1,[\"header\"]],null,[[\"@name\"],[[30,3,[\"type\",\"contentType\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[10,1],[15,0,[30,3,[\"icon\"]]],[12],[13],[1,\"\\n                    \"],[1,[30,3,[\"name\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n\\n                \"],[8,[30,1,[\"tab\"]],null,[[\"@name\"],[[30,3,[\"type\",\"contentType\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[8,[39,7],null,[[\"@namespace\"],[[30,3]]],null],[1,\"\\n\\n\"],[41,[30,3,[\"formatSet\"]],[[[1,\"                        \"],[8,[39,9],null,[[\"@namespace\",\"@workgroupData\",\"@allowReservingForAnyone\"],[[30,3],[30,4],[30,5]]],null],[1,\"\\n\"]],[]],null],[1,\"                \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"        \"]],[1]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"tabGroup\",\"@model\",\"namespace\",\"@workgroupData\",\"@allowReservingForAnyone\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"tab-group\",\"action\",\"mut\",\"each\",\"-track-array\",\"identifiers/-format\",\"if\",\"identifiers/-reservations\"]]",
    "moduleName": "plutof/components/identifiers/edit.hbs",
    "isStrictMode": false
  });
  class IdentifiersEditModel {
    constructor(_ref2) {
      let {
        store,
        namespaces
      } = _ref2;
      this.store = store;
      this.namespaces = namespaces;
    }
    async save() {
      await _rsvp.default.Promise.all(this.namespaces.map(ns => ns.save()));
    }
  }
  let IdentifiersNamespace = (_class = class IdentifiersNamespace {
    constructor(_ref3) {
      let {
        store,
        container,
        type,
        model,
        modelContainerField,
        pagination
      } = _ref3;
      _initializerDefineProperty(this, "prefix", _descriptor, this);
      _initializerDefineProperty(this, "width", _descriptor2, this);
      _initializerDefineProperty(this, "pending", _descriptor3, this);
      this.store = store;
      this.i18n = (0, _reflection.getService)(store, 'i18n');
      this.container = container;
      this.type = type;
      const module = _modules.default[type.contentType];
      this.icon = module.icon;
      this.name = module.name;
      this.model = model;
      this.modelContainerField = modelContainerField;
      this.pagination = pagination;
      this.prefix = this.container[this.type.formatPrefixField];
      this.width = (0, _utils.isNone)(this.container[this.type.formatWidthField]) ? 8 : this.container[this.type.formatWidthField];
    }
    updateFormat(prefix, width) {
      this.prefix = prefix;
      this.width = width;
      (0, _object.set)(this.container, this.type.formatPrefixField, prefix);
      (0, _object.set)(this.container, this.type.formatWidthField, width);
    }
    get formatSet() {
      return Boolean(this.prefix);
    }
    formatIdentifier(number) {
      return this.prefix + number.toString().padStart(this.width, '0');
    }
    async save() {
      // Specific first, so that non-specific don't overlap those
      //
      // Saving those one-by-one to prevent a race between
      // availability check and writing
      const specific = this.pending.filter(block => block.start && block.end);
      for (const block of specific) {
        try {
          await block.save();
          this.pagination.add(block);
          this.pending.removeObject(block);
        } catch (error) {
          throw new _errors.userError(this.i18n.translate('identifiers.errors.reserve', {
            hash: {
              block: block.representation,
              error: (0, _errors.getErrorMessage)(error)
            }
          }));
        }
      }

      // Available next, saved sequentially to lower the chance
      // of collisions. Figuring out the block available and
      // saving it are not a part of transaction
      const available = this.pending.filter(block => !block.start || !block.end);
      for (const block of available) {
        // Not bothering with wrapping the potential error. First, because
        // there shouldn't really be any, unless something is quite wrong.
        // But also, the backend error for this is as clear as I can do
        await block.save();
        this.pagination.add(block);
        this.pending.removeObject(block);
      }
      await this.pagination.save();
    }
    async checkAvailability(start, end) {
      // Adding current page to possibly save an API-side checks
      const blocks = this.pending.concat(this.pagination.objects.map(block => block.value));
      const overlapping = blocks.find(block => block.start <= end && block.end >= start);
      if (overlapping) {
        return {
          available: false,
          occurrences: [],
          overlapping_block: {
            id: overlapping.id,
            reserved_for: overlapping.get('reserved_for.representation'),
            start: overlapping.start,
            end: overlapping.end
          }
        };
      }
      if (!this.container.isNew) {
        const ajax = (0, _reflection.getService)(this.store, 'ajax');
        const response = await ajax.request((0, _reflection.get_record_url)(this.container) + 'identifiers/check-availability', {
          data: {
            occurrence_content_type: this.type.contentType,
            format_prefix: this.prefix,
            format_width: this.width,
            start,
            end
          }
        });
        return response;
      }
      return {
        available: true,
        occurrences: [],
        overlapping_block: null
      };
    }
    reserveAvailable(reservedFor, size) {
      const record = this.store.createRecord(this.model, {
        [this.modelContainerField]: this.container,
        occurrence_content_type: this.type.contentType,
        reserved_for: reservedFor,
        format_prefix: this.prefix,
        format_width: this.width,
        size
      });
      this.pending = this.pending.concat([record]);
    }
    reserveSpecific(reservedFor, start, end) {
      const record = this.store.createRecord(this.model, {
        [this.modelContainerField]: this.container,
        occurrence_content_type: this.type.contentType,
        reserved_for: reservedFor,
        format_prefix: this.prefix,
        format_width: this.width,
        start,
        end
      });
      this.pending = this.pending.concat([record]);
    }
    removeReserved(block) {
      this.pagination.remove(block);
    }
    removePending(block) {
      this.pending = this.pending.filter(b => b !== block);
      block.destroyRecord();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "prefix", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "width", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pending", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "removeReserved", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeReserved"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removePending", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removePending"), _class.prototype), _class);
  const OCCURRENCE_TYPES = [{
    contentType: 'specimen/specimen',
    formatPrefixField: 'specimen_identifier_format_prefix',
    formatWidthField: 'specimen_identifier_format_width'
  }, {
    contentType: 'materialsample/materialsample',
    formatPrefixField: 'material_sample_identifier_format_prefix',
    formatWidthField: 'material_sample_identifier_format_width'
  }, {
    contentType: 'livingculture/strain',
    formatPrefixField: 'living_specimen_identifier_format_prefix',
    formatWidthField: 'living_specimen_identifier_format_width'
  }];
  async function loadIdentifiersEditModel(_ref4) {
    let {
      store,
      container,
      model,
      modelContainerField,
      filters
    } = _ref4;
    const namespaces = await _rsvp.default.Promise.all(OCCURRENCE_TYPES.map(async type => {
      const formatPrefix = container[type.formatPrefixField];
      const formatSet = Boolean(formatPrefix);
      let underlyingPagination;
      if (formatSet) {
        const query = Object.assign({
          occurrence_content_type: type.contentType
        }, filters);
        underlyingPagination = await (0, _pagination.paginateModel)(store, model, query);
      } else {
        // If format is not set, assume there are no reservations
        underlyingPagination = _pagination.EmptyPagination.create();
      }
      const pagination = (0, _editable.default)({
        pagination: underlyingPagination,
        validations: {}
      });
      pagination.switchPage(1);
      return new IdentifiersNamespace({
        store,
        type,
        container,
        pagination,
        model,
        modelContainerField
      });
    }));
    return new IdentifiersEditModel({
      store,
      namespaces
    });
  }

  // Happens to be the same as load atm
  function createIdentifiersEditModel(_ref5) {
    let {
      store,
      container,
      model,
      modelContainerField
    } = _ref5;
    return loadIdentifiersEditModel({
      store,
      container,
      model,
      modelContainerField
    });
  }
  class IdentifiersEdit extends _component2.default {}
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IdentifiersEdit);
});