define("plutof/components/experiment/dna-extraction/experiment-material-row", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember-decorators/component", "ember-cp-validations", "plutof/utils/formatters", "plutof/mixins/component-validations", "plutof/models/agent/collection"], function (_exports, _component, _object, _computed, _utils, _component2, _emberCpValidations, _formatters, _componentValidations, _collection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'sourceValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'qualityCheckDateValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'dna.concentration': (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: _formatters.DECIMAL_REGEX
    }),
    'dna.unit_of_volume': (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: _formatters.ABS_REGEX
    })
  });
  let ExperimentMaterialRow = (_dec = (0, _component2.tagName)('tr'), _dec2 = (0, _computed.reads)('tube.dna'), _dec3 = (0, _computed.none)('dna.specimen.id'), _dec4 = (0, _computed.none)('dna.material_sample.id'), _dec5 = (0, _computed.none)('dna.living_specimen.id'), _dec6 = (0, _computed.not)('dna.hasSource'), _dec7 = (0, _object.computed)('notMaterialSample', 'notSpecimen'), _dec8 = (0, _object.computed)('notLivingSpecimen', 'notSpecimen'), _dec9 = (0, _object.computed)('notLivingSpecimen', 'notMaterialSample'), _dec10 = (0, _object.computed)('dna.guardedSpecimen.id', 'dna.materialSample.id', 'dna.livingSpecimen.id'), _dec11 = (0, _object.computed)('dna.secondary_identifier', 'noSource'), _dec(_class = (_class2 = class ExperimentMaterialRow extends _component.default.extend(Validations, _componentValidations.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "qualityCheckDateValidity", false);
      _defineProperty(this, "collectionFilters", {
        organization_type: _collection.CollectionType.DNACollection
      });
      _initializerDefineProperty(this, "dna", _descriptor, this);
      _initializerDefineProperty(this, "notSpecimen", _descriptor2, this);
      _initializerDefineProperty(this, "notMaterialSample", _descriptor3, this);
      _initializerDefineProperty(this, "notLivingSpecimen", _descriptor4, this);
      _initializerDefineProperty(this, "noSource", _descriptor5, this);
    }
    get disableLivingSpecimen() {
      return !this.notMaterialSample || !this.notSpecimen;
    }
    get disableMaterialSample() {
      return !this.notLivingSpecimen || !this.notSpecimen;
    }
    get disableSpecimen() {
      return !this.notLivingSpecimen || !this.notMaterialSample;
    }
    get contentType() {
      const sourceFields = [['guardedSpecimen.id', 'specimen/specimen'], ['materialSample.id', 'materialsample/materialsample'], ['livingSpecimen.id', 'livingculture/strain']];
      for (const [field, ctype] of sourceFields) {
        const id = this.dna.get(field);
        if (id) {
          return ctype;
        }
      }
      return null;
    }
    get sourceValidity() {
      const hasIdentifier = (0, _utils.isPresent)(this.dna.secondary_identifier);
      return hasIdentifier ? !this.noSource : true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "dna", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notSpecimen", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "notMaterialSample", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "notLivingSpecimen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "noSource", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "disableLivingSpecimen", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "disableLivingSpecimen"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "disableMaterialSample", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "disableMaterialSample"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "disableSpecimen", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "disableSpecimen"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "contentType", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "contentType"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sourceValidity", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "sourceValidity"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = ExperimentMaterialRow;
});