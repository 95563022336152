define("plutof/components/clipboard/clipboard-map", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "ember-data", "plutof/misc/abstract", "plutof/misc/config", "plutof/misc/options-getter", "plutof/utils/errors", "plutof/utils/map"], function (_exports, _component, _object, _computed, _service, _utils, _emberData, _abstract, _config, _optionsGetter, _errors, _map) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ClipboardMap = (_dec = (0, _computed.oneWay)('mapSelection.firstObject'), _dec2 = (0, _object.computed)('entries'), _class = class ClipboardMap extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "selectedMapArea", _descriptor3, this);
      _defineProperty(this, "mapPopupStrings", _emberData.default.PromiseObject.create({
        promise: (0, _optionsGetter.getModelTexts)(this.ajax, 'sample/samplingarea')
      }));
    }
    get geomArray() {
      const viewRoute = this.viewRoute;
      return this.entries.map(entry => {
        if ((0, _utils.isEmpty)(entry.geom)) {
          return null;
        } else {
          const coords = (0, _map.getWKTCenter)(entry.get('geom'));
          let recordId = (0, _object.get)(entry, 'id');
          let viewUrl = null;
          try {
            viewUrl = this.router.urlFor(viewRoute, recordId);
          } catch (e) {
            (0, _errors.publishError)(`Could not get view URL for route ${viewRoute} with record ID ${recordId}.
                        Error: ${(0, _errors.getErrorMessage)(e)}`);
          }
          return {
            geom: entry.get('geom'),
            source: entry.get('cbdatum'),
            coords: {
              lon: (0, _abstract.decimal_round)(coords[0], _config.default.Sample.COORDINATE_DECIMAL_PLACES),
              lat: (0, _abstract.decimal_round)(coords[1], _config.default.Sample.COORDINATE_DECIMAL_PLACES)
            },
            recordId: recordId,
            viewUrl: viewUrl
          };
        }
      }).compact();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedMapArea", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "geomArray", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "geomArray"), _class.prototype), _class);
  var _default = _exports.default = ClipboardMap;
});