define("plutof/components/linked-items/-edit/-files/local", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "plutof/utils/model"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
      <td>
          {{@preview}}
  
          {{@file.file.name}}
      </td>
  
      <td class="data-table-control-column">
          <PlutofSelect::Value
              @content={{this.licences}}
              @value={{@file.licence}} />
      </td>
  
      <td class="data-table-control-column">
          {{#async/bind-relation @file.owner as |value update|}}
              <Agent::AutoComplete
                  @value={{value}}
                  @update={{update}} />
          {{/async/bind-relation}}
      </td>
  
      <td class="data-table-control-column">
          <Agent::AutoComplete @update={{this.addCreator}} />
  
          <Common::RecordList
              @records={{@file.creators}}
              @remove={{this.removeCreator}} />
      </td>
  
      <td class="align-right">
          <button {{on "click" @remove}} class="btn btn-link">
              <span class={{icon "remove"}}></span>
          </button>
      </td>
  </tr>
  
  {{#if this.showRetransformControls}}
      <tr>
          <td colspan="5" class="occ-table-linked-files__retransform-selector">
              <Filerepository::ImageArea::Select
                  @file={{@file.file}}
                  @accept={{this.noRetransform}}
                  @transform={{this.retransform}}
                  @cancel={{this.cancelRetransform}} />
          </td>
      </tr>
  {{/if}}
  
  */
  {
    "id": "wHGprcoa",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n\\n        \"],[1,[30,2,[\"file\",\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[14,0,\"data-table-control-column\"],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@content\",\"@value\"],[[30,0,[\"licences\"]],[30,2,[\"licence\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[14,0,\"data-table-control-column\"],[12],[1,\"\\n\"],[6,[39,1],[[30,2,[\"owner\"]]],null,[[\"default\"],[[[[1,\"            \"],[8,[39,2],null,[[\"@value\",\"@update\"],[[30,3],[30,4]]],null],[1,\"\\n\"]],[3,4]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[14,0,\"data-table-control-column\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@update\"],[[30,0,[\"addCreator\"]]]],null],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@records\",\"@remove\"],[[30,2,[\"creators\"]],[30,0,[\"removeCreator\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[14,0,\"align-right\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-link\"],[4,[38,4],[\"click\",[30,5]],null],[12],[1,\"\\n            \"],[10,1],[15,0,[28,[37,5],[\"remove\"],null]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showRetransformControls\"]],[[[1,\"    \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"td\"],[14,\"colspan\",\"5\"],[14,0,\"occ-table-linked-files__retransform-selector\"],[12],[1,\"\\n            \"],[8,[39,7],null,[[\"@file\",\"@accept\",\"@transform\",\"@cancel\"],[[30,2,[\"file\"]],[30,0,[\"noRetransform\"]],[30,0,[\"retransform\"]],[30,0,[\"cancelRetransform\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@preview\",\"@file\",\"value\",\"update\",\"@remove\"],false,[\"plutof-select/value\",\"async/bind-relation\",\"agent/auto-complete\",\"common/record-list\",\"on\",\"icon\",\"if\",\"filerepository/image-area/select\"]]",
    "moduleName": "plutof/components/linked-items/-edit/-files/local.hbs",
    "isStrictMode": false
  });
  let LinkedItemsEditLocalFile = (_dec = (0, _model.choices)('filerepository.file.licence'), _class = class LinkedItemsEditLocalFile extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "showRetransformControls", _descriptor2, this);
      _initializerDefineProperty(this, "licences", _descriptor3, this);
      const needsRetransform = this.args.file.toBeRetransformed;
      this.updateRetransformControls(needsRetransform);
      if (needsRetransform) {
        // Prevent simultaneous retransform of the same file
        (0, _object.set)(this.args.file, 'toBeRetransformed', false);
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);

      // If row is deleted while retransform is not finished yet, clear the status
      // so if user readds the file, retransform controls are shown
      if (this.showRetransformControls) {
        (0, _object.set)(this.args.file, 'toBeRetransformed', true);
      }
    }
    noRetransform() {
      this.updateRetransformControls(false);
    }
    retransform(area) {
      this.args.file.retransformRect = area;
      this.updateRetransformControls(false);
    }
    cancelRetransform() {
      this.updateRetransformControls(false);
      this.args.remove();
    }
    updateRetransformControls(status) {
      this.showRetransformControls = status;
      if (this.args.updateSelectorStatus) {
        this.args.updateSelectorStatus(status);
      }
    }
    addCreator(agent) {
      if (!agent) {
        return;
      }
      const creator = this.store.createRecord('filerepository/creator', {
        agent
      });
      this.args.file.creators.pushObject(creator);
    }
    removeCreator(creator) {
      this.args.file.creators.removeObject(creator);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showRetransformControls", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "licences", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "noRetransform", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "noRetransform"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "retransform", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "retransform"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelRetransform", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelRetransform"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addCreator", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addCreator"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeCreator", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeCreator"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LinkedItemsEditLocalFile);
});