define("plutof/routes/organization/edit", ["exports", "plutof/components/agent/organization/edit", "plutof/utils/routes"], function (_exports, _edit, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class CollectionEditRoute extends _routes.EditRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "defaultReturnRoute", 'organization.index');
      _defineProperty(this, "confirmExit", true);
    }
    async model(params) {
      const organizationRecord = await this.store.findRecord('agent/organization', params.id);
      const organization = await (0, _edit.loadOrganizationModel)(organizationRecord);
      return {
        organization
      };
    }
    getTitle(model) {
      return model.organization.organization.representation;
    }
    transitionToDefaultRoute() {
      return this.transitionTo('organization.view', this.currentModel.organization.organization.id);
    }
  }
  _exports.default = CollectionEditRoute;
});