define("plutof/models/sample/samplingarea", ["exports", "@ember/object", "@ember/utils", "ember-data", "@ember-data/model", "rsvp", "plutof/models/plutof-model", "plutof/utils/model"], function (_exports, _object, _utils, _emberData, _model, _rsvp, _plutofModel, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const REPRESENTATION_PARTS = ['contry.representation', 'district', 'commune'];
  let SamplingArea = (_dec = (0, _model.belongsTo)('study/study'), _dec2 = (0, _model.belongsTo)('sample/samplingarea', {
    inverse: null
  }), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec5 = (0, _model.attr)('boolean'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('number'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.belongsTo)('geography/country'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model2.privateGuard)('study'), _dec20 = (0, _model2.privateGuard)('parent_samplingarea'), _dec21 = (0, _object.computed)('study'), _dec22 = (0, _object.computed)('parent_samplingarea.ancestorAreas'), _dec23 = (0, _object.computed)('name', ...REPRESENTATION_PARTS), _class = class SamplingArea extends _plutofModel.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "study", _descriptor, this);
      _initializerDefineProperty(this, "parent_samplingarea", _descriptor2, this);
      _initializerDefineProperty(this, "name", _descriptor3, this);
      _initializerDefineProperty(this, "site", _descriptor4, this);
      _initializerDefineProperty(this, "restricted", _descriptor5, this);
      _initializerDefineProperty(this, "method", _descriptor6, this);
      _initializerDefineProperty(this, "latitude", _descriptor7, this);
      _initializerDefineProperty(this, "longitude", _descriptor8, this);
      _initializerDefineProperty(this, "coordinate_uncertainty_in_meters", _descriptor9, this);
      _initializerDefineProperty(this, "geom", _descriptor10, this);
      _initializerDefineProperty(this, "country", _descriptor11, this);
      _initializerDefineProperty(this, "state", _descriptor12, this);
      _initializerDefineProperty(this, "district", _descriptor13, this);
      _initializerDefineProperty(this, "parish", _descriptor14, this);
      _initializerDefineProperty(this, "commune", _descriptor15, this);
      _initializerDefineProperty(this, "locality_text", _descriptor16, this);
      // Readonly
      _initializerDefineProperty(this, "country_name", _descriptor17, this);
      _initializerDefineProperty(this, "child_count", _descriptor18, this);
      _initializerDefineProperty(this, "guardedProject", _descriptor19, this);
      _initializerDefineProperty(this, "guardedParent", _descriptor20, this);
    }
    // Deals with inaccessible projects, because ember-data can't handle those, crashing
    // the render on the second try. Note: same bug is worked around differently with occurrence.project
    get project() {
      let promise;
      if (this.projectIsPrivate) {
        promise = _rsvp.default.Promise.resolve(null);
      } else {
        promise = this.study.then(project => project, () => {
          this.set('projectIsPrivate', true);
          return null;
        });
      }
      return _emberData.default.PromiseObject.create({
        promise
      });
    }
    set project(project) {
      if (this.projectIsPrivate) {
        throw new Error('Can\'t change area project if user has not access to the current one');
      }
      this.set('study', project);
    }
    get ancestorAreas() {
      return _emberData.default.PromiseArray.create({
        promise: this.parent_samplingarea.then(parent => {
          if ((0, _utils.isNone)(parent)) {
            return [];
          }
          return parent.get('ancestorAreas').then(parentAncestors => {
            return parentAncestors.concat(parent);
          });
        })
      });
    }
    get representation() {
      const name = this.name;
      if (!(0, _utils.isEmpty)(name)) {
        return name;
      }
      const parts = Object.values(this.getProperties(REPRESENTATION_PARTS));
      return parts.reject(_utils.isEmpty).join(', ');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "study", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "parent_samplingarea", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "restricted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "method", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "latitude", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "longitude", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "coordinate_uncertainty_in_meters", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "geom", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "district", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "parish", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "commune", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "locality_text", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "country_name", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "child_count", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "guardedProject", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "guardedParent", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "project", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "project"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ancestorAreas", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "ancestorAreas"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "representation", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "representation"), _class.prototype), _class);
  var _default = _exports.default = SamplingArea;
});