define("plutof/components/clipboard/darwin-core-export-persons", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember-decorators/component"], function (_exports, _component, _object, _computed, _utils, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function inlineFormValidator(valuePath, requiredPath, overridePath) {
    const deps = [`${valuePath}.[]`, requiredPath, overridePath].compact();
    return (0, _object.computed)(...deps, function () {
      return !(0, _utils.isEmpty)(overridePath) && this.get(overridePath) || !this.get(requiredPath) || !(0, _utils.isEmpty)(this.get(valuePath));
    });
  }

  // DRY
  // Empty string or e-mail address.
  const EMAIL_REGEX = /^(|[A-za-z0-9._-]+@[A-za-z0-9._-]+\.[a-z]{2,})$/;
  let DarwinArchiveExportPersons = (_dec = (0, _component2.classNames)('darwin-core-export-persons'), _dec2 = inlineFormValidator('data', 'options.required'), _dec3 = inlineFormValidator('newPerson', 'requirePerson', 'doNotValidate'), _dec4 = inlineFormValidator('newEmail', 'options.child.children.email.required', 'doNotValidate'), _dec5 = (0, _object.computed)('newPerson', 'newEmail'), _dec6 = (0, _computed.and)('dataValidity', 'fieldsAreEmpty'), _dec7 = (0, _object.computed)('emailPresenceValidity', 'newEmail'), _dec8 = (0, _object.computed)('emailValidity', 'personValidity', 'fieldsAreaEmpty'), _dec(_class = (_class2 = class DarwinArchiveExportPersons extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "newPerson", null);
      _defineProperty(this, "newEmail", '');
      _defineProperty(this, "data", []);
      /**
       * Person is temporarily required, until we add organization and role fields.
       * Once we have above mentioned fields, remove this property and update personValidity's
       * requiredPath.
       */
      _defineProperty(this, "requirePerson", true);
      _initializerDefineProperty(this, "dataValidity", _descriptor, this);
      _initializerDefineProperty(this, "personValidity", _descriptor2, this);
      _initializerDefineProperty(this, "emailPresenceValidity", _descriptor3, this);
      _initializerDefineProperty(this, "doNotValidate", _descriptor4, this);
    }
    get fieldsAreEmpty() {
      return [this.newPerson, this.newEmail].every(_utils.isEmpty);
    }
    get emailValidity() {
      return this.emailPresenceValidity && EMAIL_REGEX.test(this.newEmail ?? '');
    }
    get addButtonValidity() {
      return this.emailValidity && this.personValidity && !this.fieldsAreEmpty;
    }
    addDatum() {
      const person = this.newPerson;
      const email = this.newEmail;
      const representation = (0, _utils.isEmpty)(person) ? email : `${person.get('representation')} (${email})`;
      this.data.pushObject({
        person: person,
        email: email,
        representation: representation
      });
      this.set('newPerson', null);
      this.set('newEmail', '');
    }
    removeDatum(datum) {
      this.data.removeObject(datum);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "dataValidity", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "personValidity", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "emailPresenceValidity", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "fieldsAreEmpty", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "fieldsAreEmpty"), _class2.prototype), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "doNotValidate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "emailValidity", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "emailValidity"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addButtonValidity", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "addButtonValidity"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addDatum", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "addDatum"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeDatum", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "removeDatum"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = DarwinArchiveExportPersons;
});