define("plutof/helpers/call", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // NB: With great power comes great responsibility
  var _default = _exports.default = (0, _helper.helper)(function callHelper(_ref) {
    let [func, ...params] = _ref;
    return func.apply(undefined, params);
  });
});