define("plutof/components/specimen/measurements", ["exports", "@ember/component", "@ember/template-factory", "@ember/object/computed", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _computed, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Measurements::AddExtra::Panel
      @measurements={{@measurements}}
      @module="taxon_occurrence"
  >
      <Layout::RegularGrid @columns={{6}}>
          {{#if this.showIsolationFields}}
              <div class="{{unless this.isolationDateValidity 'has-error'}}">
                  {{plutof-labelc "specimen.attrib_isolation_date"}}
  
                  <DatePicker
                      @value={{@specimen.attrib_isolation_date}}
                      @validity={{this.isolationDateValidity}} />
              </div>
  
              <div class="{{unless this.emergenceDateValidity 'has-error'}}">
                  {{plutof-labelc "specimen.attrib_emergence_date"}}
  
                  <DatePicker
                      @value={{@specimen.attrib_emergence_date}}
                      @validity={{this.emergenceDateValidity}} />
              </div>
          {{/if}}
      </Layout::RegularGrid>
  
      <Measurements::MeasurementForm
          @object={{@specimen}}
          @mainform={{@specimen.mainform}}
          @data={{@measurements}}
          @row_length={{6}}
          @validationContext={{@validationContext.measurements}} />
  </Measurements::AddExtra::Panel>
  
  */
  {
    "id": "MLqKBQCA",
    "block": "[[[8,[39,0],null,[[\"@measurements\",\"@module\"],[[30,1],\"taxon_occurrence\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"showIsolationFields\"]],[[[1,\"            \"],[10,0],[15,0,[29,[[52,[51,[30,0,[\"isolationDateValidity\"]]],\"has-error\"]]]],[12],[1,\"\\n                \"],[1,[28,[35,4],[\"specimen.attrib_isolation_date\"],null]],[1,\"\\n\\n                \"],[8,[39,5],null,[[\"@value\",\"@validity\"],[[30,2,[\"attrib_isolation_date\"]],[30,0,[\"isolationDateValidity\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,0],[15,0,[29,[[52,[51,[30,0,[\"emergenceDateValidity\"]]],\"has-error\"]]]],[12],[1,\"\\n                \"],[1,[28,[35,4],[\"specimen.attrib_emergence_date\"],null]],[1,\"\\n\\n                \"],[8,[39,5],null,[[\"@value\",\"@validity\"],[[30,2,[\"attrib_emergence_date\"]],[30,0,[\"emergenceDateValidity\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,6],null,[[\"@object\",\"@mainform\",\"@data\",\"@row_length\",\"@validationContext\"],[[30,2],[30,2,[\"mainform\"]],[30,1],6,[30,3,[\"measurements\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@measurements\",\"@specimen\",\"@validationContext\"],false,[\"measurements/add-extra/panel\",\"layout/regular-grid\",\"if\",\"unless\",\"plutof-labelc\",\"date-picker\",\"measurements/measurement-form\"]]",
    "moduleName": "plutof/components/specimen/measurements.hbs",
    "isStrictMode": false
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'isolationDateValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'emergenceDateValidity': (0, _emberCpValidations.validator)('is-truthy')
  });
  let SpecimenMeasurements = (_dec = (0, _computed.reads)('specimen.mainform.attrib_emergence_date'), _class = class SpecimenMeasurements extends _component.default.extend(Validations, _componentValidations.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "showIsolationFields", _descriptor, this);
      _defineProperty(this, "validationChildren", ['measurements']);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs();
      this.set('isolationDateValidity', true);
      this.set('emergenceDateValidity', true);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "showIsolationFields", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SpecimenMeasurements);
});