define("plutof/components/livingspecimen/auto-complete", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/components/auto-complete/backends"], function (_exports, _component, _templateFactory, _component2, _object, _service, _backends) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AutoComplete::Base
      @backend={{this.backend}}
      @value={{@value}}
      @update={{@update}}
      @query={{@query}}
      @updateQuery={{@updateQuery}}
      @disabled={{@disabled}}
      @params={{@params}}
      ...attributes
  >
      <:result as |result context|>
          {{hl-subs (call this.resultName result) context.query}}
  
          {{#if result.full.owner}}
              <span class="plutof-ac-suggestion-description">
                  {{result.fullowner}}
              </span>
          {{/if}}
      </:result>
  </AutoComplete::Base>
  
  */
  {
    "id": "3ga9DoAE",
    "block": "[[[8,[39,0],[[17,1]],[[\"@backend\",\"@value\",\"@update\",\"@query\",\"@updateQuery\",\"@disabled\",\"@params\"],[[30,0,[\"backend\"]],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7]]],[[\"result\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[[28,[37,2],[[30,0,[\"resultName\"]],[30,8]],null],[30,9,[\"query\"]]],null]],[1,\"\\n\\n\"],[41,[30,8,[\"full\",\"owner\"]],[[[1,\"            \"],[10,1],[14,0,\"plutof-ac-suggestion-description\"],[12],[1,\"\\n                \"],[1,[30,8,[\"fullowner\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[8,9]]]]],[1,\"\\n\"]],[\"&attrs\",\"@value\",\"@update\",\"@query\",\"@updateQuery\",\"@disabled\",\"@params\",\"result\",\"context\"],false,[\"auto-complete/base\",\"hl-subs\",\"call\",\"if\"]]",
    "moduleName": "plutof/components/livingspecimen/auto-complete.hbs",
    "isStrictMode": false
  });
  // Yes, it's just SpecimenAutoComplete
  let LivingSpecimenAutoComplete = _exports.default = (_dec = (0, _object.computed)('args.filters'), _class = class LivingSpecimenAutoComplete extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
    }
    get backend() {
      return new _backends.ModelAutocomplete({
        ajax: this.ajax,
        store: this.store,
        modelName: 'taxonoccurrence/livingculture/strain',
        filters: this.args.filters || {
          access: 'edit'
        }
      });
    }
    resultName(result) {
      const {
        name,
        additional_identifiers
      } = result.full;
      return [name].concat(additional_identifiers).join(' | ');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "backend", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "backend"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resultName", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resultName"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LivingSpecimenAutoComplete);
});