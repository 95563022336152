define("plutof/mixins/route", ["exports", "@ember/object/mixin", "@ember/object", "@ember/runloop", "@ember/service", "@ember/string", "@ember/utils", "plutof/misc/abstract", "plutof/misc/config", "plutof/misc/routing", "plutof/mixins/reset", "plutof/mixins/route-title", "plutof/utils/errors", "plutof/utils/notifications"], function (_exports, _mixin, _object, _runloop, _service, _string, _utils, _abstract, _config, _routing, _reset, _routeTitle, _errors, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ControllerRouteActions = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const CONFIRM_TRANSITIONS = _config.default.Common.ENABLE_TRANSITION_CONFIRMATION;

  // XXX: Those should be in router service if they are to be anywhere.
  // At least its not a part of application controller anymore
  // TODO: Also, without invokeRoute can just use URLs
  let routeStack = [];
  function pushRoute(route, param) {
    routeStack.pushObject({
      route,
      param
    });
  }
  function popRoute() {
    return routeStack.popObject(); // undefined when the stack is empty is ok
  }

  // XXX: What a mess. The crux of the matter is that we need some logic
  // in routes:
  //
  // - going back, including default routes
  // - exit confirmation
  // - resets
  //
  // But at the same time we have to trigger those from controllers and Ember
  // very explicitly doesn't have any link between the two, except
  // sending "actions" from controller to route, which is some legacy crap
  // that, for example, prevents this mixin from being just a part of
  // top level Route class.
  //
  // Anyway, as a workaround, route injects this into controller
  let ControllerRouteActions = _exports.ControllerRouteActions = (_class = class ControllerRouteActions {
    constructor(route) {
      this._route = route;
    }
    goBack(parameter) {
      let {
        confirmExit = true,
        enableCurrentPath = false
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this._route.goBack(parameter, confirmExit, enableCurrentPath);
    }

    // TODO: In some later ember version, there is RouterService.refresh
    refresh() {
      this._route.refresh();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "goBack", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "goBack"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refresh", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype), _class); // TODO: Rename this
  // TODO: Common error handler (see route.error)
  //
  // XXX: No proper idea how to untangle this. Some things could probably
  // be moved into a service :shrug:
  var _default = _exports.default = _mixin.default.create(_reset.default, _routeTitle.default, {
    router: (0, _service.inject)(),
    defaultReturnRoute: '',
    confirmExit: false,
    confirmationDialog: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    init: function () {
      this._super(...arguments);
      this.set('going_back', false);
      this.set('current_path', null);
      this.set('param', null);
      // if confirmExit is true and exitConfirmed is false, exit transition is prevented
      this.set('exitConfirmed', false);
      // If true, don't spawn additional exit confirmations
      this.set('confirmationScheduled', false);
    },
    _transition: function (route, param, confirmExit) {
      let queryParams = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      if (confirmExit) {
        this.set('exitConfirmed', true);
      }
      if ((0, _utils.isNone)(param)) {
        return this.transitionTo(route, {
          queryParams
        });
      } else {
        return this.transitionTo(route, param, {
          queryParams
        });
      }
    },
    afterModel: function (model, transition, queryParams) {
      this._super(model, transition, queryParams);

      // TODO: Replace with router service
      this.set('current_path', transition.targetName);
      // XXX: This whole file needs to be scrapped
      this.set('param', Object.values(transition.to.params)[0]);
    },
    // Overridable to add more actions
    buildControllerRouteActions: function () {
      return new ControllerRouteActions(this);
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      controller.routeActions = this.buildControllerRouteActions();
      this.set('exitConfirmed', false);
      (0, _routing.getRoutingStatus)().set('routeLoading', false);
    },
    // TODO: Move all CSS shenanigans to app route
    activate: function () {
      this._super(...arguments);
      var cssClass = this.getCssClass();
      var authenticated = this.get('session.isAuthenticated');
      if (authenticated) {
        document.querySelector('body').classList.add(cssClass);

        /**
         * Because the application route activates just once, and never
         * deactivates, we remove its class every time we activate a route
         * other than application.
         */
        if (cssClass !== 'application') {
          document.querySelector('body').classList.remove('application');
        }
      } else {
        // force white body background when not authenticated
        document.querySelector('body').classList.add('white-background');
      }

      /**
       * Add route name as class to outlet container to enable variable page
       * content width per route.
       */
      (0, _runloop.schedule)('afterRender', () => {
        const container = document.querySelector('#outlet-container');
        if ((0, _utils.isNone)(container)) {
          return;
        }
        container.classList.add(cssClass);
      });
    },
    deactivate: function () {
      this._super(...arguments);
      const body = document.querySelector('body');
      const outlet = document.querySelector('#outlet-container');
      const className = this.getCssClass();
      body.classList.remove(className);
      body.classList.remove('white-background');
      if (!(0, _utils.isNone)(outlet)) {
        outlet.classList.remove(className);
      }

      /*
      Assuming we are going to the index page, since no other route's class has been added.
      Modal is a special case here. We want to add the application class even when navigation form modal to index.
      */
      const classList = document.body.classList;
      if (classList.contains('ember-application') || classList.contains('ember-application modal-open')) {
        classList.add('application');
      }
    },
    getCssClass: function () {
      return (0, _string.dasherize)(this.routeName.replace(/\./g, '-'));
    },
    goBack: function (parameter, confirmExit, enableCurrentPath) {
      var previous_route = popRoute();
      if ((0, _utils.isNone)(confirmExit)) {
        confirmExit = true;
      }

      // In case user goes A -> B/1 -> B/2 -> C, goBack sequence should be C -> B/2 -> A, not
      // C -> B/2 -> B/1 -> A
      if (enableCurrentPath) {
        previous_route = popRoute();
      } else {
        while (!(0, _utils.isNone)(previous_route) && previous_route.route === this.current_path) {
          previous_route = popRoute();
        }
      }
      if ((0, _utils.isNone)(previous_route)) {
        if (confirmExit) {
          this.set('exitConfirmed', true);
        }
        this.transitionToDefaultRoute();
      } else {
        this.set('going_back', true);
        let transition = this._transition(previous_route.route, previous_route.param, confirmExit);
        transition.then(() => {
          if (transition.isAborted) {
            this.goBack(parameter, confirmExit);
          }
        });
      }
    },
    transitionToDefaultRoute() {
      this.transitionTo(this.defaultReturnRoute);
    },
    _routeMixinHandleWillTransition(transition) {
      if (this.going_back) {
        this.set('going_back', false);
      }
      if (CONFIRM_TRANSITIONS && !(0, _utils.isNone)(transition) && this.confirmExit && !this.exitConfirmed && transition.to.queryParams.no_confirm !== 'true') {
        transition.abort();
        if (this.confirmationScheduled) {
          return false;
        }
        this.set('confirmationScheduled', true);
        this.confirmationDialog.confirm({
          title: this.i18n.t('General.routeExitDlgTitle'),
          content: this.i18n.t('General.routeExitDlgContent')
        }).then(confirmed => {
          this.set('confirmationScheduled', false);
          if (!confirmed) {
            return;
          }
          this.set('exitConfirmed', true);
          let cleanupFinished = _abstract.EMPTY_PROMISE;
          if (_config.default.Common.ENABLE_UNLOAD_ON_CONFIRMED_LEAVE) {
            const controller = this.controller;
            if (!(0, _utils.isNone)(controller) && !(0, _utils.isNone)(controller.unloadRecords)) {
              cleanupFinished = controller.unloadRecords();
            }
          }
          cleanupFinished.then(() => transition.retry());
        });
      }
      return true;
    },
    actions: {
      loading: function () {
        this._super();
        (0, _routing.getRoutingStatus)().set('routeLoading', true);
      },
      // XXX TODO: location history is still modified
      willTransition: function (transition) {
        // XXX XXX
        if (this._routeMixinHandleWillTransition(transition)) {
          this._super(transition);
        }
      },
      didTransition: function () {
        this._super();

        // TODO: Remove currentRoute from app_ctr, replacing it with router
        // service route metadata (requires ember 3.10)
        var app_ctr = this.controllerFor('application');
        app_ctr.set('currentRoute', this);

        // Ugly, ugly hacks
        if (this.current_path !== 'index') {
          pushRoute(this.current_path, this.param);
        }
      },
      error: function (error, transition) {
        (0, _notifications.reportError)(error);
        transition.abort();

        // XXX XXX XXX XXX XXX XXX
        this.transitionTo('/not-found');
        (0, _errors.processError)(error);
      }
    }
  });
});