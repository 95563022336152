define("plutof/routes/photobank/add", ["exports", "@ember/service", "@ember/utils", "rsvp", "plutof/components/edit-preparations", "plutof/components/linked-items/edit", "plutof/components/observation/observation-table", "plutof/misc/config", "plutof/misc/profile_settings", "plutof/utils/exif", "plutof/utils/cloning", "plutof/utils/model", "plutof/utils/routes"], function (_exports, _service, _utils, _rsvp, _editPreparations, _edit, _observationTable, _config, _profile_settings, _exif, _cloning, _model, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PhotobankAddRoute = (_class = class PhotobankAddRoute extends _routes.AddRoute {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "geocoding", _descriptor, this);
      _initializerDefineProperty(this, "fileUpload", _descriptor2, this);
      _defineProperty(this, "defaultReturnRoute", 'photobank.index');
      _defineProperty(this, "title", 'Photobank.newObject');
    }
    async model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const fileIDs = (0, _utils.isEmpty)(params.file_ids) ? [] : params.file_ids.split(',');
      const samplingData = await this.loadSample(params);
      const object = this.store.createRecord('photobank/collectionobject', {
        samplingevent: samplingData.event
      });
      const observationMainform = this.store.findRecord('measurement/mainform', _config.default.Observation.DEFAULT_MAINFORM_ID);
      const model = await _rsvp.default.hash({
        eventFormData: samplingData,
        object,
        additionalIdentifiers: [],
        classificationItems: [],
        agentItems: [],
        preparationsData: (0, _editPreparations.createPreparationData)(this.store, object, true),
        observationData: _observationTable.ObservationTableData.initialize(this.store, this.fileUpload, [], {
          allowZeroOccurrences: true,
          enableDeterminers: true
        }),
        itemData: this.loadFiles(object, fileIDs, samplingData),
        observationMainform: observationMainform
      });
      const organization = await ((0, _utils.isNone)(params.collection_id) ? (0, _profile_settings.get_personal_settings)(this.store).then(settings => settings.get('default_photobank_collection')) : this.store.findRecord('agent/collection', params.collection_id));
      object.set('deposited_in', organization);
      return model;
    }
    async loadFiles(object, fileIDs, sample) {
      const store = this.store;
      const files = await _rsvp.default.all(fileIDs.map(id => store.findRecord('filerepository/file', id)));
      await (0, _exif.fillSampleFromEXIF)(this.store, this.geocoding, sample, files);
      const itemData = await (0, _edit.createLinkedItemsData)(store, object, {
        useEventFiles: true
      });
      const filesStore = itemData.get('files');
      files.forEach(file => {
        filesStore.add({
          file
        });
      });
      return itemData;
    }
    async loadSample(params) {
      let sample;
      if ((0, _utils.isNone)(params) || (0, _utils.isEmpty)(params.template_id)) {
        const samplingData = await (0, _model.getSamplingDataForParams)(this.store, params);
        sample = samplingData.formData;
      } else {
        const cloneArea = params.clone_area === 'true';
        const template = await this.store.findRecord('photobank/collectionobject', params.template_id);
        const templateSD = await (0, _model.getOccurrenceSamplingData)(this.store, template);
        sample = await (0, _cloning.clone_sampling_data)(this.store, templateSD.formData, cloneArea);
      }
      return sample;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      if (!(0, _utils.isNone)(model)) {
        controller.set('objectsToDelete', []);
        controller.set('closePanels', false);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "geocoding", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fileUpload", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = PhotobankAddRoute;
});