define("plutof/components/menu/menu-tree", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "plutof/utils/menu"], function (_exports, _component, _templateFactory, _component2, _service, _menu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul
      class="menu-tree-wrapper"
  >
      {{#if this.session.isAuthenticated}}
          {{#each this.sidebarConfig.children as |child|}}
              {{#unless child.disable}}
                  <Menu::MenuTree::-Node
                      @node={{child}}
                      @collapseMenu={{@collapseMenu}}
                      @menuItemClicked={{@menuItemClicked}} />
              {{/unless}}
          {{/each}}
      {{/if}}
  
      <li class="burger-menu-button-bar-wrapper">
          <Menu::ButtonBar
              @logOut={{@logOut}}
              @changeLanguage={{@changeLanguage}} />
      </li>
  </ul>
  
  */
  {
    "id": "mn5RgT14",
    "block": "[[[10,\"ul\"],[14,0,\"menu-tree-wrapper\"],[12],[1,\"\\n\"],[41,[30,0,[\"session\",\"isAuthenticated\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"sidebarConfig\",\"children\"]]],null]],null],null,[[[41,[51,[30,1,[\"disable\"]]],[[[1,\"                \"],[8,[39,4],null,[[\"@node\",\"@collapseMenu\",\"@menuItemClicked\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n\"]],[]],null]],[1]],null]],[]],null],[1,\"\\n    \"],[10,\"li\"],[14,0,\"burger-menu-button-bar-wrapper\"],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@logOut\",\"@changeLanguage\"],[[30,4],[30,5]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"child\",\"@collapseMenu\",\"@menuItemClicked\",\"@logOut\",\"@changeLanguage\"],false,[\"if\",\"each\",\"-track-array\",\"unless\",\"menu/menu-tree/-node\",\"menu/button-bar\"]]",
    "moduleName": "plutof/components/menu/menu-tree.hbs",
    "isStrictMode": false
  });
  let MenuTree = (_class = class MenuTree extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _defineProperty(this, "sidebarConfig", _menu.menuConfig);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MenuTree);
});