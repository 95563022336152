define("plutof/components/filerepository/gallery", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "@glimmer/tracking", "plutof/components/filerepository/gallery/-model-viewer-preview"], function (_exports, _component, _templateFactory, _object, _computed, _service, _utils, _tracking, _modelViewerPreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Gallery::Gallery
      @open={{this.open}}
      @close={{this.close}}
      @previous={{if this.hasMultipleFiles (fn this.move -1)}}
      @next={{if this.hasMultipleFiles (fn this.move 1)}}
      @loading={{this.loading}}
  >
      <:preview>
          {{#if this.activeItem.file.is3dModel}}
              <Filerepository::Gallery::-ModelViewerPreview
                  @controller={{this.modelViewerPreviewController}}
                  @model={{this.activeItem.file.download_links.link}} />
          {{else}}
              <Filerepository::Gallery::-Preview
                  @filename={{this.activeItem.file.file_name}}
                  @type={{this.activeItem.file.type}}
                  @links={{this.activeItem.file.download_links}} />
          {{/if}}
      </:preview>
  
      <:footer-left as |footer|>
          <Filerepository::Gallery::-FooterLeft
              @footer={{footer}}
              @file={{this.activeItem.file}} />
      </:footer-left>
  
      <:footer-right as |footer|>
          <Filerepository::Gallery::-FooterRight
              @footer={{footer}}
              @file={{this.activeItem.file}}
              @close={{this.close}}
              @showOnMap={{if @showOnMap this._showOnMap}}
              @modelViewerPreviewController={{this.modelViewerPreviewController}} />
      </:footer-right>
  </Gallery::Gallery>
  
  */
  {
    "id": "aOQgr59g",
    "block": "[[[8,[39,0],null,[[\"@open\",\"@close\",\"@previous\",\"@next\",\"@loading\"],[[30,0,[\"open\"]],[30,0,[\"close\"]],[52,[30,0,[\"hasMultipleFiles\"]],[28,[37,2],[[30,0,[\"move\"]],-1],null]],[52,[30,0,[\"hasMultipleFiles\"]],[28,[37,2],[[30,0,[\"move\"]],1],null]],[30,0,[\"loading\"]]]],[[\"preview\",\"footer-left\",\"footer-right\"],[[[[1,\"\\n\"],[41,[30,0,[\"activeItem\",\"file\",\"is3dModel\"]],[[[1,\"            \"],[8,[39,3],null,[[\"@controller\",\"@model\"],[[30,0,[\"modelViewerPreviewController\"]],[30,0,[\"activeItem\",\"file\",\"download_links\",\"link\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[39,4],null,[[\"@filename\",\"@type\",\"@links\"],[[30,0,[\"activeItem\",\"file\",\"file_name\"]],[30,0,[\"activeItem\",\"file\",\"type\"]],[30,0,[\"activeItem\",\"file\",\"download_links\"]]]],null],[1,\"\\n\"]],[]]],[1,\"    \"]],[]],[[[1,\"\\n        \"],[8,[39,5],null,[[\"@footer\",\"@file\"],[[30,1],[30,0,[\"activeItem\",\"file\"]]]],null],[1,\"\\n    \"]],[1]],[[[1,\"\\n        \"],[8,[39,6],null,[[\"@footer\",\"@file\",\"@close\",\"@showOnMap\",\"@modelViewerPreviewController\"],[[30,2],[30,0,[\"activeItem\",\"file\"]],[30,0,[\"close\"]],[52,[30,3],[30,0,[\"_showOnMap\"]]],[30,0,[\"modelViewerPreviewController\"]]]],null],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[\"footer\",\"footer\",\"@showOnMap\"],false,[\"gallery/gallery\",\"if\",\"fn\",\"filerepository/gallery/-model-viewer-preview\",\"filerepository/gallery/-preview\",\"filerepository/gallery/-footer-left\",\"filerepository/gallery/-footer-right\"]]",
    "moduleName": "plutof/components/filerepository/gallery.hbs",
    "isStrictMode": false
  });
  // TODO: Can we make this a glimmer component?
  let GalleryOverlay = (_dec = (0, _object.computed)('items.[]', 'activeItemIndex'), _dec2 = (0, _computed.gt)('items.length', 1), _class = class GalleryOverlay extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      // For file actions; TODO: those should resolve services themselves
      _initializerDefineProperty(this, "open", _descriptor3, this);
      _initializerDefineProperty(this, "activeItemIndex", _descriptor4, this);
      _defineProperty(this, "modelViewerPreviewController", new _modelViewerPreview.GalleryModelViewerPreviewController());
      _initializerDefineProperty(this, "loading", _descriptor5, this);
      _initializerDefineProperty(this, "hasMultipleFiles", _descriptor6, this);
    }
    get activeItem() {
      return this.items ? this.items[this.activeItemIndex] : null;
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (this.active) {
        this.open = true;
        if (this.items) {
          this.activeItemIndex = this.items.findIndex(item => item === this.active) || 0;
        }
      }
    }
    close() {
      this.open = false;
    }
    move(offset) {
      this.activeItemIndex += offset;
      if (this.activeItemIndex >= this.items.length) {
        this.activeItemIndex -= this.items.length;
      } else if (this.activeItemIndex < 0) {
        this.activeItemIndex += this.items.length;
      }
    }
    keyDown(e) {
      if ((0, _utils.isNone)(this.activeItem) || (0, _utils.isNone)(this.items)) {
        return;
      }
      if (e.keyCode === 37) {
        // left
        this.move(-1);
      } else if (e.keyCode === 39) {
        // right
        this.move(1);
      }
    }
    _showOnMap() {
      this.showOnMap();
      this.setProperties({
        'isOpened': false
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "open", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "activeItemIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "activeItem", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "activeItem"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "move", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "move"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasMultipleFiles", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_showOnMap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_showOnMap"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GalleryOverlay);
});