define("plutof/routes/profile/defaults", ["exports", "@ember/object", "rsvp", "plutof/misc/profile_settings", "plutof/utils/routes", "plutof/utils/structures"], function (_exports, _object, _rsvp, _profile_settings, _routes, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  // take on ember-changeset
  // + no proxies that even ember crew thinks were a huge mistake
  // + tracks relation changes
  // - resolves everything
  //
  // Usage is limited, proper solution is to ditch ember-data
  //
  // Don't bother
  async function changeset(record) {
    let originalFields = {};
    record.eachAttribute((name, meta) => {
      originalFields[name] = record.get(name);
    });
    let relationResolves = [];
    const hasManyRelations = new Set();
    record.eachRelationship((name, descriptor) => {
      relationResolves.push(record.get(name).then(value => {
        if (descriptor.meta.kind === 'hasMany') {
          value = value.toArray();
          hasManyRelations.add(name);
        }
        originalFields[name] = value;
      }));
    });
    await _rsvp.default.all(relationResolves);
    let workingCopy;
    async function save() {
      let changes = [];
      Object.keys(originalFields).forEach(field => {
        const original = originalFields[field];
        const working = workingCopy[field];
        if (hasManyRelations.has(field)) {
          if (original.length !== working.length || (0, _structures.zip)(original, working).some(_ref => {
            let [a, b] = _ref;
            return a !== b;
          })) {
            changes.push(field);
          }
        } else {
          if (original !== working) {
            changes.push(field);
          }
        }
      });
      if (changes.length === 0) {
        // No changes, no need to save. Take that, ember-data
        return record;
      }
      changes.forEach(field => record.set(field, workingCopy[field]));
      await record.save();

      // Sync originalFields with record now that changes are applied
      changes.forEach(field => originalFields[field] = workingCopy[field]);
      return record;
    }
    function reset() {
      workingCopy = Object.assign({}, originalFields);
      for (const relation of hasManyRelations) {
        workingCopy[relation] = Array.from(originalFields[relation]);
      }
      workingCopy.save = save;
      workingCopy.discard = reset;

      // XXX: Massaging this into GenericForms::Record-compatible thing
      workingCopy.set = (field, value) => (0, _object.set)(workingCopy, field, value);
      workingCopy.constructor = record.constructor;
    }
    reset();
    return workingCopy;
  }
  class ProfileDefaultsRoute extends _routes.EditRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "title", 'Settings.defaultValues');
    }
    async model(params) {
      const profile = await (0, _profile_settings.get_personal_settings)(this.store);
      const profileChangeset = await changeset(profile);
      return {
        profile: profileChangeset
      };
    }
    setupController(controller, model) {
      super.setupController(...arguments);
      this.controllerFor('profile').set('currentTab', 'defaults');
    }
  }
  var _default = _exports.default = ProfileDefaultsRoute;
});