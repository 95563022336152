define("plutof/misc/clipboard", ["exports", "@ember/object", "@ember/utils", "ember-inflector", "plutof/config/environment", "plutof/misc/abstract", "plutof/utils/notifications"], function (_exports, _object, _utils, _emberInflector, _environment, _abstract, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CB_MODULES = void 0;
  _exports.clearClipboard = clearClipboard;
  _exports.clipboardEndpoint = clipboardEndpoint;
  _exports.ctypeClipboard = ctypeClipboard;
  _exports.get_clipboard_status = get_clipboard_status;
  _exports.removeFromClipboard = removeFromClipboard;
  _exports.sendToClipboard = sendToClipboard;
  _exports.sendToClipboardRaw = sendToClipboardRaw;
  _exports.send_all_to_clipboard = send_all_to_clipboard;
  _exports.send_to_clipboard = send_to_clipboard;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const CB_MODULES = _exports.CB_MODULES = {
    'specimen': {
      model: 'specimen/specimen',
      cbUrl: _environment.default.API_HOST + '/clipboard/clipboards/specimens/'
    },
    'livingspecimen': {
      model: 'livingculture/strain',
      cbUrl: _environment.default.API_HOST + '/clipboard/clipboards/livingcultures/'
    },
    'materialsample': {
      model: 'materialsample/materialsample'
    }
  };
  class ClipboardStatus extends _object.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "store", null);
    }
    init() {
      this.set('checked', false);
      this.set('included', false);
    }
    check() {
      let object = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return (0, _abstract.wrap_as_promise)(object).then(resolvedObject => {
        if (!(0, _utils.isNone)(resolvedObject)) {
          this.set('included', (0, _object.get)(resolvedObject, 'on_clipboard'));
        }
        this.set('checked', true);
      });
    }
    change(included) {
      return this.check().then(() => {
        if (this.included === included) {
          return _abstract.EMPTY_PROMISE;
        }
        const contentType = this.content_type;
        const objectId = this.object_id;
        return (0, _abstract.wrap_as_promise)(contentType).then(ctype => {
          return (included ? send_to_clipboard : remove_from_clipboard)(this.ajax, ctype, [objectId]);
        }).then(() => {
          this.set('included', included);
        }).catch(_notifications.reportError);
      });
    }
  }

  // { ctype_id -> { id -> status } }
  const statuses = {};
  function get_clipboard_status(ajax, store, ctype, objectID) {
    const ctypeID = ctype.get('id');
    statuses[ctypeID] = statuses[ctypeID] || {};
    if ((0, _utils.isNone)(statuses[ctypeID][objectID])) {
      statuses[ctypeID][objectID] = ClipboardStatus.create({
        ajax,
        store,
        content_type: ctype,
        object_id: objectID,
        included: false
      });
    }
    return statuses[ctypeID][objectID];
  }

  /*
   * Send multiple objects to clipboard.
   * TODO We may or may not want to update clipboard item status here.
   */
  // TODO: Those funcs should all take a clipboard name instead of ctype, so that we don't have to
  // resolves ctypes everywhere when the clipboard is known statically
  // TODO: Remove in favor of sendToClipboard
  function send_to_clipboard(ajax, contentType, objectIds) {
    return sendToClipboard(ajax, ctypeClipboard(contentType), objectIds);
  }
  function sendToClipboard(ajax, clipboard, objectIDs) {
    return ajax.request(`${_environment.default.API_HOST}/clipboard/clipboards/${clipboard}/`, {
      method: 'POST',
      data: JSON.stringify({
        object_list: objectIDs
      }),
      processData: false,
      contentType: 'application/json; charset=UTF-8'
    });
  }

  // TODO: Use sendToClipboardRaw
  function send_all_to_clipboard(ajax, contentType, object) {
    const cbProperty = object.get('clipboardProperty');
    const path = cbProperty ? null : object.get('constructor.modelName').split('/');
    const prop = cbProperty ? cbProperty : (0, _object.get)(path, 'lastObject');
    const params = {
      method: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: {}
    };
    params.data[prop] = object.get('url');
    params.data = JSON.stringify(params.data);
    return ajax.request(clipboardEndpoint(ctypeClipboard(contentType)), params);
  }
  function sendToClipboardRaw(ajax, clipboard, params) {
    return ajax.request(clipboardEndpoint(clipboard), {
      method: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(params)
    });
  }
  function clipboardEndpoint(clipboard) {
    return `${_environment.default.API_HOST}/clipboard/clipboards/${clipboard}/`;
  }

  // Remove multiple objects form clipboard.
  // TODO: Remove in favor of removeFromClipboard
  function remove_from_clipboard(ajax, contentType, objectIDs) {
    return removeFromClipboard(ajax, ctypeClipboard(contentType), objectIDs);
  }
  function removeFromClipboard(ajax, clipboard, objectIDs) {
    const url = `${_environment.default.API_HOST}/clipboard/clipboards/${clipboard}/deletion/?object_list=${objectIDs.join(',')}`;
    return ajax.request(url, {
      method: 'DELETE'
    });
  }

  // TODO: Change @clipboard to content type? Or define clipboards as an enum?
  function clearClipboard(ajax, clipboard) {
    const url = `${_environment.default.API_HOST}/clipboard/clipboards/${clipboard}/deletion/`;
    return ajax.request(url, {
      type: 'DELETE'
    });
  }

  // TODO: Something proper
  function ctypeClipboard(ctype) {
    // Using .get here, because in some cases ctype is a PromiseObject
    if (ctype.get('model') === 'strain') {
      return 'livingcultures';
    }
    if (ctype.get('model') === 'collectionobject') {
      return 'photobanks';
    }
    return (0, _emberInflector.pluralize)(ctype.get('model'));
  }
});