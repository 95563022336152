define("plutof/helpers/break-line", ["exports", "@ember/component/helper", "plutof/utils/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.breakLine = breakLine;
  _exports.default = void 0;
  function breakLine(_ref) {
    let [string = ''] = _ref;
    return (0, _string.showWithLinebreaks)(string);
  }
  var _default = _exports.default = (0, _helper.helper)(breakLine);
});