define("plutof/routes/study/add", ["exports", "@ember/utils", "rsvp", "plutof/components/agent/organization-workgroup", "plutof/components/identifiers/edit", "plutof/components/linked-items/edit", "plutof/utils/access", "plutof/utils/routes", "plutof/utils/model"], function (_exports, _utils, _rsvp, _organizationWorkgroup, _edit, _edit2, _access, _routes, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class StudyAddRoute extends _routes.AddRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "defaultReturnRoute", 'study.index');
      _defineProperty(this, "title", 'Projects.newP');
    }
    async model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const study = this.store.createRecord('study/study');
      let parent;
      if (params.parent) {
        parent = await this.store.findRecord('study/study', params.parent);
        study.set('study_parent', parent);
      }
      const rightsPromise = (0, _access.create_access_rights)(this.store, study);
      return _rsvp.default.hash({
        accessRights: rightsPromise,
        itemData: (0, _edit2.createLinkedItemsData)(this.store, study, {
          useEventFiles: false,
          disabledTabs: []
        }),
        projectPersons: [],
        study: study,
        projectAreas: [],
        identifiers: (0, _edit.createIdentifiersEditModel)({
          store: this.store,
          container: study,
          model: 'study/reserved-identifier-block',
          modelContainerField: 'project'
        }),
        workgroupData: this.createWorkgroupData(parent)
      });
    }
    async createWorkgroupData(parent) {
      const data = await (0, _organizationWorkgroup.createDelayedWorkgroupData)(this.store);
      if (parent) {
        const parentGroupID = (0, _model.relationID)(parent, 'managing_group');
        if (parentGroupID) {
          const alreadyMembers = new Set(await _rsvp.default.Promise.all(data.memberships.map(membership => membership.user)));
          const parentData = await (0, _organizationWorkgroup.loadWorkgroupData)(this.store, parentGroupID);
          for (const membership of parentData.memberships) {
            const user = await membership.user;
            if (!alreadyMembers.has(user)) {
              data.memberships.pushObject(this.store.createRecord('users/usergroup', {
                user,
                group: data.group,
                rights: membership.rights,
                status: membership.status,
                user_can_be_modified: true
              }));
            }
          }
        }
      }
      return data;
    }
    setupController(controller, model) {
      super.setupController(...arguments);
      if (!(0, _utils.isNone)(model)) {
        controller.setProperties({
          objectsToDelete: []
        });
      }
    }
  }
  var _default = _exports.default = StudyAddRoute;
});