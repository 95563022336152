define("plutof/routes/photobank/view", ["exports", "@ember/service", "rsvp", "plutof/misc/content_types", "plutof/utils/access", "plutof/utils/pagination", "plutof/utils/routes", "plutof/utils/structures"], function (_exports, _service, _rsvp, _content_types, _access, _pagination, _routes, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PhotobankViewRoute = (_class = class PhotobankViewRoute extends _routes.ViewRoute {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "defaultReturnRoute", 'photobank.index');
      _defineProperty(this, "modelTitle", 'object');
    }
    model(params) {
      const store = this.store;
      const ctypePromise = (0, _content_types.get_ctype_by_name)(store, 'photobank/collectionobject');
      const object_p = store.findRecord('photobank/collectionobject', params.id);
      return _rsvp.default.hash({
        object: object_p,
        accessRights: object_p.then(object => {
          return (0, _access.get_permissions)(store, this.ajax, object);
        }),
        additionalIdentifiers: store.query('photobank/additionalidentifier', {
          collection_object: params.id
        }).then(_structures.sortByID),
        classificationItems: (0, _pagination.paginateModel)(store, 'photobank/customitem', {
          collection_object: params.id,
          ordering: 'id'
        }),
        agentItems: ctypePromise.then(function (ctype) {
          return store.query('agent/item', {
            content_type: ctype.get('id'),
            object_id: params.id
          });
        }),
        // XXX: This must be a photobank model field instead
        representationImage: ctypePromise.then(ctype => {
          return store.query('filerepository/item', {
            content_type: ctype.get('id'),
            object_id: params.id
          }).then(items => {
            return _rsvp.default.all(items.mapBy('file')).then(files => {
              return files.findBy('isImage', true);
            });
          });
        }).catch(() => null),
        observations: object_p.then(photobankObject => photobankObject.samplingevent).then(event => (0, _pagination.paginateAjaxEndpoint)(this.ajax, 'taxonoccurrence/observation/observations', {
          filters: {
            samplingevent: event.id
          }
        }))
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = PhotobankViewRoute;
});