define("plutof/components/auto-complete/backends", ["exports", "@ember/object", "rsvp", "ember-inflector", "plutof/utils/store"], function (_exports, _object, _rsvp, _emberInflector, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.StoreQueryAutocompleteBackend = _exports.StaticAutocomplete = _exports.ModelAutocomplete = _exports.AutocompleteBackend = void 0;
  // Result :: { id, value, owner?, tooltip?, ... }
  class AutocompleteBackend {
    // Transform result into a record that will be selection
    // Result -> Promise<Record>
    resolveResult(result) {
      return _rsvp.default.Promise.resolve(result.value);
    }

    // :: string -> Promise<[Result]>
    autocomplete(query) {
      return _rsvp.default.Promise.resolve([]);
    }
    recordID(record) {
      return record.id;
    }
    recordValue(record) {
      return (0, _object.get)(record, 'representation');
    }
  }
  _exports.AutocompleteBackend = AutocompleteBackend;
  class StaticAutocomplete extends AutocompleteBackend {
    constructor() {
      let {
        records,
        id,
        value,
        limit = Infinity
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      super();
      this.items = records.map(record => ({
        id: id(record),
        value: value(record),
        comparedValue: value(record).toLowerCase(),
        record
      }));
      this.recordID = id;
      this.recordValue = value;
      this.limit = limit;
    }
    resolveResult(result) {
      return _rsvp.default.Promise.resolve(result.record);
    }
    autocomplete(query) {
      const comparedQuery = query.trim().toLowerCase();
      return _rsvp.default.Promise.resolve(this.items.filter(item => item.comparedValue.includes(comparedQuery)).slice(0, this.limit));
    }
  }
  _exports.StaticAutocomplete = StaticAutocomplete;
  class ModelAutocomplete extends AutocompleteBackend {
    constructor(_ref) {
      let {
        ajax,
        store,
        modelName,
        filters = {},
        representation = 'representation'
      } = _ref;
      super();
      this.ajax = ajax;
      this.store = store;
      this.modelName = modelName;
      this.filters = filters;
      this.representation = representation;
      this.autocompleteURL = `/${(0, _emberInflector.pluralize)(modelName.replace('.', '/'))}/autocomplete/`;
    }
    autocomplete(query) {
      return this.ajax.request(this.autocompleteURL, {
        data: Object.assign({
          name: query.trim()
        }, this.filters)
      }).then(response => response.results.map(result => ({
        id: result.id,
        value: result.name,
        full: result
      })));
    }
    resolveResult(result) {
      return this.store.findRecord(this.modelName, result.id);
    }
    recordValue(record) {
      return (0, _object.get)(record, this.representation);
    }
  }
  _exports.ModelAutocomplete = ModelAutocomplete;
  class StoreQueryAutocompleteBackend extends AutocompleteBackend {
    constructor(_ref2) {
      let {
        store,
        model,
        filter = 'name',
        queryParams = {}
      } = _ref2;
      super();
      this.store = store;
      this.model = model;
      this.filter = filter;
      this.queryParams = queryParams;
    }
    resolveResult(result) {
      return _rsvp.default.Promise.resolve(result.record);
    }
    async autocomplete(term) {
      const records = await (0, _store.query)(this.store, this.model, {
        ...this.queryParams,
        [this.filter]: term
      });
      return records.map(record => ({
        id: record.id,
        value: record.representation,
        record
      }));
    }
  }
  _exports.StoreQueryAutocompleteBackend = StoreQueryAutocompleteBackend;
});