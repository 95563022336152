define("plutof/utils/validations", ["exports", "@ember/object", "@ember/object/computed", "@ember/object/mixin", "@ember/utils", "@ember-decorators/object", "ember-cp-validations", "plutof/utils/structures"], function (_exports, _object, _computed, _mixin, _utils, _object2, _emberCpValidations, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ValidationContext = _exports.ValidatedRoute = void 0;
  _exports.nonblank = nonblank;
  _exports.notEmptySpace = notEmptySpace;
  _exports.splitContext = splitContext;
  _exports.validator = validator;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ValidationContext = _exports.ValidationContext = (_dec = (0, _object2.observes)('children.[]'), _dec2 = (0, _object.computed)('subcontexts.@each.isValid'), _dec3 = (0, _computed.and)('validity', 'childrenAreValid'), _dec4 = (0, _computed.not)('isValid'), _dec5 = (0, _object.computed)('validity', 'subcontexts.@each.invalidContexts'), _dec6 = (0, _object.computed)('invalidContexts.@each.anchor'), _class = class ValidationContext extends _object.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isValid", _descriptor, this);
      _initializerDefineProperty(this, "isInvalid", _descriptor2, this);
    }
    init() {
      super.init(...arguments);
      if ((0, _utils.isNone)(this.validity)) {
        this.set('validity', true);
      }
      if ((0, _utils.isNone)(this.children)) {
        this.set('children', []);
      }
      this.set('subcontexts', []);
      this.set('anchor', null);
      this.createSubcontexts();
    }
    addChild(name, label) {
      if ((0, _utils.isNone)(this.get(name))) {
        var child = ValidationContext.create({
          name: name,
          label: label,
          parent: this
        });
        this.subcontexts.pushObject(child);
        this.set(name, child);
      }
      return this.get(name);
    }
    removeChild(name) {
      // TODO: Destroy subcontext?
      this.set(name, null);
    }
    createSubcontexts() {
      var children = this.children;
      children.forEach(function (childName) {
        this.addChild(childName);
      }, this);
    }
    get childrenAreValid() {
      return this.subcontexts.isEvery('isValid');
    }
    get invalidContexts() {
      var invalidChildContexts = (0, _structures.concat)(this.subcontexts.mapBy('invalidContexts'));
      if (!this.validity) {
        invalidChildContexts.pushObject(this);
      }
      return invalidChildContexts;
    }
    get invalidAnchors() {
      return this.invalidContexts.mapBy('anchor').compact();
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.subcontexts.forEach(sc => sc.destroy());
      this.subcontexts.clear();
    }

    // XXX: Just for debug during integration
    getDebugStatus() {
      return {
        name: this.name,
        valid: this.isValid,
        children: this.subcontexts.invoke('getDebugStatus'),
        anchor: this.anchor
      };
    }
  }, _applyDecoratedDescriptor(_class.prototype, "createSubcontexts", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "createSubcontexts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "childrenAreValid", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "childrenAreValid"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isInvalid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "invalidContexts", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "invalidContexts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "invalidAnchors", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "invalidAnchors"), _class.prototype), _class);
  var ValidatedRoute = _exports.ValidatedRoute = _mixin.default.create({
    validationChildren: [],
    setupController: function (controller, model) {
      this._super(controller, model);
      controller.set('validationContext', ValidationContext.create({
        name: 'route',
        children: this.validationChildren
      }));
    },
    actions: {
      willTransition: function (transition) {
        this._super(transition);
        if (!transition.isAborted) {
          const validationContext = this.get('controller.validationContext');

          // context will be empty when route model loading fails: setupController is skipped,
          // but willTransition still happens for transition to error route
          if (!(0, _utils.isNone)(validationContext)) {
            validationContext.destroy();
            this.controller.set('validationContext', null);
          }
        }
        return true;
      }
    }
  });
  function splitContext(contextProperty, recordsProperty) {
    // This should be Symbol(), but alas, IE
    const SPLIT_DATA = `__splitContext(${contextProperty}, ${recordsProperty})`;
    return (0, _object.computed)(contextProperty, `${recordsProperty}.[]`, function () {
      var mainContext = this.get(contextProperty);
      if ((0, _utils.isNone)(mainContext)) {
        return [];
      }

      // Apparently, if a component has a @splitContext property, it is shared
      // between instances. Meaning that if we don't tie persistent state to
      // this and go for closure instead, it won't work.
      let {
        previousContexts = [],
        index = 0
      } = this[SPLIT_DATA] || {};

      // Don't let entries from the previous split influence the total validityd
      previousContexts.forEach(ctx => {
        // Dont set on destroyed objects.
        if (!ctx.get('isDestroyed') && !(0, _utils.isNone)(ctx.get('validity'))) {
          ctx.set('validity', true);
        }
      });
      const entries = this.get(recordsProperty).map(function (record) {
        if (!(0, _object.get)(record, 'fadeId')) {
          (0, _object.set)(record, 'fadeId', Math.random());
        }
        index++;
        return {
          fadeId: (0, _object.get)(record, 'fadeId'),
          object: record,
          context: mainContext.addChild(`${mainContext.name}:${index}`)
        };
      });
      previousContexts = entries.mapBy('context');
      this[SPLIT_DATA] = {
        previousContexts,
        index
      };
      return entries;
    });
  }
  function nonblank(maxLength) {
    return [(0, _emberCpValidations.validator)('empty-or-present'), (0, _emberCpValidations.validator)('length', {
      max: maxLength
    })];
  }

  // TODO: Trimmed.
  function notEmptySpace(maxLength) {
    return [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('empty-or-present'), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: maxLength
    })];
  }

  // Prototype for validations not using ember object model (and thus suitable
  // for glimmer components). It's somewhat of an abuse of computed properties
  // (not supposed to change state during getters), but observers are creatures
  // of a past and this looks fine when used responsibly
  const VALIDATION_STATE_KEY = '__PLUTOF_VALIDATION_STATE__'; // Again, would like ES symbols

  function getValidationState(object) {
    if (!object[VALIDATION_STATE_KEY]) {
      object[VALIDATION_STATE_KEY] = {
        validators: {}
      };
    }
    return object[VALIDATION_STATE_KEY];
  }
  function validator(object, property, descriptor) {
    return {
      get() {
        const valid = descriptor.get.call(this, property);
        const state = getValidationState(this);
        state.validators[property] = valid;
        const totalValidity = Object.values(state.validators).every(v => v);
        if (this.args.validationContext) {
          this.args.validationContext.set('validity', totalValidity);
        }
        return valid;
      }
    };
  }
});