define("plutof/models/dna-lab/common", ["exports", "@ember/object", "@ember/utils"], function (_exports, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasSource = hasSource;
  function hasSource() {
    for (var _len = arguments.length, possibleSources = new Array(_len), _key = 0; _key < _len; _key++) {
      possibleSources[_key] = arguments[_key];
    }
    const idPaths = possibleSources.map(source => `${source}.id`);
    return (0, _object.computed)(...idPaths, function () {
      return idPaths.some(path => (0, _utils.isPresent)(this.get(path)));
    });
  }
});