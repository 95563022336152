define("plutof/components/annotation/navbar/view", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Navbar::View
      @kind="annotation"
      @icon={{@icon}}
      @title={{@title}}
      @record={{@annotation}}
      @deletable={{true}}
      @permissions={{@permissions}}
      @neverDeleteRelatedFiles={{true}}
      @goBack={{@goBack}}
      as |navbar slots|
  >
      <slots.primaryControls>
          {{#if @permissions.canModerate}}
              <Annotation::ModerationButtons
                  @button={{navbar.button}}
                  @annotation={{@annotation}}
                  @moderated={{@goBack}} />
          {{/if}}
      </slots.primaryControls>
  
      <slots.secondaryControls>
          {{#if @permissions.canModify}}
              <navbar.button
                  @clicked={{this.edit}}
                  @icon={{icon "edit"}}
              >
                  {{i18n-t "General.Edit"}}
              </navbar.button>
          {{/if}}
      </slots.secondaryControls>
  </Navbar::View>
  
  */
  {
    "id": "5jvgA+s/",
    "block": "[[[8,[39,0],null,[[\"@kind\",\"@icon\",\"@title\",\"@record\",\"@deletable\",\"@permissions\",\"@neverDeleteRelatedFiles\",\"@goBack\"],[\"annotation\",[30,1],[30,2],[30,3],true,[30,4],true,[30,5]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,7,[\"primaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,4,[\"canModerate\"]],[[[1,\"            \"],[8,[39,2],null,[[\"@button\",\"@annotation\",\"@moderated\"],[[30,6,[\"button\"]],[30,3],[30,5]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,7,[\"secondaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,4,[\"canModify\"]],[[[1,\"            \"],[8,[30,6,[\"button\"]],null,[[\"@clicked\",\"@icon\"],[[30,0,[\"edit\"]],[28,[37,3],[\"edit\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,4],[\"General.Edit\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[6,7]]]]],[1,\"\\n\"]],[\"@icon\",\"@title\",\"@annotation\",\"@permissions\",\"@goBack\",\"navbar\",\"slots\"],false,[\"navbar/view\",\"if\",\"annotation/moderation-buttons\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/components/annotation/navbar/view.hbs",
    "isStrictMode": false
  });
  let AnnotationViewNavbar = (_class = class AnnotationViewNavbar extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    edit() {
      const model = this.args.annotation.constructor.modelName;

      // XXX
      if (model === 'annotation/client-annotation') {
        return this.router.transitionTo(`${this.args.parentRoute}.annotation.edit`, this.args.annotation.object_id, this.args.annotation.id);
      } else if (model === 'annotation/bulk-annotation') {
        return this.router.transitionTo('annotations.bulk.edit', this.args.annotation.id);
      } else {
        throw new Error(`Unknown annotation kind: ${model}`);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "edit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnnotationViewNavbar);
});