define("plutof/components/unite/determinations/view-single", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember-decorators/component", "plutof/controllers/analysis/utils"], function (_exports, _component, _object, _utils, _component2, _utils2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ViewSingle = (_dec = (0, _component2.classNames)('unite-determination-view'), _dec2 = (0, _object.computed)('determination.{source_type,source_details}'), _dec(_class = (_class2 = class ViewSingle extends _component.default {
    get details() {
      const analysisTypeID = this.determination.source_details.analysis;
      if ((0, _utils.isNone)(analysisTypeID)) {
        return [];
      }
      const analysisType = (0, _utils2.getAnalysisType)(analysisTypeID);
      if ((0, _utils.isNone)(analysisType) || (0, _utils.isNone)(analysisType.details)) {
        return [];
      }
      const details = this.determination.source_details.analysis_details || {};
      return analysisType.details.map(_ref => {
        let {
          label,
          extract
        } = _ref;
        return {
          label,
          value: extract(details)
        };
      });
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "details", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "details"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = ViewSingle;
});