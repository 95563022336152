define("plutof/components/annotation/bulk/annotation", ["exports", "@ember/object", "@ember/object/computed", "@ember/utils", "@glimmer/tracking", "rsvp", "plutof/misc/content_types", "plutof/utils/model", "plutof/utils/reflection", "plutof/utils/errors", "plutof/misc/options-getter", "plutof/utils/annotation/elixir"], function (_exports, _object, _computed, _utils, _tracking, _rsvp, _content_types, _model, _reflection, _errors, _optionsGetter, _elixir) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createBulkAnnotation = createBulkAnnotation;
  _exports.loadBulkAnnotation = loadBulkAnnotation;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _class2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _class3, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AnnotationModel = (_dec = (0, _computed.alias)('_annotation.comment'), _dec2 = (0, _computed.alias)('_annotation.intended_for_publication'), _class = class AnnotationModel {
    constructor(annotation, fields) {
      let {
        records,
        contentType
      } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      _initializerDefineProperty(this, "comment", _descriptor, this);
      _initializerDefineProperty(this, "intendedForPublication", _descriptor2, this);
      this._annotation = annotation;
      this.fields = fields;
      this.records = records;
      this.contentType = contentType;
    }
    async save() {
      const changes = this.fields.extract();
      this._annotation.set('annotation', {
        changes
      });
      if (this.records && this._annotation.isNew) {
        let objectIDs = [];
        for (let pageNum = 1; pageNum <= this.records.pageCount; pageNum++) {
          const page = await this.records.loadPage(pageNum);
          objectIDs = objectIDs.concat(page.map(r => r.id));
        }
        this._annotation.set('object_ids', objectIDs);
      }
      await this._annotation.save();
      return this._annotation;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "comment", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intendedForPublication", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  const RECORD_LIMIT = 100;
  async function createBulkAnnotation(store, contentType, records) {
    if (records.objectCount > RECORD_LIMIT) {
      const i18n = (0, _reflection.getService)(store, 'i18n');
      throw new _errors.userError(i18n.translate('annotation.routes.bulk.errors.recordLimit', {
        hash: {
          limit: RECORD_LIMIT
        }
      }));
    }
    if (records.isEmpty) {
      const i18n = (0, _reflection.getService)(store, 'i18n');
      throw new _errors.userError(i18n.translate('annotation.routes.bulk.errors.noRecords'));
    }
    const fields = await getAnnotationTree(store, contentType);
    const annotation = store.createRecord('annotation/bulk-annotation', {
      content_type: await (0, _content_types.get_ctype_by_name)(store, contentType),
      intended_for_publication: false
    });
    return new AnnotationModel(annotation, fields, {
      records,
      contentType
    });
  }
  async function loadBulkAnnotation(annotation) {
    const contentType = (await annotation.content_type).full_name;
    const fields = await getAnnotationTree(annotation.store, contentType);
    await fields.load(annotation.store, annotation.annotation.changes);
    return new AnnotationModel(annotation, fields);
  }
  async function getAnnotationTree(owned, contentType) {
    const i18n = (0, _reflection.getService)(owned, 'i18n');
    const ajax = (0, _reflection.getService)(owned, 'ajax');
    if (contentType === 'sequence/sequence') {
      const texts = await _rsvp.default.hash({
        sequence: (0, _optionsGetter.getModelTexts)(ajax, 'taxonoccurrence/sequence/sequence'),
        event: (0, _optionsGetter.getModelTexts)(ajax, 'sample/samplingevent'),
        area: (0, _optionsGetter.getModelTexts)(ajax, 'sample/samplingarea')
      });
      return nested(i18n.translate('Sequences.sequence'), {
        isolation_source: relation(texts.sequence.seq_source.label, 'taxonoccurrence/sequence/source'),
        low_quality: boolean(texts.sequence.quality_status.label),
        chimeric: boolean(texts.sequence.chimeric_status.label),
        is_available_for_unite: boolean(texts.sequence.is_available_for_unite.label),
        regions: multiple(i18n.translate('Sequences.sequencedRegions'), choiceCodec('taxonoccurrence.sequence.sequencetype.type')),
        event: nested(i18n.translate('General.samplingEvent'), {
          description: attr(texts.event.description.label),
          timespan_begin: attr(texts.event.timespan_begin.label),
          timespan_end: attr(texts.event.timespan_end.label),
          gathering_agents: multiple(texts.event.gathering_agents.label, modelCodec('agent/person'))
        }),
        area: nested(i18n.translate('Sample.samplingArea'), {
          country: relation(texts.area.country.label, 'geography/country')
        })
      });
    }
    throw new Error(`No annotation tree defined for ${contentType}`);
  }
  function nested(label, children) {
    let fields = Object.assign({}, children);
    fields.label = label;
    fields.load = async function (store, value) {
      await _rsvp.default.Promise.all(Object.keys(value).map(key => fields[key].load(store, value[key])));
    };
    fields.extract = function () {
      let values = {};
      for (const field of Object.keys(children)) {
        if (fields[field].active) {
          values[field] = fields[field].extract();
        }
      }
      return values;
    };
    fields.writeSummary = function (to, model) {
      for (const field of Object.keys(children)) {
        if (fields[field].active) {
          fields[field].writeSummary(to, this.label);
        }
      }
    };

    // Should be smarter
    fields.active = true;
    return fields;
  }
  function attr(label) {
    return new AnnotationLeaf(label);
  }
  function boolean(label) {
    return new AnnotationLeaf(label, idCodec, {
      defaultValue: false
    });
  }
  function relation(label, model) {
    return new AnnotationLeaf(label, modelCodec(model));
  }
  function multiple(label, codec) {
    return new AnnotationMultipleLeaf(label, codec);
  }

  // In case we need to change the format for these
  function summaryChange(model, attribute, change, field) {
    return {
      model,
      attribute,
      change,
      comment: field.comment,
      assertion: field.assertion,
      field
    };
  }
  let AnnotationLeaf = (_class2 = class AnnotationLeaf {
    constructor(label) {
      let codec = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : idCodec;
      let {
        defaultValue = null
      } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      _initializerDefineProperty(this, "value", _descriptor3, this);
      _initializerDefineProperty(this, "active", _descriptor4, this);
      _initializerDefineProperty(this, "comment", _descriptor5, this);
      _initializerDefineProperty(this, "assertion", _descriptor6, this);
      this.label = label;
      this._codec = codec;
      this._defaultValue = defaultValue;
      this.value = defaultValue;
    }
    clear() {
      this.value = this._defaultValue;
    }

    // Called only if the annotation DOES change the field
    // (null means "cleared", not "unchanged")
    async load(store, _ref) {
      let {
        value,
        comment,
        assertion
      } = _ref;
      this.value = await this._codec.load(store, value);
      this.comment = comment;
      this.assertion = assertion;
      this.active = true;
    }
    extract() {
      const value = this._codec.extract(this.value);
      return {
        value,
        comment: this.comment,
        assertion: this.assertion
      };
    }
    writeSummary(to, model) {
      to.push(summaryChange(model, this.label, this._codec.representation(this.value), this));
    }
    update(value) {
      this.value = value;
    }
    toggle() {
      this.active = !this.active;
      if (!this.active) {
        this.clear();
      }
    }
  }, _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "active", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "comment", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "assertion", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _elixir.DEFAULT_CDCH_ASSERTION;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "update"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggle"), _class2.prototype), _class2);
  const idCodec = {
    load: (store, value) => value,
    extract: value => value,
    representation: value => value
  };
  function modelCodec(model) {
    return {
      load(store, id) {
        return id ? store.findRecord(model, id) : null;
      },
      extract(value) {
        return value ? parseInt(value.id) : null;
      },
      representation(value) {
        // TODO: Defined waitForRepresentation on models, so that
        // those that require to load something, have their representation
        // consistently available outside templates
        return value ? value.representation : null;
      }
    };
  }
  function choiceCodec(field) {
    let choicesPromise;
    return {
      async load(store, id) {
        if (!choicesPromise) {
          const ajax = (0, _reflection.getService)(store, 'ajax');
          choicesPromise = (0, _model.getFieldChoices)(ajax, field);
        }
        const choices = await choicesPromise;
        return choices.findBy('value', id);
      },
      extract(value) {
        return value.value;
      },
      representation(value) {
        return (0, _utils.isNone)(value) ? null : value.display_name;
      }
    };
  }
  let AnnotationMultipleLeaf = (_class3 = class AnnotationMultipleLeaf extends AnnotationLeaf {
    constructor(label, codec) {
      super(label, codec);
      _initializerDefineProperty(this, "added", _descriptor7, this);
      _initializerDefineProperty(this, "removed", _descriptor8, this);
    }
    clear() {
      this.added = [];
      this.removed = [];
    }
    async load(store, _ref2) {
      let {
        add = [],
        remove = [],
        comment,
        assertion
      } = _ref2;
      this.added = await _rsvp.default.Promise.all(add.map(value => this._codec.load(store, value)));
      this.removed = await _rsvp.default.Promise.all(remove.map(value => this._codec.load(store, value)));
      this.comment = comment;
      this.assertion = assertion;
      this.active = true;
    }
    extract() {
      return {
        add: this.added.map(r => this._codec.extract(r)),
        remove: this.removed.map(r => this._codec.extract(r)),
        comment: this.comment,
        assertion: this.assertion
      };
    }
    writeSummary(to, model) {
      const added = this.added.map(value => `+${this._codec.representation(value)}`);
      const removed = this.removed.map(value => `-${this._codec.representation(value)}`);
      const change = added.concat(removed).join(', ');
      to.push(summaryChange(model, this.label, change, this));
    }
    _contained(value, list) {
      const id = this._codec.extract(value);
      return list.some(v => this._codec.extract(v) === id);
    }
    add(value) {
      if (!this._contained(value, this.added)) {
        this.added.pushObject(value);
      }
    }
    noLongerAdd(value) {
      this.added.removeObject(value);
    }
    remove(value) {
      if (!this._contained(value, this.removed)) {
        this.removed.pushObject(value);
      }
    }
    noLongerRemove(value) {
      this.removed.removeObject(value);
    }
  }, _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "added", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "removed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "add"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "noLongerAdd", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "noLongerAdd"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "remove"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "noLongerRemove", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "noLongerRemove"), _class3.prototype), _class3);
});