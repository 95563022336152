define("plutof/controllers/observation/add", ["exports", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "@ember/utils", "rsvp", "@ember-decorators/object", "ember-concurrency", "plutof/components/occurrences-table", "plutof/controllers/observation/edit", "plutof/controllers/observation/utils/sheets", "plutof/misc/config", "plutof/mixins/component-validations", "plutof/utils/controllers", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _object, _computed, _runloop, _service, _utils, _rsvp, _object2, _emberConcurrency, _occurrencesTable, _edit, _sheets, _config, _componentValidations, _controllers, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addSharedFileWarning = addSharedFileWarning;
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  function addSharedFileWarning(controller, _ref) {
    let {
      itemData,
      occurrenceData
    } = _ref;
    const fileCount = `${itemData}.sharedFileCount`;
    const occurrenceCount = `${occurrenceData}.occurrences.length`;

    // Guard for both observers firing
    let shown = false;
    function handler() {
      if (shown || this.get(occurrenceCount) < 2 || this.get(fileCount) === 0) {
        return;
      }
      shown = true;
      (0, _notifications.displayNotification)('warning', i18n.t('FileRepository.sharedFileWarning'), null, 0, false);
      controller.removeObserver(occurrenceCount, handler);
      controller.removeObserver(fileCount, handler);
    }
    controller.addObserver(fileCount, handler);
    controller.addObserver(occurrenceCount, handler);
  }
  const Controller = _controllers.AddController.extend(_componentValidations.default);
  let ObservationAddController = _exports.default = (_dec = (0, _computed.notEmpty)('template_id'), _dec2 = (0, _computed.alias)('model.selectedMeasurementsForm'), _dec3 = (0, _computed.alias)('model.samplingAreaEventFormData'), _dec4 = (0, _computed.equal)('model.selectedMeasurementsForm.id', _config.default.Observation.TAIMEATLAS_MAINFORM_ID), _dec5 = (0, _object2.observes)('model.selectedMeasurementsForm'), _dec6 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec7 = (0, _object2.observes)('occurrenceMainform', 'model.observationsData'), _dec8 = (0, _computed.alias)('occurrenceMainform.interaction'), _dec9 = (0, _computed.equal)('model.selectedMeasurementsForm.id', _config.default.Observation.BIRD_MAINFORM_ID), _class = class ObservationAddController extends Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "listRoutes", _descriptor2, this);
      _defineProperty(this, "queryParams", ['area_id', 'event_id', 'livingspecimen_id', 'materialsample_id', 'project_id', 'mainform_id', 'template_id', 'clone_area', 'clone_event', 'file_ids']);
      _defineProperty(this, "area_id", null);
      _defineProperty(this, "event_id", null);
      _defineProperty(this, "livingspecimen_id", null);
      _defineProperty(this, "materialsample_id", null);
      _defineProperty(this, "project_id", null);
      _defineProperty(this, "mainform_id", null);
      _defineProperty(this, "template_id", null);
      _defineProperty(this, "clone_area", null);
      _defineProperty(this, "clone_event", null);
      _defineProperty(this, "file_ids", null);
      _initializerDefineProperty(this, "preserveZoom", _descriptor3, this);
      _initializerDefineProperty(this, "occurrenceMainform", _descriptor4, this);
      _initializerDefineProperty(this, "formData", _descriptor5, this);
      _defineProperty(this, "areaEventTranslations", (0, _edit.getAreaEventTranslations)());
      _defineProperty(this, "validationChildren", [{
        name: 'form',
        label: 'Forms.formSelector.title'
      }, {
        name: 'project',
        label: 'General.project'
      }, {
        name: 'areaEvent',
        label: 'Observations.samplingAreaEvent'
      }, {
        name: 'observations',
        label: 'General.Observations'
      }, {
        name: 'associatedData',
        label: 'General.associatedData'
      }, {
        name: 'access',
        label: 'General.access'
      }, {
        name: 'interactions',
        label: 'Interaction.interaction'
      }]);
      _defineProperty(this, "mainformTaxonSheetset", null);
      _initializerDefineProperty(this, "mainformIsTaimeatlas", _descriptor6, this);
      _initializerDefineProperty(this, "loadMainformSheetset", _descriptor7, this);
      _initializerDefineProperty(this, "interactionsEnabled", _descriptor8, this);
      // Observation specific occurrence table translations
      _defineProperty(this, "occurrencesTableTranslations", {
        panelTitle: i18n.t('Observations.title'),
        individual_count: i18n.t('Observations.placeholders.observationCount'),
        formType: i18n.t('General.formType')
      });
      _initializerDefineProperty(this, "collapseHabitat", _descriptor9, this);
    }
    init() {
      super.init(...arguments);
      this.set('plutofMainforms', [{
        label: this.i18n.t('Forms.bird'),
        id: _config.default.Observation.BIRD_MAINFORM_ID
      }, {
        label: this.i18n.t('Forms.insect'),
        id: _config.default.Observation.INSECT_MAINFORM_ID
      }, {
        label: this.i18n.t('Forms.animal'),
        id: _config.default.Observation.ANIMAL_MAINFORM_ID
      }, {
        label: this.i18n.t('Forms.fungus'),
        id: _config.default.Observation.FUNGUS_MAINFORM_ID
      }, {
        label: this.i18n.t('Forms.plant'),
        id: _config.default.Observation.PLANT_MAINFORM_ID
      }, {
        label: this.i18n.t('Forms.default'),
        id: _config.default.Observation.DEFAULT_MAINFORM_ID
      }]);
      this.enableDeterminersPanel();
      this.updateMainformSheetset();
      addSharedFileWarning(this, {
        itemData: 'model.itemData',
        occurrenceData: 'model.observationsData'
      });
    }
    updateMainformSheetset() {
      this.loadMainformSheetset.perform(this.get('model.selectedMeasurementsForm'));
    }
    enableDeterminersPanel() {
      if (!(0, _utils.isNone)(this.get('model.observationsData.enableDeterminers'))) {
        const formId = this.get('occurrenceMainform.id');
        const enableDeterminers = _config.default.Observation.IDS_REQUIRE_DETERMINERS.includes(formId);
        this.set('model.observationsData.enableDeterminers', enableDeterminers);
      }
    }
    async _save() {
      (0, _notifications.clearNotifications)();
      (0, _notifications.displayNotification)('status', i18n.t('General.saving'));
      await this.model.samplingAreaEventFormData.save();
      const observations = this.model.observationsData.occurrences;
      observations.forEach(observation => {
        this.model.samplingAreaEventFormData.apply(observation);
        observation.set('related_materialsample', this.model.materialSample);
        observation.set('living_specimen', this.model.livingSpecimen);
      });
      const observationsProgress = this.model.observationsData.save(this.model.accessRights);
      (0, _occurrencesTable.notifySaveProgress)(observationsProgress, 'Observations.saveProgress');
      await observationsProgress.completionPromise;
      (0, _notifications.displayNotification)('status', i18n.t('Observations.savingRest'));
      await _rsvp.default.all([this.model.itemData.save(observations, this.model.accessRights), this.model.interactionData.save(observations), this.model.layers.save(observations)]);
      this.listRoutes.reset('observation/observation');
      (0, _notifications.clearNotifications)();
    }
    save() {
      return this._save().then(() => {
        const templateId = this.template_id;
        this.setProperties({
          'template_id': null,
          'file_ids': null,
          'routeHasBeenLoaded': false,
          'cachedMapExtent': null
        });
        return templateId ? this.transitionToRoute('observation.index') : this.routeActions.goBack();
      }).catch(_notifications.reportError);
    }
    clone(cloneArea, cloneEvent) {
      this.set('closePanels', true);
      return this._save().then(() => {
        const mainObservationID = this.get('model.observationsData.occurrences.firstObject.id');
        this.set('template_id', mainObservationID);
        this.set('clone_area', cloneArea);
        this.set('clone_event', cloneEvent);
        this.set('file_ids', null);
        this.set('routeHasBeenLoaded', false);
        (0, _runloop.later)(() => {
          this.routeActions.refresh();
        });
      }).catch(_notifications.reportError);
    }
    cancel() {
      this.set('cachedMapExtent', null);
      this.set('routeHasBeenLoaded', false);
      this.set('template_id', null);
      this.routeActions.goBack();
    }
    reset() {
      this.set('cachedMapExtent', null);
      this.routeActions.triggerReset();
    }
    togglePanels() {
      this.toggleProperty('closePanels');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "listRoutes", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "preserveZoom", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "occurrenceMainform", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "formData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "mainformIsTaimeatlas", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateMainformSheetset", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updateMainformSheetset"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loadMainformSheetset", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (mainform) {
        this.set('mainformTaxonSheetset', null);
        if (!(0, _utils.isNone)(mainform)) {
          const sheetset = yield (0, _sheets.getMainformSheetset)(this.ajax, mainform);
          this.set('mainformTaxonSheetset', sheetset);
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "enableDeterminersPanel", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "enableDeterminersPanel"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "interactionsEnabled", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "collapseHabitat", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clone", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePanels", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "togglePanels"), _class.prototype), _class);
});