define("plutof/controllers/clipboard/specimen", ["exports", "@ember/controller", "@ember/object", "plutof/config/environment", "plutof/controllers/clipboard/clipboard", "plutof/controllers/clipboard/utils/bulk-update", "plutof/components/clipboard/export"], function (_exports, _controller, _object, _environment, _clipboard, _bulkUpdate, _export) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ClipboardSpecimenController = (_class = class ClipboardSpecimenController extends _controller.default.extend(_clipboard.ClipboardControllerMixin) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "clipboardURL", _environment.default.API_HOST + '/clipboard/clipboards/specimens/');
      _defineProperty(this, "contentType", 'specimen/specimen');
      _defineProperty(this, "modelPath", 'taxonoccurrence/specimen/specimen');
      _defineProperty(this, "viewRoute", 'specimen.view');
      _defineProperty(this, "countField", 'specimens');
      _defineProperty(this, "bulkUpdatePanels", (0, _bulkUpdate.getBulkUpdatePanels)(['specimens', 'samplingareas', 'samplingevents', 'specimen-preparations', 'condition-assessments']));
      _defineProperty(this, "bulkEndpoint", 'bulk-update/specimens/');
      _defineProperty(this, "mainformType", 'specimen');
      _defineProperty(this, "clipboardModule", 'clipboard_specimen');
      _defineProperty(this, "transactionModule", 'specimen');
    }
    get exportModel() {
      return new _export.ClibpoardExportModel({
        store: this.store,
        enabledFormats: ['csv', 'list-of-species', 'index-seminum', 'darwin-core'],
        endpointPrefix: '/export/specimens/'
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "exportModel", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "exportModel"), _class.prototype), _class);
  var _default = _exports.default = ClipboardSpecimenController;
});