define("plutof/components/search/filter-panels/red-list-assessment/taxon", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelTaxon"}}
      @collapse={{true}}
  >
      <div class="out-of-form-field">
          <Search::FilterInput
              @filter={{if this.includeSubtaxa @filterInputs.parent_taxon @filterInputs.taxon_node}}
              @label={{@filterInputs.taxon_node.label}} />
      </div>
  
      <Common::Ui::OneWayCheckbox
          @value={{this.includeSubtaxa}}
          @update={{this.toggleSubtaxa}}
      >
          {{@filterInputs.parent_taxon.label}}
      </Common::Ui::OneWayCheckbox>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "GRvBd7pm",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"Search.panelTaxon\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"out-of-form-field\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@filter\",\"@label\"],[[52,[30,0,[\"includeSubtaxa\"]],[30,1,[\"parent_taxon\"]],[30,1,[\"taxon_node\"]]],[30,1,[\"taxon_node\",\"label\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,4],null,[[\"@value\",\"@update\"],[[30,0,[\"includeSubtaxa\"]],[30,0,[\"toggleSubtaxa\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1,[\"parent_taxon\",\"label\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"search/filter-input\",\"if\",\"common/ui/one-way-checkbox\"]]",
    "moduleName": "plutof/components/search/filter-panels/red-list-assessment/taxon.hbs",
    "isStrictMode": false
  });
  let RedListAssessmentTaxonFilters = (_class = class RedListAssessmentTaxonFilters extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "manualFilterToggle", _descriptor, this);
    }
    get subtaxaFilterUsed() {
      const parentFilter = this.args.filterInputs.parent_taxon;
      return parentFilter && !parentFilter.isEmpty;
    }
    get includeSubtaxa() {
      return this.subtaxaFilterUsed || this.manualFilterToggle;
    }
    toggleSubtaxa() {
      const taxonFilter = this.args.filterInputs.taxon_node;
      const parentTaxonFilter = this.args.filterInputs.parent_taxon;
      const filtersPresent = taxonFilter && parentTaxonFilter;
      if (this.includeSubtaxa) {
        if (filtersPresent) {
          taxonFilter.value = parentTaxonFilter.value;
          parentTaxonFilter.clear();
        }
        this.manualFilterToggle = false;
      } else {
        if (filtersPresent) {
          parentTaxonFilter.value = taxonFilter.value;
          taxonFilter.clear();
        }
        this.manualFilterToggle = true;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "manualFilterToggle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleSubtaxa", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSubtaxa"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RedListAssessmentTaxonFilters);
});