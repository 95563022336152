define("plutof/utils/pagination/builder", ["exports", "@ember/object", "ember-inflector", "rsvp", "plutof/config/environment", "plutof/misc/config", "plutof/utils/pagination", "plutof/utils/store"], function (_exports, _object, _emberInflector, _rsvp, _environment, _config, _pagination, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildPagination;
  /* eslint-disable func-style */

  // XXX: This is so much of a WIP that it might not well exist. Current implementation is
  // throwaway.
  function buildPagination(_ref) {
    let {
      ajax,
      store,
      pageSize = _config.default.Common.PAGE_SIZE
    } = _ref;
    let objects = [];
    let loadPage;
    let rootEndpoint;
    let countResponseFunc;
    let countEndpoint;
    let filters = {};
    let handlePage = function (page) {
      objects = page;
    };
    let preprocess = function (data) {
      return _rsvp.default.Promise.resolve(data);
    };
    async function build() {
      let {
        waitForPage = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let count = 0;
      class BuiltPagination extends _pagination.RegularPagination {
        get objects() {
          return objects;
        }
        set objects(value) {
          objects = value;
        }
        loadPage(number) {
          return loadPage(number);
        }
        handlePage(page) {
          handlePage(page);
          this.notifyPropertyChange('objects');
        }
        preprocessPage(data) {
          if (countResponseFunc) {
            (0, _object.set)(this, 'objectCount', countResponseFunc(data));
          }
          return preprocess(data);
        }
      }
      if (countEndpoint) {
        const countResponse = await ajax.request(`${_environment.default.API_HOST}/${countEndpoint}`, {
          data: filters
        });
        count = countResponse.objects_count;
      } else if (countResponseFunc) {
        count = 1; // XXX
      }
      const pagination = BuiltPagination.create({
        pageSize,
        objectCount: count
      });
      const pageReady = pagination.switchPage(1);
      if (waitForPage) {
        await pageReady;
      }
      return pagination;
    }
    function fromEndpoint(endpoint) {
      rootEndpoint = endpoint;
      loadPage = function (number) {
        return ajax.request(endpoint, {
          data: Object.assign({
            page: number,
            page_size: pageSize
          }, filters)
        });
      };
      return self;
    }
    function fromStore(model) {
      rootEndpoint = `${(0, _emberInflector.pluralize)(model)}/`;
      loadPage = function (number) {
        return (0, _store.query)(store, model, Object.assign({
          page: number,
          page_size: pageSize
        }, filters));
      };
      return self;
    }
    let self;
    const withCount = {
      fromResponse(fn) {
        countResponseFunc = fn;
        return self;
      },
      fromEndpoint() {
        let endpoint = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        if (!endpoint) {
          endpoint = rootEndpoint + 'count/';
        }
        countEndpoint = endpoint;
        return self;
      }
    };
    function withFilters(fs) {
      filters = fs;
      return self;
    }
    function withPreprocess(fn) {
      preprocess = data => _rsvp.default.Promise.resolve(fn(data));
      return self;
    }
    self = {
      fromEndpoint,
      fromStore,
      withCount,
      withFilters,
      withPreprocess,
      build
    };
    return self;
  }
});