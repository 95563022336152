define("plutof/components/information/terms-of-service", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/template", "@ember-decorators/component", "ember-concurrency"], function (_exports, _component, _object, _service, _template, _component2, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function loadToS(ajax, locale) {
    const url = `${window.location.origin}/assets/varia/tos/plutof-${locale}.html`;
    return ajax.raw(url, {
      dataType: 'text'
    }).then(_ref => {
      let {
        response
      } = _ref;
      return (0, _template.htmlSafe)(response);
    });
  }
  let TermsOfServiceDialog = (_dec = (0, _component2.classNames)('terms-of-service'), _dec2 = (0, _object.computed)('selectedLocale'), _dec3 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec4 = (0, _object.computed)('selectedLocale'), _dec(_class = (_class2 = class TermsOfServiceDialog extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "locale", _descriptor2, this);
      _defineProperty(this, "selectedLocale", this.locale.locale);
      _initializerDefineProperty(this, "loadToS", _descriptor3, this);
    }
    get strings() {
      const t = key => this.i18n.t(`General.termsOfService.${key}`, {
        locale: this.selectedLocale
      });
      return {
        title: t('dialogTitle'),
        accept: t('accept'),
        decline: t('decline')
      };
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (this.open) {
        this.loadToS.perform(this.selectedLocale);
      }
    }
    get headerButtons() {
      return ['eng', 'est'].filter(l => l !== this.selectedLocale).map(locale => ({
        name: locale.toUpperCase(),
        action: () => {
          this.set('selectedLocale', locale);
          this.loadToS.perform(this.selectedLocale);
        }
      }));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "locale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "strings", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "strings"), _class2.prototype), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "loadToS", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (locale) {
        // TODO: When ENG version is written, use locale
        const tos = yield loadToS(this.ajax, locale);
        this.set('tos', tos);
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "headerButtons", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "headerButtons"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = TermsOfServiceDialog;
});