define("plutof/modifiers/get-reference", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberModifier.modifier)(function getReference(element, _ref) {
    let [callback] = _ref;
    callback(element);
  });
});