define("plutof/components/plutof-select/direct", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofSelect::-Base
      @choices={{this.options}}
      @value={{this.selectedOption}}
      @update={{this.update}}
      @prompt={{this.prompt}}
      class={{this.selectClass}}
      disabled={{@disabled}}
      ...attributes />
  
  */
  {
    "id": "NZgT9BWg",
    "block": "[[[8,[39,0],[[16,0,[30,0,[\"selectClass\"]]],[16,\"disabled\",[30,1]],[17,2]],[[\"@choices\",\"@value\",\"@update\",\"@prompt\"],[[30,0,[\"options\"]],[30,0,[\"selectedOption\"]],[30,0,[\"update\"]],[30,0,[\"prompt\"]]]],null],[1,\"\\n\"]],[\"@disabled\",\"&attrs\"],false,[\"plutof-select/-base\"]]",
    "moduleName": "plutof/components/plutof-select/direct.hbs",
    "isStrictMode": false
  });
  let DirectSelect = (_dec = (0, _object.computed)('content.[]'), _dec2 = (0, _object.computed)('value', 'options.[]'), _class = class DirectSelect extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "prompt", '---');
      _defineProperty(this, "selectClass", 'form-control');
      _defineProperty(this, "disabled", false);
    }
    get options() {
      return this.content.map((option, idx) => ({
        value: idx.toString(),
        label: option
      }));
    }
    get selectedOption() {
      if ((0, _utils.isNone)(this.value)) {
        return null;
      }
      return this.options.findBy('label', this.value);
    }
    update(option) {
      this.set('value', option?.label);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "options", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "options"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedOption", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectedOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DirectSelect);
});