define("plutof/components/filerepository/image-area/-selector", ["exports", "@ember/component", "@ember/runloop"], function (_exports, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const MIN_SELECTION_SIZE = 50;
  function addSelectionHandle(parentElement, grabHandler) {
    const element = document.createElement('div');
    element.classList.add('selection-handle');
    element.addEventListener('mousedown', grabHandler);
    element.addEventListener('touchstart', grabHandler);
    parentElement.appendChild(element);
    return element;
  }
  class DraggableElement {
    constructor(imageElement, selectionElement, update) {
      this.update = update;
      this.startX = 0;
      this.startY = 0;
      this.bounds = {
        maxX: 0,
        maxY: 0
      };
      this.element = selectionElement;
      this.image = imageElement;
      this.handleSelectionGrab = this.startMove.bind(this);
      this.handleCornerGrab = this.startResize.bind(this);
      this.resizeHandle = addSelectionHandle(this.element, this.handleCornerGrab);
      this.handleMove = this.move.bind(this);
      this.handleResize = this.resize.bind(this);
      this.handleRelease = this.release.bind(this);
      this.element.addEventListener('mousedown', this.handleSelectionGrab);
      document.addEventListener('mouseup', this.handleRelease);
      this.element.addEventListener('touchstart', this.handleSelectionGrab);
      document.addEventListener('touchend', this.handleRelease);
    }
    setBounds(maxX, maxY) {
      this.bounds = {
        maxX,
        maxY
      };
    }
    initPosition() {
      this.element.style.left = `${(this.image.clientWidth - this.element.clientWidth) / 2}px`;
      this.update(this.element.offsetLeft, this.element.offsetTop, this.element.offsetWidth, this.element.offsetHeight);
    }
    startMove(event) {
      event.stopPropagation();
      document.addEventListener('mousemove', this.handleMove);
      document.addEventListener('touchmove', this.handleMove);
      this.setBounds(this.image.clientWidth, this.image.clientHeight);
      this.startX = event.clientX;
      this.startY = event.clientY;
    }
    startResize(event) {
      event.stopPropagation();
      document.addEventListener('mousemove', this.handleResize);
      document.addEventListener('touchmove', this.handleResize);
      this.setBounds(this.image.clientWidth, this.image.clientHeight);
      this.startX = event.clientX;
      this.startY = event.clientY;
    }
    getNewPosition(event, startX, startY) {
      const clientX = event.type === 'touchmove' ? event.touches[0].clientX : event.clientX;
      const clientY = event.type === 'touchmove' ? event.touches[0].clientY : event.clientY;
      const deltaX = clientX - startX;
      const deltaY = clientY - startY;
      return {
        clientX,
        clientY,
        deltaX,
        deltaY
      };
    }
    getClampedSize(newSize, element, bounds) {
      const maxX = bounds.maxX - element.offsetLeft;
      const maxY = bounds.maxY - element.offsetTop;
      return Math.max(MIN_SELECTION_SIZE, Math.min(newSize, maxX, maxY));
    }
    move(event) {
      const {
        clientX,
        clientY,
        deltaX,
        deltaY
      } = this.getNewPosition(event, this.startX, this.startY);
      const newLeft = this.element.offsetLeft + deltaX;
      const newTop = this.element.offsetTop + deltaY;
      const maxX = this.bounds.maxX - this.element.clientWidth;
      const maxY = this.bounds.maxY - this.element.clientHeight;
      const clampedLeft = Math.max(0, Math.min(newLeft, maxX));
      const clampedTop = Math.max(0, Math.min(newTop, maxY));
      this.element.style.left = `${clampedLeft}px`;
      this.element.style.top = `${clampedTop}px`;
      this.startX = clientX;
      this.startY = clientY;
    }
    resize(event) {
      const {
        clientX,
        clientY,
        deltaX,
        deltaY
      } = this.getNewPosition(event, this.startX, this.startY);
      const delta = Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY;
      const newWidth = this.element.clientWidth + delta;
      const newHeight = this.element.clientHeight + delta;
      const newSize = Math.min(newWidth, newHeight);
      const clampedSize = this.getClampedSize(newSize, this.element, this.bounds);
      this.element.style.width = `${clampedSize}px`;
      this.element.style.height = `${clampedSize}px`;
      this.startX = clientX;
      this.startY = clientY;
    }
    release() {
      document.removeEventListener('mousemove', this.handleMove);
      document.removeEventListener('mousemove', this.handleResize);
      document.removeEventListener('touchmove', this.handleMove);
      document.removeEventListener('touchmove', this.handleResize);
      this.update(this.element.offsetLeft, this.element.offsetTop, this.element.offsetWidth, this.element.offsetHeight);
    }
    clearListeners() {
      this.element.removeEventListener('mousedown', this.handleSelectionGrab);
      this.resizeHandle.removeEventListener('mousedown', this.handleCornerGrab);
      document.removeEventListener('mousemove', this.handleMove);
      document.removeEventListener('mousemove', this.handleResize);
      document.removeEventListener('mouseup', this.handleRelease);
      this.element.removeEventListener('touchstart', this.handleSelectionGrab);
      this.resizeHandle.removeEventListener('touchstart', this.handleCornerGrab);
      document.removeEventListener('touchmove', this.handleMove);
      document.removeEventListener('touchmove', this.handleResize);
      document.removeEventListener('touchend', this.handleRelease);
    }
  }
  class ImageAreaSelector extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "image", null);
    }
    transformSelection(x, y, width, height, scale) {
      return {
        x: Math.floor(x * scale),
        y: Math.floor(y * scale),
        width: Math.floor(width * scale),
        height: Math.floor(height * scale)
      };
    }
    updateSelection(x, y, width, height) {
      const scaleFactor = Math.max(1, this.imageWidth / this.image.clientWidth);
      this.update(this.transformSelection(x, y, width, height, scaleFactor));
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      this.image = document.getElementById(`${this.elementId}-image`);
      const rectangle = document.getElementById(`${this.elementId}-selection`);
      (0, _runloop.next)(() => {
        this.draggableElement = new DraggableElement(this.image, rectangle, this.updateSelection.bind(this));
        this.draggableElement.setBounds(this.image.clientWidth, this.image.clientHeight);
        this.draggableElement.initPosition();
      });
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.draggableElement.clearListeners();
    }
  }
  var _default = _exports.default = ImageAreaSelector;
});