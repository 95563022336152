define("plutof/components/profile/groups/managing-group/view", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "ember-concurrency", "plutof/models/users/usergroup", "plutof/utils/group", "plutof/utils/pagination/builder"], function (_exports, _component, _templateFactory, _component2, _tracking, _service, _object, _emberConcurrency, _usergroup, _group, _builder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Profile::Groups::ManagingGroup::ViewBase
      @acceptedMembers={{this.acceptedMembers}}
      @pendingMembers={{this.pendingMembers}}
      @status={{this.status}}
      @leave={{action this.leave}} />
  
  */
  {
    "id": "xm9L5hh8",
    "block": "[[[8,[39,0],null,[[\"@acceptedMembers\",\"@pendingMembers\",\"@status\",\"@leave\"],[[30,0,[\"acceptedMembers\"]],[30,0,[\"pendingMembers\"]],[30,0,[\"status\"]],[28,[37,1],[[30,0],[30,0,[\"leave\"]]],null]]],null],[1,\"\\n\"]],[],false,[\"profile/groups/managing-group/view-base\",\"action\"]]",
    "moduleName": "plutof/components/profile/groups/managing-group/view.hbs",
    "isStrictMode": false
  });
  let ManagingGroupView = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class ManagingGroupView extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "groupMembership", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "status", _descriptor4, this);
      _initializerDefineProperty(this, "acceptedMembers", _descriptor5, this);
      _initializerDefineProperty(this, "pendingMembers", _descriptor6, this);
      _initializerDefineProperty(this, "fetch", _descriptor7, this);
      this.fetch.perform();
    }
    fetchMembers(filters) {
      return (0, _builder.default)({
        ajax: this.ajax
      }).fromEndpoint('users/usergroups/').withFilters(Object.assign({
        group: this.args.workgroupId,
        ordering: '-status'
      }, filters)).withCount.fromEndpoint().build();
    }
    fetchAcceptedMembers() {
      return this.fetchMembers({
        status__gt: 0
      });
    }
    fetchPendingMembers() {
      return this.fetchMembers({
        status: 0
      });
    }
    async leave() {
      await this.groupMembership.leaveGroup(this.args.workgroupId);
      this.fetch.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "groupMembership", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "status", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "acceptedMembers", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pendingMembers", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        this.status = yield (0, _group.statusInWorkgroup)(this.store, this.args.workgroupId);
        this.acceptedMembers = yield this.fetchAcceptedMembers();
        if (this.status > _usergroup.UserStatus.Unverified) {
          this.pendingMembers = yield this.fetchPendingMembers();
        } else {
          this.pendingMembers = null;
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "leave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "leave"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManagingGroupView);
});