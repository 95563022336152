define("plutof/components/measurements/measurement-view/wrapper", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@ember/utils", "@glimmer/tracking", "ember-data", "rsvp", "plutof/misc/measurements"], function (_exports, _component, _templateFactory, _component2, _object, _service, _utils, _tracking, _emberData, _rsvp, _measurements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield this.measurements this.empty this.expand}}
  
  <Ui::Modal::Dialog
      @open={{this.modalOpen}}
      @close={{this.collapse}}
  >
      <:header>
          {{this.expandedMeasurement.fullName}}
      </:header>
  
      <:body>
          <Measurements::MeasurementView::Expanded @measurement={{this.expandedMeasurement}} />
      </:body>
  </Ui::Modal::Dialog>
  
  */
  {
    "id": "wSL9Szz3",
    "block": "[[[18,1,[[30,0,[\"measurements\"]],[30,0,[\"empty\"]],[30,0,[\"expand\"]]]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@open\",\"@close\"],[[30,0,[\"modalOpen\"]],[30,0,[\"collapse\"]]]],[[\"header\",\"body\"],[[[[1,\"\\n        \"],[1,[30,0,[\"expandedMeasurement\",\"fullName\"]]],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[8,[39,2],null,[[\"@measurement\"],[[30,0,[\"expandedMeasurement\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"ui/modal/dialog\",\"measurements/measurement-view/expanded\"]]",
    "moduleName": "plutof/components/measurements/measurement-view/wrapper.hbs",
    "isStrictMode": false
  });
  let MeasurementsViewWrapper = (_dec = (0, _object.computed)('args.object.id', 'args.mainform'), _dec2 = (0, _object.computed)('measurements.@each.value'), _class = class MeasurementsViewWrapper extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "modalOpen", _descriptor2, this);
      _initializerDefineProperty(this, "expandedMeasurement", _descriptor3, this);
    }
    async load() {
      const [object, form] = await _rsvp.default.Promise.all([this.args.object, this.args.mainform]);
      if ((0, _utils.isNone)(object)) {
        return [];
      }
      return (0, _measurements.get_object_form_measurements)(this.store, object, form);
    }
    get measurements() {
      return _emberData.default.PromiseArray.create({
        promise: this.load()
      });
    }
    get empty() {
      return this.measurements.mapBy('value').compact().length === 0;
    }
    expand(om) {
      this.expandedMeasurement = om;
      this.modalOpen = true;
    }
    collapse() {
      this.expandedMeasurement = null;
      this.modalOpen = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "measurements", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "measurements"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "empty", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "empty"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "expandedMeasurement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "expand", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "expand"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "collapse", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "collapse"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MeasurementsViewWrapper);
});