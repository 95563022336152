define("plutof/router", ["exports", "@ember/routing/router", "plutof/config/environment", "plutof/utils/annotation/routes"], function (_exports, _router, _environment, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Router extends _router.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "location", _environment.default.locationType);
    }
    init() {
      super.init(...arguments);

      // TODO: Move RoutingStatus.routeLoading here. We don't actually need
      // history logging anymore. And RoutingStatus can go as well, replaced
      // with the router service
      // this.on('routeWillChange', transition => {
      //     this.set('routeLoading', true);
      // });
      //
      // this.on('routeDidChange', transition => {
      //     this.set('routeLoading', false);
      // });
      this.on('routeDidChange', transition => {
        let titleParts = [];
        let route = transition.to;
        if (!route) {
          // No idea why it would ever be null, but we do have evidence in Rollbar
          return;
        }
        do {
          if (route.metadata && route.metadata.title) {
            titleParts.push(route.metadata.title);
          }
          route = route.parent;
        } while (route);
        document.title = titleParts.compact().join(' | ');
      });
    }
  }

  /* eslint array-callback-return: "off" */
  Router.map(function () {
    function addStandardRoutes(router) {
      router.route('index', {
        path: '/'
      });
      router.route('add');
      router.route('view', {
        path: 'view/:id'
      });
      router.route('edit', {
        path: 'edit/:id'
      });
    }

    // Auth
    this.route('login', function () {
      this.route('accept-token', {
        path: 'accept-token/:access_token'
      });
    });
    this.route('step-one-register', {
      path: 'register'
    });
    this.route('step-two-register', {
      path: 'register/step-two/:user_id'
    });
    this.route('activate', {
      path: '/activate/:key'
    });
    this.route('recover-password');
    this.route('reset-password', {
      path: 'reset-password/:key'
    });

    // Public routes
    this.route('index', {
      path: '/'
    });

    // Profile
    this.route('profile', function () {
      this.route('info');
      this.route('defaults');
      this.route('password');
      this.route('groups', function () {
        this.route('group', {
          path: '/:id'
        });
        this.route('add');
        this.route('join');
      });
      this.route('rights');
      this.route('bookmarks');
      this.route('files');
      this.route('classifications');
      this.route('notifications');
      this.route('stats');
      this.route('identities');
    });

    // Advanced search
    this.route('search', function () {
      this.route('index', {
        path: '/'
      });
    });

    // Import
    this.route('import', function () {
      this.route('index', {
        path: '/'
      });
      this.route('add');
      this.route('generate-template');
      // XXX: Remove this, /process has now taken its place
      this.route('process', {
        path: '/process/:process_id'
      });
    });

    // Specimen
    this.route('specimen', function () {
      addStandardRoutes(this);
      (0, _routes.addAnnotationSubroutes)(this);
    });

    // Study
    this.route('study', function () {
      addStandardRoutes(this);
    });

    // Publishing laboratory
    this.route('publishing-lab', function () {
      this.route('index', {
        path: '/'
      });
    });

    // Publishing
    this.route('publishing', function () {
      this.route('gbif', function () {
        addStandardRoutes(this);
      });
      this.route('ena', function () {
        addStandardRoutes(this);
      });
    });

    // Reference
    this.route('reference', function () {
      addStandardRoutes(this);
    });

    // Reference series
    this.route('series', function () {
      addStandardRoutes(this);
    });

    // Glossary
    this.route('glossary', function () {
      addStandardRoutes(this);

      // Glossary collections
      this.route('collection', function () {
        addStandardRoutes(this);
      });
    });

    // Filerepository
    this.route('filerepository', function () {
      this.route('index', {
        path: '/'
      });
      this.route('upload');
      this.route('view', {
        path: '/view/:file_id'
      });
      this.route('edit', {
        path: '/edit/:id'
      });
    });

    // Sampling area
    this.route('area', function () {
      addStandardRoutes(this);
    });
    this.route('layer', function () {
      addStandardRoutes(this);
    });

    // Sampling event
    this.route('event', function () {
      this.route('add', {
        path: 'add/:area_id'
      });
      this.route('view', {
        path: 'view/:id'
      });
      this.route('edit', {
        path: 'edit/:id'
      });
    });

    // Observation
    this.route('observation', function () {
      addStandardRoutes(this);
    });

    // Materialsample
    this.route('materialsample', function () {
      addStandardRoutes(this);
    });

    // Sequence
    this.route('sequence', function () {
      addStandardRoutes(this);
      (0, _routes.addAnnotationSubroutes)(this);
      this.route('parse-file', {
        path: 'parse-file/:id'
      });
    });

    // Clipboard
    this.route('clipboard', function () {
      this.route('index', {
        path: '/'
      });
      this.route('specimen');
      this.route('sequence');
      this.route('observation');
      this.route('photobank');
      this.route('livingspecimen');
      this.route('materialsample');
      this.route('reference-based');
    });

    // Person
    this.route('person', function () {
      addStandardRoutes(this);
    });

    // Organization
    this.route('organization', function () {
      addStandardRoutes(this);
    });
    this.route('collection', function () {
      addStandardRoutes(this);
    });

    // Datacite
    this.route('datacite', function () {
      this.route('index', {
        path: '/'
      });
      this.route('add');
      this.route('edit', {
        path: 'edit/:metadata_id'
      });
      this.route('view-private', {
        path: 'view/:metadata_id'
      });
      this.route('alignment', {
        path: 'alignment/:cluster_id'
      });
    });

    // Taxonomy laboratory
    this.route('taxonomy-lab', function () {
      this.route('index', {
        path: '/'
      });
    });

    // Taxon description
    this.route('taxon-description', function () {
      addStandardRoutes(this);
    });
    this.route('taxonomy', function () {
      this.route('trees');
      this.route('add', {
        path: 'add/:tree_id'
      });
      this.route('edit', {
        path: 'edit/:id'
      });
      this.route('view', {
        path: 'view/:id'
      });
      this.route('addtree', {
        path: 'add-tree'
      });
      this.route('edittree', {
        path: 'edit-tree/:id'
      });
    });

    // Living Culture
    this.route('livingspecimen', function () {
      addStandardRoutes(this);
      (0, _routes.addAnnotationSubroutes)(this);
    });

    // Reference Based Taxon Occurrences
    this.route('referencebased', function () {
      addStandardRoutes(this);
    });

    // Unite reference sequences
    this.route('unite', function () {
      this.route('referencesequences', {
        path: '/'
      });
      this.route('view', {
        path: 'view/:id'
      });
      this.route('statistics');
      this.route('taxonomy');
    });

    // Photobank
    this.route('photobank', function () {
      addStandardRoutes(this);
    });

    // Analysis
    this.route('analysis', function () {
      addStandardRoutes(this);
    });

    // Compound cluster
    this.route('compound', function () {
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: 'view/:cluster_id'
      });
    });

    // Collection laboratory
    this.route('collection-lab', function () {
      this.route('index', {
        path: '/'
      });
    });

    // Condition assessment
    this.route('condition-assessment', function () {
      addStandardRoutes(this);
    });

    // Treatment
    this.route('treatment', function () {
      addStandardRoutes(this);
    });

    // Transaction
    this.route('transaction', function () {
      addStandardRoutes(this);
    });

    // End collection laboratory

    this.route('moderate', function () {
      this.route('index', {
        path: '/moderate-observations'
      });
    });

    // Molecular laboratory
    this.route('molecular-lab', function () {
      this.route('index', {
        path: '/'
      });
    });

    // Analyses laboratory
    this.route('analyses-lab', function () {
      this.route('index', {
        path: '/'
      });
    });

    // GIS laboratory
    this.route('gis-lab', function () {
      this.route('index', {
        path: '/'
      });
      this.route('import');
    });

    // Video tutorials
    this.route('videos', function () {
      this.route('index', {
        path: '/'
      });
    });

    // Mainforms
    this.route('forms', function () {
      addStandardRoutes(this);
    });

    // DNA lab experiments
    this.route('experiment', function () {
      this.route('dna-extraction', function () {
        addStandardRoutes(this);
      });
      this.route('pcr', function () {
        addStandardRoutes(this);
      });
      this.route('sequencing', function () {
        addStandardRoutes(this);
      });
      this.route('dna', function () {
        addStandardRoutes(this);
      });
    });

    // Data Management Plan
    this.route('dmp', function () {
      addStandardRoutes(this);
    });

    // Traits
    this.route('trait', function () {
      addStandardRoutes(this);
    });
    this.route('trait-lab');

    // Redbook
    this.route('conservation-lab', function () {
      this.route('index', {
        path: '/'
      });
      this.route('red-list', function () {
        addStandardRoutes(this);
      });
      this.route('red-list-assessment', function () {
        addStandardRoutes(this);
      });
    });
    this.route('permit', function () {
      addStandardRoutes(this);
    });
    this.route('annotations', function () {
      this.route('index', {
        path: '/'
      });
      this.route('created');
      this.route('moderated');
      this.route('bulk', function () {
        this.route('add', {
          path: 'add/:content_type'
        });
        this.route('view', {
          path: 'view/:id'
        });
        this.route('edit', {
          path: 'edit/:id'
        });
      });
    });

    // Tests
    this.route('component-test');
    this.route('helper-test');
    this.route('not-found', {
      path: '/*path'
    });
  });
  var _default = _exports.default = Router;
});