define("plutof/controllers/sequence/annotation/add", ["exports", "plutof/config/environment", "plutof/misc/content_types", "plutof/utils/annotation/format", "plutof/utils/annotation/routes", "plutof/utils/structures"], function (_exports, _environment, _content_types, _format, _routes, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.extractSequence = extractSequence;
  class SequenceAnnotationAddController extends (0, _routes.annotationAddControllerWithElixir)({
    contentType: 'sequence/sequence',
    save(model) {
      return model.sequenceData.save();
    },
    getRecordID(model) {
      return model.sequenceData.sequence.id;
    },
    extract(extractor, changes) {
      return extractSequence(extractor, changes, this.model.sequenceData, this.store);
    }
  }) {
    get enableElixirAnnotations() {
      return _environment.default.ENABLE_ELIXIR_ANNOTATIONS;
    }
  }

  // TODO: store is probably not necessary, it was used when we were trying to do
  // the Elixir extraction without sequenceData
  _exports.default = SequenceAnnotationAddController;
  async function extractSequence(extractor, changes, sequenceData, store) {
    const sequenceChange = changes.find(change => change.operation === _format.Operation.Modify && change.model === 'taxonoccurrence/sequence/sequence');
    if (sequenceChange) {
      const annotatedSequence = await store.findRecord('taxonoccurrence/sequence/sequence', sequenceChange.id);
      const fields = (0, _structures.objectToMap)(sequenceChange.fields);

      // Source
      if (fields.has('content_type') || fields.has('object_id')) {
        const sourceContentType = await annotatedSequence.content_type;
        const sourceID = annotatedSequence.object_id;

        // TODO: What do we send when those are cleared? I suspect nothing
        if (sourceContentType && sourceID) {
          // We could get all this from the sequence edit model, but seems better
          // to isolate from that and work purely on the formatted annotation
          const source = await (0, _content_types.resolve_generic_link)(store, sourceContentType, sourceID);
          const collection = await source.deposited_in;
          let field;
          switch (source.constructor.modelName) {
            case 'taxonoccurrence/specimen/specimen':
              field = 'specimen_voucher';
              break;
            case 'taxonoccurrence/livingculture/strain':
              field = collection ? 'culture_collection/strain' : 'strain';
              break;
            case 'taxonoccurrence/materialsample/materialsample':
              if (collection) {
                field = 'bio_material';
              }
              break;
            default:
              throw new Error(`Unknown sequence parent type: ${source.constructor.modelName}`);
          }

          // This is supposed to contain collection/institution (whatever that is) codes, but atm
          // we can only put the plutof identifier
          const sourceIdentifier = source.representation;
          if (field) {
            extractor.add(field, sourceIdentifier);
          }
        }
      }

      // XXX: not the best idea to extract using a read-only field that we include in annotation
      // only because the view code expects it to be correctly generated based on updated fields.
      // But, while we have it, might as well put it to use instead of collating
      if (fields.has('compound_id')) {
        // XXX: This, apparently, needs to be sent only its parent sequence is from UNITE?!
        const parent = await annotatedSequence.parent_sequence;
        if (parent.inUNITE) {
          extractor.add('db_xref', fields.get('compound_id'));
        }
      }
      if (fields.has('seq_source')) {
        const source = await annotatedSequence.seq_source;
        extractor.add('isolation_source', source ? source.representation : '');
      }
      const PRIMER_FIELDS = [['forw_primer_name', 'fwd_name'], ['forw_primer_sequence', 'fwd_seq'], ['rev_primer_name', 'rev_name'], ['rev_primer_sequence', 'rev_seq']];
      if (PRIMER_FIELDS.some(_ref => {
        let [attr, elixirAttr] = _ref;
        return fields.has(attr);
      })) {
        const parts = [];
        for (const [attr, elixirAttr] of PRIMER_FIELDS) {
          if (annotatedSequence[attr]) {
            parts.push(`${elixirAttr}: ${annotatedSequence[attr]}`);
          }
        }
        extractor.add('PCR_primers', parts.join(', '));
      }
      const NOTE_FIELDS = [['chimeric_status', 'This sequence is chimeric'], ['quality_status', 'This sequence is of low quality']];
      if (NOTE_FIELDS.some(_ref2 => {
        let [attr, elixirAttr] = _ref2;
        return fields.has(attr);
      })) {
        let notes = [];
        for (const [attr, note] of NOTE_FIELDS) {
          if (annotatedSequence[attr]) {
            notes.push(note);
          }
        }
        extractor.add('note', notes.join(', '));
      }
    }
    await extractor.addIdentifications(sequenceData.determinations);
    const event = await sequenceData.sequence.samplingevent;
    if (event) {
      await extractor.addSample(event);
    }
  }
});