define("plutof/components/search/filters/project", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="filter-input" data-input={{@filter.config.label}}>
      <Search::Filters::-ExcludeFilter @filter={{@filter}}>
          <AutoComplete::BindMultiple @value={{@filter.value}} as |add|>
              <Project::AutoComplete
                  @update={{add}}
                  @filters={{hash}}
                  class="filter-input-ac" />
          </AutoComplete::BindMultiple>
      </Search::Filters::-ExcludeFilter>
  </div>
  
  */
  {
    "id": "EKhJ9xKe",
    "block": "[[[10,0],[14,0,\"filter-input\"],[15,\"data-input\",[30,1,[\"config\",\"label\"]]],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@filter\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,1],null,[[\"@value\"],[[30,1,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,2],[[24,0,\"filter-input-ac\"]],[[\"@update\",\"@filters\"],[[30,2],[99,3,[\"@filters\"]]]],null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@filter\",\"add\"],false,[\"search/filters/-exclude-filter\",\"auto-complete/bind-multiple\",\"project/auto-complete\",\"hash\"]]",
    "moduleName": "plutof/components/search/filters/project.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});