define("plutof/routes/analysis/edit", ["exports", "rsvp", "plutof/components/analysis/matching-source", "plutof/components/analysis/wrapper-source", "plutof/controllers/analysis/utils", "plutof/utils/routes"], function (_exports, _rsvp, _matchingSource, _wrapperSource, _utils, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class AnalysisEditRoute extends _routes.EditRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "defaultReturnRoute", 'analysis.index');
      _defineProperty(this, "confirmExit", true);
      _defineProperty(this, "modelTitle", 'run');
    }
    model(params) {
      return (0, _utils.fetchRun)(this.store, params.id).then(_ref => {
        let {
          run,
          type
        } = _ref;
        return _rsvp.default.hash({
          run,
          type,
          sourceData: (type.wrapper ? _wrapperSource.loadWrapperSourceData : _matchingSource.loadMatchingSourceData)(this.store, run)
        });
      });
    }
  }
  var _default = _exports.default = AnalysisEditRoute;
});