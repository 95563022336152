define("plutof/translations/eng/search", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    foundInfo: (count, query) => `Found ${count} results for "${query}".`,
    foundCount: count => `Found ${count} results.`,
    downloadTooltip: limit => `Download search results as a CSV file. Download is limited to ${limit} records.`,
    resultsPerPage: 'Results per page',
    columnName: 'Name',
    columnOwner: (0, _helpers.alias)('General.owner'),
    columnModified: 'Modified',
    columnTools: 'Add to clipboard',
    tabTable: 'List view',
    tabGallery: 'Image view',
    tabMap: 'Map view',
    panelTaxon: 'Taxon',
    panelTime: 'Time',
    panelLocation: 'Area',
    panelProperties: 'Properties',
    panelMeasurements: (0, _helpers.alias)('General.measurements'),
    inputLabel: 'Search string',
    inputTooltip: 'Enter search word(s) or comma-separated list of words. Words separated by spaces are expected to exist in a single record, while words separated by commas can be found in separate records.',
    module: 'Module',
    searchButton: 'Search',
    activeFilters: 'Active filters',
    filterPanels: 'Filters',
    ordering: 'Ordering',
    pageSizeToolip: 'Results per page',
    columnMenuTooltip: 'Columns',
    event: 'Event',
    createdAt: 'Created at',
    lastModified: 'Last modified',
    digitizationDate: 'Digitization date',
    dateIdentified: 'Date identified',
    booleanTrue: 'Include only',
    booleanFalse: 'Exclude',
    booleanIndeterminate: 'Undefined',
    anyValue: 'Any value',
    noValue: 'No value',
    addTrait: 'Add',
    newTrait: 'Trait',
    isParentAreaTrait: 'Parent area trait',
    traitInUse: 'Trait is already selected',
    noResults: 'No results',
    searchAllFields: 'Search over all fields',
    searchNameField: 'Search by "name" field',
    toggleAllColumns: 'All',
    loadFullMap: (0, _helpers.alias)('Clipboard.loadFullMap'),
    unloadFullMap: 'Show only current page',
    includeSynonyms: 'Synonyms',
    uniteAndInsd: 'UNITE and INSD',
    excludeFilter: 'Exclude',
    modules: {
      'taxonoccurrence': 'Taxon Occurrence',
      'study': 'Projects',
      'citizenscience': 'Citizen Science Projects',
      'specimen': 'Specimens',
      'observation': 'Observations',
      'sequence': 'Sequences',
      'livingculture': 'Living Specimens',
      'referencebased': 'Reference Based',
      'materialsample': 'Material Samples',
      'filerepository': 'File Repository',
      'publishing': (0, _helpers.alias)('Publishing.dois'),
      'photobank': 'Photobank',
      'taxonnode': 'Taxonomy',
      'taxondescription': 'Taxon Descriptions',
      'uniteSH': 'UNITE Species Hypotheses',
      'referencesequence': 'Reference Sequences',
      'reference': 'References',
      'referenceseries': 'Reference Series',
      'publisher': 'Publishers',
      'glossary': 'Keywords',
      'glossarycollection': 'Keyword Collections',
      'person': 'Persons',
      'organization': 'Organizations',
      'collection': 'Collections',
      'compoundclusters': 'Compound Clusters',
      'transaction': 'Collection Transactions',
      'dna': 'Extracted DNA',
      'dnaextraction': 'DNA Extractions',
      'pcr': 'PCR Reactions',
      'sequencing': 'DNA Sequencing'
    },
    geometry: {
      fromArea: 'Add sampling area geometry to the map',
      relation: {
        relation: 'Include',
        intersects: 'Records overlapping the search geometry',
        within: 'Records fully contained in the search geometry',
        contains: 'Records containing the search geometry'
      },
      buffer: {
        label: 'Radius/Accuracy (m)',
        tooltip: 'Creates a buffer polygon around the input geometry at a specified distance in meters'
      }
    },
    columns: {
      transactionCountAndNumber: 'Items databased / total'
    },
    topResults: {
      creator: 'Top creators',
      owner: 'Top rights holders',
      country: 'Top countries',
      determiner: 'Top determiners',
      collector: 'Top collectors',
      habitat: 'Top habitats',
      form: 'Top forms',
      natureReserve: 'Top nature reserves',
      study: 'Top projects',
      taxon: 'Top taxa',
      district: 'Top districts',
      commune: 'Top communes',
      parish: 'Top parishes',
      collection: 'Top collections',
      shCluster: 'Top Species Hypothesis',
      isolationSource: 'Top isolation sources',
      reference: 'Top references',
      cluster: 'Top clusters',
      phenoFilterWarning: 'Can not load top results for "Taxon Occurrence" search when "Unique taxa" filter is active.'
    },
    filters: {
      commonName: {
        label: 'Common name',
        tooltip: 'Find taxon node by its common name. Selecting common name will fill taxon filter with selected common name\'s scientific taxon name.'
      },
      taxon: {
        label: 'Taxon'
      },
      includeTaxonSynonyms: {
        label: 'Synonyms',
        tooltip: 'Include synonyms'
      },
      includeSubtaxa: {
        label: 'Subtaxa',
        tooltip: 'Include subtaxa'
      },
      layer: {
        label: 'Layer',
        tooltip: 'Filter layer areas by selected layer.<br/>This input does not affect search results.'
      },
      layerArea: {
        label: 'Layer area',
        tooltip: 'Find records linked to an layer area.<br/>Before selecting an area, please select a layer.'
      },
      layerAreaGeom: {
        label: 'Layer area and geometry',
        tooltip: 'Find records linked to the selected layer area as well as records within the layer area\'s geometry.'
      }
    },
    clipboard: {
      sendPageToClipboard: 'Send page to Clipboard',
      sendAllToClipboard: 'Send all results to Clipboard',
      noClipboard: 'Can not send search results to Clipboard because there\'s no Clipboard module for "{{moduleName}}" type of records.',
      noTaxonOccurrenceClipboard: '{{moduleName}} search module searches for all type of taxon occurrence record. To send taxon occurrences to clipboard, use a search module that searches for one type of taxon occurrence.'
    }
  };
});