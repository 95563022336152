define("plutof/components/i18n/model-strings", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "plutof/misc/options-getter"], function (_exports, _component, _templateFactory, _component2, _service, _optionsGetter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.texts wait=@wait as |texts|}}
      {{yield texts}}
  {{/resolve-promise}}
  
  */
  {
    "id": "nvdaXRpo",
    "block": "[[[6,[39,0],[[30,0,[\"texts\"]]],[[\"wait\"],[[30,1]]],[[\"default\"],[[[[1,\"    \"],[18,3,[[30,2]]],[1,\"\\n\"]],[2]]]]]],[\"@wait\",\"texts\",\"&default\"],false,[\"resolve-promise\",\"yield\"]]",
    "moduleName": "plutof/components/i18n/model-strings.hbs",
    "isStrictMode": false
  });
  let ModelStringsLoader = (_class = class ModelStringsLoader extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
    }
    get texts() {
      // TODO: Can cache this as well, in addition to getModelTexts cache
      return (0, _optionsGetter.getModelTexts)(this.ajax, this.args.model).then(texts => {
        const fields = {};
        for (const field of Object.keys(texts)) {
          const fieldTexts = texts[field];
          const meta = {
            label: fieldTexts.label,
            description: fieldTexts.help_text,
            infoLinks: fieldTexts.info_links || [],
            choices: {},
            selectOptions: fieldTexts.choices ?? []
          };
          if (fieldTexts.choices) {
            for (const choice of fieldTexts.choices) {
              meta.choices[choice.value] = choice.display_name;
            }
          }
          fields[field] = meta;
        }
        return fields;
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ModelStringsLoader);
});