define("plutof/components/area/edit", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/tracking", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _object, _tracking, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Project::RecordProject
      @data={{@area}}
      @validationContext={{@validationContext.project}} />
  
  <PlutofPanel::ItemList
      @collapse={{false}}
      data-test="Area::GeneralData"
      as |panel|
  >
      <panel.header
          @title={{i18n-t "General.generalData"}}
      >
          <panel.button @clicked={{this.openGeomTool}}>
              <span class={{icon "edit"}}></span>
              {{i18n-t "Sample.area.geometry"}}
          </panel.button>
      </panel.header>
  
      <panel.content>
          <Sample::SamplingArea
              @area={{@area}}
              @measurements={{@measurementsData}}
              @geometryConverterData={{@geometryConverterData}}
              @allowSiteInput={{true}}
              @geomToolOpen={{this.geomToolOpen}}
              @closeGeomTool={{this.closeGeomTool}}
              @validationContext={{@validationContext.area}} />
      </panel.content>
  </PlutofPanel::ItemList>
  
  <LinkedItems::Edit
      @data={{@associatedData}}
      @validationContext={{@validationContext.associatedData}} />
  
  {{#if @area.site}}
      <Access::EditRights
          @rights={{@accessRights}}
          @validationContext={{@validationContext.access}} />
  {{/if}}
  
  */
  {
    "id": "5e012OOY",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@validationContext\"],[[30,1],[30,2,[\"project\"]]]],null],[1,\"\\n\\n\"],[8,[39,1],[[24,\"data-test\",\"Area::GeneralData\"]],[[\"@collapse\"],[false]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"header\"]],null,[[\"@title\"],[[28,[37,2],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,3,[\"button\"]],null,[[\"@clicked\"],[[30,0,[\"openGeomTool\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[10,1],[15,0,[28,[37,3],[\"edit\"],null]],[12],[13],[1,\"\\n            \"],[1,[28,[35,2],[\"Sample.area.geometry\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@area\",\"@measurements\",\"@geometryConverterData\",\"@allowSiteInput\",\"@geomToolOpen\",\"@closeGeomTool\",\"@validationContext\"],[[30,1],[30,4],[30,5],true,[30,0,[\"geomToolOpen\"]],[30,0,[\"closeGeomTool\"]],[30,2,[\"area\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[3]]]]],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@data\",\"@validationContext\"],[[30,6],[30,2,[\"associatedData\"]]]],null],[1,\"\\n\\n\"],[41,[30,1,[\"site\"]],[[[1,\"    \"],[8,[39,7],null,[[\"@rights\",\"@validationContext\"],[[30,7],[30,2,[\"access\"]]]],null],[1,\"\\n\"]],[]],null]],[\"@area\",\"@validationContext\",\"panel\",\"@measurementsData\",\"@geometryConverterData\",\"@associatedData\",\"@accessRights\"],false,[\"project/record-project\",\"plutof-panel/item-list\",\"i18n-t\",\"icon\",\"sample/sampling-area\",\"linked-items/edit\",\"if\",\"access/edit-rights\"]]",
    "moduleName": "plutof/components/area/edit.hbs",
    "isStrictMode": false
  });
  let AreaEdit = (_class = class AreaEdit extends _component.default.extend(_componentValidations.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "validationChildren", [{
        name: 'area',
        label: 'General.generalData'
      }, {
        name: 'project',
        label: 'General.project'
      }, {
        name: 'access',
        label: 'General.access'
      }, {
        name: 'associatedData',
        label: 'General.associatedData'
      }]);
      _initializerDefineProperty(this, "geomToolOpen", _descriptor, this);
    }
    openGeomTool() {
      this.geomToolOpen = true;
    }
    closeGeomTool() {
      this.geomToolOpen = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "geomToolOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openGeomTool", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openGeomTool"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeGeomTool", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeGeomTool"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AreaEdit);
});