define("plutof/components/search/pagination-controls", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "@ember-decorators/object", "plutof/misc/abstract", "plutof/misc/config"], function (_exports, _component, _object, _component2, _object2, _abstract, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  // XXX: We still have templates/components/search/_pagination-controls, which is
  // a partial used in search that doesn't 100% conform to how paginations work
  // elsewhere (e.g. controls are disabled during load, so user can't skip pages).
  //
  // TODO: Bring it into the fold. In the end, the only component that must render
  // pagination is common/page-navigation and everything else uses it, preferably
  // through pagination/page-controls. There is no reason for anything to reimplement
  // firstPage again
  let SearchPaginationControls = (_dec = (0, _component2.classNames)('table-control__pagination'), _dec2 = (0, _object2.observes)('pagination.pageSize'), _dec3 = (0, _object.computed)('pageSize', 'maxPageSize'), _dec(_class = (_class2 = class SearchPaginationControls extends _component.default {
    constructor() {
      super(...arguments);
      // Local value, not directly bound to anything
      _defineProperty(this, "pageSize", _config.default.Common.PAGE_SIZE);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.resyncPageSize();
    }
    resyncPageSize() {
      this.set('pageSize', this.pagination.pageSize);
    }
    get pageSizeIsInvalid() {
      return !(0, _abstract.isNumeric)(this.pageSize) || parseInt(this.pageSize) > this.maxPageSize;
    }
    _updatePageSize() {
      if (this.pageSizeIsInvalid) {
        return;
      }
      this.updatePageSize(parseInt(this.pageSize));
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "resyncPageSize", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "resyncPageSize"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "pageSizeIsInvalid", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "pageSizeIsInvalid"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_updatePageSize", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_updatePageSize"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = SearchPaginationControls;
});