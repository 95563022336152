define("plutof/components/unite/determinations/edit", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/mixin", "@ember/utils", "copy-anything", "rsvp", "plutof/controllers/analysis/utils", "plutof/models/globalkey/determination", "plutof/utils/pagination"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _mixin, _utils, _copyAnything, _rsvp, _utils2, _determination, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createUniteIdentificationsModel = createUniteIdentificationsModel;
  _exports.default = void 0;
  _exports.loadUniteIdentificationsModel = loadUniteIdentificationsModel;
  var _dec, _class, _dec2, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::ItemList as |panel|>
      <panel.header
          @title={{i18n-t "UniteSH.determinations"}}
          @count={{@model.count}}
      >
          <panel.button @clicked={{@model.add}}>
              {{i18n-t "General.Add"}}
          </panel.button>
      </panel.header>
  
      {{! XXX: PlutofPanel::ItemList has no well-defined function }}
      <panel.innerPanels>
          <Validation::SplitContext
              @context={{@validationContext}}
              @items={{@model.pagination.objects}}
              as |entry context|
          >
              <Unite::Determinations::EditSingle
                  @entry={{entry}}
                  @validationContext={{context}}
                  @remove={{fn @model.remove entry}} />
          </Validation::SplitContext>
  
          <Pagination::LoadMore @pagination={{@model.pagination}} />
      </panel.innerPanels>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "O1oN4JOk",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,1],[\"UniteSH.determinations\"],null],[30,2,[\"count\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"button\"]],null,[[\"@clicked\"],[[30,2,[\"add\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"General.Add\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"],[1,\"    \"],[8,[30,1,[\"innerPanels\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@context\",\"@items\"],[[30,3],[30,2,[\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,3],null,[[\"@entry\",\"@validationContext\",\"@remove\"],[[30,4],[30,5],[28,[37,4],[[30,2,[\"remove\"]],[30,4]],null]]],null],[1,\"\\n        \"]],[4,5]]]]],[1,\"\\n\\n        \"],[8,[39,5],null,[[\"@pagination\"],[[30,2,[\"pagination\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"panel\",\"@model\",\"@validationContext\",\"entry\",\"context\"],false,[\"plutof-panel/item-list\",\"i18n-t\",\"validation/split-context\",\"unite/determinations/edit-single\",\"fn\",\"pagination/load-more\"]]",
    "moduleName": "plutof/components/unite/determinations/edit.hbs",
    "isStrictMode": false
  });
  const IdentificationPagination = _mixin.default.create({
    preprocessPage(pageData) {
      return _rsvp.default.Promise.resolve(pageData.map(identification => new UniteIdentificationEntry({
        identification
      })));
    }
  });
  let UniteIdentificationEntry = (_dec = (0, _object.computed)('identification.source_details.analysis'), _class = class UniteIdentificationEntry {
    constructor(_ref2) {
      let {
        identification
      } = _ref2;
      this.identification = identification;
      // XXX: Necessary for annotations
      this.details = Object.assign({
        analysis_details: {}
      }, (0, _copyAnything.copy)(identification.source_details));
    }
    get analysisType() {
      return (0, _utils2.getAnalysisType)(this.identification.source_details.analysis || 'blastn-sh');
    }
    save() {
      const details = this.details.analysis_details;
      this.analysisType.details.forEach(_ref3 => {
        let {
          field
        } = _ref3;
        if (!(0, _utils.isNone)(details[field])) {
          (0, _object.set)(details, field, parseFloat(details[field]));
        }
      });
      (0, _object.set)(this.identification, 'source_details', this.details);
      return this.identification.save();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "analysisType", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "analysisType"), _class.prototype), _class); // TODO: HasManyDataMixin can just be a base class, no need to proliferate mixins
  // Most of this logic and should be reused
  let UniteIdentificationsModel = (_dec2 = (0, _object.computed)('pagination.objectCount', 'localCount'), _class2 = class UniteIdentificationsModel {
    constructor(_ref4) {
      let {
        store,
        pagination,
        sequence
      } = _ref4;
      _defineProperty(this, "objectsToDelete", []);
      _initializerDefineProperty(this, "localCount", _descriptor, this);
      this.store = store;
      this.pagination = pagination;
      this.sequence = sequence;
    }
    get count() {
      return this.pagination.objectCount + this.localCount;
    }
    add() {
      const identification = this.store.createRecord('globalkey/determination', {
        sequence: this.sequence,
        source_type: _determination.SourceType.App,
        source_details: {
          analysis: 'blastn-sh',
          analysis_details: {}
        }
      });
      const entry = new UniteIdentificationEntry({
        identification
      });
      this.pagination.objects.pushObject(entry);
      this.localCount++;
    }
    remove(entry) {
      this.pagination.objects.removeObject(entry);
      this.objectsToDelete.push(entry.identification);
      this.localCount--;
    }
    async save() {
      await _rsvp.default.all(this.objectsToDelete.map(obj => obj.destroyRecord()));
      this.objectsToDelete = [];
      await _rsvp.default.all(this.pagination.objects.map(entry => entry.save()));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "localCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "count", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "count"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "add"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "remove"), _class2.prototype), _class2);
  function createUniteIdentificationsModel(_ref5) {
    let {
      store,
      sequence
    } = _ref5;
    return new UniteIdentificationsModel({
      store,
      sequence,
      pagination: _pagination.EmptyPagination.create()
    });
  }
  async function loadUniteIdentificationsModel(_ref6) {
    let {
      store,
      sequence
    } = _ref6;
    const query = {
      sequence: sequence.id,
      ordering: '-updated_at'
    };
    const mixins = [_pagination.AccumulatingPaginationMixin, IdentificationPagination];
    const pagination = await (0, _pagination.paginateModel)(store, 'globalkey/determination', query, mixins);
    return new UniteIdentificationsModel({
      store,
      pagination,
      sequence
    });
  }
  class UniteIdentifications extends _component2.default {}
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UniteIdentifications);
});