define("plutof/utils/store/proxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProxyStore {
    constructor(store) {
      this._store = store;
    }
    createRecord() {
      return this._store.createRecord(...arguments);
    }
    find() {
      return this._store.find(...arguments);
    }
    findAll() {
      return this._store.findAll(...arguments);
    }
    findRecord() {
      return this._store.findRecord(...arguments);
    }
    modelFor() {
      return this._store.modelFor(...arguments);
    }
    query() {
      return this._store.query(...arguments);
    }
    queryRecord() {
      return this._store.queryRecord(...arguments);
    }
    unloadRecord() {
      return this._store.unloadRecord(...arguments);
    }
    peekRecord() {
      return this._store.peekRecord(...arguments);
    }
    peekAll() {
      return this._store.peekAll(...arguments);
    }
    normalize() {
      return this._store.normalize(...arguments);
    }
    push() {
      return this._store.push(...arguments);
    }
    pushPayload() {
      return this._store.pushPayload(...arguments);
    }
  }
  var _default = _exports.default = ProxyStore;
});