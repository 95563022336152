define("plutof/translations/eng/dmp", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    general: {
      dmp: 'Data Management Plan'
    },
    routeTitles: {
      list: 'Data Management Plans',
      add: 'New Data Management Plan',
      edit: 'Edit Data Management Plan',
      view: 'Data Management Plan'
    },
    fields: {
      title: {
        label: 'Title',
        tooltip: 'Title of a DMP.'
      },
      description: {
        label: 'Description',
        tooltip: 'To provide any free-form text information on a DMP.'
      },
      identifier: {
        label: 'DMP identifier',
        tooltip: 'Identifier of the data management plan itself.'
      },
      identifierType: {
        label: 'DMP identifier type',
        tooltip: 'Identifier type of the data management plan itself.'
      },
      plutofProject: {
        label: 'PlutoF Project',
        tooltip: `Relevant existing project in PlutoF. This is the project under which actual raw data is
                gathered.`
      },
      ethical_issues_exist: {
        label: 'Ethical issues exists',
        tooltip: 'To indicate whether there are ethical issues related to data that this DMP describes.'
      },
      ethical_issues_description: {
        label: 'Ethical issues description',
        tooltip: 'To describe ethical issues directly in a DMP'
      },
      ethical_issues_report: {
        label: 'Ethical issues report',
        tooltip: 'To indicate where a protocol from a meeting with an ethical committee can be found.'
      }
    },
    licence: {
      ref: {
        label: 'Licence ref',
        tooltip: 'Link to licence document.',
        placeholder: 'https://example.com/licence'
      },
      start_date: {
        label: 'Licence start date',
        tooltip: 'If date is set in the future, it indicates embargo period.'
      }
    },
    project: {
      title: {
        label: 'Title',
        tooltip: 'Project title.'
      },
      description: {
        label: 'Description',
        tooltip: 'Project description.'
      },
      category: {
        label: 'Category',
        tooltip: 'Category of research project.'
      },
      type: {
        label: 'Type',
        tooltip: 'Type of research project.'
      },
      start: {
        label: 'Start date',
        tooltip: 'Project start date.'
      },
      end: {
        label: 'End date',
        tooltip: 'Project end date.'
      },
      funding: {
        status: {
          label: 'Funding status',
          tooltip: 'To express different phases of project funding lifecycle.'
        },
        funder: {
          identifier: {
            label: 'Funder ID',
            tooltip: `Funder ID, recommended to use CrossRef Funder Registry.

                        See: https://www.crossref.org/services/funder-registry/`
          },
          type: {
            label: 'Funder type',
            tooltip: ''
          }
        },
        grant: {
          identifier: {
            label: 'Grant ID',
            tooltip: 'Grant ID of the associated project.'
          },
          type: {
            label: 'Grant type',
            tooltip: ''
          }
        }
      }
    },
    dataset: {
      panel: {
        addAnother: 'Add another',
        distribution: 'Distribution',
        metadata: 'Metadata'
      },
      title: {
        label: 'Title',
        tooltip: `Title is a property in both Dataset and Distribution, in compliance with W3C DCAT. In
                some cases these might be identical, but in most cases the Dataset represents a more abstract
                concept, while the distribution can point to a specific file.`
      },
      description: {
        label: 'Description',
        tooltip: `Description is a property in both Dataset and Distribution, in compliance with W3C DCAT.
                In some cases these might be identical, but in most cases the Dataset represents a more
                abstract concept, while the distribution can point to a specific file.`
      },
      type: {
        label: 'Type',
        tooltip: `If appropriate, type according to: DataCite and/or COAR dictionary. Otherwise use the
                common name for the type, e.g. raw data, software, survey, etc.

                https://schema.datacite.org/meta/kernel-4.1/doc/DataCite-MetadataKernel_v4.1.pdf

                http://vocabularies.coar-repositories.org/pubby/resource_type.html`,
        placeholder: 'e.g. image'
      },
      language: {
        label: 'Language',
        tooltip: 'Language of the dataset expressed using ISO 639-3.',
        placeholder: 'e.g. eng'
      },
      issued: {
        label: 'Issued',
        tooltip: ''
      },
      preservation_statement: {
        label: 'Preservation statement',
        tooltip: `Preservation statement describes preservation and
                protection policy of the dataset; i.e. to what extent data must be preserved.`
      },
      personal_data: {
        label: 'Personal data',
        tooltip: ''
      },
      sensitive_data: {
        label: 'Sensitive data',
        tooltip: ''
      },
      security_and_privacy: {
        label: 'Security and privacy',
        tooltip: `Used to indicate any specific requirements related to security and privacy of a specific
                dataset, e.g. to indicate that data is not anonymized.`
      },
      technical_resource: {
        label: 'Technical resource',
        tooltip: `For specifying equipment needed/used to create or process the data, e.g. a microscope,
                etc.`
      },
      dataset_id: {
        identifier: {
          label: 'Dataset identifier',
          tooltip: 'Identifier for a dataset: Example: https://hdl.handle.net/11353/10.923628.'
        },
        type: {
          label: 'Dataset identifier type',
          tooltip: 'Identifier type. Example: handle.'
        }
      },
      keyword: {
        label: 'Dataset keywords',
        tooltip: ''
      },
      data_quality_assurance: {
        label: 'Data quality assurance',
        tooltip: ''
      },
      doiImport: {
        title: 'Fill from DOI',
        placeholder: 'DOI to import, e.g. 10.15156/BIO/3301224',
        overwriteWarning: 'This dataset\'s fields will be overwritten with DOI data'
      },
      citations: {
        label: 'Citations'
      },
      citationsCount: 'citation(s)'
    },
    datasetId: {
      type: {
        label: 'Type',
        tooltip: 'Identifier type.'
      },
      identifier: {
        label: 'Identifier',
        tooltip: 'Identifier for a dataset.'
      }
    },
    datasetTechnicalResource: {
      name: {
        label: 'Technical resource name',
        tooltip: (0, _helpers.alias)('dmp.dataset.technical_resource.tooltip')
      },
      description: {
        label: 'Technical resource description',
        tooltip: (0, _helpers.alias)('dmp.dataset.technical_resource.tooltip')
      }
    },
    datasetSecurityAndPrivacy: {
      title: {
        label: 'Security and privacy title',
        tooltip: (0, _helpers.alias)('dmp.dataset.security_and_privacy.tooltip')
      },
      description: {
        label: 'Security and privacy description',
        tooltip: (0, _helpers.alias)('dmp.dataset.security_and_privacy.tooltip')
      }
    },
    datasetMetadata: {
      description: {
        label: 'Metadata description',
        tooltip: ''
      },
      language: {
        label: 'Metadata language',
        tooltip: 'Language of the dataset metadata expressed using ISO 639-3.',
        placeholder: 'e.g. eng'
      }
    },
    datasetMetadataStandard: {
      identifier: {
        label: 'Metadata standard identifier',
        tooltip: 'Identifier for the metadata standard used.',
        placeholder: 'e.g. http://www.dublincore.org/specifications/dublin-core/dcmi-terms/'
      },
      type: {
        label: 'Metadata standard type',
        tooltip: 'Identifier type.'
      }
    },
    dmpID: {
      identifier: {
        label: 'Identifier',
        tooltip: 'Identifier for a DMP.'
      },
      type: {
        label: 'Identifier type',
        tooltip: ''
      }
    },
    datasetID: {
      identifier: {
        label: 'Identifier',
        tooltip: 'Identifier for a dataset.'
      },
      type: {
        label: 'Identifier type',
        tooltip: ''
      }
    },
    distribution: {
      title: {
        label: 'Title',
        tooltip: (0, _helpers.alias)('dmp.dataset.title.tooltip')
      },
      description: {
        label: 'Description',
        tooltip: (0, _helpers.alias)('dmp.dataset.description.tooltip')
      },
      access_url: {
        label: 'Access URL',
        tooltip: `A URL of the resource that gives access to a distribution of the dataset. e.g. landing
                page.`,
        placeholder: 'https://example.com/dataset'
      },
      available_until: {
        label: 'Available until',
        tooltip: 'Indicates how long this distribution will be/ should be available.'
      },
      byte_size: {
        label: 'Byte size',
        tooltip: ''
      },
      download_url: {
        label: 'Download URL',
        tooltip: 'The URL of the downloadable file in a given format. E.g. CSV file or RDF file.',
        placeholder: 'https://example.com/dataset/download'
      },
      format: {
        label: 'Format',
        tooltip: `Format according to: https://www.iana.org/assignments/media-types/media-types.xhtml if
                appropriate, otherwise use the common name for this format.`
      },
      data_access: {
        label: 'Data access',
        tooltip: 'Indicates access mode for data.'
      }
    },
    cost: {
      title: {
        label: 'Title',
        tooltip: 'E.g. storage and backup.'
      },
      description: {
        label: 'Description',
        tooltip: 'E.g. costs for maintaining...'
      },
      value: {
        label: 'Value',
        tooltip: ''
      },
      currency: {
        label: 'Currency',
        tooltip: 'Allowed values defined by ISO 4217.'
      }
    },
    host: {
      host: 'Host information',
      availability: {
        label: 'Availability',
        tooltip: 'Availability percentage.',
        placeholder: 'e.g. 95%'
      },
      backup_frequency: {
        label: 'Backup frequency',
        tooltip: '',
        placeholder: 'e.g. weekly'
      },
      backup_type: {
        label: 'Backup type',
        tooltip: '',
        placeholder: 'e.g. tapes'
      },
      geo_location: {
        label: 'Geo location',
        tooltip: 'Physical location of the data expressed using ISO 3166-1 country code.',
        placeholder: 'e.g. EST/GBR/USA'
      },
      storage_type: {
        label: 'Storage type',
        tooltip: 'The type of storage required.'
      },
      url: {
        label: 'URL',
        tooltip: 'The URL of the system hosting a distribution of a dataset.',
        placeholder: 'https://example.com/'
      },
      description: {
        label: 'Description',
        tooltip: ''
      },
      title: {
        label: 'Title',
        tooltip: ''
      },
      certified_with: {
        label: 'Certified with',
        tooltip: 'Repository certified to a recognised standard.'
      },
      pid_system: {
        label: 'PID system',
        tooltip: 'Persistent Identifier systems supported by the host.'
      },
      support_versioning: {
        label: 'Support versioning',
        tooltip: ''
      }
    },
    contact: {
      name: {
        label: 'Name',
        tooltip: ''
      },
      mbox: {
        label: 'E-mail',
        tooltip: 'Mail address.',
        placeholder: 'user@example.com'
      },
      identifier: {
        label: 'Identifier',
        tooltip: ''
      },
      identifierType: {
        label: 'Identifier type',
        tooltip: ''
      }
    },
    contributor: {
      name: {
        label: 'Name',
        tooltip: 'Name of the contributor.'
      },
      role: {
        label: 'Roles',
        tooltip: 'Comma-separeted list of type of contributor. Example: Data Steward, Project Manager.'
      },
      mbox: {
        label: 'E-mail',
        tooltip: 'E-mail address.',
        placeholder: 'user@example.com'
      },
      contributorID: {
        label: 'Contributor ID',
        tooltip: 'Identifier for a contact person. Example: http://orcid.org/0000-0000-0000-0000.'
      },
      contributorIDType: {
        label: 'Contributor ID type',
        tooltip: 'Identifier type. Choices: orcid, isni, openid, other.'
      }
    },
    panels: {
      contact: 'Contact',
      contributor: 'Contributors',
      cost: 'Costs',
      dataset: 'Dataset',
      distribution: 'Dataset Distribution',
      host: 'Dataset Distribution Host',
      general: 'General',
      project: 'Projects',
      funding: 'Project Funding',
      statistics: 'Dataset Statistics',
      datasetSummary: 'Datasets'
    },
    actions: {
      fillHost: {
        label: 'Autofill with PlutoF info',
        tooltip: 'Fill automatically based on PlutoF as the host.'
      }
    },
    chart: {
      datasetsTitle: 'Datasets',
      datasetsChart: 'Datasets by dataset type',
      citationsChart: 'Citations by dataset type',
      loadingCitationsChart: 'Loading citations...'
    }
  };
});