define("plutof/controllers/profile", ["exports", "@ember/controller", "@ember/object", "plutof/config/environment", "plutof/helpers/icon"], function (_exports, _controller, _object, _environment, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ProfileController = (_dec = (0, _object.computed)(), _dec2 = (0, _object.computed)('currentTab'), _class = class ProfileController extends _controller.default {
    get routes() {
      let routes = [{
        code: 'info',
        icon: 'icon-user',
        name: 'Settings.user'
      }, {
        code: 'defaults',
        icon: 'icon-defaults',
        name: 'Settings.defaultValues'
      }, {
        code: 'bookmarks',
        icon: (0, _icon.icon)('bookmark'),
        name: 'General.Bookmarks'
      }, {
        code: 'groups',
        icon: (0, _icon.icon)('person'),
        name: 'Settings.workgroups'
      }, {
        code: 'password',
        icon: 'fas fa-key',
        name: 'Settings.changePassword'
      }, {
        code: 'rights',
        icon: 'icon-user-rights',
        name: 'Settings.defaultAccess'
      }, {
        code: 'files',
        icon: (0, _icon.icon)('file'),
        name: 'Publishing.files'
      }, {
        code: 'classifications',
        icon: (0, _icon.icon)('taxonomy'),
        name: 'Taxonomy.classifications'
      }, {
        code: 'notifications',
        icon: 'fas fa-bell-slash',
        name: 'General.Notifications'
      }, {
        code: 'stats',
        icon: 'icon-stats',
        name: 'Settings.limits'
      }];
      if (_environment.default.ENABLE_SOCIAL_AUTH) {
        routes.push({
          code: 'identities',
          icon: 'icon-user',
          name: 'Settings.identities.title'
        });
      }
      return routes;
    }
    get tabs() {
      const currentTab = this.currentTab;
      return this.routes.map(_ref => {
        let {
          code,
          icon,
          name
        } = _ref;
        return {
          route: `profile.${code}`,
          icon: icon,
          name: name,
          isActive: code === currentTab
        };
      });
    }
    switchTab(tab) {
      if (tab !== this.currentTab) {
        this.set('currentTab', tab);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "routes", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "routes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tabs", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "tabs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchTab", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchTab"), _class.prototype), _class);
  var _default = _exports.default = ProfileController;
});