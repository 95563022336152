define("plutof/components/observation/moderation/forwarding-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Ui::Modal::Dialog
      @open={{@open}}
      @close={{this.cancel}}
  >
      <:header>
          {{i18n-t "moderation.selectModerators"}}
      </:header>
  
      <:body>
          <AutoComplete::BindMultiple @value={{this.moderators}} as |add|>
              <Agent::User::AutoComplete
                  @update={{add}}
                  @noDefault={{true}} />
          </AutoComplete::BindMultiple>
      </:body>
  
      <:footer>
          <button
              {{on "click" this.cancel}}
              class="btn btn-default"
          >
              {{i18n-t "General.Cancel"}}
          </button>
  
          <PromiseButton
              @clicked={{this.forward}}
              @disabled={{not this.moderators}}
              @restIcon={{icon "observation-moderation-forward"}}
              class="btn btn-default"
          >
              {{i18n-t "moderation.forward"}}
          </PromiseButton>
      </:footer>
  </Ui::Modal::Dialog>
  
  */
  {
    "id": "7q/YYbzL",
    "block": "[[[8,[39,0],null,[[\"@open\",\"@close\"],[[30,1],[30,0,[\"cancel\"]]]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"moderation.selectModerators\"],null]],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[8,[39,2],null,[[\"@value\"],[[30,0,[\"moderators\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,3],null,[[\"@update\",\"@noDefault\"],[[30,2],true]],null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,4],[\"click\",[30,0,[\"cancel\"]]],null],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"General.Cancel\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[39,5],[[24,0,\"btn btn-default\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\"],[[30,0,[\"forward\"]],[28,[37,6],[[30,0,[\"moderators\"]]],null],[28,[37,7],[\"observation-moderation-forward\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"moderation.forward\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@open\",\"add\"],false,[\"ui/modal/dialog\",\"i18n-t\",\"auto-complete/bind-multiple\",\"agent/user/auto-complete\",\"on\",\"promise-button\",\"not\",\"icon\"]]",
    "moduleName": "plutof/components/observation/moderation/forwarding-dialog.hbs",
    "isStrictMode": false
  });
  let ModerationForwardingDialog = (_class = class ModerationForwardingDialog extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "moderators", _descriptor, this);
    }
    cancel() {
      this.moderators = [];
      this.args.cancel();
    }
    async forward() {
      await this.args.forward(this.moderators);
      this.moderators = [];
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "moderators", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "forward", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "forward"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ModerationForwardingDialog);
});