define("plutof/components/fade-element", ["exports", "@ember/component", "@ember/runloop"], function (_exports, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FADE_INTERVAL = 20;
  function fadeOut(duration, node, onCompletion) {
    const tick = duration / FADE_INTERVAL;
    const chunk = 1 / tick;
    let opacity = 1;
    const timer = setInterval(() => {
      opacity -= chunk;
      if (opacity < chunk) {
        clearInterval(timer);
        onCompletion();
        return;
      }
      node.style.opacity = opacity;
    }, FADE_INTERVAL);
  }
  class FadeElement extends _component.default {
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      const component = this.element;
      const clone = component.cloneNode(true);
      clone.id = `${component.id}-clone`;
      const prev = component.previousElementSibling;
      const parent = component.parentElement;
      const fadeIns = clone.querySelectorAll('.plutof-fade-in');
      fadeIns.forEach(fade => fade.classList.remove('plutof-fade-in'));
      (0, _runloop.scheduleOnce)('afterRender', this, this._fadeOut, prev, clone, parent);
    }
    _fadeOut(prev, clone, parent) {
      if (prev) {
        prev.after(clone);
      } else if (parent) {
        parent.prepend(clone);
      }
      fadeOut(400, clone, () => clone.remove());
    }
  }
  var _default = _exports.default = FadeElement;
});