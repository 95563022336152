define("plutof/components/transaction/edit-shipments", ["exports", "@glimmer/component", "@ember/object", "@ember/object/computed", "rsvp", "plutof/utils/has-many", "plutof/utils/loading", "plutof/utils/cloning"], function (_exports, _component, _object, _computed, _rsvp, _hasMany, _loading, _cloning) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cloneTransactionShipmentsData = cloneTransactionShipmentsData;
  _exports.loadTransactionShipmentsData = _exports.default = _exports.createTransactionShipmentsData = void 0;
  var _dec, _class, _descriptor, _class2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TransactionShipmentsData = (_dec = (0, _computed.alias)('pagination.objects'), _class = class TransactionShipmentsData extends _object.default.extend(_hasMany.HasManyDataMixin) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "shipments", _descriptor, this);
    }
    _createEmpty() {
      return this.store.createRecord('transaction/shipment', {
        transaction: this.transaction
      });
    }
    save() {
      return super.save(...arguments).then(() => {
        return _rsvp.default.all(this.shipments.invoke('save'));
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "shipments", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  const {
    create: createTransactionShipmentsData,
    load: loadTransactionShipmentsData
  } = (0, _hasMany.hasManyDataConstructors)('transaction/shipment', TransactionShipmentsData, {
    parentField: 'transaction'
  });

  // Could partially define this in HasManyDataMixin, but that would require
  // quite a lot of factory parts (if model class is more complex than this),
  // negating possible benefits
  _exports.loadTransactionShipmentsData = loadTransactionShipmentsData;
  _exports.createTransactionShipmentsData = createTransactionShipmentsData;
  async function cloneTransactionShipmentsData(store, transaction, template) {
    const templateData = await loadTransactionShipmentsData(store, template);
    const templateItems = await (0, _loading.loadEntirePagination)(templateData.pagination);
    const items = await _rsvp.default.Promise.all(templateItems.map(async templateItem => {
      const item = await (0, _cloning.clone_record)(store, templateItem);
      item.set('transaction', transaction);
      return item;
    }));
    const data = await createTransactionShipmentsData(store, transaction);
    for (const item of items) {
      data.addExisting(item);
    }
    templateData.destroy();
    return data;
  }
  let EditShipments = (_class2 = class EditShipments extends _component.default {
    add() {
      this.args.data.add(1);
    }
    remove(shipment) {
      this.args.data.remove(shipment);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "add"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "remove"), _class2.prototype), _class2);
  var _default = _exports.default = EditShipments;
});