define("plutof/helpers/annotation/added-removed-class", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function (_ref) {
    let [record] = _ref;
    let {
      container = false
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (!record) {
      return '';
    }
    if (record.addedInAnnotation) {
      return 'annotated';
    }
    if (record.removedInAnnotation) {
      return container ? 'annotated-removed-container' : 'annotated-removed';
    }
    return '';
  });
});