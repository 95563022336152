define("plutof/components/search/query/active-filter", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/object/computed", "@ember/utils", "ember-data", "rsvp", "plutof/misc/abstract", "plutof/utils/notifications", "plutof/utils/search"], function (_exports, _component, _templateFactory, _object, _computed, _utils, _emberData, _rsvp, _abstract, _notifications, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{@filter.label}}
  
  {{#if this.hasMeasurements}}
      ({{this.processedMeasurements.representation}})
  {{else}}
      {{@filter.valueRepresentation}}
  {{/if}}
  
  {{! TODO: Button }}
  <span {{on "click" @clear}} class="icon-remove--red clickable"></span>
  &nbsp;
  
  */
  {
    "id": "NcWIoHIa",
    "block": "[[[1,[30,1,[\"label\"]]],[1,\"\\n\\n\"],[41,[30,0,[\"hasMeasurements\"]],[[[1,\"    (\"],[1,[30,0,[\"processedMeasurements\",\"representation\"]]],[1,\")\\n\"]],[]],[[[1,\"    \"],[1,[30,1,[\"valueRepresentation\"]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[11,1],[24,0,\"icon-remove--red clickable\"],[4,[38,1],[\"click\",[30,2]],null],[12],[13],[1,\"\\n \\n\"]],[\"@filter\",\"@clear\"],false,[\"if\",\"on\"]]",
    "moduleName": "plutof/components/search/query/active-filter.hbs",
    "isStrictMode": false
  });
  let ActiveFilterView = (_dec = (0, _object.computed)('filter.value'), _dec2 = (0, _computed.notEmpty)('filter.measurements'), _dec3 = (0, _object.computed)('filter.measurements', 'filter.value'), _dec4 = (0, _object.computed)('filter.value.[]'), _dec5 = (0, _object.computed)('filter.value', 'filter.config.choices'), _class = class ActiveFilterView extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "filter", null);
      _initializerDefineProperty(this, "hasMeasurements", _descriptor, this);
    }
    get isArray() {
      const value = this.get('filter.value');
      return !(0, _utils.isNone)(value) && (0, _utils.typeOf)(value) === 'array';
    }
    get processedMeasurements() {
      const measurements = this.filter.measurements ?? [];
      /**
       * Measurements filter's value is a string of commma-separated id pairs
       * joined by a colon. The first ID is the the ID of trait,
       * second ID is the ID of a selected choice.
       */
      const value = this.filter.value ?? '';
      return _emberData.default.PromiseObject.create({
        promise: _rsvp.default.all(value.split(';').map(async idPair => {
          const {
            id: measurementId,
            value: measurementValue,
            any: measurementAny,
            isParentAreaTrait: measurementParentArea
          } = (0, _search.decodeMeasurementValue)(idPair);
          const measurement = measurements.findBy('id', measurementId);
          if ((0, _utils.isNone)(measurement)) {
            return null;
          }
          let representation = measurementValue;
          if (!(0, _utils.isNone)(measurementAny)) {
            representation = this.i18n.t(measurementAny ? 'Search.anyValue' : 'Search.noValue');
          } else if (measurement.get('isSet')) {
            representation = await (measurement.elements ?? _abstract.EMPTY_PROMISE).then(function () {
              let elements = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
              const matching = (measurementValue || '').split(',').map(id => elements.findBy('id', id)).compact();
              return matching.mapBy('representation').join(', ');
            });
          }
          if ((0, _utils.isEmpty)(representation)) {
            return null;
          }
          const parentText = measurementParentArea ? `${this.i18n.t('Search.isParentAreaTrait')} ` : '';
          return `${parentText}${measurement.get('representation')} (${representation})`;
        })).then(response => {
          return {
            representation: response.compact().join(', ')
          };
        }).catch(_notifications.reportError)
      });
    }
    get arrayRepresentation() {
      const value = this.get('filter.value');
      if ((0, _utils.typeOf)(value === 'array')) {
        return value.map(element => {
          return element.representation ?? element;
        }).join(', ');
      } else {
        return '';
      }
    }
    get choiceRepresentation() {
      const choices = this.get('filter.config.choices');
      if ((0, _utils.isEmpty)(choices)) {
        return null;
      } else {
        const choice = choices.filter(choice => {
          return String(choice.value) === this.get('filter.value');
        }).shift();
        return (0, _utils.isEmpty)(choice) ? null : (0, _object.get)(choice, 'display_name');
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "isArray", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isArray"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "hasMeasurements", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "processedMeasurements", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "processedMeasurements"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "arrayRepresentation", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "arrayRepresentation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "choiceRepresentation", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "choiceRepresentation"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActiveFilterView);
});