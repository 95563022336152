define("plutof/components/common/record-list-view", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ol class="record-list-view-ng" ...attributes>
      {{#each this.entries as |entry|}}
          <li class="record-list-view-ng__entry">
              <span class="record-list-view-entry-content {{annotation/added-removed-class entry.record}}">
                  {{#if (has-block)}}
                      {{yield entry.record entry}}
                  {{else}}
                      {{! This is common enough that I grudgingly let it be default }}
                      {{entry.record.representation}}
                  {{/if}}
              </span>
  
              <span>
                  {{#if entry.last}}
                      {{@terminator}}
                  {{else}}
                      {{this.separator}}
                  {{/if}}
              </span>
          </li>
      {{/each}}
  </ol>
  
  */
  {
    "id": "ZY4NzwPd",
    "block": "[[[11,\"ol\"],[24,0,\"record-list-view-ng\"],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"entries\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[14,0,\"record-list-view-ng__entry\"],[12],[1,\"\\n            \"],[10,1],[15,0,[29,[\"record-list-view-entry-content \",[28,[37,2],[[30,2,[\"record\"]]],null]]]],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"                    \"],[18,4,[[30,2,[\"record\"]],[30,2]]],[1,\"\\n\"]],[]],[[[1,\"                    \"],[1,[30,2,[\"record\",\"representation\"]]],[1,\"\\n\"]],[]]],[1,\"            \"],[13],[1,\"\\n\\n            \"],[10,1],[12],[1,\"\\n\"],[41,[30,2,[\"last\"]],[[[1,\"                    \"],[1,[30,3]],[1,\"\\n\"]],[]],[[[1,\"                    \"],[1,[30,0,[\"separator\"]]],[1,\"\\n\"]],[]]],[1,\"            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"entry\",\"@terminator\",\"&default\"],false,[\"each\",\"-track-array\",\"annotation/added-removed-class\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "plutof/components/common/record-list-view.hbs",
    "isStrictMode": false
  });
  let RecordListView = (_dec = (0, _object.computed)('args.records.[]'), _class = class RecordListView extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "separator", ',');
      if (this.args.separator !== undefined) {
        this.separator = this.args.separator;
      }
    }
    get entries() {
      const records = this.args.records || [];
      const lastIndex = records.length - 1;
      return records.map((record, index) => ({
        record,
        last: index === lastIndex
      }));
    }
  }, _applyDecoratedDescriptor(_class.prototype, "entries", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "entries"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RecordListView);
});