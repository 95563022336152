define("plutof/components/experiment/utils", ["exports", "@ember/object", "@ember/utils", "rsvp", "plutof/misc/abstract", "plutof/misc/profile_settings", "plutof/utils/access", "plutof/utils/objects", "plutof/utils/pagination", "plutof/utils/reflection"], function (_exports, _object, _utils, _rsvp, _abstract, _profile_settings, _access, _objects, _pagination, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MATERIAL_SOURCE_TYPES = _exports.MATERIAL_SOURCE_KEYS = void 0;
  _exports.experimentPermissions = experimentPermissions;
  _exports.filterChangedMaterials = filterChangedMaterials;
  _exports.labwareTubeLabel = labwareTubeLabel;
  _exports.makeEmptyMaterials = makeEmptyMaterials;
  _exports.makeMaterialStrips = makeMaterialStrips;
  _exports.makeMaterialsWithOffset = makeMaterialsWithOffset;
  _exports.makeMaterialsWithSource = makeMaterialsWithSource;
  _exports.paginateExperiments = paginateExperiments;
  const LABWARE_COLS = 8;
  const LABWARE_COL_LABELS = 'ABCDEFGH';
  const MATERIAL_SOURCE_KEYS = _exports.MATERIAL_SOURCE_KEYS = ['specimen', 'living_specimen', 'material_sample', 'dna', 'pcr_product'];
  const MATERIAL_SOURCE_TYPES = _exports.MATERIAL_SOURCE_TYPES = [{
    display_name: 'Specimen',
    value: {
      name: 'specimen',
      model: 'taxonoccurrence/specimen/specimen',
      label: 'dna.specimen'
    }
  }, {
    display_name: 'Living specimen',
    value: {
      name: 'living_specimen',
      model: 'taxonoccurrence/livingculture/strain',
      label: 'dna.living_specimen'
    }
  }, {
    display_name: 'Material sample',
    value: {
      name: 'material_sample',
      model: 'taxonoccurrence/materialsample/materialsample',
      label: 'dna.material_sample'
    }
  }, {
    display_name: 'DNA',
    value: {
      name: 'dna',
      model: 'dna-lab/dna',
      label: 'pcr-product.dna'
    }
  }, {
    display_name: 'PCR product',
    value: {
      name: 'pcr_product',
      model: 'dna-lab/pcr-product',
      label: 'pcr-product.pcr_product'
    }
  }];
  function labwareTubeLabel(index) {
    let columnFirst = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const ordinal = index + 1;
    const cols = LABWARE_COLS;
    const row = `${Math.ceil(ordinal / cols)}`;
    const col = cols - (row * cols - index);
    const rowLabel = row.length === 1 ? `0${row}` : row;
    const colLabel = LABWARE_COL_LABELS.split('')[col];
    return columnFirst ? `${colLabel}${rowLabel}` : `${rowLabel} ${colLabel}`;
  }
  function extraTubeCount(count) {
    if (count % LABWARE_COLS) {
      const rows = Math.floor((count + LABWARE_COLS) / LABWARE_COLS);
      return rows * LABWARE_COLS - count;
    } else {
      return 0;
    }
  }
  function makeMaterialsWithOffset(_ref) {
    let {
      count,
      offset,
      model,
      properties,
      store
    } = _ref;
    return Array.from(Array(count).keys()).map(number => {
      const recordProperties = Object.assign(properties, {
        tube_nr: number + offset
      });
      return store.createRecord(model, recordProperties);
    });
  }
  async function makeMaterialsWithSource(_ref2) {
    let {
      model,
      properties,
      sourceKey,
      sourceIds,
      store
    } = _ref2;
    const materials = await sourceIds.map((id, index) => {
      const recordProperties = Object.assign(properties, {
        tube_nr: index
      });
      const material = store.createRecord(model, recordProperties);
      const sourceType = MATERIAL_SOURCE_TYPES.find(type => {
        return type.value.name === sourceKey;
      });
      return store.findRecord(sourceType.value.model, id).then(source => {
        (0, _object.set)(material, sourceKey, source);
        return material;
      });
    });
    const sourcelessMaterials = await makeMaterialsWithOffset({
      store: store,
      count: extraTubeCount(sourceIds.length),
      offset: sourceIds.length,
      model: model,
      properties: properties
    }).map(_abstract.wrap_as_promise);
    return _rsvp.default.all(materials.concat(sourcelessMaterials));
  }
  async function makeMaterialStrips(_ref3) {
    let {
      oldMaterials,
      newMaterials,
      materialProperties,
      model,
      store
    } = _ref3;
    const firstNewTubeNr = (0, _utils.isEmpty)(oldMaterials) ? 0 : Math.max(...oldMaterials.map(p => p.tube_nr)) + 1;
    newMaterials.forEach((material, index) => material.set('tube_nr', firstNewTubeNr + index));
    const materials = oldMaterials.concat(newMaterials);

    // Empty materials leading loaded materials.
    const leadingMaterials = await makeEmptyMaterials({
      materials: materials,
      model: model,
      properties: materialProperties,
      store: store
    });

    // Empty materials trailing loaded materials (to fill the strips of 8 materials).
    const trailingMaterials = await makeMaterialsWithOffset({
      count: extraTubeCount(materials.length + leadingMaterials.length),
      offset: firstNewTubeNr + newMaterials.length,
      model: model,
      properties: materialProperties,
      store: store
    });
    return materials.concat(leadingMaterials).concat(trailingMaterials);
  }
  function makeMaterialsFromNumbers(_ref4) {
    let {
      numbers,
      model,
      properties,
      store
    } = _ref4;
    return numbers.map(number => {
      const recordProperties = Object.assign(properties, {
        tube_nr: number
      });
      return store.createRecord(model, recordProperties);
    });
  }
  function getNewTubeNumbers(model, tubes) {
    if (tubes.length === 0) {
      return Array.from(LABWARE_COLS);
    }
    const usedTubeNumbers = tubes.map(tube => tube.tube_nr);
    const lastTubeNumber = Math.max.apply(null, usedTubeNumbers);
    const totalLabwares = Math.ceil(lastTubeNumber / LABWARE_COLS) || 1;
    const totalTubes = totalLabwares * LABWARE_COLS;
    const newTubeNumbers = Array.from(Array(totalTubes).keys()).filter(nr => {
      return !usedTubeNumbers.includes(nr);
    });
    return newTubeNumbers;
  }
  function makeEmptyMaterials(_ref5) {
    let {
      materials = [],
      model,
      properties,
      store
    } = _ref5;
    const newTubeNumbers = getNewTubeNumbers(model, materials);
    return makeMaterialsFromNumbers({
      numbers: newTubeNumbers,
      model: model,
      properties: properties,
      store: store
    });
  }
  async function paginateExperiments(store, model) {
    let filters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const user = await (0, _profile_settings.get_current_user)(store);
    const query = Object.assign({
      ordering: '-updated_at',
      created_by: user.id
    }, filters);
    return (0, _pagination.paginatePublishingModel)(store, model, query);
  }
  function experimentPermissions(store, record, parent) {
    return (0, _access.groupBasedPermissions)(store, record, {
      getManagingGroup: () => parent ? parent.managing_group : null
    });
  }
  function filterChangedMaterials(materials, initialMaterialState) {
    return materials.filter(material => {
      if (!material.id) {
        return true;
      }
      const initialState = initialMaterialState[material.id];
      const currentState = (0, _reflection.serializeRecord)(material);
      return !(0, _objects.deepEquality)(initialState, currentState);
    });
  }
});