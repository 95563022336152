define("plutof/controllers/datacite/add", ["exports", "@ember/service", "@ember/utils", "plutof/utils/controllers"], function (_exports, _service, _utils, _controllers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function updateDmpIdentifier(dmpId, identifier, store) {
    return store.findRecord('dmp/data-management-plan', dmpId).then(record => {
      record.set('dmp.dmp.dmp_id.identifier', identifier);
      record.set('dmp.dmp.dmp_id.type', 'doi');
      return record.save();
    });
  }
  let DOIAddController = (_class = class DOIAddController extends _controllers.AddController {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "listRoutes", _descriptor, this);
      // TODO: Is this still needed? If so, it should not be.
      _defineProperty(this, "routeHasBeenLoaded", null);
      // A list of filerepo IDs.
      _defineProperty(this, "queryParams", ['dmp_id', 'resource_file_ids']);
    }
    init() {
      super.init(...arguments);
      this.set('routeHasBeenLoaded', true);
    }
    async _save() {
      await this.model.doi.save();
      if (!(0, _utils.isNone)(this.dmp_id)) {
        await updateDmpIdentifier(this.dmp_id, this.model.doi.metadata.identifier, this.store);
      }
      this.listRoutes.reset('datacite/metadata');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "listRoutes", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = DOIAddController;
});