define("plutof/components/experiment/common/material-container", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember/object/computed", "@ember/service", "plutof/utils/structures"], function (_exports, _component, _object, _utils, _computed, _service, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // Map of material type to route, to which it can be forwarded.
  const FORWARD_ROUTE = {
    'dna': '/experiment/pcr',
    'pcr': '/experiment/sequencing'
  };
  const TARGET_AUTOCOMPLETE = {
    'dna': 'dna-lab/pcr',
    'pcr': 'dna-lab/sequencing'
  };
  let MaterialContainer = (_dec = (0, _computed.reads)('selection.length'), _dec2 = (0, _object.computed)('materialType'), _dec3 = (0, _object.computed)('labID'), _class = class MaterialContainer extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "selection", []);
      _defineProperty(this, "panelIsOpen", false);
      _initializerDefineProperty(this, "selectionSize", _descriptor2, this);
    }
    toggle(record, include) {
      if (include) {
        this.selection.pushObject(record);
      } else {
        this.selection.removeObject(record);
      }
    }
    toggleMultiple(rawMaterials) {
      const materials = rawMaterials.filter(material => material.hasSource);
      const selectedMaterials = materials.filter(material => this.selection.includes(material));
      const unselectedMaterials = (0, _structures.set_difference)(materials, selectedMaterials);
      if (selectedMaterials.length === materials.length) {
        this.selection.removeObjects(selectedMaterials);
      } else {
        this.selection.pushObjects(unselectedMaterials);
      }
    }
    forwardSelection(experimentID) {
      let route = FORWARD_ROUTE[this.materialType];
      route += (0, _utils.isPresent)(experimentID) ? `/edit/${experimentID}` : '/add';
      const orderedSelection = this.selection.slice();
      orderedSelection.sort((x, y) => x.tube_nr - y.tube_nr);
      this.forwardMaterials(route, orderedSelection.map(material => material.id));
    }
    toggleForwardingPanel() {
      this.set('panelIsOpen', !this.panelIsOpen);
    }
    get targetAutocompleteData() {
      return TARGET_AUTOCOMPLETE[this.materialType];
    }
    get materialQueryParams() {
      return {
        laboratory: this.labID
      };
    }
    forwardMaterials(route, ids) {
      let url = `${route}?materials=${ids.toString()}`;
      if ((0, _utils.isPresent)(this.labID)) {
        url += `&lab=${this.labID}`;
      }
      this.router.transitionTo(url);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectionSize", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMultiple", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMultiple"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "forwardSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "forwardSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleForwardingPanel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleForwardingPanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "targetAutocompleteData", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "targetAutocompleteData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "materialQueryParams", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "materialQueryParams"), _class.prototype), _class);
  var _default = _exports.default = MaterialContainer;
});