define("plutof/components/annotation/-summary/elixir", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "plutof/utils/annotation/elixir"], function (_exports, _component, _templateFactory, _component2, _elixir) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="spacer-large-below">
      {{common/local-label "annotation.elixir.changes.all"}}
  
      <Annotation::-Summary::Changes
          @changes={{@annotation.changes}}
          @containsPreviousValues={{true}} />
  </div>
  
  <div class="spacer-large"></div>
  
  <div class="spacer-large-below">
      {{common/local-label "annotation.elixir.changes.elixir"}}
  
      <Tables::List
          @records={{@annotation.elixirChanges}}
          as |Field change|
      >
          <Field @label={{i18n-t "annotation.elixir.fields.field"}}>
              {{change.field}}
          </Field>
  
          <Field @label={{i18n-t "annotation.elixir.fields.value"}}>
              {{change.value}}
          </Field>
  
          <Field @label={{component "common/local-label" "annotation.elixir.fields.assertion" infoLink="https://www.ebi.ac.uk/QuickGO/term/ECO:0000000"}}>
              {{get this.assertionLabels change.assertion}} ({{change.assertion}})
          </Field>
  
          <Field @label={{i18n-t "annotation.elixir.fields.assertionSource"}}>
              {{change.assertionSource}}
          </Field>
  
          <Field @label={{i18n-t "annotation.elixir.fields.comment"}}>
              {{change.comment}}
          </Field>
      </Tables::List>
  </div>
  
  */
  {
    "id": "B9STJOPS",
    "block": "[[[10,0],[14,0,\"spacer-large-below\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"annotation.elixir.changes.all\"],null]],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@changes\",\"@containsPreviousValues\"],[[30,1,[\"changes\"]],true]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-large\"],[12],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-large-below\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"annotation.elixir.changes.elixir\"],null]],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@records\"],[[30,1,[\"elixirChanges\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"annotation.elixir.fields.field\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"field\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"annotation.elixir.fields.value\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"value\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[50,\"common/local-label\",0,[\"annotation.elixir.fields.assertion\"],[[\"infoLink\"],[\"https://www.ebi.ac.uk/QuickGO/term/ECO:0000000\"]]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,5],[[30,0,[\"assertionLabels\"]],[30,3,[\"assertion\"]]],null]],[1,\" (\"],[1,[30,3,[\"assertion\"]]],[1,\")\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"annotation.elixir.fields.assertionSource\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"assertionSource\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"annotation.elixir.fields.comment\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"comment\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@annotation\",\"Field\",\"change\"],false,[\"common/local-label\",\"annotation/-summary/changes\",\"tables/list\",\"i18n-t\",\"component\",\"get\"]]",
    "moduleName": "plutof/components/annotation/-summary/elixir.hbs",
    "isStrictMode": false
  });
  const assertionLabels = function () {
    const labels = {};
    for (const {
      value,
      label
    } of _elixir.CDCH_ASSERTIONS) {
      labels[value] = label;
    }
    return labels;
  }();
  class ElixirAnnotationSummary extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "assertionLabels", assertionLabels);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ElixirAnnotationSummary);
});