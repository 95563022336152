define("plutof/utils/file-actions", ["exports", "@ember/object", "plutof/config/environment", "plutof/helpers/icon", "plutof/models/filerepository/file", "plutof/utils/filetypes", "plutof/utils/notifications", "plutof/utils/reflection"], function (_exports, _object, _environment, _icon, _file, _filetypes, _notifications, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getFileActions = getFileActions;
  // Keep in mind, that file can be not the ember-data model, but a partial thing that
  // backend sideloads into fileuploads. So, no file.extension etc.
  function extension() {
    for (var _len = arguments.length, variants = new Array(_len), _key = 0; _key < _len; _key++) {
      variants[_key] = arguments[_key];
    }
    return file => variants.includes((0, _filetypes.get_filename_extension)(file.name || file.file_name));
  }
  function type() {
    for (var _len2 = arguments.length, variants = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      variants[_key2] = arguments[_key2];
    }
    return file => variants.includes(file.type);
  }
  function createFromFile(file, container, route) {
    const router = (0, _reflection.getService)(container, 'router');
    return function () {
      return router.transitionTo(route, {
        queryParams: {
          file_ids: file.id
        }
      });
    };
  }
  const actions = [
  // Download FASTA as CSV
  {
    check: extension('fasta', 'fas', 'fa', 'fsa'),
    action(file, container) {
      return {
        icon: (0, _icon.icon)('csv'),
        title: 'FileRepository.FASTACSVButton',
        position: 'right',
        action() {
          const ajax = (0, _reflection.getService)(container, 'ajax');
          const i18n = (0, _reflection.getService)(container, 'i18n');
          const router = (0, _reflection.getService)(container, 'router');
          const url = `${_environment.default.API_HOST}/filerepository/files/csv/`;
          (0, _notifications.displayNotification)('status', i18n.translate('General.loading'));
          return ajax.request(url, {
            type: 'POST',
            processData: false,
            contentType: 'application/json; charset=UTF-8',
            data: JSON.stringify({
              file: file.url,
              header_delimiter: ';'
            })
          }).then(response => {
            const fileUrl = router.urlFor('filerepository.view', (0, _object.get)(response, 'id'));
            const message = `
                            ${i18n.translate('FileRepository.FASTACSVMessage')}
                            <a href="${fileUrl}">
                            ${i18n.translate('FileRepository.FASTACSVLink')}
                            </a>
                        `;
            (0, _notifications.displayHtmlNotification)('success', message, null, 12000);
          }).catch(_notifications.reportError);
        }
      };
    }
  }, {
    check: extension('geojson'),
    action(file, container) {
      return {
        icon: (0, _icon.icon)('area'),
        title: 'Sample.area.createFromGeoJSON',
        action() {
          const router = (0, _reflection.getService)(container, 'router');
          return router.transitionTo('area.add', {
            queryParams: {
              predefined: false,
              geo_file: file.id
            }
          });
        }
      };
    }
  }, {
    check: type(_file.FileType.Image),
    action(file, container) {
      return {
        icon: (0, _icon.icon)('observation'),
        title: 'Observations.newObservation',
        action: createFromFile(file, container, 'observation.add')
      };
    }
  }, {
    check: type(_file.FileType.Image),
    action(file, container) {
      return {
        icon: 'icon-photobank',
        title: 'Photobank.newObject',
        action: createFromFile(file, container, 'photobank.add')
      };
    }
  }, {
    check: extension('fasta', 'fsa', 'nexus', 'nex', 'nxs', 'gbk'),
    action: (file, container) => ({
      icon: (0, _icon.icon)('preview'),
      title: 'FileRepository.preview',
      action() {
        const router = (0, _reflection.getService)(container, 'router');
        return router.transitionTo('sequence.parse-file', file.id);
      }
    })
  }];
  function getFileActions(file, container) {
    return actions.filter(_ref => {
      let {
        check
      } = _ref;
      return check(file);
    }).map(_ref2 => {
      let {
        action
      } = _ref2;
      return action(file, container);
    });
  }
});