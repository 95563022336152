define("plutof/controllers/gis-lab/import", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "rsvp", "plutof/utils/notifications"], function (_exports, _controller, _object, _tracking, _rsvp, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let GisLabImportController = (_dec = (0, _object.computed)('areasData.@each.isValid'), _class = class GisLabImportController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "areasData", _descriptor, this);
    }
    updateAreas(areasData) {
      let clear = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (clear) {
        this.areasData.clear();
      }
      this.areasData.pushObjects(areasData);
    }
    get isValid() {
      const hasAreas = this.areasData.length > 0;
      const areValid = this.areasData.every(ad => ad.isValid);
      return hasAreas && areValid;
    }
    save() {
      (0, _notifications.clearNotifications)();
      (0, _notifications.displayNotification)('status', this.i18n.t('General.saving'));
      const areas = this.areasData.map(ad => ad.area);
      _rsvp.default.all(areas.invoke('save')).then(() => {
        (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
        this.areasData.clear();
        this.routeActions.goBack();
      }).catch(_notifications.reportError);
    }
    reset() {
      this.areasData.clear();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "areasData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateAreas", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateAreas"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _class);
  var _default = _exports.default = GisLabImportController;
});