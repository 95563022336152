define("plutof/components/map/view-map", ["exports", "@ember/utils", "ol/format/WKT", "plutof/components/map/base-map", "plutof/components/map/mixins/preferred-layerset", "ol/geom", "plutof/utils/map"], function (_exports, _utils, _WKT, _baseMap, _preferredLayerset, _geom, _map) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ViewMap extends _baseMap.default.extend(_preferredLayerset.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layoutName", 'components/map/base-map');
      _defineProperty(this, "singular", false);
      _defineProperty(this, "mutable", false);
      _defineProperty(this, "geomProperty", 'geom');
      _defineProperty(this, "modifyOnLocateMe", false);
      _defineProperty(this, "enabledControls", ['navigate', 'select', 'layerset']);
      _defineProperty(this, "defaultControl", 'select');
    }
    init() {
      super.init(...arguments);
      this.set('toolPanelOpen', false);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      const zoomToGeometry = this.get('attrs.zoomToGeometry.value');
      if (!(0, _utils.isNone)(zoomToGeometry)) {
        const wkt = new _WKT.default();
        const geometry = wkt.readGeometry(zoomToGeometry);
        const coords = (0, _map.getGeometryCenter)(geometry);
        const point = new _geom.Point(this.geoToMapCoords(coords));
        this.focusOn(point, false);
      }
    }
  }
  var _default = _exports.default = ViewMap;
});