define("plutof/controllers/observation/edit", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/utils", "rsvp", "ember-cp-validations", "plutof/misc/config", "plutof/mixins/component-validations", "plutof/mixins/edit-controller", "plutof/mixins/moderation", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _controller, _object, _computed, _utils, _rsvp, _emberCpValidations, _config, _componentValidations, _editController, _moderation, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getAreaEventTranslations = getAreaEventTranslations;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const COUNT_REGEXP = new RegExp('^(-1)$|^([0-9]{1,8})$');
  const i18n = (0, _i18n.getI18n)();
  function getAreaEventTranslations() {
    const obj = {
      'SamplingEvent': {
        gatheringAgents: 'Observations.observers',
        gatheringAgentsTooltip: 'Observations.observers',
        beginDate: 'Observations.beginDate',
        beginTime: 'Observations.beginTime',
        endDate: 'Observations.endDate',
        endTime: 'Observations.endTime'
      },
      'Sample': {
        samplingArea: 'Observations.samplingArea',
        existingSamplingArea: 'Observations.existingSamplingArea',
        existingSamplingAreaTooltip: 'Observations.existingSamplingAreaTooltip',
        parentSamplingArea: 'Observations.parentSamplingArea',
        parentSamplingAreaTooltip: 'Observations.parentSamplingAreaTooltip'
      },
      'General': {
        samplingEvent: 'Observations.samplingEvent',
        samplingAreaEvent: 'Observations.samplingAreaEvent'
      }
    };
    return obj;
  }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'countIsValid': (0, _emberCpValidations.validator)('is-truthy'),
    'observation.remarks': (0, _emberCpValidations.validator)('length', {
      max: 2048
    })
  });
  const Base = _controller.default.extend(_editController.default, Validations, _componentValidations.default, _moderation.default);
  let ObservationEditController = (_dec = (0, _computed.alias)('model.observation'), _dec2 = (0, _computed.equal)('observation.mainform.id', _config.default.Observation.BIRD_MAINFORM_ID), _dec3 = (0, _object.computed)('countIsRequired', 'observation.individual_count'), _class = class ObservationEditController extends Base {
    constructor() {
      super(...arguments);
      _defineProperty(this, "validationChildren", [{
        name: 'areaEvent',
        label: 'Observations.samplingAreaEvent'
      }, {
        name: 'substrate',
        label: 'General.substrate'
      }, {
        name: 'measurements',
        label: 'General.measurements'
      }, {
        name: 'associatedData',
        label: 'General.associatedData'
      }, {
        name: 'access',
        label: 'General.access'
      }, {
        name: 'interactions',
        label: 'Interaction.interaction'
      }, {
        name: 'determinations',
        label: 'General.identifications'
      }, {
        name: 'project',
        label: 'General.project'
      }]);
      _defineProperty(this, "recordsToUnload", [{
        record: 'observation',
        subRecords: ['substrate']
      }]);
      _initializerDefineProperty(this, "observation", _descriptor, this);
      _initializerDefineProperty(this, "countIsRequired", _descriptor2, this);
      _defineProperty(this, "areaEventTranslations", getAreaEventTranslations());
    }
    get countIsValid() {
      const count = this.get('observation.individual_count');
      return (0, _utils.isPresent)(count) ? COUNT_REGEXP.test(count) : !this.countIsRequired;
    }
    addAdditionalIdentifier(code, source) {
      const identifier = this.store.createRecord('taxonoccurrence/observation/additional-identifier', {
        observation: this.model.observation,
        code,
        source
      });
      this.model.additionalIdentifiers.pushObject(identifier);
    }
    removeAdditionalIdentifier(identifier) {
      this.model.additionalIdentifiers.removeObject(identifier);
      this.objectsToDelete.pushObject(identifier);
    }
    save() {
      const {
        relatedObjectsToDelete,
        objectsToDelete
      } = this.getProperties(['relatedObjectsToDelete', 'objectsToDelete']);
      const {
        observation,
        samplingAreaEventFormData,
        accessRights,
        itemData,
        observationData,
        substrateData,
        interactions
      } = this.model;
      const onSaveSuccess = () => {
        (0, _notifications.displayNotification)('success', i18n.t('General.done'));
        this.set('routeHasBeenLoaded', null);
        this.routeActions.goBack();
      };
      const saveObjects = () => {
        return samplingAreaEventFormData.save().then(() => {
          samplingAreaEventFormData.apply(observation);
          return observation.save().then(() => {
            return accessRights.saveWithTarget(observation).then(() => {
              const parts = _rsvp.default.all([this.model.determinations.save(), itemData.save(observation, accessRights), observationData.save(), interactions.save(), this.model.layers.save([observation]), _rsvp.default.all(this.model.additionalIdentifiers.invoke('save'))]);
              const relatedObjectsDeleted = _rsvp.default.all(relatedObjectsToDelete.invoke('destroyRecord'));
              const deleteObjects = relatedObjectsDeleted.then(() => {
                return _rsvp.default.all(objectsToDelete.invoke('destroyRecord'));
              });
              return _rsvp.default.all([parts, deleteObjects]);
            });
          });
        });
      };
      (0, _notifications.clearNotifications)();
      (0, _notifications.displayNotification)('status', i18n.t('General.saving'));
      return substrateData.save().then(saveObjects).then(onSaveSuccess).catch(_notifications.reportError);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "observation", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "countIsRequired", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "countIsValid", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "countIsValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addAdditionalIdentifier", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addAdditionalIdentifier"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAdditionalIdentifier", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeAdditionalIdentifier"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _class);
  var _default = _exports.default = ObservationEditController;
});