define("plutof/components/common/delete-options", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "plutof/utils/i18n"], function (_exports, _component, _templateFactory, _component2, _object, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <p>
      <span class="{{icon "info"}}"></span>
      {{i18n-t "navbar.delete.info"}}
  </p>
  
  <p>
      <Common::RadioGroup
          @active={{this.selectedDeleteMode}}
          @activate={{fn (mut this.selectedDeleteMode)}}
          @invalid={{@invalid}}
          as |radioGroup|
      >
          {{#each-in this.deleteChoices as |key choice|}}
              <radioGroup.choice
                  @name={{key}}
                  @clicked={{fn this.updateDeleteChoice choice}}
              >
                  {{choice.label}}
              </radioGroup.choice>
          {{/each-in}}
      </Common::RadioGroup>
  </p>
  
  */
  {
    "id": "NSWG4tWb",
    "block": "[[[10,2],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[[28,[37,0],[\"info\"],null]]]],[12],[13],[1,\"\\n    \"],[1,[28,[35,1],[\"navbar.delete.info\"],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,2],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@active\",\"@activate\",\"@invalid\"],[[30,0,[\"selectedDeleteMode\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"selectedDeleteMode\"]]],null]],null],[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,6],[[30,0,[\"deleteChoices\"]]],null],null,[[[1,\"            \"],[8,[30,2,[\"choice\"]],null,[[\"@name\",\"@clicked\"],[[30,4],[28,[37,3],[[30,0,[\"updateDeleteChoice\"]],[30,3]],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"label\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[3,4]],null],[1,\"    \"]],[2]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@invalid\",\"radioGroup\",\"choice\",\"key\"],false,[\"icon\",\"i18n-t\",\"common/radio-group\",\"fn\",\"mut\",\"each\",\"-each-in\"]]",
    "moduleName": "plutof/components/common/delete-options.hbs",
    "isStrictMode": false
  });
  const i18n = (0, _i18n.getI18n)();
  const DELETE_CHOICES = {
    area: {
      label: i18n.t('navbar.delete.choices.area'),
      remove: {
        remove_areas: true,
        remove_events: true
      }
    },
    event: {
      label: i18n.t('navbar.delete.choices.event'),
      remove: {
        remove_events: true
      }
    },
    record: {
      label: i18n.t('navbar.delete.choices.record'),
      remove: {}
    }
  };
  let DeleteOptions = (_class = class DeleteOptions extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "deleteChoices", DELETE_CHOICES);
      _defineProperty(this, "selectedDeleteMode", null);
    }
    updateDeleteChoice(choice) {
      this.args.changed(choice.remove);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "updateDeleteChoice", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateDeleteChoice"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DeleteOptions);
});