define("plutof/routes/glossary/add", ["exports", "rsvp", "plutof/utils/access", "plutof/utils/routes"], function (_exports, _rsvp, _access, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class GlossaryAddRoute extends _routes.AddRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "defaultReturnRoute", 'glossary.index');
      _defineProperty(this, "title", 'Glossary.newGlossary');
    }
    model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const glossary = this.store.createRecord('glossary/glossary');
      return _rsvp.default.hash({
        accessRights: (0, _access.create_access_rights)(this.store, glossary),
        glossary: glossary,
        thesauri: []
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        thesauriMarkedForDeletion: []
      });
    }
  }
  var _default = _exports.default = GlossaryAddRoute;
});