define("plutof/helpers/relation-id", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // NB: Isn't reactive on record.set(relation, ...)
  var _default = _exports.default = (0, _helper.helper)(function relationID(_ref) {
    let [record, relation] = _ref;
    return record ? record.belongsTo(relation).id() : null;
  });
});