define("plutof/components/study/edit", ["exports", "@ember/component", "@ember/template-factory", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Study::GeneralData
      @project={{@project}}
      @projectPersons={{@projectPersons}}
      @addPerson={{@addPerson}}
      @removePerson={{@removePerson}}
      @useNewLogo={{@useNewLogo}}
      @validationContext={{@validationContext.project}} />
  
  {{#if @workgroupData}}
      <Agent::OrganizationWorkgroup
          @workgroupData={{@workgroupData}}
          @validationContext={{@validationContext.managingGroup}} />
  {{/if}}
  
  <Study::ProjectAreas
      @projectAreas={{@projectAreas}}
      @add={{@addProjectArea}}
      @remove={{@removeProjectArea}} />
  
  <Identifiers::Edit
      @model={{@identifiers}}
      @workgroupData={{@workgroupData}}
      @allowReservingForAnyone={{@project.public_linking}} />
  
  <LinkedItems::Edit
      @data={{@associatedData}}
      @validationContext={{@validationContext.associatedData}} />
  
  <Study::Access
      @rights={{@accessRights}}
      @project={{@project}}
      @warnAboutRecordPermissions={{@warnAboutPermissionChanges}} />
  
  */
  {
    "id": "PlMISB84",
    "block": "[[[8,[39,0],null,[[\"@project\",\"@projectPersons\",\"@addPerson\",\"@removePerson\",\"@useNewLogo\",\"@validationContext\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6,[\"project\"]]]],null],[1,\"\\n\\n\"],[41,[30,7],[[[1,\"    \"],[8,[39,2],null,[[\"@workgroupData\",\"@validationContext\"],[[30,7],[30,6,[\"managingGroup\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,3],null,[[\"@projectAreas\",\"@add\",\"@remove\"],[[30,8],[30,9],[30,10]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@model\",\"@workgroupData\",\"@allowReservingForAnyone\"],[[30,11],[30,7],[30,1,[\"public_linking\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@data\",\"@validationContext\"],[[30,12],[30,6,[\"associatedData\"]]]],null],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@rights\",\"@project\",\"@warnAboutRecordPermissions\"],[[30,13],[30,1],[30,14]]],null],[1,\"\\n\"]],[\"@project\",\"@projectPersons\",\"@addPerson\",\"@removePerson\",\"@useNewLogo\",\"@validationContext\",\"@workgroupData\",\"@projectAreas\",\"@addProjectArea\",\"@removeProjectArea\",\"@identifiers\",\"@associatedData\",\"@accessRights\",\"@warnAboutPermissionChanges\"],false,[\"study/general-data\",\"if\",\"agent/organization-workgroup\",\"study/project-areas\",\"identifiers/edit\",\"linked-items/edit\",\"study/access\"]]",
    "moduleName": "plutof/components/study/edit.hbs",
    "isStrictMode": false
  });
  class StudyEdit extends _component.default.extend(_componentValidations.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "validationChildren", [{
        name: 'project',
        label: 'General.generalData'
      }, {
        name: 'access',
        label: 'General.access'
      }, {
        name: 'associatedData',
        label: 'General.associatedData'
      }, {
        name: 'managingGroup',
        label: 'group.panels.workgroup'
      }]);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StudyEdit);
});