define("plutof/helpers/icon", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.icon = icon;
  const ICONS = {
    'access-whitelist': 'icon-access-rights',
    'add-new': 'icon-add-new',
    analysis: 'icon-analyses-lab',
    annotation: 'fas fa-highlighter',
    area: 'icon-area',
    back: 'fas fa-arrow-left',
    bookmark: 'icon-bookmark',
    cancel: 'icon-remove icon-remove--black',
    'check-double': 'fas fa-check-double',
    clipboard: 'fas fa-clipboard',
    'clipboard-add': 'icon-clipboard-add',
    'clipboard-add-all': 'icon-all-to-clipboard',
    'clipboard-remove': 'icon-clipboard-remove',
    clone: 'fas fa-copy',
    close: 'icon-remove',
    collection: 'fas fa-archive',
    comment: 'far fa-comment',
    'condition-assessment': 'icon-condition-assessment',
    conservation: 'fas fa-seedling',
    copy: 'fas fa-copy',
    csv: 'icon-file-csv',
    ena: 'icon-genbank svg-icon',
    datacite: 'icon-datacite',
    delete: 'icon-trash',
    detach: 'fas fa-unlink',
    dmp: 'fas fa-tasks',
    download: 'icon-download',
    edit: 'fas fa-edit',
    event: 'icon-event',
    experiment: 'icon-experiment',
    'external-link': 'fas fa-external-link-alt',
    file: 'icon-file',
    'file-dataset': 'icon-file-data',
    'file-sound': 'icon-file-sound',
    'file-text': 'icon-file-text',
    'file-application': 'icon-file-software',
    'file-image': 'icon-file-image',
    'file-video': 'icon-file-video',
    'file-3d': 'icon-file-3d',
    form: 'icon-mainform',
    'gis-lab': 'icon-gis-lab',
    gbif: 'icon-gbif-leaf',
    glossary: 'icon-glossary',
    'glossary-collection': 'icon-glossary-collection',
    import: 'icon-import',
    info: 'icon-info',
    laboratory: 'fas fa-flask',
    livingspecimen: 'icon-livingculture',
    loading: 'loading-icon',
    'map-marker': 'fas fa-map-marker-alt',
    materialsample: 'icon-materialsample',
    'menu-toggle': 'fas fa-bars',
    moderate: 'fas fa-gavel',
    'molecular-lab': 'icon-molecular-lab',
    observation: 'icon-observation',
    'observation-moderation-pending': 'icon-moderation-status-pending',
    'observation-moderation-rejected': 'icon-remove',
    'observation-moderation-forward': 'fas fa-share',
    ok: 'fas fa-check',
    'open-in-new-tab': 'icon-open-new',
    organization: 'icon-organization',
    permit: 'fas fa-file-signature',
    person: 'icon-person',
    preview: 'fas fa-eye',
    project: 'icon-project',
    photobank: 'icon-photobank',
    private: 'icon-access-private plutof-red-light',
    public: 'icon-access-public',
    'publishing-lab': 'icon-publishing-lab',
    refbased: 'icon-referencebased',
    reference: 'icon-reference',
    remove: 'icon-remove icon-remove--red',
    // XXX
    reset: 'icon-reset',
    restricted: 'icon-restricted',
    search: 'icon-search',
    settings: 'fas fa-sliders-h',
    sequence: 'icon-sequence',
    sequencing: 'icon-sequencing',
    share: 'fas fa-share',
    specimen: 'icon-specimen',
    'taxon-description': 'icon-taxon-description',
    'taxon-node': 'fas fa-tag',
    'taxon-occurrence': 'fas fa-th-large',
    taxonomy: 'fas fa-sitemap',
    trait: 'fas fa-ruler-combined',
    transaction: 'icon-transaction',
    treatment: 'icon-treatment',
    'unite-compound-cluster': 'icon-compound-cluster',
    'unite-hypothesis': 'icon-unite-hypotheses',
    'unite-sh-cluster': 'fas fa-sort-amount-down',
    'unite-statistics': 'fas fa-chart-bar',
    'unite-taxonomy': 'icon-sh-taxonomy',
    upload: 'icon-upload',
    'use-as-template': 'fas fa-link',
    user: 'icon-user',
    expand: 'fa fa-expand',
    compress: 'fa fa-compress'
  };
  function icon(name) {
    return ICONS[name];
  }
  var _default = _exports.default = (0, _helper.helper)(function (_ref) {
    let [name] = _ref;
    return icon(name);
  });
});