define("plutof/routes/experiment/dna-extraction/view", ["exports", "plutof/components/experiment/utils", "plutof/utils/routes"], function (_exports, _utils, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class DNAExtractionViewRoute extends _routes.ViewRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "defaultReturnRoute", 'experiment.dna-extraction.index');
      _defineProperty(this, "title", 'experiment.extraction.routeTitles.view');
    }
    async model(params) {
      const experiment = await this.store.findRecord('dna-lab/dna-extraction', params.id);
      const permissions = await (0, _utils.experimentPermissions)(this.store, experiment, await experiment.laboratory);
      const dnas = await this.store.query('dna-lab/dna', {
        dna_extraction: params.id,
        include: 'specimen,material_sample,living_specimen'
      }).then(dnas => {
        const emptyTubes = (0, _utils.makeEmptyMaterials)({
          store: this.store,
          model: 'dna-lab/dna',
          materials: dnas,
          properties: {
            dna_extraction: experiment
          }
        });
        return dnas.toArray().concat(emptyTubes);
      });
      return {
        experiment,
        dnas,
        permissions
      };
    }
  }
  var _default = _exports.default = DNAExtractionViewRoute;
});