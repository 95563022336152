define("plutof/components/unite/node-sh-list", ["exports", "@ember/component", "@ember/object", "@ember/utils", "plutof/utils/pagination"], function (_exports, _component, _object, _utils, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let NodeSHList = (_dec = (0, _object.computed)('taxon', 'level', 'parentTaxon', 'parentLevel', 'includeSingletons', 'threshold', 'version'), _dec2 = (0, _object.computed)('query', 'modelPath'), _class = class NodeSHList extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "level", null);
      _defineProperty(this, "taxon", null);
      _defineProperty(this, "parentTaxon", null);
      _defineProperty(this, "modelPath", 'globalkey/shtaxonomytree');
      _defineProperty(this, "prefixer", 'dsh_');
      _defineProperty(this, "pageSize", 50);
      _defineProperty(this, "pageNumber", 1);
    }
    get query() {
      const query = {};
      if ((0, _utils.isNone)(this.taxon)) {
        query[`is_${this.level}_null`] = true;
        if (!(0, _utils.isNone)(this.parentTaxon)) {
          query[this.prefixer + this.parentLevel] = this.parentTaxon.id;
        }
      } else {
        query[this.prefixer + this.level] = this.taxon.id;
      }
      if (!(0, _utils.isNone)(this.includeSingletons)) {
        query.is_singleton = this.includeSingletons;
      }
      if (!(0, _utils.isNone)(this.threshold)) {
        query.threshold = this.threshold.id;
      }
      if (!(0, _utils.isNone)(this.version)) {
        query.version = this.version.id;
      }
      query.page_size = this.pageSize;
      return query;
    }
    get listPagination() {
      return (0, _pagination.paginateModel)(this.store, this.modelPath, this.query);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "query", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "query"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "listPagination", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "listPagination"), _class.prototype), _class);
  var _default = _exports.default = NodeSHList;
});