define("plutof/routes/profile/files", ["exports", "rsvp", "plutof/misc/profile_settings", "plutof/utils/routes"], function (_exports, _rsvp, _profile_settings, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ProfileFilesRoute extends _routes.Route {
    constructor() {
      super(...arguments);
      _defineProperty(this, "title", 'Publishing.files');
    }
    model(params) {
      const store = this.store;
      return _rsvp.default.hash({
        profile: (0, _profile_settings.get_personal_settings)(store),
        creators: store.findAll('profile/personalsettings/personalsettingscreator').then(creators => {
          return creators.toArray();
        })
      });
    }
    setupController(controller, model) {
      super.setupController(...arguments);
      this.controllerFor('profile').set('currentTab', 'files');
      controller.setProperties({
        removedCreators: []
      });
    }
  }
  var _default = _exports.default = ProfileFilesRoute;
});