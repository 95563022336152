define("plutof/components/dmp/contact", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object/computed", "plutof/utils/validations"], function (_exports, _component, _templateFactory, _component2, _computed, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    
  <PlutofPanel::Simple @title={{i18n-t "dmp.panels.contact"}}>
      <Layout::RegularGrid @columns={{2}}>
          <GuidedForm @path="dmp.contact.name" class={{unless this.nameIsValid "has-error"}}>
              <Input @type="text" @value={{@contact.name}} class="form-control" />
          </GuidedForm>
  
          <GuidedForm @path="dmp.contact.mbox" class={{unless this.mboxIsValid "has-error"}}>
              <Input @type="text" @value={{@contact.mbox}} class="form-control" />
          </GuidedForm>
  
          <GuidedForm @path="dmp.contact.identifier" class={{unless this.identifierValid "has-error"}}>
              <Input @type="text" @value={{@contact.contact_id.identifier}} class="form-control" />
          </GuidedForm>
  
          <GuidedForm @path="dmp.contact.identifierType">
              <PlutofSelect::Direct
                  @content={{array "orcid" "isni" "openid" "other"}}
                  @value={{@contact.contact_id.type}}
                  @prompt={{null}} />
          </GuidedForm>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "AIXBy+eC",
    "block": "[[[1,\"\\n\"],[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"dmp.panels.contact\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],[[16,0,[52,[51,[30,0,[\"nameIsValid\"]]],\"has-error\"]]],[[\"@path\"],[\"dmp.contact.name\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],[[24,0,\"form-control\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"name\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],[[16,0,[52,[51,[30,0,[\"mboxIsValid\"]]],\"has-error\"]]],[[\"@path\"],[\"dmp.contact.mbox\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],[[24,0,\"form-control\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"mbox\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],[[16,0,[52,[51,[30,0,[\"identifierValid\"]]],\"has-error\"]]],[[\"@path\"],[\"dmp.contact.identifier\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],[[24,0,\"form-control\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"contact_id\",\"identifier\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.contact.identifierType\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],null,[[\"@content\",\"@value\",\"@prompt\"],[[28,[37,7],[\"orcid\",\"isni\",\"openid\",\"other\"],null],[30,1,[\"contact_id\",\"type\"]],null]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@contact\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"guided-form\",\"unless\",\"input\",\"plutof-select/direct\",\"array\"]]",
    "moduleName": "plutof/components/dmp/contact.hbs",
    "isStrictMode": false
  });
  let DmpContact = _exports.default = (_dec = (0, _computed.notEmpty)('args.contact.name'), _dec2 = (0, _computed.notEmpty)('args.contact.mbox'), _dec3 = (0, _computed.notEmpty)('args.contact.contact_id.identifier'), _class = class DmpContact extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "nameIsValid", _descriptor, this);
      _initializerDefineProperty(this, "mboxIsValid", _descriptor2, this);
      _initializerDefineProperty(this, "identifierValid", _descriptor3, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "nameIsValid", [_validations.validator, _dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mboxIsValid", [_validations.validator, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "identifierValid", [_validations.validator, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DmpContact);
});