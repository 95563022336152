define("plutof/components/observation/moderation/forwarded", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "ol/extent"], function (_exports, _component, _templateFactory, _component2, _object, _service, _extent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.forwarded as |forwardedTo|}}
      {{#if forwardedTo.names}}
          <ToolTip @tooltip={{i18n-t "moderation.forwarding.forwardedTo" moderators=forwardedTo.names}}>
              <span class={{icon "observation-moderation-forward"}}></span>
          </ToolTip>
  
          {{#if forwardedTo.self}}
              <ToolTip @tooltip={{i18n-t "moderation.forwarding.forwardedRecords.tooltip"}}>
                  <span class="{{icon 'user'}} "></span>
              </ToolTip>
          {{/if}}
      {{/if}}
  {{/resolve-promise}}
  
  */
  {
    "id": "O94kxiln",
    "block": "[[[6,[39,0],[[30,0,[\"forwarded\"]]],null,[[\"default\"],[[[[41,[30,1,[\"names\"]],[[[1,\"        \"],[8,[39,2],null,[[\"@tooltip\"],[[28,[37,3],[\"moderation.forwarding.forwardedTo\"],[[\"moderators\"],[[30,1,[\"names\"]]]]]]],[[\"default\"],[[[[1,\"\\n            \"],[10,1],[15,0,[28,[37,4],[\"observation-moderation-forward\"],null]],[12],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,1,[\"self\"]],[[[1,\"            \"],[8,[39,2],null,[[\"@tooltip\"],[[28,[37,3],[\"moderation.forwarding.forwardedRecords.tooltip\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[10,1],[15,0,[29,[[28,[37,4],[\"user\"],null],\" \"]]],[12],[13],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]],null]],[1]]]]]],[\"forwardedTo\"],false,[\"resolve-promise\",\"if\",\"tool-tip\",\"i18n-t\",\"icon\"]]",
    "moduleName": "plutof/components/observation/moderation/forwarded.hbs",
    "isStrictMode": false
  });
  let ObservationModerationForwarded = (_dec = (0, _object.computed)('args.record.forwarded.[]'), _class = class ObservationModerationForwarded extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "settings", _descriptor, this);
    }
    get forwarded() {
      return this.settings.getUser().then(user => {
        const forwarded = this.args.record.forwarded;
        if ((0, _extent.isEmpty)(forwarded)) {
          return;
        }
        const ids = forwarded.map(user => user.id);
        const names = forwarded.map(user => user.name);
        return {
          self: ids.includes(parseInt(user.id)),
          names: names.join(', ')
        };
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "forwarded", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "forwarded"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ObservationModerationForwarded);
});