define("plutof/components/transaction/view/objects/tabs", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::ItemList
      data-test="Transaction::View::Objects::Tabs"
      as |panel|
  >
      <panel.header @title={{i18n-t "transactions.items"}}>
          {{#if @setEditMode}}
              <panel.button
                  @clicked={{@setEditMode}}
                  @disabled={{@transaction.locked_for_update}}
              >
                  {{i18n-t "General.Edit"}}
              </panel.button>
          {{/if}}
      </panel.header>
  
      <panel.content>
          <TabGroup @tabSwitched={{this.tabSwitched}}>
              <:tabs as |tabGroup|>
                  {{#let
                      (component "related-objects/-tabs/transaction-objects"
                          tabGroup=tabGroup
                          loaded=(fn this.loaded tabGroup.group))
                      as |transactionObjects|
                  }}
                      {{yield (hash
                          objects=(component transactionObjects
                              name="objects"
                              filter="name"
                              transaction=@transaction
                              accessRights=@accessRights
                              disableControls=@disableControls
                              enableClipboard=true
                              title=(i18n-t "transactions.objects.tabs.databased")
                              content=(component "transaction/view/objects/list" canOrder=true ))
                          items=(component transactionObjects
                              name="items"
                              transaction=@transaction
                              accessRights=@accessRights
                              disableControls=@disableControls
                              title=(i18n-t "transactions.objects.tabs.notDatabased")
                              content=(component "transaction/view/objects/list"))
                      )}}
                  {{/let}}
              </:tabs>
          </TabGroup>
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "WJ9gSs5V",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Transaction::View::Objects::Tabs\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,[[\"@title\"],[[28,[37,1],[\"transactions.items\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,2],[[[1,\"            \"],[8,[30,1,[\"button\"]],null,[[\"@clicked\",\"@disabled\"],[[30,2],[30,3,[\"locked_for_update\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,1],[\"General.Edit\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@tabSwitched\"],[[30,0,[\"tabSwitched\"]]]],[[\"tabs\"],[[[[1,\"\\n\"],[44,[[50,\"related-objects/-tabs/transaction-objects\",0,null,[[\"tabGroup\",\"loaded\"],[[30,4],[28,[37,6],[[30,0,[\"loaded\"]],[30,4,[\"group\"]]],null]]]]],[[[1,\"                    \"],[18,8,[[28,[37,8],null,[[\"objects\",\"items\"],[[50,[30,5],0,null,[[\"name\",\"filter\",\"transaction\",\"accessRights\",\"disableControls\",\"enableClipboard\",\"title\",\"content\"],[\"objects\",\"name\",[30,3],[30,6],[30,7],true,[28,[37,1],[\"transactions.objects.tabs.databased\"],null],[50,\"transaction/view/objects/list\",0,null,[[\"canOrder\"],[true]]]]]],[50,[30,5],0,null,[[\"name\",\"transaction\",\"accessRights\",\"disableControls\",\"title\",\"content\"],[\"items\",[30,3],[30,6],[30,7],[28,[37,1],[\"transactions.objects.tabs.notDatabased\"],null],[50,\"transaction/view/objects/list\",0,null,null]]]]]]]]],[1,\"\\n\"]],[5]]],[1,\"            \"]],[4]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"panel\",\"@setEditMode\",\"@transaction\",\"tabGroup\",\"transactionObjects\",\"@accessRights\",\"@disableControls\",\"&default\"],false,[\"plutof-panel/item-list\",\"i18n-t\",\"if\",\"tab-group\",\"let\",\"component\",\"fn\",\"yield\",\"hash\"]]",
    "moduleName": "plutof/components/transaction/view/objects/tabs.hbs",
    "isStrictMode": false
  });
  // Dirty hacks to switch to the first non-empty tab. Doing it the proper way means that
  // at either this component should setup all the paginations or they must be passed from outside.
  // Both of those are messy as well, so this will do for now.
  let TransactionViewObjectsTabs = _exports.default = (_class = class TransactionViewObjectsTabs extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "initialTabSet", false);
    }
    loaded(tabGroup, name, pagination) {
      if (this.initialTabSet) {
        return;
      }
      if (pagination.objectCount > 0) {
        tabGroup.switchTab(tabGroup.getTab(name));
        this.initialTabSet = true;
      }
    }
    tabSwitched() {
      this.initialTabSet = true;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "loaded", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loaded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tabSwitched", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "tabSwitched"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TransactionViewObjectsTabs);
});