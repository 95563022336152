define("plutof/components/auto-complete/generic", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (component this.implementation
      value=@value
      update=@update
      query=@query
      updateQuery=@updateQuery
      controller=@controller
      disabled=@disabled
      sideActionDisabled=@sideActionDisabled
      params=@params)
      as |implementation|
  }}
      {{!--
          XXX: @model is used only for default (Ac::Model), maybe should just wrap this
          in if block instead of passing @model to everything? Would have
          to specify passthrough component attrs twice though
      --}}
      <implementation @model={{@model}} @filters={{@filters}} ...attributes />
  {{/let}}
  
  */
  {
    "id": "9mr5sy1M",
    "block": "[[[44,[[50,[30,0,[\"implementation\"]],0,null,[[\"value\",\"update\",\"query\",\"updateQuery\",\"controller\",\"disabled\",\"sideActionDisabled\",\"params\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]]]],[[[1,\"    \"],[8,[30,9],[[17,10]],[[\"@model\",\"@filters\"],[[30,11],[30,12]]],null],[1,\"\\n\"]],[9]]]],[\"@value\",\"@update\",\"@query\",\"@updateQuery\",\"@controller\",\"@disabled\",\"@sideActionDisabled\",\"@params\",\"implementation\",\"&attrs\",\"@model\",\"@filters\"],false,[\"let\",\"component\"]]",
    "moduleName": "plutof/components/auto-complete/generic.hbs",
    "isStrictMode": false
  });
  const IMPLEMENTATIONS = {
    'agent/agent': 'agent/auto-complete',
    'agent/collection': 'collection/auto-complete',
    'agent/organization': 'agent/organization/auto-complete',
    'agent/person': 'agent/person/auto-complete',
    'determination/cultivargroup': 'determination/cultivar-group/auto-complete',
    'filerepository/file': 'filerepository/auto-complete',
    'glossary/glossary': 'glossary/auto-complete',
    'permit/permit': 'collection-lab/permit/autocomplete',
    'reference/reference': 'reference/auto-complete',
    'sample/samplingarea': 'sample/area/auto-complete',
    'study/study': 'project/auto-complete',
    'taxonoccurrence/livingculture/strain': 'livingspecimen/auto-complete',
    'taxonoccurrence/specimen/specimen': 'specimen/auto-complete',
    'taxonomy/commonname': 'taxonomy/common-names/auto-complete',
    'taxonomy/taxonnode': 'taxonomy/taxon/auto-complete',
    'taxonomy/taxonrank': 'taxonomy/taxon/rank-auto-complete',
    'transaction/transaction': 'transaction/auto-complete',
    'users/user': 'agent/user/auto-complete',
    'users/workgroup': 'profile/groups/auto-complete'
  };

  // TODO? Rename to AutoComplete::Proxy
  let GenericAutocomplete = _exports.default = (_dec = (0, _object.computed)('args.model'), _class = class GenericAutocomplete extends _component2.default {
    get implementation() {
      // TODO: Add existing model-specific implementations
      return IMPLEMENTATIONS[this.args.model] || 'auto-complete/model';
    }
  }, _applyDecoratedDescriptor(_class.prototype, "implementation", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "implementation"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GenericAutocomplete);
});