define("plutof/helpers/html-unsafe", ["exports", "@ember/component/helper", "@ember/template"], function (_exports, _helper, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // NB: Better be 100% sure that this is sanitized. NO user-provided data
  var _default = _exports.default = (0, _helper.helper)(function (_ref) {
    let [html] = _ref;
    return (0, _template.htmlSafe)(html);
  });
});