define("plutof/components/taxonomy/taxon/rank-auto-complete", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/components/auto-complete/backends", "plutof/utils/caching", "plutof/utils/store", "plutof/misc/config"], function (_exports, _component, _templateFactory, _component2, _object, _service, _backends, _caching, _store, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.backend wait=true as |backend|}}
      <AutoComplete::Base
          @backend={{backend}}
          @value={{@value}}
          @update={{@update}}
          @disabled={{@disabled}}
          @params={{merge-params @params (hash
              enableVoidQuery=true
          )}}
          ...attributes />
  {{/resolve-promise}}
  
  */
  {
    "id": "+ruUx2jA",
    "block": "[[[6,[39,0],[[30,0,[\"backend\"]]],[[\"wait\"],[true]],[[\"default\"],[[[[1,\"    \"],[8,[39,1],[[17,2]],[[\"@backend\",\"@value\",\"@update\",\"@disabled\",\"@params\"],[[30,1],[30,3],[30,4],[30,5],[28,[37,2],[[30,6],[28,[37,3],null,[[\"enableVoidQuery\"],[true]]]],null]]],null],[1,\"\\n\"]],[1]]]]]],[\"backend\",\"&attrs\",\"@value\",\"@update\",\"@disabled\",\"@params\"],false,[\"resolve-promise\",\"auto-complete/base\",\"merge-params\",\"hash\"]]",
    "moduleName": "plutof/components/taxonomy/taxon/rank-auto-complete.hbs",
    "isStrictMode": false
  });
  const getRanks = (0, _caching.lazy)(function (store) {
    return (0, _store.query)(store, 'taxonomy/taxonrank', {
      page: 1,
      page_size: 100
    });
  });
  let TaxonRankAutoComplete = _exports.default = (_dec = (0, _object.computed)('args.ancestorID'), _class = class TaxonRankAutoComplete extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    get backend() {
      return getRanks(this.store).then(ranks => {
        let usedRanks = ranks;
        if (this.args.ancestorID) {
          const ancestorID = parseInt(this.args.ancestorID);
          usedRanks = ranks.filter(rank => {
            const id = parseInt(rank.id);
            return id > ancestorID || id === _config.default.Taxonomy.ranks.unspecified;
          });
        }
        return new _backends.StaticAutocomplete({
          records: usedRanks,
          id: rank => rank.id,
          value: rank => rank.representation
        });
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "backend", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "backend"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TaxonRankAutoComplete);
});