define("plutof/utils/controllers", ["exports", "@ember/controller", "@ember/object", "@ember/utils", "@ember/runloop", "plutof/mixins/add-controller", "plutof/mixins/edit-controller", "plutof/utils/notifications"], function (_exports, _controller, _object, _utils, _runloop, _addController, _editController, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ViewController = _exports.EditController = _exports.AddControllerWithCloning = _exports.AddController = void 0;
  var _class, _class2, _class3;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let AddController = _exports.AddController = (_class = class AddController extends _controller.default.extend(_addController.default) {
    async save() {
      await (0, _notifications.notifyProcess)(this._save(...arguments), this.i18n);
      this.routeActions.goBack();
    }

    // XXX: This is reproduction of the SaveAndReset component logic for
    // compatibility, but there should be a better place for these things
    async saveAndReset() {
      this.set('closePanels', true);
      (this.queryParams ?? []).forEach(qp => {
        if ((0, _utils.isPresent)(this.get(qp))) {
          this.set(qp, null);
        }
      });
      try {
        await this._save(...arguments);
        this.refresh();
      } catch (error) {
        (0, _notifications.reportError)(error);
        throw error;
      } finally {
        this.set('closePanels', false);
      }
    }
    refresh() {
      this.set('routeHasBeenLoaded', false);
      this.routeActions.refresh();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveAndReset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveAndReset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refresh", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype), _class);
  let AddControllerWithCloning = _exports.AddControllerWithCloning = (_class2 = class AddControllerWithCloning extends AddController {
    clone(cloneArea, cloneEvent) {
      this.set('cloning', true);
      return this._save().then(() => {
        this.set('closePanels', true);
        const templateID = this.get('primaryOccurrence.id');
        this.set('template_id', templateID);
        this.set('clone_area', cloneArea);
        this.set('clone_event', cloneEvent);
        this.set('cloning', false);
        this.set('routeHasBeenLoaded', false); // XXX

        (0, _runloop.later)(() => {
          this.refresh();
        });
      }, _notifications.reportError);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "clone", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "clone"), _class2.prototype), _class2);
  let EditController = _exports.EditController = (_class3 = class EditController extends _controller.default.extend(_editController.default) {
    async save() {
      await (0, _notifications.notifyProcess)(this._save(...arguments), this.i18n);
      this.routeActions.goBack();
    }
  }, _applyDecoratedDescriptor(_class3.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "save"), _class3.prototype), _class3);
  class ViewController extends _controller.default {}
  _exports.ViewController = ViewController;
});