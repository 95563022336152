define("plutof/components/tooltip-wrapper", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component"], function (_exports, _component, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const MIN_WIDTH = 200;
  function setNodeStyle(node, styleProperties) {
    for (let key in styleProperties) {
      if (styleProperties[key]) {
        node.style[key] = styleProperties[key];
      }
    }
  }
  function resetTooltipPosition(node) {
    node.style.left = null;
    node.style.top = null;
    node.style.width = 'auto';
  }
  function tooltipFit(node, targetNode) {
    const targetRect = targetNode.getBoundingClientRect();
    const tooltipRect = node.getBoundingClientRect();
    const maxRight = window.innerWidth - targetRect.left;
    const width = tooltipRect.width;
    const right = MIN_WIDTH < maxRight;
    const left = !right;
    const up = width > maxRight;

    // TODO Bottom position.
    return {
      right: right,
      left: left,
      up: up
    };
  }

  // TODO: Support tooltips inside absolutely-positioned containers
  function moveNode(node, targetNode, fit) {
    const targetRect = targetNode.getBoundingClientRect();
    let tooltipRect = node.getBoundingClientRect();
    const width = tooltipRect.width;
    let left = fit.right ? targetRect.left : targetRect.left - width + 20;
    if (left < 0) {
      left = 0;
    }
    const maxWidth = window.innerWidth - left;
    setNodeStyle(node, {
      'left': `${left}px`,
      'width': 'initial'
    });
    if (fit.up) {
      node.style.top = null;
      setNodeStyle(node, {
        'top': 'initial',
        'width': `${maxWidth}px`
      });
      tooltipRect = node.getBoundingClientRect();
      setNodeStyle(node, {
        'left': `${left}px`,
        'width': 'initial'
      });
    }
    setNodeStyle(node, {
      'top': `${targetRect.top - tooltipRect.height - 4}px`
    });
  }
  function showNode(node) {
    setNodeStyle(node, {
      'visibility': 'visible'
    });
  }
  let TooltipWrapper = (_dec = (0, _component2.tagName)('div'), _dec2 = (0, _component2.classNames)('plutof-tooltip-hover'), _dec3 = (0, _object.computed)('elementId'), _dec(_class = _dec2(_class = (_class2 = class TooltipWrapper extends _component.default {
    get tid() {
      return `${this.elementId}-tooltip`;
    }
    moveTooltip() {
      const tooltipNode = document.getElementById(this.tid);
      resetTooltipPosition(tooltipNode);
      const fit = tooltipFit(tooltipNode, this.element);
      if (fit.left) {
        tooltipNode.classList.add('wrapper-tooltip--left');
      } else {
        tooltipNode.classList.remove('wrapper-tooltip--left');
      }
      moveNode(tooltipNode, this.element, fit);
      showNode(tooltipNode);
    }
    hideTooltip() {
      if (!this.element) {
        return;
      }
      const tooltipNode = document.getElementById(`${this.element.id}-tooltip`);
      setNodeStyle(tooltipNode, {
        'visibility': 'hidden'
      });
    }
    handleMouseOver() {
      this.moveTooltip();
      this.set('shown', true);
    }
    handleMouseOut() {
      this.hideTooltip();
      this.set('shown', false);
    }
    didRender() {
      super.didRender(...arguments);
      const tooltipNode = document.getElementById(this.tid);
      if (tooltipNode) {
        this._handleMouseOver = this.handleMouseOver.bind(this);
        this._handleMouseOut = this.handleMouseOut.bind(this);
        this.element.addEventListener('mouseover', this._handleMouseOver);
        this.element.addEventListener('mouseout', this._handleMouseOut);
      }
    }
    willClearRender() {
      super.willClearRender(...arguments);
      this.element.removeEventListener('mouseover', this._handleMouseOver);
      this.element.removeEventListener('mouseout', this._handleMouseOut);
      this._handleMouseOver = null;
      this._handleMouseOut = null;
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "tid", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "tid"), _class2.prototype), _class2)) || _class) || _class);
  var _default = _exports.default = TooltipWrapper;
});