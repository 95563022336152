define("plutof/components/determination/view-parent-determination", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@ember/utils", "plutof/misc/content_types"], function (_exports, _component, _templateFactory, _component2, _object, _service, _utils, _content_types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.determination as |determination|}}
      {{#if determination}}
          <ViewSingleDetermination
              @determination={{determination}}
              @determiners={{determination.determiners}}
              @isParentDetermination={{true}}
              @enableBasis={{@enableBasis}}
              class="view-determinations__determination view-determinations__determination--parent" />
      {{/if}}
  {{/resolve-promise}}
  
  */
  {
    "id": "55U5nF+v",
    "block": "[[[6,[39,0],[[30,0,[\"determination\"]]],null,[[\"default\"],[[[[41,[30,1],[[[1,\"        \"],[8,[39,2],[[24,0,\"view-determinations__determination view-determinations__determination--parent\"]],[[\"@determination\",\"@determiners\",\"@isParentDetermination\",\"@enableBasis\"],[[30,1],[30,1,[\"determiners\"]],true,[30,2]]],null],[1,\"\\n\"]],[]],null]],[1]]]]]],[\"determination\",\"@enableBasis\"],false,[\"resolve-promise\",\"if\",\"view-single-determination\"]]",
    "moduleName": "plutof/components/determination/view-parent-determination.hbs",
    "isStrictMode": false
  });
  function getDetermination(store, contentType, id) {
    if ((0, _utils.isNone)(contentType)) {
      return null;
    }
    return store.queryRecord('determination/determination', {
      content_type: contentType.id,
      object_id: id,
      ordering: '-is_current,-id'
    });
  }
  async function getParentDetermination(store, parentContentType, parentId, parentRecord) {
    const contentType = (0, _utils.isNone)(parentContentType) ? await (0, _content_types.get_object_ctype)(store, parentRecord) : await parentContentType;
    const id = (0, _utils.isNone)(parentId) ? parentRecord.id : parentId;
    const determination = await getDetermination(store, contentType, id);
    if (determination) {
      return determination;
    }
    if (!contentType) {
      return null;
    }
    if (contentType.full_name === 'sequence/sequence' || contentType.full_name === 'livingculture/strain') {
      const grandparent = await (0, _content_types.resolve_generic_link)(store, contentType, id);
      const grandparentContentType = await grandparent.content_type;
      const objectId = grandparent.object_id;
      const grandparentDetermination = await getDetermination(store, grandparentContentType, objectId);
      return grandparentDetermination;
    }
    return null;
  }
  let ViewParentDetermination = (_dec = (0, _object.computed)('args.parentContentType.id', 'args.parentId', 'args.parentRecord.id'), _class = class ViewParentDetermination extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    get determination() {
      return getParentDetermination(this.store, this.args.parentContentType, this.args.parentId, this.args.parentRecord);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "determination", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "determination"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewParentDetermination);
});