define("plutof/controllers/step-two-register", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/utils", "ember-cp-validations", "plutof/controllers/step-one-register", "plutof/misc/abstract", "plutof/utils/i18n", "plutof/utils/model", "plutof/utils/notifications"], function (_exports, _controller, _object, _computed, _utils, _emberCpValidations, _stepOneRegister, _abstract, _i18n, _model, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  const Validations = (0, _emberCpValidations.buildValidations)({
    'profile.language': (0, _emberCpValidations.validator)('presence', true),
    'person.given_name': [(0, _emberCpValidations.validator)('present-not-blank'), (0, _emberCpValidations.validator)('format', {
      regex: _stepOneRegister.NAME_REGEX
    })],
    'person.family_name': [(0, _emberCpValidations.validator)('present-not-blank'), (0, _emberCpValidations.validator)('format', {
      regex: _stepOneRegister.NAME_REGEX
    })]
  });
  let StepTwoRegisterController = (_dec = (0, _computed.alias)('model.agentAssocation.address'), _dec2 = (0, _computed.alias)('model.person'), _dec3 = (0, _computed.alias)('model.profile'), _dec4 = (0, _model.choices)('profile.personalsettings.personalsetting.default_licence'), _class = class StepTwoRegisterController extends _controller.default.extend(Validations) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "address", _descriptor, this);
      _initializerDefineProperty(this, "person", _descriptor2, this);
      _initializerDefineProperty(this, "profile", _descriptor3, this);
      _initializerDefineProperty(this, "licences", _descriptor4, this);
    }
    register() {
      var address_p = this.get('model.agentAssocation.organization').then(org => {
        if ((0, _utils.isNone)(org)) {
          return _abstract.EMPTY_PROMISE;
        }
        return this.address.then(address => {
          return address.save();
        });
      });
      return address_p.then(address => {
        return this.person.save().then(() => {
          const agentPromise = address ? this.get('model.agentAssocation').save() : _abstract.EMPTY_PROMISE;
          return agentPromise.then(() => {
            return this.profile.save().then(() => {
              this.transitionToRoute('index');
              (0, _notifications.displayNotification)('success', i18n.t('Registration.successRegister'));
            });
          });
        });
      }).catch(_notifications.reportError);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "address", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "person", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "profile", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "licences", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "register", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype), _class);
  var _default = _exports.default = StepTwoRegisterController;
});