define("plutof/components/unite/conflict-list", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/utils", "plutof/misc/config", "plutof/utils/pagination"], function (_exports, _component, _object, _service, _utils, _config, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const LEVELS = ['kingdom', 'subkingdom', 'phylum', 'class', 'order', 'family', 'genus', 'species'];
  let ConflictList = (_dec = (0, _object.computed)('args.{level,taxon,threshold,version,includeSingletons}'), _dec2 = (0, _object.computed)('queryParams'), _class = class ConflictList extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    get queryParams() {
      let query = {};
      if (this.args.level) {
        const lower_taxon = LEVELS[LEVELS.indexOf(this.args.level) + 1];
        query['dsh_' + this.args.level] = this.args.taxon.id;
        if (!(0, _utils.isNone)(lower_taxon)) {
          query.conflict = lower_taxon;
        }
      }
      if (!(0, _utils.isNone)(this.args.includeSingletons)) {
        query.is_singleton = this.args.includeSingletons;
      }
      if (!(0, _utils.isNone)(this.args.threshold)) {
        query.threshold = (0, _object.get)(this.args, 'threshold.id');
      }
      if (!(0, _utils.isNone)(this.args.version)) {
        query.version = (0, _object.get)(this.args, 'version.id');
      }
      query.page_size = _config.default.Common.PAGE_SIZE;
      return query;
    }
    get conflictsPagination() {
      return (0, _pagination.paginateModel)(this.store, 'globalkey/shtaxonomytree', this.queryParams);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "queryParams", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "queryParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "conflictsPagination", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "conflictsPagination"), _class.prototype), _class);
  var _default = _exports.default = ConflictList;
});