define("plutof/components/collection-lab/view/condition-assessments", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/object/computed", "@ember/service", "plutof/misc/config", "plutof/utils/pagination", "plutof/misc/content_types"], function (_exports, _component, _templateFactory, _component2, _object, _computed, _service, _config, _pagination, _content_types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.pagination wait=true as |pagination|}}
      <RelatedObjects::View
          @title={{i18n-t "CollectionManagement.mainTitle"}}
          @pagination={{pagination}}
          @canModify={{this.canModify}}
          @add={{this.add}}
      >
          <RelatedObjects::Content @pagination={{pagination}} as |Field assessment|>
              <Field @label={{backend-translations/label "condition-assessment.assessment_of_condition"}}>
                  <LinkTo @route="condition-assessment.view" @model={{assessment.id}}>
                      {{assessment.conditionDisplayName}}
                  </LinkTo>
              </Field>
  
              <Field @label={{backend-translations/label "condition-assessment.parent_record_name"}}>
                  {{assessment.parent_record_name}}
              </Field>
  
              <Field @label={{backend-translations/label "condition-assessment.assessed_by"}}>
                  {{assessment.assessed_by.representation}}
              </Field>
  
              <Field @label={{backend-translations/label "condition-assessment.assessed_date"}}>
                  {{assessment.assessed_date}}
              </Field>
  
              <Field @label={{backend-translations/label "condition-assessment.remarks"}}>
                  {{assessment.remarks}}
              </Field>
          </RelatedObjects::Content>
      </RelatedObjects::View>
  {{/resolve-promise}}
  
  */
  {
    "id": "zs+voMzO",
    "block": "[[[6,[39,0],[[30,0,[\"pagination\"]]],[[\"wait\"],[true]],[[\"default\"],[[[[1,\"    \"],[8,[39,1],null,[[\"@title\",\"@pagination\",\"@canModify\",\"@add\"],[[28,[37,2],[\"CollectionManagement.mainTitle\"],null],[30,1],[30,0,[\"canModify\"]],[30,0,[\"add\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@pagination\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,2],null,[[\"@label\"],[[28,[37,4],[\"condition-assessment.assessment_of_condition\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"condition-assessment.view\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,3,[\"conditionDisplayName\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[30,2],null,[[\"@label\"],[[28,[37,4],[\"condition-assessment.parent_record_name\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"parent_record_name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[30,2],null,[[\"@label\"],[[28,[37,4],[\"condition-assessment.assessed_by\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"assessed_by\",\"representation\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[30,2],null,[[\"@label\"],[[28,[37,4],[\"condition-assessment.assessed_date\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"assessed_date\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[30,2],null,[[\"@label\"],[[28,[37,4],[\"condition-assessment.remarks\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"remarks\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[2,3]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"pagination\",\"Field\",\"assessment\"],false,[\"resolve-promise\",\"related-objects/view\",\"i18n-t\",\"related-objects/content\",\"backend-translations/label\",\"link-to\"]]",
    "moduleName": "plutof/components/collection-lab/view/condition-assessments.hbs",
    "isStrictMode": false
  });
  async function getModelName(store, object) {
    if (object.organization_type === _config.default.Organization.COLLECTION_TYPE) {
      return 'collection';
    }
    const contentType = await (0, _content_types.get_object_ctype)(store, object);
    return contentType.model;
  }
  let ViewConditionAssessments = _exports.default = (_dec = (0, _computed.notEmpty)('args.occurrenceType'), _dec2 = (0, _object.computed)('args.object'), _class = class ViewConditionAssessments extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "canModify", _descriptor3, this);
    }
    add() {
      return this.router.transitionTo('condition-assessment.add', {
        queryParams: {
          occurrenceId: this.args.object.id,
          occurrenceType: this.args.occurrenceType
        }
      });
    }
    get pagination() {
      const object = this.args.object;
      return getModelName(this.store, object).then(modelName => (0, _pagination.paginateModel)(this.store, 'collection-lab/condition-assessment', {
        [modelName]: object.id
      }));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "canModify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pagination", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "pagination"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewConditionAssessments);
});