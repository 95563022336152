define("plutof/components/filter-selection", ["exports", "@ember/component", "@ember/object", "@ember/utils", "rsvp", "@ember-decorators/component", "@ember-decorators/object"], function (_exports, _component, _object, _utils, _rsvp, _component2, _object2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function cycleInteger(int, modifier, lowerLimit, upperLimit) {
    var i = int + modifier;
    if (i > upperLimit) {
      return lowerLimit;
    }
    if (i < lowerLimit) {
      return upperLimit;
    }
    return i;
  }

  // XXX TODO: Replace this with autocomplete
  let FilterSelection = (_dec = (0, _component2.classNames)('filter-selection'), _dec2 = (0, _component2.classNameBindings)('hasSelection:plutof-ac-input-success'), _dec3 = (0, _object.computed)('hasFocus', 'filteredContent.length'), _dec4 = (0, _object.computed)('content', 'value'), _dec5 = (0, _object.computed)('filteredContent.[]', 'rowCount'), _dec6 = (0, _object2.observes)('selection'), _dec7 = (0, _object.computed)('selection', 'value'), _dec(_class = _dec2(_class = (_class2 = class FilterSelection extends _component.default {
    constructor() {
      super(...arguments);
      // Input.
      _defineProperty(this, "content", null);
      _defineProperty(this, "optionValuePath", null);
      _defineProperty(this, "optionLabelPath", null);
      _defineProperty(this, "value", null);
      // Output.
      _defineProperty(this, "selection", null);
      // Properties.
      _defineProperty(this, "hasFocus", false);
      // Component itself.
      _defineProperty(this, "focusedItem", null);
      _defineProperty(this, "inputClassNames", ['form-control']);
      _defineProperty(this, "class", null);
    }
    get showDropdownMenu() {
      return this.hasFocus && this.get('filteredContent.length') > 0;
    }
    get filteredContent() {
      if ((0, _utils.isNone)(this.get('content.length'))) {
        return [];
      } else {
        var labelPath = this.optionLabelPath;
        var valuePath = this.optionValuePath;
        var value = (this.value || '').toLowerCase();
        return this.content.map(function (item) {
          var optionLabel = item[labelPath];
          var optionValue = (0, _utils.isEmpty)(valuePath) ? item : item[valuePath];
          if ((0, _utils.isEmpty)(optionLabel) || (0, _utils.isEmpty)(optionValue)) {
            return null;
          }
          return optionLabel.toLowerCase().includes(value) ? _object.default.create({
            label: optionLabel,
            value: optionValue,
            hasFocus: false
          }) : null;
        }).compact();
      }
    }
    get visibleItems() {
      var items = this.filteredContent;
      var count = this.rowCount;
      return (0, _utils.isNone)(count) ? items : items.slice(0, count);
    }
    init() {
      super.init();
      var content = this.content;
      var selection = this.selection;
      var valuePath = this.optionValuePath;
      if (content && selection) {
        _rsvp.default.resolve(content).then(function (contentArray) {
          var value;
          if ((0, _utils.isNone)(valuePath)) {
            value = (0, _object.get)(selection, this.optionLabelPath);
          } else {
            // TODO: should use labelpath?
            var matches = contentArray.filterBy(valuePath, selection);
            if ((0, _utils.isPresent)(matches)) {
              value = matches[0][valuePath];
            }
          }
          this.set('value', value);
        }.bind(this));
      }
      var placeholder = this.placeholder;
      var translator = this.i18n;
      if ((0, _utils.isNone)(placeholder)) {
        this.set('placeholder', translator.t('General.autoCompletePlaceholder', {}));
      } else {
        this.set('placeholder', translator.t(placeholder), {});
      }
    }
    clearValueOnEmptySelection() {
      if ((0, _utils.isNone)(this.selection)) {
        this.set('value', null);
      }
    }
    get hasSelection() {
      var areEqual = this.selection === this.value;
      var hasValue = !(0, _utils.isNone)(this.value);
      return areEqual && hasValue;
    }
    makeSelection() {
      // var focusedItem = this.get('filteredContent').findBy('hasFocus', true);
      var focusedItem = this.focusedItem;
      if (!(0, _utils.isEmpty)(focusedItem)) {
        this.set('value', focusedItem.label);
        if (!this.oneWay) {
          this.set('selection', focusedItem.value);
        }
        if (this.changed) {
          this.changed(focusedItem.value);
        }
      }
    }
    click() {
      this.makeSelection();
    }
    keyDown(event) {
      var direction = 0;
      var filteredContent = this.filteredContent;
      var focusedItem = this.focusedItem || filteredContent.findBy('focus', true);
      var index = filteredContent.indexOf(focusedItem);
      switch (event.keyCode) {
        case 38:
          {
            // UP
            event.preventDefault();
            direction = -1;
            break;
          }
        case 40:
          {
            // DOWN
            event.preventDefault();
            direction = 1;
            break;
          }
        case 13:
          {
            // RETURN
            event.preventDefault();
            this.makeSelection();
            direction = 0;
            break;
          }
        case 27:
          {
            // ESC
            break;
          }
        case 9:
          {
            // TAB
            if (!(0, _utils.isEmpty)(this.value) || index !== -1) {
              this.makeSelection();
            }
            direction = 0;
            break;
          }
        default:
          {
            direction = 0;
            return;
          }
      } // switch

      if (!(0, _utils.isNone)(focusedItem)) {
        focusedItem.set('hasFocus', false);
      }
      var newIndex = cycleInteger(index, direction, -1, filteredContent.length - 1);
      if (newIndex >= 0) {
        filteredContent[newIndex].set('hasFocus', true);
        this.set('focusedItem', filteredContent[newIndex]);
      } else {
        this.set('focusedItem', null);
      }
    }
    focusIn() {
      this.set('hasFocus', true);
    }
    focusOut() {
      this.set('hasFocus', false);
      this.makeSelection();
    }
    touchItem() {
      this.makeSelection();
    }
    focusItem(item) {
      item.set('hasFocus', true);
      // Not ideal but focusing different parts of the component is wonky.
      // This ensures we don't lose the focused item.
      this.set('focusedItem', item);
    }
    unfocusItem(item) {
      item.set('hasFocus', false);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "showDropdownMenu", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "showDropdownMenu"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "filteredContent", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "filteredContent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "visibleItems", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "visibleItems"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clearValueOnEmptySelection", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "clearValueOnEmptySelection"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hasSelection", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "hasSelection"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "touchItem", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "touchItem"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "focusItem", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "focusItem"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "unfocusItem", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "unfocusItem"), _class2.prototype), _class2)) || _class) || _class);
  var _default = _exports.default = FilterSelection;
});