define("plutof/components/clipboard/clipboard-table", ["exports", "@ember/object", "@ember/runloop", "@ember/service", "@ember/utils", "ember-data", "ember-inflector", "plutof/components/search/results-table", "plutof/misc/options-getter", "plutof/utils/errors", "plutof/utils/search", "plutof/utils/structures"], function (_exports, _object, _runloop, _service, _utils, _emberData, _emberInflector, _resultsTable, _optionsGetter, _errors, _search, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function getColumnVisibility(columnName, ignoredColumns, state) {
    return state || ignoredColumns.includes(columnName) || state;
  }
  const EXCLUDED_COLUMNS = ['content_type', 'content_type_id', 'files', 'id', 'measurements', 'object_id', 'gathering_agents'];
  const DEFAULT_COLUMNS = ['name'];

  // TODO: Don't subclass ResultsTable
  let ClipboardTable = (_dec = (0, _object.computed)('rows', 'headers.@each.isVisible'), _dec2 = (0, _object.computed)('modelPath'), _dec3 = (0, _object.computed)('modelPath'), _dec4 = (0, _object.computed)('pageData.{totalCount,orderableLimit}'), _dec5 = (0, _object.computed)('fields.fields', 'disableOrdering', 'settings.profile.default_fields'), _dec6 = (0, _object.computed)('fields.fields', 'pageData.results', 'pagination.objects.[]', 'headers'), _class = class ClipboardTable extends _resultsTable.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "settings", _descriptor3, this);
      // Inputs
      _defineProperty(this, "pageData", []);
      _defineProperty(this, "showColumnMenu", false);
      _defineProperty(this, "viewRoute", null);
      _defineProperty(this, "selectedCount", 0);
    }
    get showTable() {
      const hasRows = !(0, _utils.isEmpty)(this.rows);
      const hasVisibleColumns = this.headers.some(header => header.isVisible);
      return hasRows && hasVisibleColumns;
    }
    getOptions(endpoint, propertyPath) {
      // The search part here may be temporary
      return (0, _optionsGetter.getPromise)(this.ajax, `/${endpoint}/search/`).then(options => {
        return (0, _object.get)(options, propertyPath);
      });
    }

    // Search result field descriptions from search end-point options
    get fields() {
      const endpoint = (0, _emberInflector.pluralize)(this.modelPath);
      return _emberData.default.PromiseObject.create({
        promise: this.getOptions(endpoint, 'actions.GET.fields')
      });
    }

    // Search configuration options
    // XXX make sure its used for something more than a tooltip
    get options() {
      const endpoint = (0, _emberInflector.pluralize)(this.modelPath);
      return _emberData.default.PromiseObject.create({
        promise: this.getOptions(endpoint, 'actions.GET.options')
      });
    }
    get disableOrdering() {
      const count = this.get('pageData.totalCount');
      const limit = this.get('pageData.orderableLimit');
      return count > limit;
    }
    get headers() {
      const fields = this.get('fields.fields');
      if ((0, _utils.isEmpty)(fields)) {
        return [];
      }
      const fieldNames = (0, _structures.set_difference)(Object.keys(fields), EXCLUDED_COLUMNS);
      let savedConfiguration = this.get(`settings.profile.default_fields.${this.configurationKey}`);
      if (savedConfiguration && fieldNames.every(field => !savedConfiguration.includes(field))) {
        // Prevent empty and invalid configurations (coming from who knows where) from hiding all columns
        savedConfiguration = null;
      }
      return fieldNames.map(fieldName => {
        const config = fields[fieldName];

        // Override ordering.
        if (this.disableOrdering) {
          (0, _object.set)(config, 'ordering', false);
        }
        return _object.default.create({
          name: fieldName,
          config,
          ascendingOrder: this.ordering[0] !== '-',
          ordering: fieldName,
          isVisible: !savedConfiguration || savedConfiguration.includes(fieldName),
          isPinned: fieldName === this.get('savedPinnedColumnName')
        });
      }).sort(_search.sortHeaders);
    }

    // TODO It may be it updates one time too many when switching pages
    get rows() {
      const currentPage = this.get('pagination.objects');
      const results = this.get('pageData.results');
      const fields = this.get('fields.fields');
      const headers = this.headers;
      const viewRoute = this.viewRoute;
      let viewURL = null;
      if ([currentPage, results, fields, headers].any(_utils.isEmpty)) {
        return null;
      }
      return results.map((dataEntry, rowIndex) => {
        const recordId = dataEntry.id;
        const matchingEntry = currentPage.filter(entry => {
          return String(entry.get('id')) === String(dataEntry.id);
        }).get('firstObject');
        try {
          viewURL = this.router.urlFor(viewRoute, recordId);
        } catch (e) {
          (0, _errors.publishError)(`Could not get view URL for route ${viewRoute} with record ID ${recordId}.
                    Error: ${(0, _errors.getErrorMessage)(e)}`);
        }
        return _object.default.create({
          source: dataEntry,
          recordId,
          viewURL,
          ordinal: rowIndex + 1,
          name: dataEntry.name,
          // What is this for?
          on_clipboard: dataEntry.on_clipboard,
          // FIXME: It shouldn't come to this.
          selected: !(0, _utils.isNone)(matchingEntry) ? matchingEntry.get('selected') : false,
          highlighted: false,
          cells: headers.map(header => {
            const fieldName = header.get('name');
            return _object.default.create({
              name: fieldName,
              // Nice to have
              source: dataEntry[fieldName],
              value: (0, _search.getCellValue)(fieldName, dataEntry[fieldName], fields[fieldName]),
              isVisible: header.get('isVisible'),
              icons: fieldName === 'related_objects' ? (0, _search.getCellIcons)(dataEntry[fieldName]) : []
            });
          })
        });
      });
    }
    columnMenuButton(state) {
      const show = (0, _utils.isNone)(state) ? !this.showColumnMenu : state;
      this.set('showColumnMenu', show);
    }

    // Show or hide all columns
    setColumnsVisibility(state) {
      const headers = this.headers;
      const rows = this.rows;

      // Update headers
      headers.forEach(header => {
        header.set('isVisible', getColumnVisibility(header.name, DEFAULT_COLUMNS, state));
      });

      // Update cells
      if (!(0, _utils.isEmpty)(rows)) {
        rows.forEach(row => {
          row.get('cells').forEach(cell => {
            cell.set('isVisible', getColumnVisibility(cell.name, DEFAULT_COLUMNS, state));
          });
        });
      }
      this.persistHeaderConfiguration();
    }
    persistHeaderConfiguration() {
      (0, _runloop.debounce)(this, this._persistHeaderConfiguration, 3500);
    }
    _persistHeaderConfiguration() {
      let profile = this.settings.profile;
      profile.default_fields[this.configurationKey] = this.headers.filterBy('isVisible').mapBy('name');
      profile.save();
    }
    toggleColumn(header) {
      const isVisible = header.get('isVisible');
      const rows = this.rows;
      const headers = this.headers;
      const headerIndex = headers.indexOf(header); // XXX WTH

      header.toggleProperty('isVisible');

      // Update cells
      if (!(0, _utils.isEmpty)(rows)) {
        rows.forEach(row => {
          row.get('cells')[headerIndex].set('isVisible', !isVisible);
        });
      }
      this.persistHeaderConfiguration();
    }

    // TODO Beautification
    selectRow(row) {
      const entry = this.pagination.objects.find(entry => {
        return entry.get('id') === row.recordId;
      });
      entry.set('selected', !row.selected);
      row.set('selected', !row.selected);
    }
    selectAllRows(state) {
      // Perhaps not do it here, but send upwards
      this.pagination.objects.forEach(entry => {
        entry.set('selected', !state);
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showTable", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "showTable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fields", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "fields"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "options", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "options"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableOrdering", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "disableOrdering"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "headers", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rows", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "rows"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "columnMenuButton", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "columnMenuButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setColumnsVisibility", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setColumnsVisibility"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleColumn", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleColumn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectRow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectAllRows", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectAllRows"), _class.prototype), _class);
  var _default = _exports.default = ClipboardTable;
});