define("plutof/components/profile/groups/group-link", ["exports", "@ember/component", "@ember/service", "@ember-decorators/component", "@ember-decorators/object", "ember-concurrency", "plutof/utils/store"], function (_exports, _component, _service, _component2, _object, _emberConcurrency, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let GroupLink = (_dec = (0, _component2.tagName)('button'), _dec2 = (0, _component2.classNames)('btn-fake-link'), _dec3 = (0, _object.on)('click'), _dec(_class = _dec2(_class = (_class2 = class GroupLink extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "settings", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "open", _descriptor3, this);
    }
    clicked() {
      this.open.perform();
    }
    gotoView() {
      return this.router.transitionTo('profile.groups.group', this.group.id);
    }
    gotoJoin() {
      return this.router.transitionTo('profile.groups.join', {
        queryParams: {
          group_id: this.group.id
        }
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "clicked", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "clicked"), _class2.prototype), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "open", [_emberConcurrency.dropTask], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        // Shortcut: if user is admin, no need to request anything, we already know they
        // are a member
        if (this.group.is_admin) {
          return this.gotoView();
        }
        const user = yield this.settings.profile.user;
        const membership = yield (0, _store.query)(this.store, 'users/usergroup', {
          group: this.group.id,
          user: user.id
        });
        if (membership.length > 0) {
          return this.gotoView();
        } else {
          return this.gotoJoin();
        }
      };
    }
  }), _class2)) || _class) || _class);
  var _default = _exports.default = GroupLink;
});