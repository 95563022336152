define("plutof/routes/condition-assessment/add", ["exports", "plutof/misc/content_types", "plutof/components/collection-lab/condition-assessment/edit", "plutof/components/collection-lab/condition-assessment/-general-data", "plutof/utils/routes"], function (_exports, _content_types, _edit, _generalData, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ConditionAssessmentAddRoute extends _routes.AddRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "defaultReturnRoute", 'condition-assessment.index');
      _defineProperty(this, "title", 'CollectionManagement.mainTitle');
    }
    async model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const assessment = _edit.ConditionAssessmentEditModel.create(this.store);
      if (params.occurrenceId && params.occurrenceType) {
        const type = _generalData.ASSESSMENT_TYPES.findBy('value', params.occurrenceType);
        const contentType = await (0, _content_types.get_ctype_by_name)(this.store, type.content_type);
        const record = await this.store.findRecord(contentType.model_name, params.occurrenceId);
        assessment.generalData.type = type;
        assessment.generalData.add(record);
      }
      return {
        assessment
      };
    }
  }
  var _default = _exports.default = ConditionAssessmentAddRoute;
});