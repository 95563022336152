define("plutof/components/annotation/annotate-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "rsvp"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="annotation-button">
      <PromiseButton
          @clicked={{this.start}}
          @disabled={{@disabled}}
          @restIcon={{icon "ok"}}
          class="btn btn-default"
      >
          {{i18n-t "annotation.annotate"}}
      </PromiseButton>
  
      <Ui::Modal::Dialog
          @open={{this.dialogOpen}}
          @close={{this.cancel}}
      >
          <:header>
              {{i18n-t "annotation.commentDialog.title"}}
          </:header>
  
          <:body>
              <FlexibleTextarea
                  @value={{this.comment}}
                  @rows={{2}}
                  placeholder={{i18n-t "annotation.commentDialog.placeholder"}}
                  class="form-control" />
          </:body>
  
          <:footer>
              <PromiseButton
                  @clicked={{this.accept}}
                  @disabled={{this.commentIsEmpty}}
                  class="btn btn-default plutof-btn-green terms-of-service__accept"
              >
                  {{i18n-t "annotation.annotate"}}
              </PromiseButton>
  
              <PromiseButton
                  @clicked={{this.cancel}}
                  class="btn btn-default"
              >
                  {{i18n-t "General.Cancel"}}
              </PromiseButton>
          </:footer>
      </Ui::Modal::Dialog>
  </div>
  
  */
  {
    "id": "FwJ8cVn0",
    "block": "[[[10,0],[14,0,\"annotation-button\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"btn btn-default\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\"],[[30,0,[\"start\"]],[30,1],[28,[37,1],[\"ok\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"annotation.annotate\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@open\",\"@close\"],[[30,0,[\"dialogOpen\"]],[30,0,[\"cancel\"]]]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"annotation.commentDialog.title\"],null]],[1,\"\\n        \"]],[]],[[[1,\"\\n            \"],[8,[39,4],[[16,\"placeholder\",[28,[37,2],[\"annotation.commentDialog.placeholder\"],null]],[24,0,\"form-control\"]],[[\"@value\",\"@rows\"],[[30,0,[\"comment\"]],2]],null],[1,\"\\n        \"]],[]],[[[1,\"\\n            \"],[8,[39,0],[[24,0,\"btn btn-default plutof-btn-green terms-of-service__accept\"]],[[\"@clicked\",\"@disabled\"],[[30,0,[\"accept\"]],[30,0,[\"commentIsEmpty\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,2],[\"annotation.annotate\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[39,0],[[24,0,\"btn btn-default\"]],[[\"@clicked\"],[[30,0,[\"cancel\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,2],[\"General.Cancel\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@disabled\"],false,[\"promise-button\",\"icon\",\"i18n-t\",\"ui/modal/dialog\",\"flexible-textarea\"]]",
    "moduleName": "plutof/components/annotation/annotate-button.hbs",
    "isStrictMode": false
  });
  let AnnotateButton = (_class = class AnnotateButton extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialogOpen", _descriptor, this);
      _initializerDefineProperty(this, "comment", _descriptor2, this);
    }
    get commentIsEmpty() {
      return this.comment.trim().length === 0;
    }
    start() {
      this.dialogOpen = true;
    }
    accept() {
      return _rsvp.default.Promise.resolve(this.args.annotate(this.comment.trim())).finally(() => {
        this.dialogOpen = false;
        this.comment = '';
      });
    }
    cancel() {
      this.dialogOpen = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "dialogOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "comment", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "start", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "start"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "accept", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "accept"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnnotateButton);
});