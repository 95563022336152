define("plutof/utils/caching", ["exports", "rsvp", "localforage", "plutof/config/environment", "plutof/utils/i18n"], function (_exports, _rsvp, _localforage, _environment, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CacheManager = void 0;
  _exports.cacheAdapterRecords = cacheAdapterRecords;
  _exports.lazy = lazy;
  _exports.simple = simple;
  const i18n = (0, _i18n.getI18n)();
  function lazy(fn) {
    let result;
    return function () {
      if (!result) {
        result = fn(...arguments);
      }
      return result;
    };
  }

  // Cache single-param function fn in local storage with fallback
  function simple(cacheID, fn) {
    let {
      ttl = 1 * 7 * 24 * 60 * 60 * 1000
    } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    // Don't touch LS unless necessary
    let inMemory = new Map();

    // item :: { timestamp: num, value: T }
    let persistent = _localforage.default.createInstance({
      name: `cache.simple.${cacheID}`
    });
    function store(id, value) {
      return persistent.setItem(id, {
        timestamp: Date.now(),
        version: _environment.default.currentRevision,
        value
      }).finally(() => null); // Don't choke on unavailable storage
    }
    return function (id) {
      if (!inMemory.has(id)) {
        const resultPromise = persistent.getItem(id).catch(() => null) // Don't choke on unavailable storage
        .then(item => {
          if (item && item.version === _environment.default.currentRevision) {
            // If outdated, refresh in the background, while returning the stale value.
            //
            // Revision changes force cache clear just in case, but most of the TTL-triggered
            // refreshes are expected to be unimportant
            if (Date.now() - item.timestamp > ttl) {
              _rsvp.default.Promise.resolve(fn(id)).then(value => store(id, value));
            }
            return item.value;
          } else {
            return _rsvp.default.Promise.resolve(fn(id)).then(value => {
              // No need to wait for save
              store(id, value).catch(() => null);
              return value;
            });
          }
        });
        inMemory.set(id, resultPromise);
      }
      return inMemory.get(id);
    };
  }

  // Manages key-value caches with in-memory-fallback and version-based breaking
  const CacheManager = _exports.CacheManager = function () {
    const registry = _localforage.default.createInstance({
      name: 'cache.manager'
    });
    let storageEnabled = true;
    let statePromise = function () {
      let state = {};
      return registry.iterate((descriptor, name) => state[name] = descriptor).catch(() => storageEnabled = false).then(() => state);
    }();
    function storageInstanceName(cacheName) {
      return `cache.managed.${cacheName}`;
    }
    function storageInstance(cacheName) {
      const name = storageInstanceName(cacheName);
      return _localforage.default.createInstance({
        name
      });
    }
    class PersistentCache {
      constructor(storage) {
        this._storage = storage;
        this._inmemory = {};
      }
      get(key) {
        if (this._inmemory.hasOwnProperty(key)) {
          return _rsvp.default.Promise.resolve(this._inmemory[key]);
        }
        return this._storage.getItem(key).then(value => {
          this._inmemory[key] = value;
          return value;
        }).catch(() => null);
      }
      set(key, value) {
        this._inmemory[key] = value;
        return this._storage.setItem(key, value).catch(() => null);
      }
      all() {
        return this._storage.iterate((value, key) => {
          this._inmemory[key] = value;
        }).then(() => Object.assign({}, this._inmemory));
      }
    }
    class InMemoryCache {
      constructor() {
        this._cache = {};
      }
      get(key) {
        return _rsvp.default.Promise.resolve(this._cache[key]);
      }
      set(key, value) {
        this._cache[key] = value;
        return _rsvp.default.Promise.resolve(null);
      }
      all() {
        return _rsvp.default.Promise.resolve(Object.assign({}, this._cache));
      }
    }
    async function registerCache(_ref) {
      let {
        name,
        version = 1
      } = _ref;
      const state = await statePromise;
      if (storageEnabled && _environment.default.environment !== 'acceptancetesting') {
        const instance = storageInstance(name);
        function writeDescriptor() {
          const newDescriptor = {
            version
          };
          state[name] = newDescriptor;
          return registry.setItem(name, newDescriptor).catch(() => null);
        }
        if (state.hasOwnProperty(name)) {
          let descriptor = state[name];
          if (descriptor.version < version) {
            await instance.clear();
            await writeDescriptor();
          }
        } else {
          await writeDescriptor();
        }
        return new PersistentCache(instance);
      } else {
        return new InMemoryCache();
      }
    }

    // TODO: Make options cache managed. Or register all simple caches
    async function dropCaches() {
      const state = await statePromise;
      if (!storageEnabled) {
        return;
      }
      await Object.keys(state).map(storageInstance).map(storage => storage.dropInstance());
      await registry.dropInstance();
      statePromise = _rsvp.default.Promise.resolve({});
    }
    return {
      registerCache,
      dropCaches
    };
  }();
  function cacheAdapterRecords(Adapter, _ref2) {
    let {
      cacheName,
      version = 1,
      translated = true
    } = _ref2;
    if (_environment.default.environment === 'accceptancetesting') {
      return Adapter;
    }
    const cachePromise = CacheManager.registerCache({
      name: translated ? `adapters.${cacheName}.${i18n.locale}` : `adapters.${cacheName}`,
      version
    });
    return class extends Adapter {
      async findRecord(store, type, id, snapshot) {
        const cache = await cachePromise;
        let recordData = await cache.get(id);
        if (!recordData) {
          recordData = await super.findRecord(store, type, id, snapshot);
          await cache.set(id, recordData);
        }
        return recordData;
      }
    };
  }
});