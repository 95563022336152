define("plutof/components/agent/person-form", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember-decorators/component", "ember-cp-validations", "plutof/controllers/step-one-register", "plutof/mixins/component-validations"], function (_exports, _component, _object, _utils, _component2, _emberCpValidations, _stepOneRegister, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'givenNameIsValid': (0, _emberCpValidations.validator)('is-truthy'),
    'person.family_name': [(0, _emberCpValidations.validator)('present-not-blank'), (0, _emberCpValidations.validator)('format', {
      regex: _stepOneRegister.NAME_REGEX
    })],
    'person.primary_given_name': (0, _emberCpValidations.validator)('length', {
      max: 128
    }),
    'deathDateValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'birthDateValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'person.prefix': (0, _emberCpValidations.validator)('length', {
      max: 64
    }),
    'person.suffix': (0, _emberCpValidations.validator)('length', {
      max: 64
    })
  });
  let PersonForm = (_dec = (0, _component2.classNames)('person-form'), _dec2 = (0, _object.computed)('givenNameRequired', 'person.given_name'), _dec(_class = (_class2 = class PersonForm extends _component.default.extend(Validations, _componentValidations.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "givenNameRequired", true);
    }
    get givenNameIsValid() {
      if (!this.givenNameRequired) {
        return true;
      }
      const name = this.get('person.given_name');
      if ((0, _utils.isEmpty)(name)) {
        return false;
      }
      return _stepOneRegister.NAME_REGEX.test(name);
    }
    init() {
      super.init(...arguments);
      this.set('birthDateValidity', true);
      this.set('deathDateValidity', true);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "givenNameIsValid", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "givenNameIsValid"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = PersonForm;
});