define("plutof/controllers/experiment/sequencing/edit", ["exports", "@ember/controller", "@ember/object", "@ember/utils", "rsvp", "plutof/mixins/edit-controller", "plutof/utils/notifications"], function (_exports, _controller, _object, _utils, _rsvp, _editController, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function hasSource(result) {
    return [(0, _object.get)(result.dna, 'id'), (0, _object.get)(result.pcr_product, 'id')].any(_utils.isPresent);
  }
  let SequencingEditController = (_class = class SequencingEditController extends _controller.default.extend(_editController.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", ['materials']);
    }
    async save() {
      await this.model.experiment.save();
      this.model.materials.forEach(material => {
        (0, _object.set)(material, 'sequencing', this.model.experiment);
      });
      await _rsvp.default.all(this.model.materials.filter(hasSource).invoke('save'));
      await _rsvp.default.all(this.model.materials.filter(m => !hasSource(m) && !m.isNew).invoke('destroyRecord'));
      await this.model.linkedItemsData.save(this.model.experiment, this.model.accessRights);
    }
    saveExperiment() {
      (0, _notifications.clearNotifications)();
      (0, _notifications.displayNotification)('status', this.i18n.t('General.saving'));
      return this.save().then(() => {
        (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
        this.clearParams();
        this.set('routeHasBeenLoaded', false);
        this.routeActions.goBack();
      }).catch(_notifications.reportError);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "saveExperiment", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveExperiment"), _class.prototype), _class);
  var _default = _exports.default = SequencingEditController;
});