define("plutof/components/gis-lab/import/areas/map", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Map::ViewMap
      @geodata={{this.areas}}
      @geomProperty={{"geom"}}
      @labelProperty={{"name"}}
      @singular={{false}}
      @zoomToGeometry={{@zoomToGeometry}}
      @defaultControl="navigate"
      @expandVertically={{true}} />
  
  */
  {
    "id": "6o3E9uu+",
    "block": "[[[8,[39,0],null,[[\"@geodata\",\"@geomProperty\",\"@labelProperty\",\"@singular\",\"@zoomToGeometry\",\"@defaultControl\",\"@expandVertically\"],[[30,0,[\"areas\"]],\"geom\",\"name\",false,[30,1],\"navigate\",true]],null],[1,\"\\n\"]],[\"@zoomToGeometry\"],false,[\"map/view-map\"]]",
    "moduleName": "plutof/components/gis-lab/import/areas/map.hbs",
    "isStrictMode": false
  });
  let GisLabImportAreasMap = (_dec = (0, _object.computed)('args.areasData.[]'), _dec2 = (0, _object.computed)('args.areasData.[]'), _class = class GisLabImportAreasMap extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "selectedGeometry", _descriptor, this);
    }
    get areas() {
      const areasData = this.args.areasData;
      if ((0, _utils.isEmpty)(areasData)) {
        return [];
      }
      return areasData.map(ad => ad.area);
    }
    get geodata() {
      const areasData = this.args.areasData;
      if ((0, _utils.isEmpty)(areasData)) {
        return [];
      }
      const geoms = this.args.areasData.map(areaData => areaData.area.geom);
      return geoms;
    }
    focusOnSomething(geometry) {
      this.selectedGeometry = geometry;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedGeometry", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "areas", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "areas"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "geodata", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "geodata"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusOnSomething", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "focusOnSomething"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GisLabImportAreasMap);
});