define("plutof/components/filerepository/select-or-upload", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SelectOrUploadFileModel = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! TODO: has-error }}
  <div ...attributes>
      <div class="input-group">
          {{#if @model.pendingUpload}}
              <Input
                  @type="text"
                  @value={{@model.pendingUpload.file.name}}
                  @readonly={{true}}
                  class="form-control" />
          {{else}}
              <Filerepository::AutoComplete
                  @value={{@model.selection}}
                  @update={{@model.selectExisting}}
                  @filters={{hash access="edit"}}
                  @disabled={{@disabled}} />
          {{/if}}
  
          {{#if @model.present}}
              <span class="input-group-btn">
                  <button
                      {{on "click" @model.clear}}
                      class="btn btn-default"
                  >
                      <span class={{icon "remove"}}></span>
                  </button>
              </span>
          {{else}}
              <span class="input-group-btn">
                  <FileSelector
                      @selected={{@model.selectNew}}
                      @multiple={{false}}
                      @name="General.Upload"
                      @disabled={{@disabled}} />
              </span>
          {{/if}}
      </div>
  </div>
  
  */
  {
    "id": "DRChXG5C",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n    \"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n\"],[41,[30,2,[\"pendingUpload\"]],[[[1,\"            \"],[8,[39,1],[[24,0,\"form-control\"]],[[\"@type\",\"@value\",\"@readonly\"],[\"text\",[30,2,[\"pendingUpload\",\"file\",\"name\"]],true]],null],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[39,2],null,[[\"@value\",\"@update\",\"@filters\",\"@disabled\"],[[30,2,[\"selection\"]],[30,2,[\"selectExisting\"]],[28,[37,3],null,[[\"access\"],[\"edit\"]]],[30,3]]],null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,2,[\"present\"]],[[[1,\"            \"],[10,1],[14,0,\"input-group-btn\"],[12],[1,\"\\n                \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,4],[\"click\",[30,2,[\"clear\"]]],null],[12],[1,\"\\n                    \"],[10,1],[15,0,[28,[37,5],[\"remove\"],null]],[12],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,1],[14,0,\"input-group-btn\"],[12],[1,\"\\n                \"],[8,[39,6],null,[[\"@selected\",\"@multiple\",\"@name\",\"@disabled\"],[[30,2,[\"selectNew\"]],false,\"General.Upload\",[30,3]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@model\",\"@disabled\"],false,[\"if\",\"input\",\"filerepository/auto-complete\",\"hash\",\"on\",\"icon\",\"file-selector\"]]",
    "moduleName": "plutof/components/filerepository/select-or-upload.hbs",
    "isStrictMode": false
  });
  let SelectOrUploadFileModel = _exports.SelectOrUploadFileModel = (_dec = (0, _object.computed)('selection', 'pendingUpload'), _class = class SelectOrUploadFileModel {
    constructor(_ref2) {
      let {
        fileUpload,
        selection
      } = _ref2;
      _initializerDefineProperty(this, "selection", _descriptor, this);
      _initializerDefineProperty(this, "pendingUpload", _descriptor2, this);
      this.selection = selection;
      this.fileUpload = fileUpload;
    }
    selectExisting(file) {
      this.pendingUpload = null;
      this.selection = file;
    }
    async selectNew(file) {
      try {
        // TODO: Save should wait for this
        [this.pendingUpload] = await this.fileUpload.addLocalFiles([file]);
        this.selection = null;
      } catch (err) {
        (0, _notifications.reportError)(err);
      }
    }
    clear() {
      this.pendingUpload = null;
      this.selection = null;
    }
    get size() {
      if (!this.present) {
        return 0;
      }
      return this.selection ? this.selection.size : this.pendingUpload.size;
    }

    // @computed required only because of old-style WrapperSource, which somehow fails
    // to listed to this oterwise
    get present() {
      return Boolean(this.selection || this.pendingUpload);
    }
    async save(access) {
      if (this.pendingUpload) {
        const file = await this.pendingUpload.save(access);
        this.selectExisting(file);
      }
      return this.selection;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "selection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pendingUpload", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectExisting", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectExisting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectNew", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "present", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "present"), _class.prototype), _class);
  class SelectOrUploadFile extends _component2.default {}
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SelectOrUploadFile);
});