define("plutof/models/redbook/red-list-assessment", ["exports", "@ember-data/model", "@ember/object/computed"], function (_exports, _model, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _dec52, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47, _descriptor48, _descriptor49, _descriptor50, _descriptor51, _descriptor52;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let RedListAssessment = (_dec = (0, _model.belongsTo)('redbook/red-list'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.belongsTo)('taxonomy/taxonnode'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('enum', {
    jsonAPI: true
  }), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('enum', {
    jsonAPI: true
  }), _dec8 = (0, _model.belongsTo)('agent/agent'), _dec9 = (0, _model.belongsTo)('agent/agent'), _dec10 = (0, _model.hasMany)('agent/agent'), _dec11 = (0, _model.attr)('date'), _dec12 = (0, _model.attr)('date'), _dec13 = (0, _model.attr)('date'), _dec14 = (0, _model.attr)('enum', {
    jsonAPI: true
  }), _dec15 = (0, _model.attr)('enum', {
    jsonAPI: true
  }), _dec16 = (0, _model.attr)('enum-multiple', {
    jsonAPI: true,
    defaultValue: () => []
  }), _dec17 = (0, _model.attr)('enum', {
    jsonAPI: true
  }), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('number'), _dec20 = (0, _model.attr)('number'), _dec21 = (0, _model.attr)('boolean'), _dec22 = (0, _model.attr)('number'), _dec23 = (0, _model.attr)('boolean'), _dec24 = (0, _model.attr)('boolean'), _dec25 = (0, _model.attr)('boolean'), _dec26 = (0, _model.attr)('boolean'), _dec27 = (0, _model.attr)('boolean'), _dec28 = (0, _model.attr)('boolean'), _dec29 = (0, _model.attr)('boolean'), _dec30 = (0, _model.attr)('boolean'), _dec31 = (0, _model.attr)('boolean'), _dec32 = (0, _model.attr)('string'), _dec33 = (0, _model.attr)('number'), _dec34 = (0, _model.attr)('enum', {
    jsonAPI: true
  }), _dec35 = (0, _model.attr)('enum', {
    jsonAPI: true
  }), _dec36 = (0, _model.attr)('number'), _dec37 = (0, _model.attr)('number'), _dec38 = (0, _model.attr)('string'), _dec39 = (0, _model.attr)('enum-multiple', {
    jsonAPI: true,
    defaultValue: () => []
  }), _dec40 = (0, _model.attr)('enum-multiple', {
    jsonAPI: true,
    defaultValue: () => []
  }), _dec41 = (0, _model.attr)('enum-multiple', {
    jsonAPI: true,
    defaultValue: () => []
  }), _dec42 = (0, _model.attr)('enum-multiple', {
    jsonAPI: true,
    defaultValue: () => []
  }), _dec43 = (0, _model.attr)('enum-multiple', {
    jsonAPI: true,
    defaultValue: () => []
  }), _dec44 = (0, _model.attr)('boolean'), _dec45 = (0, _model.attr)('string'), _dec46 = (0, _model.attr)('enum', {
    jsonAPI: true
  }), _dec47 = (0, _model.attr)('string'), _dec48 = (0, _model.hasMany)('habitat/community'), _dec49 = (0, _model.attr)('datetime'), _dec50 = (0, _model.attr)('datetime'), _dec51 = (0, _model.belongsTo)('users/user'), _dec52 = (0, _computed.reads)('title'), _class = class RedListAssessment extends _model.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "red_list", _descriptor, this);
      _initializerDefineProperty(this, "title", _descriptor2, this);
      _initializerDefineProperty(this, "taxon_node", _descriptor3, this);
      _initializerDefineProperty(this, "eelis_id", _descriptor4, this);
      _initializerDefineProperty(this, "category", _descriptor5, this);
      _initializerDefineProperty(this, "category_description", _descriptor6, this);
      _initializerDefineProperty(this, "status", _descriptor7, this);
      _initializerDefineProperty(this, "assessor", _descriptor8, this);
      _initializerDefineProperty(this, "reviewer", _descriptor9, this);
      _initializerDefineProperty(this, "co_assessors", _descriptor10, this);
      _initializerDefineProperty(this, "assessment_date", _descriptor11, this);
      _initializerDefineProperty(this, "due_date", _descriptor12, this);
      _initializerDefineProperty(this, "board_date", _descriptor13, this);
      // IUCN fields
      _initializerDefineProperty(this, "population_type", _descriptor14, this);
      _initializerDefineProperty(this, "preliminary_category", _descriptor15, this);
      _initializerDefineProperty(this, "iucn_criteria_a", _descriptor16, this);
      _initializerDefineProperty(this, "population_reduction", _descriptor17, this);
      _initializerDefineProperty(this, "category_a_description", _descriptor18, this);
      _initializerDefineProperty(this, "extent_of_occurrence", _descriptor19, this);
      _initializerDefineProperty(this, "area_of_occupancy", _descriptor20, this);
      _initializerDefineProperty(this, "fragmented_population", _descriptor21, this);
      _initializerDefineProperty(this, "total_populations", _descriptor22, this);
      _initializerDefineProperty(this, "declining_occupancy_extent", _descriptor23, this);
      _initializerDefineProperty(this, "declining_occupancy_area", _descriptor24, this);
      _initializerDefineProperty(this, "declining_habitat_quality", _descriptor25, this);
      _initializerDefineProperty(this, "declining_subpopulations", _descriptor26, this);
      _initializerDefineProperty(this, "declining_mature_specimens", _descriptor27, this);
      _initializerDefineProperty(this, "eoo_fluctuations", _descriptor28, this);
      _initializerDefineProperty(this, "aoo_fluctuations", _descriptor29, this);
      _initializerDefineProperty(this, "subpopulation_fluctuations", _descriptor30, this);
      _initializerDefineProperty(this, "mature_specimens_fluctuations", _descriptor31, this);
      _initializerDefineProperty(this, "category_b_description", _descriptor32, this);
      _initializerDefineProperty(this, "mature_individuals", _descriptor33, this);
      _initializerDefineProperty(this, "population_fall", _descriptor34, this);
      _initializerDefineProperty(this, "population_trend", _descriptor35, this);
      _initializerDefineProperty(this, "subpopulation_mature_individuals", _descriptor36, this);
      _initializerDefineProperty(this, "percentage_mature_individuals", _descriptor37, this);
      _initializerDefineProperty(this, "category_c_description", _descriptor38, this);
      _initializerDefineProperty(this, "iucn_threats", _descriptor39, this);
      _initializerDefineProperty(this, "conservation_actions", _descriptor40, this);
      _initializerDefineProperty(this, "conservation_actions_needed", _descriptor41, this);
      _initializerDefineProperty(this, "research_needed", _descriptor42, this);
      _initializerDefineProperty(this, "utilisation", _descriptor43, this);
      _initializerDefineProperty(this, "restricted_population", _descriptor44, this);
      _initializerDefineProperty(this, "category_d_description", _descriptor45, this);
      _initializerDefineProperty(this, "extinction_probability", _descriptor46, this);
      _initializerDefineProperty(this, "category_e_description", _descriptor47, this);
      _initializerDefineProperty(this, "iucn_habitats", _descriptor48, this);
      _initializerDefineProperty(this, "created", _descriptor49, this);
      _initializerDefineProperty(this, "modified", _descriptor50, this);
      _initializerDefineProperty(this, "modified_by", _descriptor51, this);
      _initializerDefineProperty(this, "representation", _descriptor52, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "red_list", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "taxon_node", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "eelis_id", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "category_description", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "assessor", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "reviewer", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "co_assessors", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "assessment_date", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "due_date", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "board_date", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "population_type", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "preliminary_category", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "iucn_criteria_a", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "population_reduction", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "category_a_description", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "extent_of_occurrence", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "area_of_occupancy", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "fragmented_population", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "total_populations", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "declining_occupancy_extent", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "declining_occupancy_area", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "declining_habitat_quality", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "declining_subpopulations", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "declining_mature_specimens", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "eoo_fluctuations", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "aoo_fluctuations", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "subpopulation_fluctuations", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "mature_specimens_fluctuations", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "category_b_description", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "mature_individuals", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "population_fall", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "population_trend", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "subpopulation_mature_individuals", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "percentage_mature_individuals", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "category_c_description", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "iucn_threats", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "conservation_actions", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "conservation_actions_needed", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "research_needed", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "utilisation", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "restricted_population", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "category_d_description", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "extinction_probability", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = _applyDecoratedDescriptor(_class.prototype, "category_e_description", [_dec47], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor48 = _applyDecoratedDescriptor(_class.prototype, "iucn_habitats", [_dec48], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor49 = _applyDecoratedDescriptor(_class.prototype, "created", [_dec49], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor50 = _applyDecoratedDescriptor(_class.prototype, "modified", [_dec50], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor51 = _applyDecoratedDescriptor(_class.prototype, "modified_by", [_dec51], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor52 = _applyDecoratedDescriptor(_class.prototype, "representation", [_dec52], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = RedListAssessment;
});