define("plutof/components/measurements/measurement-form", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/utils", "@glimmer/tracking", "rsvp", "@ember-decorators/component", "@ember-decorators/object", "ember-concurrency", "plutof/misc/measurements", "plutof/mixins/component-validations", "plutof/services/ajax"], function (_exports, _component, _object, _computed, _runloop, _utils, _tracking, _rsvp, _component2, _object2, _emberConcurrency, _measurements, _componentValidations, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MeasurementsData = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _dec5, _class2, _class3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let MeasurementsData = _exports.MeasurementsData = (_dec = (0, _computed.reads)('loadMeasurements.isRunning'), _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec3 = (0, _object2.observes)('mainform.id', 'object.id'), _dec4 = (0, _emberConcurrency.task)({
    enqueue: true
  }), _class = class MeasurementsData extends _object.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "loading", _descriptor, this);
      _initializerDefineProperty(this, "loadMeasurements", _descriptor2, this);
      _initializerDefineProperty(this, "_includeExternal", _descriptor3, this);
    }
    init() {
      super.init(...arguments);
      if ((0, _utils.isNone)(this.objectMeasurements)) {
        this.set('objectMeasurements', []);
      }
      this.reload();
    }
    // TODO: Most of this is probably unnecessary
    reload() {
      const mainformId = this.get('mainform.id');
      const loadedId = this.currentFormId;

      // XXX When Cloning we have objectMeasurements that we dont want to reset on the initial load.
      if ((0, _utils.isNone)(loadedId) && !(0, _utils.isEmpty)(this.objectMeasurements)) {
        this.set('currentFormId', mainformId);
        return;
      }

      // XXX Saving object triggers "loadData" meaning no measurement data is saved
      if (loadedId !== undefined && mainformId === loadedId) {
        return;
      }
      this.loadMeasurements.perform();
    }
    includeExternal(store, measurements) {
      this._includeExternal.perform(store, measurements);
    }
    save() {
      return _rsvp.default.all(this.objectMeasurements.invoke('save'));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loadMeasurements", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const store = this.store;

        // Need to wrap in resolve() here when this.mainform is an ember-data
        // relationship for some reason
        const mainform = yield _rsvp.default.Promise.resolve(this.mainform);
        const object = yield _rsvp.default.Promise.resolve(this.object);
        if ((0, _utils.isNone)(object)) {
          this.set('objectMeasurements', []);
          return;
        }
        const fields = yield (0, _measurements.get_object_form_measurements)(store, object, mainform);
        this.set('objectMeasurements', fields);
        this.set('currentFormId', this.get('mainform.id'));
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "reload", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "reload"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_includeExternal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (store, measurements) {
        yield (0, _emberConcurrency.waitForProperty)(this, 'loadMeasurements.isIdle');
        const existing = new Set(this.objectMeasurements.mapBy('measurementData.id').map(id => id.toString()));
        const object = yield this.object;
        const newOMs = measurements.filter(m => !existing.has(m.id)).map(measurementData => _measurements.ObjectMeasurement.create({
          ajax: (0, _ajax.ajaxFromControlled)(store),
          store,
          object,
          measurementData,
          isExternal: true
        }));
        this.objectMeasurements.pushObjects(newOMs);
      };
    }
  }), _class);
  let MeasurementForm = (_dec5 = (0, _component2.classNames)('measurement-form'), _dec5(_class2 = (_class3 = class MeasurementForm extends _component.default.extend(_componentValidations.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "expandedMeasurement", _descriptor4, this);
      _initializerDefineProperty(this, "expandedMeasurementValidations", _descriptor5, this);
      _initializerDefineProperty(this, "modalOpen", _descriptor6, this);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      (0, _runloop.once)(this, this.loadData);
    }
    loadData() {
      if (this.data.isDestroyed) {
        return;
      }

      // TODO: Think about just padding object and mainform to data.reload()
      this.data.setProperties({
        object: this.object,
        mainform: this.mainform
      });
    }
    expand(measurement, context) {
      this.expandedMeasurement = measurement;
      this.expandedMeasurementValidations = context;
      this.modalOpen = true;
    }
    collapse() {
      this.modalOpen = false;
    }
  }, _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "expandedMeasurement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "expandedMeasurementValidations", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "modalOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "expand", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "expand"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "collapse", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "collapse"), _class3.prototype), _class3)) || _class2);
  var _default = _exports.default = MeasurementForm;
});