define("plutof/components/my-menu/-measurement", ["exports", "@ember/component", "@ember/object", "@ember/service", "ember-data", "@ember-decorators/component", "ember-concurrency"], function (_exports, _component, _object, _service, _emberData, _component2, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let MyMenuMeasurement = (_dec = (0, _component2.tagName)('li'), _dec2 = (0, _object.computed)('personalMenu.selectedMeasurements.[]', 'result.id'), _dec3 = (0, _object.computed)('result.id'), _dec4 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec(_class = (_class2 = class MyMenuMeasurement extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "personalMenu", _descriptor, this);
      _defineProperty(this, "infoOpen", false);
      _initializerDefineProperty(this, "update", _descriptor2, this);
    }
    get isSelected() {
      const id = this.result.id.toString();
      return this.personalMenu.selectedMeasurements.some(m => m.id === id);
    }
    get fullMeasurement() {
      return _emberData.default.PromiseObject.create({
        promise: this.store.findRecord('measurement/measurement', this.result.id)
      });
    }
    toggleInclusion() {
      this.update.perform();
    }
    toggleInfo() {
      this.toggleProperty('infoOpen');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "personalMenu", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isSelected", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "isSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "fullMeasurement", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "fullMeasurement"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "update", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const measurement = yield this.fullMeasurement;
        if (this.isSelected) {
          this.personalMenu.selectedMeasurements.removeObject(measurement);
        } else {
          this.personalMenu.selectedMeasurements.pushObject(measurement);
        }
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "toggleInclusion", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleInclusion"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleInfo", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleInfo"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = MyMenuMeasurement;
});