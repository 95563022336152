define("plutof/routes/conservation-lab/red-list-assessment/view", ["exports", "plutof/utils/routes", "plutof/utils/access"], function (_exports, _routes, _access) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class RedListAssessmentViewRoute extends _routes.ViewRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "defaultReturnRoute", 'conservation-lab.red-list-assessment.index');
      _defineProperty(this, "title", 'conservation.redListAssessment.view.title');
    }
    async model(_ref) {
      let {
        id
      } = _ref;
      const assessment = await this.store.findRecord('redbook/red-list-assessment', id);
      const permissions = await (0, _access.groupBasedPermissions)(this.store, assessment, {
        getManagingGroup: () => assessment.red_list.then(redlist => redlist && redlist.workgroup)
      });
      return {
        assessment,
        permissions
      };
    }
  }
  var _default = _exports.default = RedListAssessmentViewRoute;
});