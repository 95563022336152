define("plutof/templates/livingspecimen/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "P66iAH0I",
    "block": "[[[10,0],[14,0,\"route-marker-livingspecimen-add\"],[14,\"data-test\",\"route-livingspecimen.add\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoContent\",\"@goBack\"],[[28,[37,1],[\"livingspecimen\"],null],[28,[37,2],[\"LivingCultures.newLivingC\"],null],[30,0,[\"model\",\"strain\"]],[30,0,[\"routeActions\",\"triggerReset\"]],\"information/content/living-specimen/add\",[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@model\",\"@quickFormChoices\",\"@validationContext\",\"@showFormSelector\",\"@usingDefaultForm\",\"@allowAreaReset\",\"@preserveZoom\"],[[30,0,[\"model\",\"livingSpecimenModel\"]],[30,0,[\"plutofMainforms\"]],[30,0,[\"validationContext\"]],true,[30,0,[\"model\",\"usingDefaultForm\"]],true,[30,0,[\"preserveZoom\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[8,[39,6],null,[[\"@save\",\"@clone\",\"@saveAndReset\",\"@cancel\",\"@validationContext\"],[[30,0,[\"save\"]],[30,0,[\"saveAndClone\"]],[30,0,[\"saveAndReset\"]],[30,0,[\"routeActions\",\"goBack\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"livingspecimen/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"save-panel\"]]",
    "moduleName": "plutof/templates/livingspecimen/add.hbs",
    "isStrictMode": false
  });
});