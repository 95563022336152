define("plutof/modifiers/on-click-outside", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberModifier.modifier)((element, _ref) => {
    let [callback] = _ref;
    function handleClick(event) {
      if (!element.contains(event.target)) {
        callback(event);
      }
    }
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
});