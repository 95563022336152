define("plutof/models/contenttype", ["exports", "@ember/object", "@ember/object/computed", "@ember-data/model", "plutof/models/plutof-model"], function (_exports, _object, _computed, _model, _plutofModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fullModelName = fullModelName;
  _exports.shortModelName = shortModelName;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /*
      Back-end makes cool-looking endpoints such as
      collection-lab/condition-assessment for better readability. For Ember data,
      we copy these patterns. But in the contenttype result (model, applabel) are
      different than we Expect: dna-extraction - Received: dnaextraction.
  
      Another issue - counter proccedure. We have an ember record and we need to
      find contentype with filter ?model={record.constructor.modelName} Expected:
      dnaextraction - Received: dna-extraction
  */
  // TODO: Use utils/modules are a source of truth for all this; though the
  // end game is to remplace ctype models with ctype strings app_label/model
  // in the API
  const MODEL_NAME_EXCEPTIONS = {
    'conditionassessment': 'condition-assessment',
    'dnaextraction': 'dna-extraction',
    'redlist': 'red-list',
    'redlistassessment': 'red-list-assesment',
    'layerarea': 'layer-area'
  };
  const LABEL_EXCEPTIONS = {
    'dnalab': 'dna-lab',
    'collection_lab': 'collection-lab',
    'taxonomylab': 'laboratory/taxonomylab',
    // Taxon occurrence models...
    'materialsample': 'taxonoccurrence/materialsample',
    'referencebased': 'taxonoccurrence/referencebased',
    'livingculture': 'taxonoccurrence/livingculture',
    'interaction': 'taxonoccurrence/interaction',
    'observation': 'taxonoccurrence/observation',
    'specimen': 'taxonoccurrence/specimen',
    'sequence': 'taxonoccurrence/sequence',
    'auth': 'users'
  };
  function fullModelName(app_label, model) {
    if (LABEL_EXCEPTIONS[app_label]) {
      app_label = LABEL_EXCEPTIONS[app_label];
    }
    if (MODEL_NAME_EXCEPTIONS[model]) {
      model = MODEL_NAME_EXCEPTIONS[model];
    }
    return app_label + '/' + model;
  }
  function shortModelName(model) {
    const parts = model.split('/');
    if (parts.length > 2) {
      return parts[parts.length - 2] + '/' + parts[parts.length - 1];
    } else {
      return model;
    }
  }
  let ContentType = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _object.computed)('app_label', 'model'), _dec5 = (0, _object.computed)('app_label', 'model'), _dec6 = (0, _computed.reads)('name'), _class = class ContentType extends _plutofModel.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "name", _descriptor, this);
      _initializerDefineProperty(this, "app_label", _descriptor2, this);
      _initializerDefineProperty(this, "model", _descriptor3, this);
      _initializerDefineProperty(this, "representation", _descriptor4, this);
    }
    get full_name() {
      return this.app_label + '/' + this.model;
    }
    get model_name() {
      let model = this.model;
      let app_label = this.app_label;
      return fullModelName(app_label, model);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "app_label", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "full_name", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "full_name"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "model_name", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "model_name"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "representation", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  ContentType.reopenClass({
    cacheable: true
  });
  var _default = _exports.default = ContentType;
});