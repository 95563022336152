define("plutof/components/layer/edit", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Layer::GeneralData
      @layer={{@layer}}
      @validationContext={{@validationContext.generalData}} />
  
  <TabGroup
      @selected={{this.selectedTab}}
      @tabSwitched={{this.switchTab}}
  >
      <:tabs as |tabGroup|>
          <Layer::Areas
              @areas={{@areas}}
              @tabGroup={{tabGroup}}
              @validationContext={{@validationContext.areas}} />
  
          <Layer::Map
              @layer={{@layer}}
              @tabGroup={{tabGroup}}
              @updateFilter={{this.updateFilter}} />
      </:tabs>
  </TabGroup>
  
  */
  {
    "id": "Ymr2OpLj",
    "block": "[[[8,[39,0],null,[[\"@layer\",\"@validationContext\"],[[30,1],[30,2,[\"generalData\"]]]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@selected\",\"@tabSwitched\"],[[30,0,[\"selectedTab\"]],[30,0,[\"switchTab\"]]]],[[\"tabs\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@areas\",\"@tabGroup\",\"@validationContext\"],[[30,4],[30,3],[30,2,[\"areas\"]]]],null],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@layer\",\"@tabGroup\",\"@updateFilter\"],[[30,1],[30,3],[30,0,[\"updateFilter\"]]]],null],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\"]],[\"@layer\",\"@validationContext\",\"tabGroup\",\"@areas\"],false,[\"layer/general-data\",\"tab-group\",\"layer/areas\",\"layer/map\"]]",
    "moduleName": "plutof/components/layer/edit.hbs",
    "isStrictMode": false
  });
  let LayerEdit = _exports.default = (_class = class LayerEdit extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      _initializerDefineProperty(this, "selectedTab", _descriptor2, this);
      this.args.validationContext.addChild('generalData', this.i18n.translate('General.generalData'));
      this.args.validationContext.addChild('areas', this.i18n.translate('Layer.tabs.areas'));
    }
    updateFilter(filter) {
      this.args.areas.filter = filter;
      this.args.areas.updateFilter();
      this.switchTab('areas');
    }
    switchTab(tabName) {
      this.selectedTab = tabName;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedTab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'map';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchTab", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchTab"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LayerEdit);
});