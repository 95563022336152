define("plutof/components/file-selector", ["exports", "@ember/component", "@ember-decorators/component", "plutof/helpers/icon", "plutof/misc/fileupload"], function (_exports, _component, _component2, _icon, _fileupload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  let FileSelector = (_dec = (0, _component2.tagName)('button'), _dec2 = (0, _component2.classNameBindings)('classes'), _dec3 = (0, _component2.attributeBindings)('disabled'), _dec(_class = _dec2(_class = _dec3(_class = class FileSelector extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "icon", (0, _icon.icon)('upload'));
      _defineProperty(this, "classes", 'btn btn-default');
      _defineProperty(this, "multiple", false);
    }
    click() {
      (0, _fileupload.selectFile)({
        multiple: this.multiple,
        accept: this.accept
      }).then(file => {
        if (file) {
          this.selected(file);
        }
      });
    }
  }) || _class) || _class) || _class);
  var _default = _exports.default = FileSelector;
});