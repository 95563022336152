define("plutof/translations/est/clipboard", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    all: 'kõik',
    removeMissing: 'Eemalda viited puuduvatele kirjetele ({{count}})',
    removeInvalid: 'Eemalda kõik vigased viited',
    removeMissingTooltip: 'Eemalda kustutatud kirjete näpitstahvli viited.',
    showInvalid: 'Kuva vigased näpitstahvli viited ({{count}})',
    showInvalidTooltip: `Näpitstahvli viited kirjetele, mis pole hekel kasutatavad, kuna neil puudub
    otsitav informatsioon. Probleemi püsimisel palun võtta ühendust kasutajatoega.`,
    saveToFile: 'Eksport',
    showOnMap: 'Kuva kaardil',
    loadFullMap: 'Kuva kõik',
    unloadFullMap: 'Kuva ainult valitud',
    analysis: 'Analüüsid',
    yourAnalysis: 'Minu analüüsid',
    toAnalysis: 'Analüüsima',
    sequenceTitle: 'Sekventsid',
    specimenTitle: 'Eksemplarid',
    observationTitle: 'Vaatlused',
    photobankTitle: 'Fotopanga objektid',
    livingspecimenTitle: 'Eluseksemplarid',
    sequenceNavbarTitle: 'Sekventsi näpitstahvel',
    specimenNavbarTitle: 'Eksemplari näpitstahvel',
    observationNavbarTitle: 'Vaatluse näpitstahvel',
    photobankNavbarTitle: 'Fotopanga näpitstahvel',
    livingspecimenNavbarTitle: 'Eluseksemplari näpitstahvel',
    materialSampleTitle: 'Proovid',
    refbased: {
      title: 'Kirjanduspõhised esinemised',
      fullTitle: 'Kirjanduspõhiste esinemiste näpitstahvel',
      info: 'Valitud kirjanduspõhiste esinemiste vaatamine, haldamine, analüüsimine ja eksport.'
    },
    select: 'Vali',
    selected: 'Valitud',
    pages: 'Leheküljed',
    noLocationData: 'Puudub leiukoha info',
    groupBy: 'Grupeeri',
    massBLASTer: 'massBLASTer',
    megaBlastDataset: 'Blast multiple sequences against UNITE, UNITE Envir. or annotated INSD datasets using Mega BLAST algorithm.',
    ITSx: 'ITSx',
    ExtractITS: 'Extract ITS1 and ITS2 from ribosomal ITS sequences of fungi and other eucaryotes for use in environmental sequencing.',
    assignFungal: 'Assign fungal ITS sequences to UNITE Species Hypotheses.',
    seqAnalysis: 'Sekventside analüüsid',
    yourRuns: 'Minu analüüsid',
    specimenInfo: 'Valitud eksemplaride vaatamine, haldamine, analüüsimine ja eksport.',
    livingspecimenInfo: 'Valitud eluseksemplaride vaatamine, haldamine, analüüsimine ja eksport.',
    materialsampleInfo: 'Valitud proovide vaatamine, haldamine, analüüsimine ja eksport.',
    sequenceInfo: 'Valitud sekventside vaatamine, haldamine, analüüsimine ja eksport.',
    observationInfo: 'Valitud vaatluste vaatamine, haldamine, analüüsimine ja eksport.',
    photobankInfo: 'Valitud objektide vaatamine, haldamine, analüüsimine ja eksport.',
    exportMethod: 'Ekspordi meetod',
    genBankExport: 'GenBank',
    genBankFile: 'Alusfail (.sbt)',
    exportAllFields: 'Kõik väljad',
    exportedToFile: 'Fail salvestati failide repositooriumi nimega',
    exportFinishesLater: `Suure hulga kirjete eksportimine on ajamahukas.
    Teavitus töö lõppemisest saadetakse Teie e-postile.`,
    editFinishesLater: `Suure hulga kirjete muutmine on ajamahukas.
    Teavitus töö lõppemisest saadetakse Teie e-postile.`,
    exportFormatCsv: 'CSV',
    exportFormatListOfSpecies: 'Liiginimekiri',
    exportFormatGenBank: 'Geenipanga (GenBank) üleslaadimisfail',
    exportFormatFasta: 'FASTA',
    exportFormatDarwinCore: 'Darwin Core Archive',
    exportFormatIndexSeminum: 'Index Seminum',
    export: {
      csv: {
        layers: 'Kihid'
      }
    },
    bulkOperations: 'Tegevused mitme kirjega',
    bulkRemove: 'Eemalda',
    bulkAdd: 'Lisa',
    bulkEdit: 'Muuda',
    bulkDelete: 'Kustuta',
    bulkAreaSwitch: 'Vaheta ala',
    bulkAreaEdit: 'Muuda ala',
    bulkActiveInputs: 'Aktiivsed väljad',
    bulkDeleteAll: 'Kustuta kõik',
    labelFormat: 'Etiketi formaat',
    paperSize: 'Paberi mõõt',
    labelsIncludeProjectInfo: 'Projekti info',
    labelsIncludeAreaName: 'Prooviala nimi',
    labelsUsePredefinedArea: 'Kasuta eeldefineeritud ala nime',
    labelsIncludeAllGAs: 'Kõik kogujad',
    labelsIncludeAllDeterminers: 'Kõik määrajad',
    labelsUseAdditionalName: 'Kasuta täpsustatud taksoni nime',
    labelsUseValidName: 'Asenda kehtiva taksoni nimega, kui sisestatud sünonüümina',
    labelsUsePhenophase: 'Kaasa fenofaas',
    labelsRemarksNull: 'Jäta tühjaks',
    labelsRemarksGeneral: 'Üldised märkused',
    labelsRemarksIdentification: 'Määrangu märkused',
    labelsRemarksBoth: 'Mõlemad märkused',
    primaryCode: 'Primaarne kood',
    groupCritNone: 'Ära grupeeri',
    groupCritIsolationSource: 'DNA päritolu järgi',
    groupCritHabitatType: 'Elupaiga tüübi järgi',
    coordinateFormat: 'Koordinaadi formaat',
    createdAt: 'Lisatud näpitstahvlile',
    updateSource: (0, _helpers.alias)('Sequences.source'),
    loading: 'Laadin...',
    loadingProject: 'Laadin projekti...',
    loadingRecords: 'Laadin kirjeid...',
    savingArea: 'Salvestan prooviala ja kogumise andmeid...',
    savingAccess: 'Salvestan ligipääsu õiguseid...',
    savingRecords: 'Salvestan kirjeid...',
    removingEntries: 'Eemaldan näpitstahvli viiteid...',
    clipboardUsage: 'Näpitstahvli kasutus',
    clipboardUsageTooltip: 'Viiteid näpitstahvlil ja näpitstahvli limiit',
    orderingDisabled: 'Sorteermine pole saadaval kui näpitstahvli kasutus ületab {{limit}} viite.',
    labelStrings: {
      locality: 'Leiukoht',
      substrate: 'Substraat',
      habitat: 'Elupaik',
      latitude: 'Lat.',
      longitude: 'Long.',
      altitude: 'Alt.',
      collectors: 'Koguja(d):',
      determiners: 'Määraja(d):',
      idprim: 'Eksemplari nr:',
      sequenceTypes: 'Sekveneeritud: ',
      photo: 'Foto andmebaasis',
      latlon: 'Laius/Pikkus',
      project: 'Projekt',
      remarks: 'Märkused',
      attribution: 'Prinditud PlutoF kaudu',
      additionalids: 'Teisesed koodid:',
      andothers: 'jt.',
      eventdate: 'Leiuaeg',
      taimeatlasSquare: 'Taimeatlase ruut',
      ELM: {
        idprim: '',
        collectors: 'leg:',
        determiners: 'det:'
      },
      TA: {
        collectors: 'Leg:',
        determiners: 'Det:'
      }
    },
    labelPrinting: {
      labelPrinting: 'Etikettide printimine',
      willBeAsync: 'Rohkem kui 250 etiketi printimine võtab aega. Etikettide valmimisest teavitame teid e-kirjaga.',
      timedOut: 'Etikettide printimine võttis liiga palju aega ja katkestati. Proovi printida väiksemate osadena.',
      downloadStarted: 'Allalaadimine...'
    },
    gbif: {
      title: 'GBIF publitseerimine',
      dataset: 'Andmestik',
      description: 'Lisa või eemalda GBIF andmestiku kirjeid.',
      mode: {
        append: {
          label: 'Lisa',
          tooltip: 'Lisa kirjed GBIF andmestikku.'
        },
        overwrite: {
          label: 'Kirjuta üle',
          tooltip: 'Eemalda olemasolevad kirjed GBIF andmestikust ja lisa uued kirjed.'
        },
        remove: {
          label: 'Eemalda',
          tooltip: 'Eemalda kirjed GBIF andmestikest.'
        }
      }
    },
    delete: {
      title: 'Kustuta kirjed',
      result: {
        sync: '{{removed}} kirjeid kustutatud, {{unremoved}} kirjeid ei saanud kustutada.',
        async: 'Suure hulga kirjete kustutamine on ajamahukas. Teavitus töö lõppemisest saadetakse Teie e-postile.'
      }
    },
    dnaExtraction: {
      addSelected: 'Lisa valitud',
      description: 'Lisa valitud kirjed (kuni 96) uute, või olemasolevasse, DNA ekstraheerimise eksperimenti.',
      title: 'DNA ekstraheerimine',
      new: 'Uus eksperiment',
      existing: 'Olemasolev eksperiment',
      laboratory: 'Laboratoorium',
      experiment: 'Eksperiment'
    },
    bulkUpdate: {
      description: 'Kirjuta üle või kustuta kirjete väärtuseid.',
      fields: {
        sequence: {
          unitestatus: 'Taotle UNITE koodi'
        }
      },
      panels: {
        conditionAssessments: 'Hinnangud seisundile'
      },
      info: {
        preparations: 'Lisa kirjetele uus prepareerimise tüüp.',
        conditionAssessments: 'Anna eksemplaride seisundile uus hinnang.'
      }
    },
    transaction: {
      options: {
        new: 'Create a new transaction',
        existing: 'Use existing transaction'
      },
      add: 'Lisa valitud',
      create: 'Create transaction',
      description: 'Lisa valitud kirjed transaktsioonile.',
      isUpdating: `Valitud transaktsiooni objekte uuendatakse. Uusi objekte
            saab lisada protsessi lõppedes.`,
      title: 'Transaktsioon',
      transaction: 'Transaktsioon'
    },
    actions: {
      addAll: {
        label: 'Kõik',
        tooltip: 'Saada kõik kirjed näpitstahvlile'
      },
      addPage: {
        label: 'Lehekülg',
        tooltip: (0, _helpers.alias)('Search.clipboard.sendPageToClipboard')
      },
      remove: {
        label: 'Eemalda näpitstahvlilt ({{count}})'
      },
      startCA: {
        label: 'Uus hinnang ({{count}})'
      },
      startTreatment: {
        label: 'Uus hooldus ({{count}})'
      },
      startBLASTSHMatching: {
        label: 'Uus BLAST liigihüpoteeside määrangute analüs ({{count}})'
      },
      startSHMatching: {
        label: 'Uus liigihüpoteeside määrangute analüs ({{count}})'
      },
      printLabels: {
        label: 'Etikettide printimine ({{count}})'
      },
      saveAssociatedData: {
        label: 'Salvesta ({{count}})'
      },
      bulkUpdate: {
        label: 'Uuenda'
      },
      deleteRecords: {
        label: 'Kustutda kirjed'
      },
      export: {
        label: 'Ekspordi'
      },
      bulkAnnotate: {
        label: 'Annoteeri'
      }
    },
    count: {
      some: '({{count}})',
      all: '(kõik {{count}})'
    },
    citation: {
      dialogTitle: 'Andmestiku kasutamisel viidake',
      citation: 'Tsiteerimine',
      copy: 'Kopeerida lõikelauale',
      copied: 'Kopeeritud'
    }
  };
});